import React from 'react';
import _ from "lodash";
import moment from "moment-jalaali";
import { api } from "services";
import { FormattedSimpleMsg, durationToSecond } from "GlobalFunctions";
import { filterAccess } from "./frontEndAccess";
import { existsList } from "GlobalVariables";
import MessageData from "../messageData/MessageData";
import NotAccessPageView from "./NotAccessPageView";


let allUserPermissions = null;
let userInfo;

/**
 * keep user permissions that called on app
 * @type {Array}
 */
let accessCache = [];

/**
 * keep user allowed time
 * @type {{}}
 */
let allowedTimeCache = {};

/**
 * is current user Unlimited?
 * @type {null}
 */
let isUnlimitedUser = null;

export const getAllPermissions = () => {

	// this.userInfo = api.userInfo();
	// const userInfo = api.userInfo();

	let permissions = null;

	if (userInfo && !_.isUndefined(userInfo.user) && !_.isUndefined(userInfo.user.permissions)) {

		permissions = userInfo.user.permissions;

	}



	return permissions;

};

let differentTimestamp = null;

/**
 * This functions is to fix client side time using difference server time
 * @returns {*}
 */
export const getDiffTimestamp = () => {

	if (_.isNull(differentTimestamp)) {
		differentTimestamp = localStorage.getItem("differentTimestamp");
	}

	return differentTimestamp;

};

/**
 * Manage user permissions on app
 */
class UserAccess extends React.Component {

	static jalaliFormat = "jYYYY-jMM-jDD HH:mm";

	constructor(props) {
		super(props);

		const { can, type, validations } = this.props;

		this.state = {
			hasAccess: UserAccess.can(can, type, validations)
		};

		userInfo = api.userInfo();

    }
    
    /**
     * بررسی وضعیت نا محدود بودن زمان در دسترسی کاربر
     */
    static isUnlimitedTime(){
        const access = UserAccess.get("SearchTimeInterval", "SearchTimeInterval");
        const accessDuration = UserAccess.get("SearchTimeDuration", "SearchTimeDuration");

        if (!access?.int_value_1 && !accessDuration?.int_value_1) {
            return true;
        }
        return false;
    }

	/**
	 * check if user is unlimited or no
	 * @returns {*}
	 */
	static isUnlimitedAccess() {

		//  return isUnlimitedUser === "yes";

		if (!userInfo){
			userInfo = api.userInfo();
		}
		if (!userInfo){
			return true
        }

		if (!isUnlimitedUser) {

			// const userInfo = this.userInfo;

			if (userInfo && !_.isUndefined(userInfo.user) && !_.isUndefined(userInfo.user.department)) {
				isUnlimitedUser = userInfo.user.department.unlimited_access && userInfo.user.department.unlimited_access === 1 ? "yes" : "no";
			}

        }

		return isUnlimitedUser === "yes";
	}

	/**
	 * if user has access to one or more user
	 * @returns {boolean}
	 */
	static accessToEntities() {
		let can = false;
		existsList.forEach(entity => {
			if (UserAccess.can(entity.replace("extracted_", ""), "EntityResources")) {
				can = true;
			}
		});
		return can;
	}

	/**
	 * If user can access to filters in search and box filters
	 * @param key
	 * @param type
	 * @returns {boolean}
	 */
	static canFilter(key, type = "FrontEnd") {

		if (filterAccess[key]) {
			return UserAccess.can(filterAccess[key], type);
		} else if (key === "exists") {
			return UserAccess.accessToEntities();
		}

		return true;
	}

	/**
	 * get a access of user
	 * @param ability
	 * @param type
	 * @returns {boolean}
	 */
	static get(ability, type) {

		/**
		 * Optimize performance for prevent calculate permissions on each component because it's static and don't need to recalculate
		 */
		if (!allUserPermissions) {
			allUserPermissions = getAllPermissions();
		}

		const permissions = allUserPermissions;

		return permissions && !_.isUndefined(permissions[type]) && !_.isUndefined(permissions[type][ability]) ? permissions[type][ability] : false;

	}

	/**
	 * if current user has a special ability
	 * @param ability
	 * @param type
	 * @param validations
	 * @returns {boolean}
	 */
	static can(ability, type, validations = {}) {

		const { hasValidCount = false, count, hasValidDate = false, from, to } = validations;

		const resCatch = accessCache.find(item => item.type === type && item.ability === ability && (!hasValidCount || item.count === count));

		if (resCatch) {
			return resCatch.hasPermission
		}

		let dynamicProps = {};

		if (hasValidCount) {
			dynamicProps.count = count;
		}

		/**
		 * if user be a Unlimited Access User, he/she can do everything in app
		 */
		if (UserAccess.isUnlimitedAccess()) {
			accessCache.push({
				ability,
				type,
				hasPermission: true,
				...dynamicProps
			});
			return true;
		}

		const access = UserAccess.get(ability, type);

		let hasPermission = access && access.permission === 1;

		if (hasValidCount && access) {
			hasPermission = hasPermission && count < access.int_value_1;
		}

		if (hasValidDate && access) {
			hasPermission = hasPermission && from >= access.int_value_1 && to <= access.int_value_2;
		}

		accessCache.push({
			ability,
			type,
			hasPermission,
			...dynamicProps
		});

		return hasPermission;

	}

	/**
	 * get Allowed Time for current user
	 * @returns {*}
	 */
	static getAllowedTime() {

		if (allowedTimeCache && allowedTimeCache.from) {
			return allowedTimeCache;
		}

		const access = UserAccess.get("SearchTimeInterval", "SearchTimeInterval");

		let from, to;

		if (!access.int_value_1) {

			const accessDuration = UserAccess.get("SearchTimeDuration", "SearchTimeDuration");

			if (!accessDuration.int_value_1) {
				return {};
			}

			to = moment().unix() + getDiffTimestamp();
			from = to - accessDuration.int_value_1;

		} else {
			from = access.int_value_1;
			to = access.int_value_2;
		}

		allowedTimeCache = {
			from,
			to
		};

		return {
			from,
			to
		};

	}

	/**
	 * Is allow given Time Duration
	 * @param duration
	 * @returns {boolean}
	 */
	static isAllowTimeDuration(duration) {

		if (UserAccess.isUnlimitedAccess()) {
			return true;
		}

        const access = UserAccess.get("SearchTimeInterval", "SearchTimeInterval");
        
        const timeDuration = durationToSecond(duration);
        
		if (!access.int_value_1) {
            
            const accessDuration = UserAccess.get("SearchTimeDuration", "SearchTimeDuration");
			
			if (!accessDuration.int_value_1) {
				return true;
			}

			return timeDuration <= accessDuration.int_value_1;

		} else {
			/**
			 * We need to sync this with server time
			 */
			const now = moment().unix() + getDiffTimestamp();
			const from = access.int_value_1;
			const to = access.int_value_2;
			const allowedTo = now;
			const allowedFrom = allowedTo - timeDuration; // console.log( "--from--to--allowedTo---allowedFrom--" , allowedFrom - from , allowedTo - to );

			return from && allowedFrom >= from && (!to || allowedTo <= to);
		}

	}

	/**
	 * get Time Range Access a user
	 */
	static getTimeRangeAccess() {

		// disable TimeRange Access:
		return {};

		if (UserAccess.isUnlimitedAccess()) {
			return {};
		}

		const { from, to } = UserAccess.getAllowedTime();

		let result = {};

		const { jalaliFormat } = UserAccess;

		if (from) {
			result.disableStartDate = moment(moment.unix(from).format(jalaliFormat), jalaliFormat);
		}

		if (to) {
			result.disableEndDate = moment(moment.unix(to).format(jalaliFormat), jalaliFormat);
		}

		return result;
	}

	//  برای دریافت لیست مجاز برای یک تایپ از پرمیژن
	static getAllowedParams(type){

		const allPermission = getAllPermissions();
		const data = allPermission[type];

		let allowedList = [];

		if(!_.isEmpty(data)){
			allowedList = Object.keys(data).filter(item => data[item].permission === 1)
		}
		
		return allowedList

	}

	componentDidUpdate(prevProps) {
		const { can, type, validations } = this.props;
		if (prevProps.can !== can || prevProps.type !== type || !_.isEqual(prevProps.validations, validations)) {
			this.setState({
				hasAccess: UserAccess.can(can, type, validations)
			});
		}
	}

	render() {

		const { children, showMessage, customMessage, messageType } = this.props;
		const { hasAccess } = this.state;
	    /**
	     * if current user can access to this ability we show that ability for her/him
	     */
		if (hasAccess) {

			return (
				<>
					{children}
				</>
			);

		}

	    /**
	     * show message for end users if we need to it
	     * show message if user don't have a special ability
	     */
		if (showMessage) {

			if (messageType === "full-page") {
				return <NotAccessPageView />
			}

			return (
				<>

					{messageType === "alert" ? (
						<section className="app-section-not-access">
							<div className="not-access-container">
								<div className="page-inner">
									<MessageData
										icon="follower"
										description={customMessage ? customMessage : (<FormattedSimpleMsg id="global.access_level_forbidden" />)}
									/>
								</div>
							</div>
						</section>
					) : (
							<section className="app-page-not-access">
								<div className="page-inner">
									<MessageData
										icon="follower"
										description={customMessage ? customMessage : (<FormattedSimpleMsg id="global.access_level_forbidden" />)}
									/>
								</div>
							</section>
						)}

				</>
			);

		}

		return null;

	}

}

UserAccess.defaultProps = {
	showMessage: false,
	validations: {},
	messageType: "alert"
};

export default UserAccess;


