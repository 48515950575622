import Enlang from './entries/en-US';
import FaLang from './entries/fa_IR';
import ArLang from './entries/ar-IQ';
import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: Enlang,
  fa: FaLang,
  ar: ArLang
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.fa.data);
addLocaleData(AppLocale.ar.data);

export default AppLocale;
