import {
    BOX_CHANGE_PUBLIC_SETTINGS,
    BOX_SET_STEP,
	WIDGET_RESIZE_STOP,
	DASHBOARD_SAVE_CACHE,
	DASHBOARD_UPDATE_CACHE,
	DASHBOARD_RESET_CACHE
} from '../actions/types';
import _ from "lodash";

const initialState = {
    step: 0,
    public_setting: {
        box_type: 'table',
        box_title: "",
        tags: []
    },
	widgetResizeStopKey : "",
	//dashboardDataCache : []
};

const boxReducer = (state = initialState, { type, payload }) => {
    switch (type) {

	    case WIDGET_RESIZE_STOP:
		    state = {
			    ...state,
			    widgetResizeStopKey: payload
		    };
		    break;

/*	    case DASHBOARD_SAVE_CACHE:  console.log("-----state.dashboardDataCache-----" , state.dashboardDataCache);

		    state = {
			    ...state,
			    dashboardDataCache: [
				    ...state.dashboardDataCache,
				    {
					    id: payload.id,
					    params: payload.params
				    }
			    ]
		    };
		    break;

	    case DASHBOARD_UPDATE_CACHE:
		    state = {
			    ...state,
			    dashboardDataCache: state.dashboardDataCache.map( item => {
				    if( item.id === payload.id ){
					    item.result = payload.result;
				    }
				    return item;
			    })
		    };
		    break;

	    case DASHBOARD_RESET_CACHE:
		    state = {
			    ...state,
			    dashboardDataCache: []
		    };
		    break;*/

        //تغییر تنظیمات عمومی
        case BOX_CHANGE_PUBLIC_SETTINGS:
            state = {
                ...state,
                public_setting: payload
            };
            break;

        //ست کردن استپ در هر مرحله از ساخت باکس
        case BOX_SET_STEP:
            state = {
                ...state,
                step: payload
            };
            break;

        default:
            break;

    }
    return state;
};

export default boxReducer;