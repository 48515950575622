import React, { Component } from "react";
import propTypes from "prop-types";
import { FormattedDate, FormattedNumber, injectIntl } from "react-intl";
import { sanitize } from "dompurify";
import Scrollbar from "react-scrollbars-custom";
import { randomBgColor, charAvatar, getPlatforms } from "GlobalFunctions";
import _ from "lodash";
import { imagesStaticUrl } from "GlobalVariables";
import Loadings from "shared/loadings/Loadings";
import "./AccountInfoPopover.scss";
import { UiPopover } from "ui-components";

class AccountInfoPopover extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageLoaded: true,
        };

        this.addDefaultSrc = this.addDefaultSrc.bind(this);
    }

    addDefaultSrc() {
        this.setState({
            imageLoaded: false,
        });
    }

    handleAvatarPath(avatar) {
        return !!avatar && avatar.includes("http") ? avatar : imagesStaticUrl + avatar;
    }

    render() {
        const { intl, children, trigger, mouseEnterDelay, className, data, platform, platformName } = this.props;
        if (_.isEmpty(data)) {
            return;
        }
        const user = {
            avatar: data["relation.avatar"] || data["avatar"] || data["src"] || "",
            bio: data["bio"] || data["relation.bio"],
            full_name:
                data["relation.title"] ||
                data["relation.full_name"] ||
                data["relation.name"] ||
                data["title"] ||
                data["name"],
            username: data["relation.username"] || data["username"],
            // id: data["id"],
            image: data["image"] || data["relation.image"],
            about: data["about"] || data["relation.description"],

            follower_count: data["follower_count"],
            following_count: data["following_count"],
            member_count: data["member_count"],
            comment_count: data["comment_count"] || null,
            post_count: data["post_count"],
            tweets: data["tweets"] || data["relation.tweets"],
            retweets: data["retweets"] || data["copy_count_range"] || null,
            // like_count: data["like_count"] || data["favorite_count"] || null,
            // view_count: data["view_count"] || null,
            // media_count: data["media_count"] || data["relation.media_count"],

            relationid: data["relationid"] || data["relation.id"] || data["id"],
            // relationinfluence: data["relationinfluence"] || data["relation.influence"],
            relationRobot: data["relation.robot_see_date"] || data["robot"] || data["robot_see_date"],

            newsinfluence: data["newsinfluence"] || data["news_influence"],
            influence: data["influence"],

            // is_private: data["is_private"] || data["relation.is_private"],
            // is_verified: data["is_verified"] || data["relation.is_verified"],

            language: data["lang"] || data["relation.language"],
            // media_type: data["media_type"],
            // message_type: data["message_type"] || data["media_type"],

            // robot: data["robot"] || data["robot_see_date"],
            alexa_rank: data["alexa_rank"],
            ir_rank: data["ir_rank"],

            // url: data["url"],
            relationUrl: data["relationUrl"] || data["relation.url"] || data["url"],
            // time: data["time"],

            hashtag: data["hashtag"] ? data["hashtag"].split(",") : "",
            gender: data["gender"],
            ages: data["ages"],
            engagement_rate: data["engagement_rate"],
            relation_engagement_rate_max: data["relation.engagement_rate_max"],
            engagement_rate_max: data["engagement_rate_max"],
        };
        let avatar = user.avatar || user.image;
        let randomBgColorClass = randomBgColor(user.full_name);
        let charAvatars = charAvatar(user.full_name);
        let currentPlatform = platform || getPlatforms(platformName, intl);

        const popOverData = (
            <div className="popover userinfo-popover">
                <div className="accountinfo">
                    <div className="accountinfo-right">
                        <i className={"icon " + currentPlatform.icon}></i>
                        {currentPlatform.label}
                    </div>
                    <div className="accountinfo-toolbar">
                        <span className="circle-toolbar-icon">
                            <i className="icon icon-star"></i>
                        </span>
                    </div>
                </div>
                <div className="userinfo ">
                    <figure className={"img-wrap " + randomBgColorClass}>
                        <div
                            className="img-wrap-text"
                            dangerouslySetInnerHTML={{ __html: sanitize(charAvatars) }}
                        ></div>
                        {avatar && (
                            <div className="userinfo-avatar-loading">
                                {" "}
                                <Loadings type="button" />
                            </div>
                        )}
                        {avatar && <img src={this.handleAvatarPath(avatar)} alt="" />}
                    </figure>
                    <div className="userinfo-data">
                        <div className="major">
                            <b>{user.full_name}</b>
                            {user.username && (
                                <span className="small" title={user.full_name}>
                                    {user.username}
                                </span>
                            )}
                            {/* {user.media_type && !Number.isInteger(user.media_type) && <span class="small">{user.media_type}</span>} */}
                        </div>
                        {user.follower_count && (
                            <div className="minor">
                                Followers: <FormattedNumber value={user.follower_count} />
                            </div>
                        )}
                        {user.following_count && (
                            <div className="minor">
                                Following: <FormattedNumber value={user.following_count} />
                            </div>
                        )}
                    </div>
                </div>
                {(user.bio || user.description || user.about) && (
                    <Scrollbar noScrollX={true} className="scrollbar" style={{ height: "100px" }}>
                        <div className="moreinfo">{user.bio || user.description || user.about}</div>
                    </Scrollbar>
                )}
                <div className="mediainfo">
                    {/*platformName.platform.includes('instagram') && <div><i className="icon icon-camera-icon"></i><span>مدیا</span><p><span> {user.media_count}</span></p></div>*/}
                    {platformName && platformName.includes("telegram") && user.member_count > 0 && (
                        <div>
                            <i className="icon icon-follower"></i>
                            <span>تعداد اعضا</span>
                            <p>
                                {" "}
                                <FormattedNumber value={user.member_count} />
                            </p>
                        </div>
                    )}
                    {user.comment_count > 0 && (
                        <div>
                            <i className="icon icon-follower"></i>
                            <span>تعداد کامنت</span>
                            <p>
                                {" "}
                                <FormattedNumber value={user.comment_count} />
                            </p>
                        </div>
                    )}
                    {user.retweets > 0 && (
                        <div>
                            <i className="icon icon-retweet"></i>
                            <span> بازنشر</span>
                            <p>
                                {" "}
                                <FormattedNumber value={user.retweets} />
                            </p>
                        </div>
                    )}
                    {user.post_count > 0 && (
                        <div>
                            <i className="icon icon-copy"></i>
                            <span> تعداد پست</span>
                            <p>
                                {" "}
                                <FormattedNumber value={user.post_count} />
                            </p>
                        </div>
                    )}

                    {/*user.follower_count > 0 && <div><i className="icon icon-follower"></i><span>تعداد دنبال‌کننده</span><p><span> {user.follower_count}</span></p></div>}
                        {user.following_count > 0 && <div><i className="icon icon-follower"></i><span>تعداد دنبال‌شونده</span><p><span> {user.following_count}</span></p></div>*/}

                    {user.language && (
                        <div>
                            <i className="icon icon-word-key"></i>
                            <span> زبان</span>
                            <p>
                                <span> {user.language.toUpperCase()}</span>
                            </p>
                        </div>
                    )}

                    {user.influence > 0 && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> ضریب نفوذ</span>
                            <p>
                                <span> {Math.floor(user.influence * 100) / 100}</span>
                            </p>
                        </div>
                    )}
                    {user.newsinfluence > 0 && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> ضریب نفوذ خبر</span>
                            <p>
                                <span> {Math.floor(user.newsinfluence * 100) / 100}</span>
                            </p>
                        </div>
                    )}

                    {user.tweets && (
                        <div>
                            <i className="icon icon-comment-icon"></i>
                            <span> توئیت</span>
                            <p>
                                {" "}
                                <FormattedNumber value={user.tweets} />
                            </p>
                        </div>
                    )}
                    {user.relationid && (
                        <div>
                            <i className="icon icon-info"></i>
                            <span> آیدی</span>
                            <p>
                                <span> {user.relationid}</span>
                            </p>
                        </div>
                    )}
                    {user.relationRobot && (
                        <div>
                            <i className="icon icon-add-dashbord"></i>
                            <span> مشاهده توسط ربات</span>
                            <p>
                                <span> {<FormattedDate value={user.relationRobot} />}</span>
                            </p>
                        </div>
                    )}

                    {user.alexa_rank && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> رتبه الکسا </span>
                            <p>
                                <span> {user.alexa_rank}</span>
                            </p>
                        </div>
                    )}
                    {user.ir_rank && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> رتبه الکسا در ایران</span>
                            <p>
                                <span> {user.ir_rank}</span>
                            </p>
                        </div>
                    )}

                    {user.relationUrl && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> آدرس وب</span>
                            <p>
                                <span>
                                    {" "}
                                    <a href={user.relationUrl}>{user.relationUrl}</a>
                                </span>
                            </p>
                        </div>
                    )}
                    {user.extractedUrl && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> آدرس وب</span>
                            <p>
                                <span>
                                    <a href={user.extractedUrl}> {user.extractedUrl}</a>
                                </span>
                            </p>
                        </div>
                    )}

                    {user.location && (
                        <div>
                            <i className="icon icon-bar-chart"></i>
                            <span> مکان</span>
                            <p>
                                <span> {user.location}</span>
                            </p>
                        </div>
                    )}
                    {user?.ages && (
                        <div>
                            <i className="icon icon-user"></i>
                            <span> سن </span>
                            <p>
                                <span> {user.ages?.join(",")}</span>
                            </p>
                        </div>
                    )}
                    {user?.engagement_rate && (
                        <div>
                            <i className="icon icon-engagement"></i>
                            <span> درصد تعامل پست </span>
                            <p>
                                <span> {Math.round(user?.engagement_rate * 100 + Number.EPSILON) / 100}</span>
                            </p>
                        </div>
                    )}
                    {user?.relation_engagement_rate_max && (
                        <div>
                            <i className="icon icon-engagement"></i>
                            <span> درصد تعامل منبع </span>
                            <p>
                                <span>
                                    {" "}
                                    {Math.round(user?.relation_engagement_rate_max * 100 + Number.EPSILON) / 100}
                                </span>
                            </p>
                        </div>
                    )}
                    {user?.engagement_rate_max && (
                        <div>
                            <i className="icon icon-engagement"></i>
                            <span> درصد تعامل منبع </span>
                            <p>
                                <span>
                                    {" "}
                                    {Math.round(user?.engagement_rate_max * 100 + Number.EPSILON) / 100}
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
        return (
            //(اطلاعات اکانت) استفاده شده در صفحه ریزالت در قسمت باکس ها
            <UiPopover
                content={popOverData}
                trigger={trigger}
                placement="left"
                mouseEnterDelay={1000}
                className={className}
            >
                {children}
            </UiPopover>
        );
    }
}

//
// نوع و نام پراپ های ورودی
AccountInfoPopover.propTypes = {
    trigger: propTypes.string,
    mouseEnterDelay: propTypes.number,
    className: propTypes.string,
    user: propTypes.object,
    platformName: propTypes.string,
};

AccountInfoPopover = injectIntl(AccountInfoPopover);
export default AccountInfoPopover;
