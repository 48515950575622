import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import { root } from "GlobalVariables";
import { isString } from "lodash-es";

const UserAvatar = (props) => {
    let src = require("./../../assets/profile_NN.jpg");
    if (props.cache) {
        src = `${root}/user/avatars/${localStorage.getItem("avatar")}`;
    } else if (props.src) {
        src = props.src;
    }
    if (isString(src)) src = src?.replace("_normal", "");
    return (
        // <img src={src} />
        //size={100}
        <Avatar className={props.className} src={src} size={props.size} style={props.style} crossOrigin="*" />
    );
};

UserAvatar.propTypes = {
    src: PropTypes.string, // آدرس تصویر
    cache: PropTypes.bool, // از کش بخواند
    size: PropTypes.number, // اندازه تصویر
    className: PropTypes.string, // اگر نیاز به کلاس خاص داشت
    style: PropTypes.string, // اگر نیاز به style خاص داشت
};

export default UserAvatar;
