/**
 * @import scripts
 */
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment-jalaali";
import _ from "lodash";
import { connect } from "react-redux";
import ConfirmBox from "../ConfirmBox";
import Carousel from "../carousel/Carousel";
import { FormattedSimpleMsg, handleRangeParameters } from "GlobalFunctions";
import TimeDurationListFilter from "./fields/custom/TimeDurationListFilter";
import DateTimeFromToFilter from "./fields/DateTimeFromToFilter";
import "./AppliedFilters.scss";
import { categorySubCategory } from "GlobalVariables";

const ingnoreList = [
    "view_checked",
    "copy_checked",
    "like_checked",
    "post_checked",
    "following_checked",
    "follower_checked",
    "member_checked",
    "resource_objects",
    "resource_objects_resource_ids",
    "resource_objects_post_resource_ids",
    "resourceIdsDetails",
    "query_field",
    "media_checked",
    "comment_checked",
    "data",
    "apply",
    "from",
    "to",
    "time_duration",
    "tagContent",
    "cluster_id",
    "influence_time",
    "tagId",
    "totalResourceCount",
    "selectedResourceCount",
    "Varzeshi",
    "Siasi",
    "Eghtesadi",
    "ElmiVaDaneshghai",
    "Farhang",
    "Ejtemaee",
    "HonarVaResane",
    "Beinolmelal",
    "FarhangHonarVaResane",
    "Roozmare",
    "0",
    "newData"
];

/**
 * Show Applied Filters on Widgets
 */
class AppliedFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDeletingFilter: "",
            confirmation: false,
            resetAllConfirmation: false,
            resetType: "single",
            forbiddenAll: false,
            forbiddenDate: false,
            date: {
                from: props.filtersValues.from,
                to: props.filtersValues.to,
                time_duration: props.filtersValues.time_duration,
            },
        };

        this.toggleConfirmation = this.toggleConfirmation.bind(this);
        this.resetFilterConfirmation = this.resetFilterConfirmation.bind(this);
        this.confirmDeleteAction = this.confirmDeleteAction.bind(this);
        this.confirmResetAllFilters = this.confirmResetAllFilters.bind(this);
    }

    componentDidMount() {
        this.manageLimits();
    }

    componentDidUpdate(prevProps, prevState) {
        const { filtersValues } = this.props;

        if (filtersValues && !_.isEqual(filtersValues, prevProps.filtersValues)) {
            this.manageLimits();
            this.setState({
                date: {
                    from: filtersValues.from,
                    to: filtersValues.to,
                    time_duration: filtersValues.time_duration,
                },
            });
        }

        if (prevProps.apiPath !== this.props.apiPath) {
            this.manageLimits();
        }
    }

    manageLimits = () => {
        const { apiPath, filtersValues } = this.props;

        if (apiPath) {
            // check Forbidden Date
            const localSearch = JSON.parse(localStorage.search);
            const forbiddenDate = _.isEmpty(localSearch.api_params[apiPath].from);

            // check Forbidden All
            let cloneFilters = Object.keys(filtersValues).filter(
                (item) => !["from", "to", "time_duration"].includes(item),
            );
            const forbiddenAll = cloneFilters.length === 0 && forbiddenDate;

            this.setState({
                forbiddenDate,
                forbiddenAll,
            });
        }
    };

    /**
     * all filters values that need to translate
     * @param filterKey
     * @returns {boolean}
     */
    static needToTrans(filterKey) {
        const keys = [
            "sort",
            "sort_type",
            "exists",
            "type",
            "q_type",
            "info",
            "langs",
            "resource_ids",
            "post_resource_ids",
            "content_tag_ids",
            "media_type",
            "source",
            "min_view",
            "max_view",
            "min_like",
            "max_like",
            "min_comment",
            "max_comment",
            "min_copy",
            "max_copy",
            "min_influence",
            "max_influence",
            "min_post",
            "max_post",
            "min_follower",
            "max_follower",
            "min_following",
            "max_following",
            "min_media",
            "max_media",
            "min_rank",
            "max_rank",
            "min_price",
            "max_price",
            "min_member",
            "max_member",
            "tag_id",
            "is_bot",
            "strategy",
            "media_types",
            "time_duration",
            "query",
            "gender",
            "min_advert_noise",
            "max_advert_noise",
            "has_location",
            "category",
            "categories",
            "sub_category",
            "rank_in_cluster",
            "top_rankings",
        ];
        return keys.includes(filterKey);
    }

    /**
     * return formatted values
     * @param data
     * @returns {*}
     */
    getValueFormatted(data) {
        const { intl } = this.props;
        const { formatNumber, formatMessage } = intl;
        try {
            if (_.isNumber(data)) {
                return formatNumber(data);
            } else if (_.isBoolean(data)) {
                return formatMessage({ id: `filtersVal.${data.toString()}` });
            } else {
                return formatMessage({ id: `filtersVal.${data.toLowerCase()}` });
            }
        } catch (error) {
            return data;
        }
    }

    /**
     * control date and object values
     * @param key
     * @returns {*}
     */
    finalValues(key) {
        const { filtersValues, jalaliFormat, originalFormat } = this.props;

        if (["from", "to"].includes(key) && filtersValues[key]) {
            return <bdi>{moment(filtersValues[key], originalFormat).format(jalaliFormat)}</bdi>;
        }

        return _.isObjectLike(filtersValues[key]) ? "" : filtersValues[key];
    }

    /**
     * return translated value of filters
     * @param filterVal
     * @param filterKey
     * @returns {*}
     */
    getTransValue(filterVal, filterKey) {
        const { filtersValues } = this.props; // console.log( "-------filtersValues--------" , filtersValues );

        const { tags, rules } = this.props;

        //this.objectsParams(filterKey)
        if (filterKey === "resource_ids") {
            // TODO: we need to add only resource_objects that use the same platform
            if (
                filtersValues["resource_objects"] &&
                filtersValues["resource_objects"].length > 0 &&
                filtersValues["resource_objects"][0]
            ) {
                const items =
                    filtersValues["resource_objects"][0].objs &&
                    filtersValues["resource_objects"][0].objs.map((item) => item.title);
                return <span>{items && items.join(", ")}</span>;
            }
            if (
                filtersValues["resource_objects_resource_ids"] &&
                filtersValues["resource_objects_resource_ids"].length > 0 &&
                filtersValues["resource_objects_resource_ids"][0]
            ) {
                const items =
                    filtersValues["resource_objects_resource_ids"][0].objs &&
                    filtersValues["resource_objects_resource_ids"][0].objs.map((item) => item.title);
                return <span>{items && items.join(", ")}</span>;
            }
            return <FormattedMessage id={`filtersVal.custom`} />;
        } else if (filterKey === "post_resource_ids") {
            if (
                filtersValues["resource_objects_post_resource_ids"] &&
                filtersValues["resource_objects_post_resource_ids"].length > 0 &&
                filtersValues["resource_objects_post_resource_ids"][0]
            ) {
                const items =
                    filtersValues["resource_objects_post_resource_ids"][0].objs &&
                    filtersValues["resource_objects_post_resource_ids"][0].objs.map((item) => item.title);
                return <span>{items && items.join(", ")}</span>;
            }
            return <FormattedMessage id={`filtersVal.custom`} />;
        } else if (filterKey === "content_tag_ids") {
            if (filtersValues["content_tag_ids"] && filtersValues["content_tag_ids"].length > 0) {
                // TODO
                const items = filtersValues?.["content_tag_ids"];
                const itemsTitle = [];
                if (this.props.tagContent?.length > 0) {
                    this.props.tagContent.forEach((child) => {
                        if (child.children.length > 0) {
                            child.children.forEach((item) => {
                                if (items.includes(item.key)) {
                                    itemsTitle.push(item.title);
                                }
                            });
                        }
                    });
                    return <span>{items && itemsTitle.join(", ")}</span>;
                }
                // return <FormattedMessage id={`filtersVal.custom`} />;
            }
        } else if (filterKey === "tag_id" && tags) {
            // پیدا کردن اسم از روی آیدی تگ های موجود در ریداکس
            const tag = tags.find((item) => item.id === filterVal);
            return _.isEmpty(tag) ? undefined : tag.name;
        } else if (filterKey === "query") {
            if (_.isString(filterVal)) {
                // isPlainObject
                return <FormattedMessage id={`filtersVal.custom`} />;
            } else {
                const selectedRule = rules && rules.find((x) => x.id === filterVal);
                return selectedRule ? selectedRule.title : "";
            }
        } else if (filterKey === "min_advert_noise" || filterKey === "max_advert_noise") {
            return "%" + filtersValues[filterKey];
        } else if (filterKey === "has_location") {
            return filtersValues[filterKey] === 0 ? "ندارد" : "دارد";
        } else if (filterKey === "top_rankings") {
            return filtersValues[filterKey] === 0 ? "نباشد" : "باشد";
        } else if (filterKey === "category") {
            return <FormattedMessage id={`multi_level_chart.${filtersValues[filterKey]}`} />;
        } else if (filterKey === "sub_category") {
            return <FormattedMessage id={`multi_level_chart.${filtersValues[filterKey]}`} />;
        } else {
            return _.isArray(filterVal)
                ? filterVal.map((value) => this.getValueFormatted(value)).join("، ")
                : this.getValueFormatted(filterVal);
        }
    }

    /**
     * remove related filters together
     * @param filters
     * @returns {*[]}
     */
    static addRelationFilters(filters) {
        const allRelationFilters = {
            sort: ["sort", "sort_type"],
            resource_ids: ["resource_objects", "resource_objects_resource_ids"],
            post_resource_ids: ["resource_objects_post_resource_ids"],
            tag_content_ids: ["tag_content_ids"],
            from: ["to"],
            query: ["query_field"],
        };
        const newFilters = [...filters];

        Object.keys(allRelationFilters).forEach((filter) => {
            if (filters.includes(filter)) {
                allRelationFilters[filter].forEach((relFilters) => {
                    if (!filters.includes(relFilters)) {
                        newFilters.push(relFilters);
                    }
                });
            }
        });

        return newFilters;
    }

    /**
     * Show confirmation modal for get confirm from user for that action
     * @param filterKey
     */
    resetFilterConfirmation(filterKey) {
        this.setState((state) => ({
            confirmation: !state.confirmation,
            currentDeletingFilter: filterKey,
            resetType: "single",
        }));
    }

    /**
     * Toggle modal confirmation for remove one or some filters
     */
    toggleConfirmation(type) {
        this.setState((state) => ({
            confirmation: !state.confirmation,
            resetType: type,
        }));
    }

    /**
     * removes selected showed filter
     */
    confirmDeleteAction() {
        const { resetFilter } = this.props;
        const { currentDeletingFilter } = this.state;

        const finalFilters = AppliedFilters.addRelationFilters([currentDeletingFilter]);

        resetFilter(finalFilters);

        this.setState({
            confirmation: false,
            currentDeletingFilter: "",
        });
    }

    /**
     * removes all showed filters
     * @param finalFilters
     */
    confirmResetAllFilters(finalFilters) {
        const { resetAllFilters } = this.props;

        const allFilters = AppliedFilters.addRelationFilters(finalFilters);

        resetAllFilters(allFilters);

        this.setState({
            confirmation: false,
        });
    }

    /**
     * save and change date filter
     * @param {string} value value of input
     * @param {string} type of params
     */
    changeDateFilter = (value, type) => {
        const { filtersValues, fireSearch } = this.props;
        const { date } = this.state;

        let params = {
            ...date,
            [type]: value,
        };

        if (type === "from") {
            delete params.to;
        }

        this.setState(
            {
                date: params,
            },
            () => {
                if (type === "time_duration" || type === "to") {
                    // fire search with new params
                    fireSearch && fireSearch({ ...filtersValues, ...params });
                }
            },
        );
    };

    render() {
        const { filtersValues, requiredFilters, type, extraIgnore = [], jalaliFormat, originalFormat } = this.props;
        const { forbiddenDate, forbiddenAll } = this.state;

        if (forbiddenAll) {
            return null;
        }

        let filtersValuesForShow = _.cloneDeep(filtersValues);

        // handle unchecked values
        filtersValuesForShow = handleRangeParameters(filtersValuesForShow);

        // دریافت و ساخت دسته بندی محتوا بر اساس min و max
        // برای حذف آنها از لیست فیلتر های اعمال شده
        const minCategories = Object.keys(categorySubCategory)?.map((category) => `min_${category}`);
        const maxCategories = Object.keys(categorySubCategory)?.map((category) => `max_${category}`);

        /**
         * validate filters that they need to showed on UI
         */
        let finalFilters = Object.keys(filtersValuesForShow)
            .filter((item) => !requiredFilters.includes(item))
            .filter((item) => !(["from", "to", "time_duration"].includes(item) && filtersValues[item] === null))
            .filter((item) => !item.includes("_checked") && !ingnoreList.includes(item) && !extraIgnore.includes(item))
            // .filter((item) => !["resource_ids"].includes(item))
            .filter(
                (item) =>
                    (_.isArray(filtersValues[item]) && !_.isEmpty(filtersValues[item])) ||
                    (_.isPlainObject(filtersValues[item]) && Object.keys(filtersValues[item]).length > 0) ||
                    (filtersValues[item] && !_.isArray(filtersValues[item]) && !_.isPlainObject(filtersValues[item])) ||
                    filtersValues[item] === false ||
                    filtersValues[item] === 0,
            )
            .filter(
                (item) =>
                    !item.includes("prob_categories") &&
                    item !== "categories" &&
                    item !== "sub_categories" &&
                    !minCategories.includes(item) &&
                    !maxCategories.includes(item),
            );

        if (_.isEmpty(finalFilters)) {
            const dateParams = Object.keys(filtersValuesForShow).filter((item) =>
                ["from", "to", "time_duration"].includes(item),
            );
            if (_.isEmpty(dateParams)) {
                return null;
            }
        }

        const { confirmation, currentDeletingFilter, resetType, date } = this.state;

        const { intl, setVisible, theme } = this.props;
        const { formatMessage } = intl;
        const selectedFilter = currentDeletingFilter
            ? formatMessage({ id: `filtersKey.${currentDeletingFilter}` })
            : "";
            
        return (
            <div className="applied-filters">
                <div className="inner-content">
                    <span className="label">
                        <FormattedSimpleMsg id="global.filters" />
                    </span>

                    {(date["from"] || date["to"]) && !forbiddenDate && (
                        <span className="custom-date-picker-form-item small-date-picker">
                            <DateTimeFromToFilter
                                data={{ from: date["from"], to: date["to"] }}
                                handleClearDatePicker={(name) => this.changeDateFilter(null, name)}
                                handleDatePicker={(value, name) =>
                                    this.changeDateFilter(moment(value, jalaliFormat).format(originalFormat), name)
                                }
                            />
                        </span>
                    )}

                    {date["time_duration"] && !forbiddenDate && (
                        <TimeDurationListFilter
                            theme={theme}
                            data={date["time_duration"]}
                            handleChange={(value) => this.changeDateFilter(value, "time_duration")}
                        />
                    )}

                    <div className="list">
                        <Carousel updateKey={JSON.stringify(filtersValues)} type={type}>
                            <ul>
                                {finalFilters.map((filterKey) => {
                                    // if(['from','to','time_duration'].includes(filterKey)){return}
                                    return (
                                        <li key={filterKey} className={filterKey}>
                                            <span className="pointer" onClick={setVisible}>
                                                <FormattedMessage id={`filtersKey.${filterKey.toLowerCase()}`} />
                                                &nbsp;
                                                {AppliedFilters.needToTrans(filterKey) ? (
                                                    this.getTransValue(filtersValues[filterKey], filterKey)
                                                ) : (
                                                    <span>{this.finalValues(filterKey)}</span>
                                                )}
                                            </span>
                                            <i
                                                onClick={this.resetFilterConfirmation.bind(this, filterKey, "single")}
                                                className="icon icon-close-icon"
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </Carousel>
                    </div>
                    <span onClick={this.toggleConfirmation.bind(this, "all")} className="close">
                        <i className="icon icon-close-icon" />{" "}
                    </span>

                    <ConfirmBox
                        display={confirmation}
                        title={
                            resetType === "single"
                                ? formatMessage({ id: "global.filter_remove" })
                                : formatMessage({ id: "global.all_filter_remove" })
                        }
                        desc={
                            resetType === "single"
                                ? formatMessage({ id: "global.filter_remove_alert" }, { selectedFilter })
                                : formatMessage({ id: "global.all_filter_remove_alert" })
                        }
                        icon="delete"
                        functionToBeCalled={this.toggleConfirmation.bind(this, resetType)}
                        changeSaveMode={this.toggleConfirmation.bind(this, resetType)}
                        confirmAction={
                            resetType === "single"
                                ? this.confirmDeleteAction
                                : this.confirmResetAllFilters.bind(this, finalFilters)
                        }
                        confirmText={formatMessage({ id: "global.delete" })}
                        cancelText={formatMessage({ id: "global.cancel" })}
                    />
                </div>
            </div>
        );
    }
}

AppliedFilters.defaultProps = {
    jalaliFormat: "jYYYY-jMM-jDD HH:mm",
    originalFormat: "YYYY-M-D HH:mm:ss",
    resetFilter: () => {},
    requiredFilters: [],
};

const mapStateToProps = (state) => {
    return {
        tags: state.globalFilter.tags,
        rules: state.globalFilter.rules,
        tagContent: state.globalFilter.tagContent,
    };
};

export default injectIntl(connect(mapStateToProps, null)(AppliedFilters));
