import React from "react";
import PropTypes from "prop-types";

/**
 * خط افقی(ریل) مربوط به رنج اسلایدر
 * @param {*func} getRailProps
 */
const SliderRail = ({ getRailProps }) => {
    return (
        <>
            <div className="ui-input-range-rail-outer-style" {...getRailProps()} />
            <div className="ui-input-range-rail-inner-style" />
        </>
    );
};

SliderRail.propTypes = {
    getRailProps: PropTypes.func.isRequired,
};

export default SliderRail;
