import React, { useState } from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { injectIntl } from "react-intl";
import "./InputStringField.scss";
import { UiInput} from "ui-components";

const InputStringField = ({ name, platformParams, handleInputText, size, intl ,typeDescriptionFilter,theme}) => {
    const [error, setError] = useState(false);

    const onChange = (value, name) => {
        setError(false);
        const regex = new RegExp("^[ا-ی a-z A-Z +]*$");
        if (!regex.test(value)) {
            setError(true);
            return;
        }
        handleInputText(value, name);
    };

   
   
    return (
        <Row key={name}>
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">{intl.formatMessage({ id: `strings.${name}` })}{typeDescriptionFilter}</span>
            </Col>
            <Col lg={14} xl={size === "md" ? 18 : 6}>
                <UiInput
                    className={["filter-location", error ? "error" : ""].join(" ")}
                    value={_.isUndefined(platformParams[name]) ? "" : platformParams[name]}
                    onChange={(value) => onChange(value, name)}
                    theme={theme}
                />
                {error && (
                    <span style={{ color: "red", fontSize: 11 }}>
                        برای جدا کردن عبارات تنها از + میتوانید استفاده کنید
                    </span>
                )}
            </Col>
        </Row>
    );
};

export default injectIntl(InputStringField);
