import antdAr from 'antd/lib/locale-provider/ar_EG';
import appLocaleData from 'react-intl/locale-data/ar';
import arMessages from '../locales/ar_IQ.json';
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const ArLang = {
  messages: {
    ...arMessages,
  },
  antd: antdAr,
  locale: 'ar-IQ',
  data: appLocaleData,
};
export default ArLang;
