import React, { useContext } from "react";
import Warning from "components/planning/sections/box/body/box-item/sections/warning/Warning";
import { UiTableStateContext } from "../context/UiTableContext";
import UiTableInlineError from "./UiTableInlineError";
import { isEmptyObject } from "jquery";
/**
 * نمایش پیغام های خطا داخل جدول
 */
const UiTableErrors = () => {
    const tableState = useContext(UiTableStateContext);
    // const [moreError, setMoreError] = useState(false);

    // با موفقیت کال شد اما دیتا یافت نشد api اگر
    if (tableState?.totalCountData === "empty") {
        return (
            <Wrapper>
                <b>{tableState?.title}</b>، یافت نشد
                <UiTableInlineError errors={tableState?.errors} />
            </Wrapper>
        );
    }

    // اگر لیست ارورها پر بود
    if (!isEmptyObject(tableState?.errors)) {
        return (
            <Wrapper>
                <UiTableInlineError errors={tableState?.errors} />
            </Wrapper>
        );
    }

    return null;
};
export default UiTableErrors;

// eslint-disable-next-line react/prop-types
export const Wrapper = ({ children }) => {
    return (
        <div className="ui-table-error">
            <Warning noData className="ui-table-warning" noAbsolute description={children} />
        </div>
    );
};
