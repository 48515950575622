import React from "react";
import UserAccess from "./../../../permission/UserAccess";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { UiSelect } from "ui-components";

const options = [
    {
        value: "today",
        label: <FormattedSimpleMsg id="filters.time_duration_list.today" />,
        access: UserAccess.isAllowTimeDuration("today"),
    },
    {
        value: "6h",
        label: <FormattedSimpleMsg id="filters.time_duration_list.6h" />,
        access: UserAccess.isAllowTimeDuration("6h"),
    },
    {
        value: "24h",
        label: <FormattedSimpleMsg id="filters.time_duration_list.24h" />,
        access: UserAccess.isAllowTimeDuration("24h"),
    },
    {
        value: "1w",
        label: <FormattedSimpleMsg id="filters.time_duration_list.1w" />,
        access: UserAccess.isAllowTimeDuration("1w"),
    },
    {
        value: "2w",
        label: <FormattedSimpleMsg id="filters.time_duration_list.2w" />,
        access: UserAccess.isAllowTimeDuration("2w"),
    },
    {
        value: "1m",
        label: <FormattedSimpleMsg id="filters.time_duration_list.1m" />,
        access: UserAccess.isAllowTimeDuration("1m"),
    },
    {
        value: "6m",
        label: <FormattedSimpleMsg id="filters.time_duration_list.6m" />,
        access: UserAccess.isAllowTimeDuration("6m"),
    },
    {
        value: "12m",
        label: <FormattedSimpleMsg id="filters.time_duration_list.12m" />,
        access: UserAccess.isAllowTimeDuration("120m"),
    },
];

const timeDurationListFilter = (props) => {
    const { data, handleChange, theme = "", themeForce="" } = props;

    return (
        // تایم دیوریشن صفحه ریزالت در قسمت جستجو
        <UiSelect
            placeholder={<FormattedSimpleMsg id="filters.time_duration_list.not_have" />}
            value={data}
            size="sm"
            theme={theme}
            style={{
                maxWidth: "200px",
            }}
            onChange={(value) => handleChange(value)}
            list={options.filter((x) => x.access)}
            output="value"
            className={themeForce}
        />
    );
};

export default timeDurationListFilter;
