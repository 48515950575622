import { FILTER_CHANGE, UPDATE_RULES, UPDATE_TAGS, GET_TAG_CONTENT }
    from '../actions/types';

const initialState = {
    // from:"2019-01-20 23:56:01",
    // to:"2019-02-20 23:56:01",
    // ruleId:11,
    time_duration:"1w"
};

const globalFilterReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FILTER_CHANGE:
            state = {
                ...state,
                ...payload,
            };
            break;
        case UPDATE_RULES:
            state = {
                ...state,
                rules: [...payload],
            };
            break;
        case UPDATE_TAGS:
            state = {
                ...state,
                tags: [...payload],
            };
            break;
        case GET_TAG_CONTENT:
            state = {
                ...state,
                tagContent: [...payload],
            };
            break;
        default:
            break;
    }
    return state;
}

export default globalFilterReducer;