import React from "react";
import moment from "moment-jalaali";
import Days from "./Partials/Days";
import Months from "./Partials/Months";
import Styles from "./Partials/Styles";
import Years from "./Partials/Years";
import TimePicker from "./Partials/TimePicker";
import Input from "./Partials/Input";
import $ from "jquery";

const canUseDOM = !!(typeof window !== "undefined" && window.document && window.document.createElement);

class DateTimePicker extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        moment.loadPersian([]);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.canclePicker = this.canclePicker.bind(this);
        this.submitHandler = this.submitHandler.bind(this);

        let states = this.getDefaultStates();

        this.state = {
            openPicker: false,
            selectedMonthFirstDay: moment(
                moment().format("jYYYY") + "/" + moment().format("jMM") + "/01",
                "jYYYY/jMM/jDD",
            ).weekday(),
            ...states,
        };

        this.state.daysCount = this.daysInMonth(moment().format("jMM"), moment().format("jYYYY"));
    }

    getDefaultStates() {
        const { format } = this.props;

        let preSelected = "";

        if (this.props.preSelected) {
            preSelected = this.props.preSelected;
        }

        let current = moment(preSelected, format);

        return {
            selectedYear:
                preSelected.length > 1 ? parseInt(current.format("jYYYY")) : parseInt(moment().format("jYYYY")),
            currentMonth: preSelected.length > 1 ? parseInt(current.format("jMM")) : parseInt(moment().format("jMM")),
            selectedDay: preSelected.length > 1 ? current.format("jYYYYjMMjDD") : "",
            selectedTime: preSelected.length > 1 ? current.format("HH:mm") : moment().format("HH:mm"),
            inputValue: preSelected,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener("mousedown", this.closeDatePicker, false);
        $("body").bind("click.JDCalender", (event) => {
            // check if the clicked element is a descendent of navigation  && $(event.target).closest(".JDinput", $(this.container)).length === 0
            // if($(event.target).closest(".jalali-date-picker-container", $(this.container)).length === 0) {
            // 	if (this._isMounted) {
            // 		if (this.state.openPicker === true) {
            //             this.applyPickerValue(true);
            // 		}
            // 	}
            // }
        });
    }

    closeDatePicker = (event) => {
        if (this.container && !this.container.contains(event.target) && this.state.openPicker) {
            this.applyPickerValue(true);
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { preSelected } = this.props;

        if (prevProps.preSelected !== preSelected || (prevState.openPicker !== this.state.openPicker && !preSelected)) {
            let states = this.getDefaultStates();

            this.setState(states);
        }
    }

    daysInMonth(month, selectedYear) {
        if (0 < month && month < 7) return 31;
        else if (6 < month && month < 12) return 30;
        else if (parseInt(month) === 12 && moment.jIsLeapYear(selectedYear)) return 30;
        else if (parseInt(month) === 12 && !moment.jIsLeapYear(selectedYear)) return 29;
    }

    componentWillMount() {
        let { selectedMonthFirstDay } = this.state;
        if (canUseDOM && !document.getElementById("jdstyle")) {
            let css = Styles(selectedMonthFirstDay),
                head = document.head || document.getElementsByTagName("head")[0],
                style = document.createElement("style");

            style.type = "text/css";
            style.id = "jdstyle";
            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.closeDatePicker, false);

        this._isMounted = false;

        $("body").unbind("click.JDCalender");
    }

    daysClicked(day, momentDay) {
        let { format } = this.props;
        let { selectedTime } = this.state;
        if (!format) format = "jYYYY-jMM-jDD HH:mm";
        if (this.state.selectedDay !== momentDay)
            this.setState({
                selectedDay: momentDay,
                inputValue: moment(momentDay + " " + selectedTime, "jYYYYjMMjDD HH:mm").format(format),
            });
    }

    monthsClicked(month) {
        let { selectedYear } = this.state;
        let year = selectedYear;
        let thisMonth = month;
        this.setState({ daysCount: 0 });
        if (month === 0) {
            this.setState({
                currentMonth: 12,
                daysCount: this.daysInMonth(12, selectedYear - 1),
                selectedYear: selectedYear - 1,
            });
            thisMonth = 12;
            year = selectedYear - 1;
        } else if (month === 13) {
            this.setState({
                currentMonth: 1,
                daysCount: this.daysInMonth(1, selectedYear + 1),
                selectedYear: selectedYear + 1,
            });
            thisMonth = 1;
            year = selectedYear + 1;
        } else
            this.setState({
                currentMonth: month,
                daysCount: this.daysInMonth(month, selectedYear),
            });
        this.firstDayOfMonth(thisMonth, year);
    }

    firstDayOfMonth(mo, ye) {
        let month = mo.toString();
        let year = ye.toString();
        if (month.length === 1) month = "0" + month;
        this.setState({
            selectedMonthFirstDay: moment(year + "/" + month + "/01", "jYYYY/jMM/jDD").weekday(),
        });
    }

    yearSelected(year) {
        this.setState({ selectedYear: year });
        this.firstDayOfMonth(this.state.currentMonth, year);
    }

    timeSelected(time) {
        let { format } = this.props;
        let { selectedDay } = this.state;
        if (!format) format = "jYYYY-jMM-jDD HH:mm";
        this.setState({
            selectedTime: time,
            inputValue: moment(selectedDay + " " + time, "jYYYYjMMjDD HH:mm").format(format),
        });
    }

    submitHandler(e) {
        e.preventDefault();
        this.applyPickerValue();
    }

    applyPickerValue(forceClose = false) {
        let { onChange, format } = this.props;

        let { selectedTime, selectedDay } = this.state;

        if (!!selectedDay && !!selectedTime) {
            this.setState({ openPicker: false });

            let formatted;
            if (!!format) formatted = moment(selectedDay + " " + selectedTime, "jYYYYjMMjDD HH:mm").format(format);
            if (onChange)
                this.props.onChange(moment(selectedDay + " " + selectedTime, "jYYYYjMMjDD HH:mm").unix(), formatted);
        } else {
            if (forceClose) {
                this.setState({ openPicker: false });
            }
        }
    }
    clearPicker = (e) => {
        e.preventDefault();
        this.setState({ openPicker: false, inputValue: "" });
        typeof this.props.onClear === "function" && this.props.onClear();
    };
    canclePicker(e) {
        e.preventDefault();
        this.setState({ openPicker: false });
    }

    /**
     * validate disable dates
     *
     **/
    validateDisableDates() {
        const { disableFromUnix, disableStartDate, disableEndDate } = this.props;

        let disableStart = disableStartDate && moment(disableStartDate).isValid() ? disableStartDate.unix() : "";

        let disableEnd = disableEndDate && moment(disableEndDate).isValid() ? disableEndDate.unix() : "";

        let start = disableStart ? disableStart : disableFromUnix ? disableFromUnix : "";

        if (disableFromUnix && disableStart) {
            if (disableFromUnix > disableStart) {
                start = disableFromUnix;
            }
        }

        if (disableStart && disableEnd) {
            if (disableEnd <= disableStart) {
                console.error("disableStartDate should be less than disableEndDate");
            }
        }

        if (disableFromUnix && disableEnd) {
            if (disableEnd <= disableFromUnix) {
                console.error("disableFromUnix should be less than disableEndDate");
            }
        }

        let end = disableEnd ? disableEnd : "";

        return {
            start,
            end,
        };
    }

    render() {
        const {
            openPicker,
            daysCount,
            selectedDay,
            currentMonth,
            selectedYear,
            selectedMonthFirstDay,
            inputValue,
            selectedTime,
        } = this.state;
        const {
            id,
            placeholder,
            disableFromUnix,
            customClass,
            containerClass,
            inputTextAlign,
            monthTitleEnable,
            inputComponent,
            isDisabled,
            leftSide,
            className,
            format,
        } = this.props;
        let inputAlign = !!inputTextAlign && typeof inputTextAlign != "undefined" ? inputTextAlign : "right";

        const { start, end } = this.validateDisableDates();

        return (
            <div
                style={{ textAlign: "initial" }}
                ref={(el) => (this.container = el)}
                className={" " + className + " " + containerClass + (isDisabled ? " disabled" : "")}
            >
                <Input
                    type="text"
                    id={id}
                    placeholder={placeholder}
                    dir="ltr"
                    style={{ textAlign: inputAlign }}
                    readOnly
                    value={inputValue && moment(inputValue, format).format("jYYYY-jMM-jDD HH:mm")}
                    className={"JDinput"}
                    disabled={isDisabled ? "disabled" : ""}
                    onClick={() => {
                        this.setState((prevState) => ({
                            openPicker: !prevState.openPicker,
                        }));
                    }}
                    component={inputComponent}
                />
                <div className="left-side" style={{ display: leftSide ? "block" : "none" }} />
                {openPicker && (
                    <div
                        id={`jalali-date-picker-container-id-${id}`}
                        className={"jalali-date-picker-container JDatePicker " + customClass}
                    >
                        <div className="JDheader">
                            <div className="right">
                                <Years
                                    changeEvent={(returnedYear) => this.yearSelected(returnedYear)}
                                    year={selectedYear}
                                />
                            </div>
                            <div className="left">
                                <TimePicker
                                    disableFromUnix={disableFromUnix}
                                    selectedYear={selectedYear}
                                    selectedDay={selectedDay}
                                    currentMonth={currentMonth}
                                    changeEvent={(returnedTime) => this.timeSelected(returnedTime)}
                                    selectedTime={selectedTime}
                                />
                            </div>
                        </div>
                        <Months
                            monthTitleEnable={monthTitleEnable}
                            clickEvent={(returnedMonth) => this.monthsClicked(returnedMonth)}
                            month={currentMonth}
                        />
                        <div className="days-titles">
                            <div>ش</div>
                            <div>ی</div>
                            <div>د</div>
                            <div>س</div>
                            <div>چ</div>
                            <div>پ</div>
                            <div>ج</div>
                        </div>
                        <Days
                            disableFromUnix={start}
                            disableEndDate={end}
                            selectedYear={selectedYear}
                            selectedDay={selectedDay}
                            currentMonth={currentMonth}
                            daysCount={daysCount}
                            firstDay={selectedMonthFirstDay}
                            clickEvent={(day, momentDay) => this.daysClicked(day, momentDay)}
                        />
                        <div className="JDbuttons">
                            <button onClick={this.submitHandler} className="JDsubmit">
                                تایید
                            </button>
                            {/*<button className="JDcancel" onClick={this.canclePicker}>
								بستن
							</button>*/}
                            <button className="JDclear" onClick={this.clearPicker}>
                                پاک‌کردن
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

DateTimePicker.defualtProps = {
    preSelected: "",
};

export default DateTimePicker;
