import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import usePrevious from "hooks/usePrevious";
import "./UiMultiSelect.scss";
import { isEqual } from "lodash";
const UiMultiSelect = (props) => {
    const {
        placeholder = "",
        list = [],
        width,
        size = "md",
        theme,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        disabled = false,
        output = "object",
        value = [],
        withoutShowAllSelected,
    } = props;

    //remove Additional characters
    const additionalCharactersRegex = /[\u2000-\u200E\u2028-\u202F]/gm;

    const selectedIds = value || [];
    const wrapperRef = useRef(null);
    let inputRef = useRef(null);

    const prevProps = usePrevious({ list, selectedIds });
    const [toggleList, setToggleList] = useState(false);
    const [query, setQuery] = useState("");
    const [inputWidth, setInputWidth] = useState("");

    const focusInput = () => {
        inputRef?.current && inputRef.current.focus();
    };
    // یافتن آیتمی که به عنوان انتخاب شده از ورودی به عنوان selectedIds دریافت شده است
    // و به عنوان آیتم انتخاب شده به selected اختصاص می‌یابد
    const findSelectedList =
        selectedIds?.map((item) => {
            return {
                ...list?.find((x) => x.value.toString() === item?.toString()),
            };
        }) || [];

    const [dataList, setDataList] = useState(list || []);
    const [selectedList, setSelectedList] = useState(findSelectedList);

    useEffect(() => {
        setInputWidth(query.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    /**
     *
     * افزودن ایونت بسته شدن سلکت بعد از کلیک کردن در خارج از سلکت
     * این ایونت به داکیومنت اضافه میشود
     *
     */
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    /**
     *
     * بررسی اینکه دیتا تغییر کرده است یا خیر
     *
     */
    useEffect(() => {
        if (!isEqual(prevProps?.list, list)) {
            setDataList(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    /**
     * آپدیت کردن آیتم انتخاب شده در لیست
     */
    useEffect(() => {
        if (selectedIds && !isEqual(prevProps?.selectedIds, selectedIds)) {
            setSelectedList(findSelectedList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIds]);

    /**
     *
     * کنترل کردن کلیک خارج از سلکت برای بسته شدن آن
     * وقتی این ایونت فراخوانی شود دیتا به کامپوونت پدر انتقال داده  می شود
     *
     * @param {*event} event
     */
    const handleClickOutside = (event) => {
        if (toggleList && wrapperRef?.current && !wrapperRef?.current?.contains(event.target)) {
            if (
                selectedList &&
                !isEqual(
                    prevProps?.selectedIds,
                    selectedList?.map((x) => x.value),
                )
            ) {
                typeof onChange === "function" &&
                    onChange(output === "object" ? selectedList : selectedList?.map((x) => x.value));
            }
            handleToggle(false);
        }
    };

    /**
     * باز و بسته کردن سلکت
     *
     * @param {*bool} state
     */
    const handleToggle = (state) => {
        if (disabled) return;
        setToggleList(state);
        focusInput();
    };
    /**
     * هنگام اینتر زدن داخل اینپوت اولین ایتم در صورت وجود سلکت شه
     *
     * @param {*bool} state
     */
    const handelEnter = () => {
        if (filteredList[0] && !selectedList.find((i) => i.value === filteredList[0].value)) {
            addItem(filteredList[0]);
            setQuery("");
        }
    };

    /**
     * حذف یک ایتم از اینم های انتخاب شده
     *
     * @param {*object} item
     * @param {*bool} canChange در لحظه دیتا رو به کامپوننت پدر ارسال کند
     */

    const removeItem = (item, canChange) => {
        const filterItems = selectedList.filter((i) => i.value !== item.value);
        setSelectedList(filterItems);

        if (canChange) {
            typeof onChange === "function" &&
                onChange(output === "object" ? filterItems : filterItems.map((x) => x.value));
        }
    };
    /**
     * افزودن یک ایتم به ایتم های انتخاب شده
     *
     * @param {*object} item
     */

    const addItem = (item) => {
        setSelectedList([...selectedList, item]);
    };

    /**
     * جستجو در لیست یا کمه کلیدی
     *
     * @param {*string} value
     */

    const handleSearch = (value) => {
        !toggleList && handleToggle(true);

        const text = value.replace(additionalCharactersRegex, " ");
        setQuery(text);
    };
    /**
     * آیا ایتم انتخاب شده یا خیر
     *
     * @param {*object} item
     */
    const checkSelected = (item) => {
        if (selectedList.find((i) => i.value == item.value)) return true;
        else return false;
    };

    /**
     * تصمیم به این که ایتم باید حذف شود یا اضافه شود
     *
     * @param {*object} item
     */
    const handleSelected = (item) => {
        if (checkSelected(item)) removeItem(item, false);
        else addItem(item);
        focusInput();
    };
    /**
     * برگرداندت لیست با توجه به کلمه کلیدی
     *
     * @param {*array} nodes
     * @param {*sting} keyword
     */
    const keywordFilter = (nodes, keyword) => {
        let newNodes = [];
        for (let n of nodes) {
            n.label = n.label.toString();
            n.label = n.label?.replace(additionalCharactersRegex, " ");

            if (
                n.label
                    ?.toLowerCase()
                    .trim()
                    .replace(/\s/g, "")

                    .includes(keyword.toLowerCase().trim().replace(/\s/g, ""))
            ) {
                newNodes.push(n);
            }
        }
        return newNodes;
    };

    const filteredList = keywordFilter([...dataList], query);

    /**
     *
     * رندر کردن محتوا با استفاده از متد
     *
     */
    return renderSelect(
        width,
        theme,
        wrapperRef,
        inputRef,
        size,
        toggleList,
        handleToggle,
        selectedList,
        setSelectedList,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        placeholder,
        disabled,
        query,
        handelEnter,
        handleSearch,
        handleSelected,
        checkSelected,
        removeItem,
        filteredList,
        withoutShowAllSelected,
        inputWidth,
    );
};

function renderSelect(
    width,
    theme,
    wrapperRef,
    inputRef,
    size,
    toggleList,
    handleToggle,
    selectedList,
    setSelectedList,
    onChange,
    footer,
    style,
    className,
    withoutImage,
    placeholder,
    disabled,
    query,
    handelEnter,
    handleSearch,
    handleSelected,
    checkSelected,
    removeItem,
    filteredList,
    withoutShowAllSelected,
    inputWidth,
) {
    if (width) {
        style = { ...style, ...{ width: width } };
    }

    const onImageError = (ev) => {
        ev.target.src = "/images/no_image.jpg";
    };

    return (
        <div
            className={["ui-multi-select", theme, size, className, disabled ? "disabled" : ""].join(" ")}
            ref={wrapperRef}
            style={style}
        >
            <div className={[`ui-multi-select-selection ${toggleList ? "open" : ""}`].join(" ")}>
                <div className="ui-select-click-area"></div>

                <ul
                    onClick={() => handleToggle(!toggleList)}
                    className={[`ui-multi-select-selected ${!withoutShowAllSelected ? "showMultiSelected" : ""}`].join(
                        " ",
                    )}
                >
                    {((withoutShowAllSelected && selectedList?.length <= 1) || !withoutShowAllSelected) &&
                        selectedList?.map((item, index) => {
                            return (
                                <li key={index} className={"ui-multi-select-selected-item"}>
                                    {item?.img && !withoutImage && (
                                        <img
                                            onClick={() => handleToggle(!toggleList)}
                                            className="ui-multi-select-image"
                                            src={item?.img}
                                            alt={item?.label}
                                            onError={onImageError}
                                        />
                                    )}
                                    <span onClick={() => handleToggle(!toggleList)}>{item.label}</span>
                                    <i onClick={() => removeItem(item, true)} className="icon icon-close-icon" />
                                </li>
                            );
                        })}
                    {withoutShowAllSelected && selectedList?.length > 1 && (
                        <li className={"ui-multi-select-selected-count"} onClick={() => handleToggle(!toggleList)}>
                            <span> {selectedList?.length} </span>
                            <span> انتخاب </span>
                        </li>
                    )}
                    <li
                        style={
                            selectedList?.length > 0 || query.length > 0
                                ? { width: `calc(${inputWidth}ch + 5px)` }
                                : { width: "100%" }
                        }
                        className={"ui-multi-select-input"}
                        onClick={() => handleToggle(true)}
                    >
                        <input
                            ref={inputRef}
                            value={query}
                            onChange={(e) => {
                                handleSearch(e.target.value);
                            }}
                            onPaste={(e) => {
                                handleSearch(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                const key = e.key || e.keyCode;
                                if (key === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            onKeyUp={(e) => {
                                const key = e.key || e.keyCode;
                                if (key === "Enter") {
                                    const val = e.target.value;
                                    handelEnter(val);
                                }
                            }}
                            disabled={disabled}
                            placeholder={selectedList?.length <= 0 ? placeholder : ""}
                        />
                    </li>
                </ul>

                {selectedList?.length > 0 && (
                    <i
                        className="icon icon-close-icon ui-multi-select-close"
                        onClick={() => {
                            setSelectedList([]);
                            typeof onChange === "function" && onChange([]);
                        }}
                    />
                )}
                {selectedList?.length <= 0 && (
                    <i
                        onClick={() => handleToggle(!toggleList)}
                        className={`ui-multi-select-arrow icon ${toggleList ? "icon-arrow-up" : "icon-arrow-down"}`}
                    />
                )}
            </div>
            {toggleList && (
                <ul className="ui-multi-select-list">
                    {filteredList?.map((item, index) => {
                        return (
                            <li
                                className={`ui-multi-select-list-item ${checkSelected(item) ? "selected-item" : ""}`}
                                key={index}
                                onClick={() => handleSelected(item)}
                            >
                                {item?.node ? (
                                    item.node
                                ) : (
                                    <>
                                        <span>
                                            {item?.icon && !withoutImage && (
                                                <img
                                                    className="ui-multi-select-list-item-image-icon"
                                                    src={item.icon}
                                                    alt={item.label}
                                                />
                                            )}
                                            {item?.img && !withoutImage && (
                                                <img
                                                    className="ui-multi-select-list-item-img"
                                                    onError={onImageError}
                                                    src={item.img}
                                                    alt={item.label}
                                                />
                                            )}
                                            {item.label}
                                        </span>
                                    </>
                                )}
                                {checkSelected(item) && (
                                    <span>
                                        <i className="icon icon-checkbox-tick" />
                                    </span>
                                )}
                            </li>
                        );
                    })}
                    {filteredList?.length <= 0 && <li className="ui-multi-select-data-empty">داده‌ای یافت نشد</li>}
                    {footer && <li className="ui-multi-select-list-item-footer">{footer}</li>}
                </ul>
            )}
        </div>
    );
}

UiMultiSelect.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    list: PropTypes.array,
    width: PropTypes.number,
    footer: PropTypes.node,
    withoutImage: PropTypes.bool,
    withoutShowAllSelected: PropTypes.bool, //بعد از این که تعداد ایتم انتخاب شده بیشتر از 1 شد همه را نمایش دهد یا فقط تعداد رو بنویسد
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    output: PropTypes.oneOf(["object", "value"]),
    disabled: PropTypes.bool,
    value: PropTypes.array,
};

export default UiMultiSelect;
