import "./UiTable.scss";
import React from "react";
import PropTypes from "prop-types";
import UiTableBody from "./body/UiTableBody";
import { UiTableProvider } from "./context/UiTableContext";

const UiTable = (props) => {
    const { fields, searchParams, mappFields, breadcrumbData, header } = props || {};

    // تمام فید هایی که در کانفیگ جدول مشخص شده
    const allFields = Object.keys(fields);

    // فیلدهای مختص به جدول - مانند ابزار و چک‌باکس و کولپس
    const ignoreFields = ["multiSelect", "actions", "expandable", "dataFields"];

    // تمام فید هایی که قرار است دیتای آن از ای‌پی‌آی دریافت شود
    const dataFields = allFields?.filter((x) => !ignoreFields.includes(x));

    // فیلد هایی که مشخص شده در دریافت دیتا باشند ولی ستون‌های جدول نمایش داده نشوند
    if (allFields.includes("dataFields")) {
        fields.dataFields.forEach((item) => {
            dataFields.push(item);
        });
        allFields.splice(allFields.indexOf("dataFields"), 1);
    }

    // فیلد هایی که قرار است در کولپس نمایش داده شوند
    const expandableFields = fields?.expandable?.fields;

    // فیلد هایی که قراره در ستون‌ها نمایش داده شوند
    // نکته : فیلدهایی که در کولپس باشند در ستون ها نمایش داده نمیشوند
    const showFields = allFields?.filter((x) => !expandableFields?.includes(x));

    // محاسبه اندازه عرض تمام ستون ها
    const collWidth = {
        gridTemplateColumns: showFields?.map((field) => fields?.[field]?.width)?.join(" "),
    };

    const values = {
        ...props,
        dataFields,
        showFields,
        collWidth,
        expandableFields,
        mappFields,
        breadcrumbData,
        header,
    };

    return (
        <UiTableProvider values={values}>
            <UiTableBody searchParams={searchParams} header={header} />
        </UiTableProvider>
    );
};

UiTable.propTypes = {
    theme: PropTypes.oneOf(["dark", "light"]),
    className: PropTypes.string,
    title: PropTypes.string,
    onLoadData: PropTypes.func,
    id: PropTypes.number,
    apiPaths: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    searchParams: PropTypes.object,
    breadcrumbData: PropTypes.object,
};

export default UiTable;
