import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import PropTypes from "prop-types";
import usePrevious from "hooks/usePrevious";
import "./UiMultiTreeSelect.scss";
import { isEqual, cloneDeep } from "lodash";
import { PortalWithState } from "react-portal";
import { UiModal } from "ui-components";

const onImageError = (ev) => {
    ev.target.src = "/images/no_image.jpg";
};
const flatten = (tree) => {
    let arr = tree.reduce((accumulator, item) => {
        return item?.children?.length > 0
            ? accumulator.concat([item, ...flatten(item?.children)])
            : accumulator.concat(item);
    }, []);
    return arr;
};
//ایجاد کانتکست برای نگه داری متود ها و استیت ها - پاس دادن به کامپوننت های بازگشتی
export const UiMultiTreeSelectContext = createContext(null);

const UiMultiTreeSelect = (props) => {
    const {
        placeholder = "",
        list = [],
        width,
        size = "md",
        theme,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        disabled = false,
        output = "object",
        value = [],
        withoutShowAllSelected,
        canSelectParent = false,
        defaultExpandAll = false,
        isPortal = false,
        deleteConfirmation = false,
    } = props;

    //remove Additional characters
    const additionalCharactersRegex = /[\u2000-\u200E\u2028-\u202F]/gm;

    const selectedIds = value || [];
    const wrapperRef = useRef(null);
    let inputRef = useRef(null);

    const prevProps = usePrevious({ list, selectedIds });
    // نمایش محل بلز شدن دراپ‌داون در بالا یا پایین - با توجه به موقعیت در صفحه
    const [portalPositionAlignment, setPortalPositionAlignment] = useState("open");
    // محل باز شدن دراپ‌داون به صورت پورتال
    const [portalPosition, setPortalPosition] = useState({});
    const [toggleList, setToggleList] = useState(false);
    const [query, setQuery] = useState("");
    const [inputWidth, setInputWidth] = useState("");

    const focusInput = () => {
        inputRef?.current && inputRef.current.focus();
    };
    // یافتن آیتمی که به عنوان انتخاب شده از ورودی به عنوان selectedIds دریافت شده است
    // و به عنوان آیتم انتخاب شده به selected اختصاص می‌یابد
    const listTemp = flatten(list || []);

    const findSelectedList =
        selectedIds?.map((item) => {
            return {
                ...listTemp?.find((x) => x.value.toString() === item?.toString()),
            };
        }) || [];

    const [dataList, setDataList] = useState(list || []);
    const [selectedList, setSelectedList] = useState(findSelectedList);
    const [expanded, setExpanded] = useState([]);

    /**
     * برگرداندت لیست ایتم هایی که باید به صورت باز شده باشند
     *
     * @param {*array} nodes
     * @param {*bool} firstLevel
     */

    const getAllValuesFromNodes = (nodes, firstLevel) => {
        if (firstLevel) {
            const values = [];
            for (let n of nodes) {
                values.push(n.value);
                if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                }
            }
            return values.map((i) => i.toString());
        } else {
            const values = [];
            for (let n of nodes) {
                values.push(n.value);
                if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                }
            }
            return values.map((i) => i.toString());
        }
    };

    useEffect(() => {
        setInputWidth(query.length);

        if (query && query.trim() !== "") {
            setExpanded(getAllValuesFromNodes(filteredList, true));
        } else {
            setExpanded([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    //باز شدن تمام زیر شاخه ها اگر مقدار  defaultExpandAll true  باشد
    useEffect(() => {
        if (defaultExpandAll && dataList.length > 0) {
            setExpanded(listTemp?.map((x) => x.value.toString()));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultExpandAll, dataList]);

    /**
     *
     * افزودن ایونت بسته شدن سلکت بعد از کلیک کردن در خارج از سلکت
     * این ایونت به داکیومنت اضافه میشود
     *
     */
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
    useEffect(() => {
        const scrollingbaseElem = document.querySelector(".accounts__container");
        scrollingbaseElem && scrollingbaseElem.addEventListener("scroll", onScroll);
        return () => {
            const scrollingbaseElem = document.querySelector(".accounts__container");
            scrollingbaseElem && scrollingbaseElem.removeEventListener("scroll", onScroll);
        };
    });

    /**ّ
     *  تغییر مکان پورتال
     * @returns
     */
    const onScroll = () => {
        if (!isPortal && toggleList) return;

        const rect = wrapperRef?.current?.getBoundingClientRect();
        const _bottom = rect?.bottom;
        const _left = rect?.left;
        const _width = rect?.width;

        let _style = {
            top: _bottom,
            left: _left,
            width: _width,
            position: "fixed",
            right: "auto",
        };

        if (_bottom + 300 > window.innerHeight) {
            _style["top"] = _bottom - 300 - 35;
            setPortalPositionAlignment("open-top");
        }

        setPortalPosition(_style);
        toggleList && handleToggle(false);
    };

    /**
     *
     * بررسی اینکه دیتا تغییر کرده است یا خیر
     *
     */
    useEffect(() => {
        if (!isEqual(prevProps?.list, list)) {
            setDataList(list);
            setSelectedList(findSelectedList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    /**
     * آپدیت کردن آیتم انتخاب شده در لیست
     */
    useEffect(() => {
        if (selectedIds && !isEqual(prevProps?.selectedIds, selectedIds)) {
            setSelectedList(findSelectedList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIds]);

    /**
     *
     * کنترل کردن کلیک خارج از سلکت برای بسته شدن آن
     * وقتی این ایونت فراخوانی شود دیتا به کامپوونت پدر انتقال داده  می شود
     *
     * @param {*event} event
     */
    const handleClickOutside = (event) => {
        if (toggleList && wrapperRef?.current && !wrapperRef?.current?.contains(event.target)) {
            if (
                selectedList &&
                !isEqual(
                    prevProps?.selectedIds,
                    selectedList?.map((x) => x.value),
                )
            ) {
                typeof onChange === "function" &&
                    onChange(output === "object" ? selectedList : selectedList?.map((x) => x.value));
            }
            handleToggle(false);
        }
    };

    /**
     * باز و بسته کردن سلکت
     *
     * @param {*bool} state
     */
    const handleToggle = (state) => {
        if (disabled) return;
        setToggleList(state);
        focusInput();
    };
    /**
     * هنگام اینتر زدن داخل اینپوت اولین ایتم در صورت وجود سلکت شه
     *
     * @param {*bool} state
     */
    const handelEnter = () => {
        if (canSelectParent && filteredList[0] && !selectedList.find((i) => i.value === filteredList[0].value)) {
            addItem(filteredList[0]);
            setQuery("");
        }
    };

    /**
     * حذف یک ایتم از اینم های انتخاب شده
     *
     * @param {*object} item
     * @param {*bool} canChange در لحظه دیتا رو به کامپوننت پدر ارسال کند
     */

    const removeItem = (item, canChange) => {
        const filterItems = selectedList.filter((i) => i.value !== item.value);
        setSelectedList(filterItems);

        if (canChange) {
            typeof onChange === "function" &&
                onChange(output === "object" ? filterItems : filterItems.map((x) => x.value));
        }
    };
    /**
     * افزودن یک ایتم به ایتم های انتخاب شده
     *
     * @param {*object} item
     */

    const addItem = (item) => {
        setSelectedList([...selectedList, item]);
    };

    /**
     * جستجو در لیست یا کمه کلیدی
     *
     * @param {*string} value
     */

    const handleSearch = (value) => {
        !toggleList && handleToggle(true);

        const text = value?.replace(additionalCharactersRegex, " ");

        setQuery(text);
    };
    /**
     * آیا ایتم انتخاب شده یا خیر
     *
     * @param {*object} item
     */
    const checkSelected = (item) => {
        if (selectedList.find((i) => i.value == item.value)) return true;
        else return false;
    };

    /**
     * تصمیم به این که ایتم باید حذف شود یا اضافه شود
     *
     * @param {*object} item
     */
    const handleSelected = (item) => {
        if (!item.disabled) {
            if (checkSelected(item)) removeItem(item, false);
            else addItem(item);
            focusInput();
        }
    };

    /**
     * برگرداندت لیست با توجه به کلمه کلیدی
     *
     * @param {*array} nodes
     * @param {*sting} keyword
     */

    const keywordFilter = (nodes, keyword) => {
        let newNodes = [];
        for (let n of nodes) {
            n.label = n.label.toString();
            n.label = n.label?.replace(additionalCharactersRegex, " ");

            if (n.children) {
                const nextNodes = keywordFilter(n.children, keyword);
                if (nextNodes?.length > 0) {
                    if (
                        !n.label
                            ?.toLowerCase()
                            .trim()
                            .replace(/\s/g, "")
                            .includes(keyword?.toLowerCase().trim().replace(/\s/g, ""))
                    ) {
                        n.children = nextNodes;
                    }
                }
                if (
                    nextNodes.length > 0 ||
                    n.label
                        ?.toLowerCase()
                        .trim()
                        .replace(/\s/g, "")
                        .includes(keyword.toLowerCase().trim().replace(/\s/g, ""))
                ) {
                    newNodes.push(n);
                }
                if (nextNodes?.length <= 0) {
                    n.children = [];
                }
            } else {
                if (
                    n.label
                        ?.toLowerCase()
                        .trim()
                        .replace(/\s/g, "")
                        .includes(keyword.toLowerCase().trim().replace(/\s/g, ""))
                ) {
                    newNodes.push(n);
                }
            }
        }
        return newNodes;
    };

    const filteredList = keywordFilter(cloneDeep(dataList), query);
    const [visibleModal, setVisibleModal] = useState(false);
    const [itemData, setItemData] = useState(false);

    /**
     * باز و بست کردن مودال کانفرم
     */
    const handleToggleModal = (e, item) => {
        setItemData(item);
        setVisibleModal((visible) => !visible);
    };

    /**
     *
     * رندر کردن محتوا با استفاده از متد
     *
     */
    return renderSelect(
        handleToggleModal,
        visibleModal,
        itemData,
        width,
        theme,
        wrapperRef,
        inputRef,
        size,
        toggleList,
        handleToggle,
        selectedList,
        setSelectedList,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        canSelectParent,
        placeholder,
        disabled,
        query,
        handelEnter,
        handleSearch,
        handleSelected,
        checkSelected,
        removeItem,
        filteredList,
        withoutShowAllSelected,
        inputWidth,
        expanded,
        focusInput,
        portalPosition,
        setPortalPosition,
        portalPositionAlignment,
        setPortalPositionAlignment,
        isPortal,
        deleteConfirmation,
    );
};

function renderSelect(
    handleToggleModal,
    visibleModal,
    itemData,
    width,
    theme,
    wrapperRef,
    inputRef,
    size,
    toggleList,
    handleToggle,
    selectedList,
    setSelectedList,
    onChange,
    footer,
    style,
    className,
    withoutImage,
    canSelectParent,
    placeholder,
    disabled,
    query,
    handelEnter,
    handleSearch,
    handleSelected,
    checkSelected,
    removeItem,
    filteredList,
    withoutShowAllSelected,
    inputWidth,
    expanded,
    focusInput,
    portalPosition,
    setPortalPosition,
    portalPositionAlignment,
    setPortalPositionAlignment,
    isPortal,
    deleteConfirmation,
) {
    if (width) {
        style = { ...style, ...{ width: width } };
    }
    const state = {
        expanded,
        footer,
        canSelectParent,
        withoutImage,
        handleSelected,
        checkSelected,
    };

    /**
     * باز شدن پورتال
     * @returns
     */
    const onOpen = () => {
        if (!isPortal) return;
        const rect = wrapperRef?.current?.getBoundingClientRect();
        const _bottom = rect?.bottom;
        const _left = rect?.left;
        const _width = rect?.width;

        let _style = {
            top: _bottom,
            left: _left,
            width: _width,
            position: "fixed",
            right: "auto",
        };

        if (_bottom + 300 > window.innerHeight) {
            _style["top"] = _bottom - 300 - 35;
            setPortalPositionAlignment("open-top");
        }

        setPortalPosition(_style);
    };

    /**
     * بسته شدن پورتال
     * @returns
     */
    const onClose = () => {
        if (!isPortal) return;
        setPortalPosition({});
    };

    return (
        <PortalWithState closeOnEsc onOpen={onOpen} onClose={onClose}>
            {({ openPortal, closePortal }) => (
                <>
                    <UiModal
                        visible={visibleModal}
                        title="حذف"
                        onOk={() => {
                            if (!itemData) {
                                setSelectedList([]);
                                typeof onChange === "function" && onChange([]);
                            } else {
                                removeItem(itemData, true);
                            }

                            handleToggleModal(null);
                        }}
                        onCancel={() => handleToggleModal(null)}
                        type="danger"
                    >
                        {/* توضیحات داخل مودال */}
                        <span className="text">
                            {" "}
                            آیا از حذف [ {itemData ? itemData.label : "کلیه آیتم ها"} ] اطمینان دارید؟
                        </span>
                    </UiModal>
                    <UiMultiTreeSelectContext.Provider value={state}>
                        <div
                            className={[
                                "ui-multi-tree-select",
                                theme,
                                size,
                                className,
                                disabled ? "disabled" : "",
                            ].join(" ")}
                            ref={wrapperRef}
                            style={style}
                        >
                            <div
                                className={[
                                    `ui-multi-tree-select-selection ${toggleList ? portalPositionAlignment : ""}`,
                                ].join(" ")}
                            >
                                <div className="ui-select-click-area"></div>

                                <ul
                                    onClick={() => {
                                        !toggleList ? openPortal() : closePortal();
                                        !toggleList && handleToggle(!toggleList);
                                        focusInput();
                                    }}
                                    className={[
                                        `ui-multi-tree-select-selected ${
                                            !withoutShowAllSelected ? "showMultiSelected" : ""
                                        }`,
                                    ].join(" ")}
                                >
                                    {((withoutShowAllSelected && selectedList?.length <= 1) ||
                                        !withoutShowAllSelected) &&
                                        selectedList?.map((item, index) => {
                                            return (
                                                <li key={index} className={"ui-multi-tree-select-selected-item"}>
                                                    {item?.img && !withoutImage && (
                                                        <img
                                                            onClick={() => {
                                                                !toggleList ? openPortal() : closePortal();
                                                                handleToggle(!toggleList);
                                                            }}
                                                            className="ui-multi-tree-select-image"
                                                            src={item?.img}
                                                            alt={item?.label}
                                                            onError={onImageError}
                                                        />
                                                    )}
                                                    <span
                                                        onClick={() => {
                                                            !toggleList ? openPortal() : closePortal();
                                                            handleToggle(!toggleList);
                                                        }}
                                                    >
                                                        {item.label}
                                                    </span>
                                                    <i
                                                        onClick={(e) => {
                                                            if (deleteConfirmation) {
                                                                e.stopPropagation();
                                                                handleToggleModal(e, item);
                                                            } else {
                                                                removeItem(item, true);
                                                            }
                                                        }}
                                                        className="icon icon-close-icon"
                                                    />
                                                </li>
                                            );
                                        })}
                                    {withoutShowAllSelected && selectedList?.length > 1 && (
                                        <li
                                            className={"ui-multi-tree-select-selected-count"}
                                            onClick={() => {
                                                !toggleList ? openPortal() : closePortal();
                                                handleToggle(!toggleList);
                                            }}
                                        >
                                            <span> {selectedList?.length} </span>
                                            <span> انتخاب </span>
                                        </li>
                                    )}
                                    <li
                                        style={
                                            selectedList?.length > 0 || query.length > 0
                                                ? { width: `calc(${inputWidth}ch + 5px)` }
                                                : { width: "100%" }
                                        }
                                        className={"ui-multi-tree-select-input"}
                                        onClick={() => {
                                            openPortal();
                                            handleToggle(true);
                                        }}
                                    >
                                        <input
                                            ref={inputRef}
                                            value={query}
                                            onChange={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                            onPaste={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                const key = e.key || e.keyCode;
                                                if (key === "Enter") {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onKeyUp={(e) => {
                                                const key = e.key || e.keyCode;
                                                if (key === "Enter") {
                                                    const val = e.target.value;
                                                    handelEnter(val);
                                                }
                                            }}
                                            disabled={disabled}
                                            placeholder={selectedList?.length <= 0 ? placeholder : ""}
                                        />
                                    </li>
                                </ul>

                                {selectedList?.length > 0 && (
                                    <i
                                        className="icon icon-close-icon ui-multi-tree-select-close"
                                        onClick={(e) => {
                                            if (deleteConfirmation) {
                                                e.stopPropagation();
                                                handleToggleModal(e, null);
                                            } else {
                                                setSelectedList([]);
                                                typeof onChange === "function" && onChange([]);
                                            }
                                        }}
                                    />
                                )}
                                {selectedList?.length <= 0 && (
                                    <i
                                        onClick={() => {
                                            !toggleList ? openPortal() : closePortal();
                                            handleToggle(!toggleList);
                                        }}
                                        className={`ui-multi-tree-select-arrow icon ${
                                            toggleList ? "icon-arrow-up" : "icon-arrow-down"
                                        }`}
                                    />
                                )}
                            </div>
                            {toggleList && (
                                <RenderNodeList
                                    list={filteredList}
                                    isFirstLevel={true}
                                    portalPosition={portalPosition}
                                    portalPositionAlignment={portalPositionAlignment}
                                />
                            )}
                        </div>
                    </UiMultiTreeSelectContext.Provider>
                </>
            )}
        </PortalWithState>
    );
}

UiMultiTreeSelect.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    list: PropTypes.array,
    width: PropTypes.number,
    footer: PropTypes.node,
    withoutImage: PropTypes.bool,
    canSelectParent: PropTypes.bool,
    defaultExpandAll: PropTypes.bool,
    withoutShowAllSelected: PropTypes.bool, //بعد از این که تعداد ایتم انتخاب شده بیشتر از 1 شد همه را نمایش دهد یا فقط تعداد رو بنویسد
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    output: PropTypes.oneOf(["object", "value"]),
    disabled: PropTypes.bool,
    value: PropTypes.array,
    isPortal: PropTypes.bool,
};

export default UiMultiTreeSelect;

//کامپوننت رتدر کردن لیست به صورت درختی
// eslint-disable-next-line react/prop-types
export const RenderNodeList = ({ list = [], isFirstLevel = false, portalPosition, portalPositionAlignment }) => {
    const { footer } = useContext(UiMultiTreeSelectContext);
    return (
        <ul
            className={[
                "ui-multi-tree-select-list",
                isFirstLevel ? "first" : "",
                portalPositionAlignment,
                isFirstLevel && list?.length <= 0 ? "empty" : "",
            ].join(" ")}
            style={portalPosition}
        >
            {list?.map((item, index) => {
                return <RenderNodeItem key={index} item={item} isFirstLevel={false} />;
            })}
            {isFirstLevel && list?.length <= 0 && <li className="ui-multi-tree-select-data-empty">داده‌ای یافت نشد</li>}
            {isFirstLevel && footer && <li className="ui-multi-tree-select-list-item-footer">{footer}</li>}
        </ul>
    );
};

//کامپوننت رند کردن هر ایتم درخت و ایجاد درخت زیرین به صورت بازگشتی
// eslint-disable-next-line react/prop-types
export const RenderNodeItem = ({ item = {}, index }) => {
    const [marquee, setMarquee] = useState(false);
    const { checkSelected, handleSelected, withoutImage, canSelectParent, expanded } =
        useContext(UiMultiTreeSelectContext);
    const [inlineToggleList, setInlineToggleList] = useState(expanded.includes(item.value.toString()) ? true : false);
    useEffect(() => {
        setInlineToggleList(expanded.includes(item.value.toString()) ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    /**
     * باز و بسته کردن سلکت
     *
     * @param {*bool} state
     */
    const handleInlineToggle = (state) => {
        setInlineToggleList(state);
    };

    return (
        <li key={index} style={!item?.children?.length > 0 ? { paddingRight: "14px" } : {}}>
            <div
                className={`ui-multi-tree-select-list-item ${checkSelected(item) ? "selected-item" : ""}`}
                onClick={() =>
                    item?.children && item?.children?.length > 0
                        ? !canSelectParent
                            ? handleInlineToggle(!inlineToggleList)
                            : null
                        : handleSelected(item)
                }
                onMouseEnter={() => setMarquee(true)}
                onMouseLeave={() => setMarquee(false)}
            >
                {item?.children?.length > 0 && (
                    <span className="ui-multi-tree-select-child-icons">
                        <i
                            onClick={() => canSelectParent && handleInlineToggle(!inlineToggleList)}
                            className={`ui-multi-tree-select-arrow icon ${
                                inlineToggleList ? "icon-arrow-up" : "icon-arrow-down"
                            }`}
                        />
                    </span>
                )}
                <div
                    className="ui-multi-tree-select-list-item-title"
                    onClick={() => canSelectParent && handleSelected(item)}
                >
                    {item?.node ? (
                        item.node
                    ) : (
                        <span className={"marquee"}>
                            {item?.icon && !withoutImage && (
                                <img
                                    className="ui-multi-tree-select-list-item-image-icon"
                                    src={item.icon}
                                    alt={item.label}
                                />
                            )}
                            {item?.img && !withoutImage && (
                                <img
                                    className="ui-multi-tree-select-list-item-img"
                                    onError={onImageError}
                                    src={item.img}
                                    alt={item.label}
                                />
                            )}
                            <span className={` ${marquee ? "animate" : ""}`}>{item.label}</span>
                        </span>
                    )}
                    {checkSelected(item) && (
                        <span className="ui-multi-tree-select-list-item-title-checked">
                            <i className="icon icon-checkbox-tick" />
                        </span>
                    )}
                </div>
            </div>
            {inlineToggleList && item?.children && <RenderNodeList list={item?.children} isFirstLevel={false} />}
        </li>
    );
};
