import React, { useCallback } from "react";
import { api } from "services";

export const usePermission = () => {
    const userInfo = api.userInfo();
    const userPermissions = userInfo?.user?.permissions || {};

    const Permission = useCallback(({ children, ...props }) => {
        const { type, action, showMessage } = props;
        const hasPermission = userPermissions[type][action].permission;
        if (hasPermission === 0 && showMessage) return <h1>عدم دسترسی به این بخش</h1>;
        if (hasPermission === 0) return null;
        return children;
    }, []);

    const permission = useCallback((props) => {
        const { type, action } = props || {};
        return userPermissions?.[type]?.[action];
    }, []);

    return { permission, Permission };
};
