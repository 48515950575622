import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { UiTableStateContext } from "../context/UiTableContext";
import UiActionEdit from "./UiActionEdit";
import UiActionView from "./UiActionView";
import UiActionRefresh from "./UiActionRefresh";
import UiActionAnalyzer from "./UiActionAnalyzer";
import UiActionDelete from "./UiActionDelete";
import UiActionShare from "./share/UiActionShare";
import useUiTablePermissions from "../hooks/useUiTablePermissions";

/**
 * تمام اکشن های جدول در این بخش ساخته می‌شوند مانند ویرایش و حذف و غیره
 * تمامی اکشن‌ها در تمام جداول پروژه در این قسمت تفکیک و طراحی میشوند
 * هر اکشن در یک کامپوننت مجزا ساخته میشود تا کانفلیکت‌ها به حداقل برسند
 * @param {*number} itemId شناسه آیتم
 * @param {*string} btnType نوع دکمه - آیکن - دکمه
 * @param {*} item هر رکورد یا آیتم
 */
const UiTableActions = ({ itemId, btnType = "icon", item }) => {
    const tableState = useContext(UiTableStateContext);
    const { tablePermission } = useUiTablePermissions(tableState);
    const { editOptions, removeOptions } = tableState?.apiPaths || {};

    const editRoute = editOptions?.render ? editOptions?.render(item) : editOptions?.route?.replace(":id", itemId);

    // کانفیگ‌های اکشن‌ها که در تیبل اصلی مشخص شده است به صورت آبجکتی از اکشن‌ها
    const tableActionsConfigs = tableState?.fields?.actions?.list || {};
    // آرایه‌ای از عنوان اکشن‌ها
    const tableActionsName = Object.keys(tableActionsConfigs) || [];
    // لیست تمامی اکشن‌ها در تمام جداول پروژه
    const actions = useMemo(
        () => [
            {
                key: "pin",
                tooltip: "پین",
                type: "single",
                icon: "icon-pin",
                permission: 1,
            },
            {
                key: "copy",
                tooltip: "کپی",
                type: "single",
                icon: "icon-copy",
                permission: 1,
            },
            {
                key: "analyzer",
                tooltip: "تخصیص گروه خبری",
                type: "single",
                icon: "icon-news-group",
                permission: 1,
            },
            {
                key: "export",
                tooltip: "انتشار",
                type: "single",
                icon: "icon-export",
                permission: 1,
            },
            {
                key: "add",
                tooltip: "افزودن",
                type: "single",
                icon: "icon-new",
                permission: tablePermission?.add?.permission,
            },
            {
                key: "edit",
                tooltip: "ویرایش",
                type: "single",
                icon: "icon-edit",
                disabled: editOptions?.disabled,
                href: editRoute,
                component: UiActionEdit,
                permission: tablePermission?.edit?.permission,
            },
            {
                key: "view",
                tooltip: "مشاهده",
                type: "single",
                icon: "icon-eye",
                component: UiActionView,
                permission: tablePermission?.view?.permission,
            },
            {
                key: "refresh",
                tooltip: "بروزرسانی",
                type: "multi",
                icon: "icon-reload",
                component: UiActionRefresh,
                permission: tablePermission?.refresh?.permission,
            },
            {
                key: "analyzer",
                tooltip: "افزودن شاخص آنالیز",
                type: "both",
                icon: "icon-analyzer",
                component: UiActionAnalyzer,
                permission: tablePermission?.analyzer?.permission,
            },
            {
                key: "delete",
                tooltip: "حذف",
                type: "single",
                icon: "icon-delete",
                disabled: removeOptions?.disabled,
                path: removeOptions?.path?.replace(":id", itemId),
                component: UiActionDelete,
                permission: tablePermission?.delete?.permission,
            },
            {
                key: "share",
                tooltip: "اشتراک‌گذاری",
                type: "single",
                icon: "icon-share",
                component: UiActionShare,
                permission: tablePermission?.share?.permission,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [itemId],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let finalActions = useCallback(
        actions
            // فیلتر کردن اکشن‌ها بر اساس آنهایی که در زمان ساخت جدول مشخص شده‌اند
            ?.filter((x) => tableActionsName.includes(x.key))
            // فیلتر کردن اکشن‌ها بر اساس آنهایی که فقط بر روی هر یک از رکورد ها انجام میشوند
            ?.filter((x) => ["single", "both"].includes(x.type))
            // فیلتر کردن اکشن هایی که پرمیشن دارند
            ?.filter((x) => x.permission)
            // اضافه کردن کانفیگ‌ اکشن‌ (در جدول تعریف شده) به اکشن معادل آن در لیست اکشن‌های پروژه
            ?.map((item) => {
                return { ...item, ...tableActionsConfigs[item.key] };
            }),
        [itemId],
    );

    // اگر درخواست از popup operation آمده بود
    // بررسی شود که اکشن هایی که پراپرتی مالتی دارن، نمایش داده شوند
    if (btnType === "button") {
        finalActions = finalActions?.filter((x) => tableActionsConfigs?.[x.key]?.multi);
    }

    return (
        <div className="ui-table-actions">
            {finalActions?.map((action) => {
                // رندر شدن دکمه از بیرون جدول
                if (action.render) return action.render(item, action, btnType);

                // اگر برای هر دکمه قانون نمایش وجود داشت
                if (action.condition) {
                    const isValid = action.condition(item);
                    if (!isValid) return;
                }

                // رندر شدن دکمه
                const Component = action.component;
                return <Component key={action.key} action={action} itemId={itemId} btnType={btnType} item={item} />;
            })}
        </div>
    );
};

UiTableActions.propTypes = {
    itemId: PropTypes.any,
    btnType: PropTypes.oneOf(["button", "icon"]),
    item: PropTypes.object,
};

export default UiTableActions;
