import React, { useState } from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import SliderRail from "./components/SliderRail";
import Handle from "./components/Handle";
import Track from "./components/Track";
import Tick from "./components/Tick";
import "./UiInputRange.scss";
import PropTypes from "prop-types";

/**
 * اینپوت رنج اسلایدر
 * @param {*number} min
 * @param {*number} max
 * @param {*bool} hasTick
 * @param {*number} mode
 * @param {*bool} disabled
 * @param {*number} step
 * @param {*arrey} defaultValues
 * @param {*func} onChange
 * @param {*bool} tooltipVisible
 * @param {*string} className
 */
const UiInputRange = ({
    min = 0,
    max = 100,
    hasTick,
    disabled,
    mode = 2,
    step = 1,
    defaultValues = mode === 1 ? [min] : [min, max],
    onChange,
    tooltipVisible = false,
    className = "",
}) => {
    const domain = [min, max];
    const [values, setValues] = useState(defaultValues ? defaultValues : domain);

    const handleChange = (values) => {
        setValues(values);
        typeof onChange === "function" && onChange(values);
    };

    return (
        <div className={["ui-input-range", className].join(" ")}>
            <Slider
                className="ui-input-range-container"
                mode={mode}
                step={step}
                domain={domain}
                onChange={handleChange}
                values={values}
                disabled={disabled}
            >
                <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className="slider-handles">
                            {handles.map((handle) => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                    disabled={disabled}
                                    tooltipVisible={tooltipVisible}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={mode === 1 ? undefined : false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                    disabled={disabled}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
                {hasTick && (
                    <Ticks count={10}>
                        {({ ticks }) => (
                            <div className="slider-ticks">
                                {ticks.map((tick) => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                )}
            </Slider>
        </div>
    );
};
UiInputRange.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    hasTick: PropTypes.bool,
    mode: PropTypes.number,
    disabled: PropTypes.bool,
    step: PropTypes.number,
    defaultValues: PropTypes.array,
    onChange: PropTypes.func,
    tooltipVisible: PropTypes.bool,
    className: PropTypes.string,
};
export default UiInputRange;
