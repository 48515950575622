import { useContext, useCallback } from "react";
import { api } from "services";
import { isArray, mapKeys } from "lodash";
import { UiTableDispatchContext, UiTableStateContext } from "../context/UiTableContext";
import { UiToast } from "ui-components";

/**
 * عملیات دیتا
 * مانند حذف - دریافت لیست
 */
const useUiTableData = () => {
    const tableState = useContext(UiTableStateContext);
    const tableDispatch = useContext(UiTableDispatchContext);
    const { getOptions, removeOptions } = tableState?.apiPaths; //, editOptions

    /**
     * مپ کردن فیلد هایی که در زمان ساخت UiComponent درخواست داده شده دا در خروجی جدول نمایش داده شود
     * @param {*} response
     * @param {*} fields
     */
    const mappingFields = (response) => {
        const newList = [];
        response.forEach((item) => {
            const newItem = {};
            mapKeys(item, (value, key) => {
                if (tableState?.dataFields?.includes(key)) {
                    newItem[key] = value;
                }
            });
            mapKeys(tableState.mappFields, (value, key) => {
                newItem[key] = item[value];
            });

            newList.push(newItem);
        });

        return newList;
    };

    /**
     * دریافت لیست آیتم ها
     */
    const getData = (page, searchParams) => {
        let { path, defaultParams = {} } = getOptions;

        // شماره صفحه
        defaultParams.page = page || 1;

        if (!defaultParams.no_paginate) {
            defaultParams.per_page = 10;
        }
        defaultParams = { ...defaultParams, ...searchParams };
        tableDispatch({
            type: "getData",
            oldSearchParams: {
                searchParams,
                page,
            },
        });
        api.get(path, defaultParams)
            .then((response) => response.data.result)
            .then((response) => {
                const finalData = mappingFields(response?.data || []);
                const total = response?.meta?.total || 0;
                const noPaginate = tableState?.apiPaths?.getOptions?.defaultParams?.no_paginate;
                tableDispatch({
                    type: "success",
                    value: finalData,
                    totalCount: total > 0 ? total : noPaginate && finalData.length > 0 ? "" : "empty",
                });

                // استفاده از دیتا در بیرون از جدول
                typeof tableState.onLoadData === "function" && tableState.onLoadData(response);
            })
            .catch((error) => {
                tableDispatch({ type: "error", value: error || "خطایی رخ داده است" });
            });
    };

    /**
     * حذف آیتم ها
     * @param {*} itemId
     */
    const removeItem = (itemId, callback) => {
        let ids = itemId;
        if (!isArray(itemId)) {
            ids = [ids];
        }
        let { path } = removeOptions;
        path = path.replace(":id", ids.join(","));
        tableDispatch({ type: "remove" });
        api.delete(path)
            .then((response) => {
                // بعد از حذف شدن رکورد یا رکوردها دیتای بک اند به فراخوان جدول برگردانده میشود
                if (typeof tableState.onDeleted === "function") {
                    tableState.onDeleted(response, tableState?.data, (tableData) => {
                        typeof callback === "function" && callback();
                        tableDispatch({ type: "successRemove", value: tableData });
                    });
                } else {
                    let finalData = tableState?.data;
                    if (isArray(itemId)) {
                        itemId.forEach((_id) => {
                            finalData = finalData?.filter((x) => _id != x.id);
                        });
                    } else {
                        finalData = finalData?.filter((x) => !ids.includes(x.id));
                    }
                    typeof callback === "function" && callback();
                    tableDispatch({ type: "successRemove", value: finalData });
                }
                UiToast.success("حذف با موفقیت انجام شد");
            })
            .catch((error) => {
                tableDispatch({ type: "errorRemove", value: error || "خطایی رخ داده است" });
            });
    };

    /**
     * به اشتراک گذاشتن آیتم با دپارتمان
     * @param {*} params
     */
    const shareWithDepartments = (params) => {
        tableDispatch({ type: "share" });
        const payloadFunction = tableState?.fields?.actions?.list?.share?.payload;
        let { path } = tableState?.fields?.actions?.list?.share;
        let payload = params;
        if (typeof payloadFunction === "function") payload = payloadFunction(params);
        api.post(path, payload)
            .then((response) => response.data.result)
            .then(() => {
                tableDispatch({ type: "successShare" });
                getData(tableState?.oldSearchParams?.page, tableState?.oldSearchParams?.searchParams);
                UiToast.success("اشتراک‌ گذاری با موفقیت انجام شد");
            })
            .catch((error) => {
                tableDispatch({ type: "errorShare", value: error || "خطایی رخ داده است" });
            });
    };

    /**
     * پیدا کردن آیتم با استفاده از شناسه
     * که به صورت کلاینتی انجام میشود
     * @param {*} id
     */
    const findByIds = useCallback(
        (ids) => {
            const showFieldInConfirm = tableState?.fields?.actions?.list?.delete?.showFieldInConfirm || "id";
            const items = tableState?.data?.filter((x) => ids.includes(x.id));
            return items.map((x) => x[showFieldInConfirm]).join(", ");
        },
        [tableState?.data, tableState?.fields?.actions?.list?.delete?.showFieldInConfirm],
    );

    return { getData, removeItem, shareWithDepartments, findByIds };
};

export default useUiTableData;
