import React from "react";
import PropTypes from "prop-types";
import "./UiButton.scss";
import { withRouter } from "react-router";
import UiTooltip from "./../ui-tooltip/UiTooltip";

/**
 * دکمه از نوع آیکن و باتن
 * @param {*String} className
 * @param {*string} icon
 * @param {*func} onClick
 * @param {*node} prefix
 * @param {*node} sufix
 * @param {*node} children
 * @param {*bool} loading
 * @param {*bool} hasBorder
 * @param {*string} type
 * @param {*string} target
 * @param {*string} href
 * @param {*string} buttonType
 * @param {*any} width
 * @param {*node} tooltip
 * @param {*string} tooltipPlacement
 * @param {*string} color oneOf([
        "default",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark",
    ]),
 */
let UiButton = (props) => {
    // پراپ های مشترک
    const {
        id,
        className,
        onClick,
        loading = false,
        type = "button",
        history,
        disabled = false,
        size = "md",
        tooltip,
        tooltipPlacement = "top",
        "selenium-id": seleniumId,
    } = props;

    let { style = {} } = props;

    // پراپ های دکمه از نوع مستطیل
    const { prefix, sufix, children, target, href, width, removeBorder = false, buttonType = "submit" } = props;

    // پراپ های دکمه از نوع آیکن
    const { hasBorder = true, color, icon } = props;

    const handleClick = (e) => {
        if (loading || disabled) return;
        if (target === "_blank" && href) {
            window.open(href);
        } else if (!target && href) {
            history.push(href);
        } else if (!href) {
            typeof onClick === "function" && onClick(e);
        }
    };

    // اگر دکمه باتن بود
    if (type === "button") {
        if (width) {
            style = { ...style, ...{ width: width } };
        }
        return (
            <UiTooltip title={tooltip} placement={tooltipPlacement}>
                <button
                    selenium-id={seleniumId}
                    disabled={disabled}
                    id={id}
                    type={buttonType}
                    className={[
                        className,
                        "ui-button",
                        prefix ? "ui-button-prefix" : "",
                        sufix ? "ui-button-sufix" : "",
                        loading ? "loading" : "",
                        disabled ? "disabled" : "",
                        size,
                        removeBorder ? "remove-border" : "",
                    ].join(" ")}
                    onClick={handleClick}
                    style={style}
                >
                    {prefix}
                    <span className="ui-button-text">{children}</span>
                    {sufix}
                </button>
            </UiTooltip>
        );
    }

    // اگر دکمه آیکن بود
    else if (type === "icon") {
        return (
            <UiTooltip title={tooltip} placement={tooltipPlacement}>
                <span
                    selenium-id={seleniumId}
                    id={id}
                    className={[
                        "ui-button-icon",
                        hasBorder ? "has-boeder" : "",
                        loading ? "loading" : "",
                        disabled ? "disabled" : "",
                        size,
                        color,
                        className,
                    ].join(" ")}
                    style={style}
                    onClick={handleClick}
                >
                    <i className={icon}></i>
                </span>
            </UiTooltip>
        );
    }
};

UiButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    prefix: PropTypes.node,
    sufix: PropTypes.node,
    children: PropTypes.node,
    loading: PropTypes.bool,
    hasBorder: PropTypes.bool,
    type: PropTypes.oneOf(["button", "icon"]),
    target: PropTypes.string,
    href: PropTypes.string,
    buttonType: PropTypes.string,
    width: PropTypes.any,
    tooltip: PropTypes.node,
    tooltipPlacement: PropTypes.string,
    color: PropTypes.oneOf([
        "default",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "light",
        "dark",
    ]),
};
UiButton = withRouter(UiButton);

UiButton.Button = (props) => {
    const {
        children,
        color = "primary",
        radius = "both", //left | right | both (default) | none
        outline, //true | false
        className = "",
        ...rest
    } = props;
    let radiusClass = "radius";
    if (radius) {
        if (radius === "none") radiusClass = "";
        if (radius === "left") radiusClass = "radius-left";
        if (radius === "right") radiusClass = "radius-right";
    }
    const outlineClass = outline ? "-outline" : "";

    return (
        <UiButton className={`btn ${radiusClass} btn${outlineClass}-${color} ${className}`} type="button" {...rest}>
            {children}
        </UiButton>
    );
};

UiButton.Button.propTypes = {
    children: PropTypes.string,
    color: PropTypes.string,
    radius: PropTypes.string,
    outline: PropTypes.bool,
    className: PropTypes.string,
};

UiButton.Button.displayName = "UiButton.Button";

UiButton.Icon = (props) => {
    const { children, ...rest } = props;

    return (
        <UiButton type="icon" {...rest}>
            {children}
        </UiButton>
    );
};

UiButton.Icon.propTypes = {
    children: PropTypes.string,
};

UiButton.Icon.displayName = "UiButton.Icon";

export default UiButton;
