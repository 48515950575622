import React from "react";
import { Row, Col } from "antd";
import { getfilterParamsPrefix } from "GlobalFunctions";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { UiMultiSelect } from "ui-components";
const MultiSelectField = (props) => {
    const { name, platformParams, item, handleMultiSelect, size, intl, typeDescriptionFilter , theme } = props;

    const { formatMessage } = intl;

    const preName = getfilterParamsPrefix(name);

    const options = item.items
        ? item.items.enum.map((val, index) => ({
              value: val,
              label: formatMessage({ id: `strings.${preName + val.toLowerCase()}` }),
          }))
        : item.enum.map((val, index) => ({
              value: val,
              label: formatMessage({ id: `strings.${preName + val.toLowerCase()}` }),
          }));

    const selectedX =
        _.isEmpty(platformParams) || _.isEmpty(platformParams[name])
            ? []
            : options.filter((x) => platformParams[name].includes(x.value)).map((i) => i.value);

    return (
        <Row key={name} align="middle" type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: `strings.${name}` })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={size === "md" ? 18 : 15} xl={size === "md" ? 18 : 17}>
                <UiMultiSelect
                    output="object"
                    onChange={(val) => handleMultiSelect(val, name)}
                    placeholder={formatMessage({ id: "filters.multi_select.not_have" })}
                    list={options}
                    value={selectedX}
                    withoutImage
                    theme={theme}
                />
            </Col>
        </Row>
    );
};

export default injectIntl(MultiSelectField);
