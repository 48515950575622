import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { FormattedDate, FormattedMessage } from "react-intl";
import Loadings from "../../loadings/Loadings";
import { FormattedSimpleMsg } from "../../../../src/GlobalFunctions";
import "./PostScheduleExecute.scss";
import { img_twitter_logo, img_instagram_logo } from "ui-components/ui-images";
import { UiButton } from "ui-components";

const PostScheduleExecute = ({
    itemData,
    removeNotification,
    notifId,
    loading = { [notifId]: false },
    sendNewPostPermission,
}) => {
    const {
        error = {},
        account = { title: "test", username: "test", image: "", avatar: "", id: 1, platform: "instagram" },
        time = "2020-10-10 23:12",
        status = "need_approve",
        status_code = 1,
        post_id = 1,
        executed_at,
        created_at,
        schedule_time,
        is_scheduled = false,
        type,
        user = {},
    } = itemData;

    let errorMessage = "",
        doneMessage = "",
        isDone = false,
        isFailed = false,
        subject = "";
    const isScheduled = type === "post_created" && is_scheduled === true;
    const errorCode = error?.code;
    const badNews = isScheduled ? "زمانبندی محتوا با خطا مواجه شد" : ".انتشار محتوا با خطا مواجه شد.";

    switch (type) {
        case "post_created":
            subject = isScheduled ? "زمانبندی محتوا" : "انتشار پست جدید";
            break;
        case "post_schedule_execute":
            subject = "انتشار پست زمانبندی شده";
            break;
        case "post_schedule_need_approve":
            subject = "محتوایی در انتظار تایید شماست";
            break;
        default:
            subject = "انتشار پست جدید";
    }
    switch (status) {
        case "new":
            isDone = true;
            doneMessage = isScheduled ? "زمانبندی محتوا با موفقیت انجام شد" : ".انتشار محتوا با موفقیت انجام شد";
            break;
        case "done":
            isDone = true;
            doneMessage = "انتشار محتوا با موفقیت انجام شد.";
            break;
        case "failed":
            isFailed = true;
            break;
    }
    switch (errorCode) {
        case 403:
            errorMessage = <FormattedSimpleMsg id="ajax_error.request_forbidden" />;
            break;
        case 404:
            errorMessage = <FormattedSimpleMsg id="ajax_error.page_not_found" />;
            break;
        case 422:
            errorMessage = <FormattedSimpleMsg id="ajax_error.unprocessable_entity" />;
            break;
        case 500:
            errorMessage = <FormattedSimpleMsg id="ajax_error.internal_server_error" />;
            break;
        case 187:
            errorMessage = <FormattedSimpleMsg id="ajax_error.status_is_a_duplicate" />;
            break;
        default:
            errorMessage = "انتشار محتوا با خطا مواجه شد";
    }
    const accountImage = account?.platform === "instagram" ? img_instagram_logo : img_twitter_logo;
    const accountAddress =
        account?.platform === "instagram"
            ? `https://www.instagram.com/${account?.username}`
            : `https://twitter.com/${account?.username}`;

    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className="post-notification">
                <div className="calendar">
                    <div className="right-section">
                        <bdi dir="auto">
                            <span style={{ whiteSpace: "nowrap" }}>
                                {<FormattedDate value={executed_at?.split(" ")[0] || created_at?.split(" ")[0]} />} - {executed_at?.split(" ")[1] || created_at?.split(" ")[1]}
                            </span>
                        </bdi>
                    </div>

                    <div className="left-section">
                        {notifId && (
                            <UiButton
                                type="icon"
                                size="xxs"
                                className="icon icon-close-icon "
                                onClick={() => {
                                    removeNotification(notifId);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="assign-notif-body">
                    <div className="notif-info">
                        <i className={`icon ${isScheduled ? "icon-calendar" : "icon-copy"}`} />
                        <bdi className="text"  dir="auto">
                            {subject} ( وضعیت : <FormattedMessage id={`notification.status.${status}`} /> )
                        </bdi>
                    </div>
                    {(isDone || isFailed) && (
                        <div className={`notif-status ${isDone ? "done" : isFailed ? "failed" : ""}`}>
                            <i className={`icon ${isDone ? "icon-tick-circle" : isFailed ? "icon-warning" : ""}`} />
                            <bdi dir="auto">
                                {isDone && doneMessage}
                                {isFailed && (
                                    <>
                                        {badNews} {errorMessage}
                                    </>
                                )}{" "}
                            </bdi>
                        </div>
                    )}
                    <div className="row">
                        <span className="label"> اکانت : </span>
                        <a target="_blank" href={accountAddress}>
                            <span> {account?.title} </span>
                        </a>

                        <img style={{ width: 17, position: "relative", top: -5, right: 10 }} src={accountImage} />
                    </div>
                    {status === "need_approve" && (
                        <div className="row">
                            <span className="label">ایجاد کننده : </span>
                            <bdi dir="auto">
                                {user.name} {user.last_name}
                            </bdi>
                        </div>
                    )}
                    {isDone && schedule_time && (
                        <div className="row">
                            <span className="label">تاریخ انتشار : </span>
                            <bdi dir="auto">
                                {<FormattedDate value={schedule_time?.split(" ")[0]} />} - {schedule_time?.split(" ")[1]}
                            </bdi>
                        </div>
                    )}
                    <div className="row">
                        <span className="label">تاریخ ایجاد : </span>
                        <bdi dir="auto">
                            {<FormattedDate value={created_at.split(" ")[0]} />} - {created_at.split(" ")[1]}
                        </bdi>
                    </div>
                    <NavLink target="_blank" to={"/schedule/posts?status=" + status}>
                        <div className="view">مشاهده تقویم و زمانبندی</div>
                    </NavLink>
                </div>
            </div>
        </Loadings>
    );
};

PostScheduleExecute.propTypes = {
    platform: PropTypes.string,
    avatar: PropTypes.string,
};

export default PostScheduleExecute;
