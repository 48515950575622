import React from 'react';
import { Object } from 'core-js';
import _ from 'lodash';

const SelectedSourcesView = ({
    selectedResourceCount,
    unCategoryResourcesSelected,
    className,
    showModal,
    editMode,
    selectedResources
}) => {

    // const unCatData = _.cloneDeep(unCategoryResourcesSelected);
    const unCatData = selectedResources?.map((x) => x.objs);

    let count = 0;
    let totalCount = 0;

    return <div className={`selected-sources-list ${className}`} onClick={showModal}>

        {/* نمایش دکمه ای حداکثر 5تا منابع اول */}
        {!_.isEmpty(unCatData) &&

            Object.keys(unCatData).map((key) => {

                totalCount = totalCount + unCatData[key].length;

                return (

                    unCatData[key].map((item, index) => {

                        const finalTitle = item.title || item.name ;

                        if (count < 5) {

                            if ( (editMode && !item.isRemoved && !_.isUndefined(item.isRemoved)) || 
                                 (editMode && _.isUndefined(item.isRemoved)) || 
                                 !editMode 
                                ) {

                                count++;

                                return (
                                    <span 
                                        key={index} 
                                        className="button grey-filled small-button inline-button"
                                    >
                                        
                                        {finalTitle && finalTitle.length > 30 ? finalTitle.substr(0, 30) + " ..." : finalTitle}

                                    </span>
                                    )
                            } 
                        }
                    })
                )
            })
        }

        {/* نمایش دکمه سه نقطه در صورت بیشتر شدن تعداد منابع انتخابی از 5 تا */}
        {(selectedResourceCount > 5 || totalCount > 5) &&

            <span className="button grey-filled small-button inline-button three-dot-button">
                    
                    . . . 
            
            </span>
        }
    </div>
}

export default SelectedSourcesView