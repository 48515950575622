import React, { Suspense, lazy } from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { FormattedSimpleMsg } from "GlobalFunctions";
import Loadings from "shared/loadings/Loadings";

const QueryField = lazy(() => import("shared/queryBuilder/QueryField"));

const QueryFilterField = (props) => {
    const { name, platformParams, handleQueryField, size, theme, typeDescriptionFilter } = props;

    const defaultQuery = {
        parsedQuery: {},
        rawQuery: "",
        selectedKeyword: "",
        hasCustomQuery: false,
    };

    return (
        <Row key={name} type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {<FormattedSimpleMsg id="global.query" />}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={9} xl={size === "md" ? 15 : 11}>
                <Suspense
                    fallback={<Loadings type="box" style={{ height: "100px", width: "100px" }} spinning={true} />}
                >
                    <QueryField
                        value={_.isUndefined(platformParams.query) ? defaultQuery : platformParams.query}
                        onChange={handleQueryField}
                        theme={theme}
                    />
                </Suspense>
            </Col>
        </Row>
    );
};

export default QueryFilterField;
