import React from "react";
import PropTypes from "prop-types";
import "./UiSpin.scss";
import { isArray, isUndefined } from "lodash";

const Uispin = (props) => {
    const { children, loading = false, className, indicator, style = {} } = props;

    /**
     * اگر فرزندان در یک تگ واحد نبودند
     */
    if (isArray(children)) {
        return (
            <div className={["ui-spin inline-block", className].join(" ").trim()} style={style}>
                {children}
                {!indicator && loading ? <div className="ui-spin-loading loading"></div> : loading && indicator}
            </div>
        );
    }

    /**
     * اگر از سلف گلوزینگ تگ استفاده شده بود
     * مثلا به این صورت : <UiSpin loading={true} />
     */
    if (isUndefined(children?.type)) {
        if (!loading) return null;
        return (
            <span className={["ui-spin inline", className].join(" ").trim()} style={style}>
                {!indicator && loading ? <div className="ui-spin-loading loading"></div> : loading && indicator}
            </span>
        );
    }

    if (typeof children.type === "function") {
        const Component1 = children;
        return (
            <span className={["ui-spin block", className].join(" ").trim()} style={style}>
                {Component1}
                {!indicator && loading ? <div className="ui-spin-loading loading"></div> : loading && indicator}
            </span>
        );
    }

    const Component = children.type;
    const { children: child, className: propClassName = "", style: propStyle, ...other } = children.props;
    return (
        <Component
            {...other}
            className={["ui-spin", propClassName, className].join(" ").trim()}
            style={{ ...propStyle, ...style }}
        >
            {child}
            {!indicator && loading ? <div className="ui-spin-loading loading"></div> : loading && indicator}
        </Component>
    );
};

Uispin.propTypes = {
    children: PropTypes.node,
    indicator: PropTypes.node,
    loading: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default Uispin;
