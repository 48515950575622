import React from "react";
import "./BreadcrumbView.scss";
import { withRouter } from "react-router";

let BreadcrumbView = ({ items, history }) => {
    const totalItems = items.length;
    const onItemClick = (link) => history.push(link);
    return (
        <div className="breadcrumb-wrapper">
            <div className="breadcrumb-container">
                {items?.map(({ title, link }, index) => {
                    return (
                        <React.Fragment key={title + index}>
                            <div
                                key={title + index}
                                className="breadcrumb-item"
                                onClick={() => {
                                    onItemClick(link);
                                }}
                            >
                                {title}
                            </div>
                            {index < totalItems - 1 && <i className="breadcrumb-item-separator icon icon-arrow-left" />}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};
BreadcrumbView = withRouter(BreadcrumbView);
export default BreadcrumbView;
