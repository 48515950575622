import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./UiSwitch.scss";

/**
 * سوئیچ
 * @param {*func} onChange
 * @param {*string} className
 * @param {*bool} disabled
 * @param {*bool} checked
 * @param {*string}  name
 */
const UiSwitch = (props) => {
    const func = () => {};
    const { checked: defaultChecked, onChange = func, className = "", disabled, name = "switch" } = props;
    const [checked, setChacked] = useState();
    useEffect(() => {
        if (defaultChecked) {
            setChacked(true);
        } else {
            setChacked(false);
        }
    }, [defaultChecked]);

    return (
        <span className="ui-switch">
            <input
                type="checkbox"
                id={name}
                className="ui-switch-input"
                onChange={(e) => {
                    const temp = e.target.checked;
                    setChacked(() => {
                        typeof onChange === "function" && onChange(temp);
                        return temp;
                    });
                }}
                disabled={disabled}
                checked={checked ? true : false}
            />
            <label
                htmlFor={name}
                className={["ui-switch-toggle", className, disabled ? "ui-switch-disabled" : ""].join(" ")}
            ></label>
        </span>
    );
};

UiSwitch.propTypes = {
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
};

export default UiSwitch;
