import React, { useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import { icons } from "GlobalVariables";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { Form, Input } from "antd";
import { api } from "services";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { UiInput, UiRadio, UiRadioGroup, UiTooltip, UiToast } from "ui-components";

let ResourcesViewRightPart = ({
    form,
    platforms,
    finalTotalResourceCount,
    totalResourceCount,
    handleOk,
    handleClose,
    handleCancel,
    showResetBtn,
    editMode,
    typeWrapper,
    inlineTag,
    selectedResources,
    selectPlatform,
    selectedPlatform,
    unCategoryResourcesSelected,
    intl,
}) => {
    const { formatMessage } = intl;
    const { getFieldDecorator } = form;
    const [color, setColor] = useState("black");
    /*
     * ارسال فرم
     */
    const handleAddTag = () => {
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let formData = new FormData();

                formData.set("name", values.inputTitle);
                formData.set("force", 1);
                formData.set("type", values.radioType);
                formData.set("color", color);
                formData.set("marked", 0);

                Object.values(unCategoryResourcesSelected).forEach((platformItem, i) => {
                    formData.set(`resources[${i}][platform]`, platforms[Object.keys(unCategoryResourcesSelected)[i]]);
                    platformItem.forEach((item, index) => {
                        formData.set(`resources[${i}][ids][${index}]`, item.id);
                    });
                });

                const request = api.post("/search/tags", formData, [{ "Content-Type": "application/vnd.ms-excel" }]);

                request
                    .then((response) => {
                        const errorList = response.data.errors;

                        // اگر ارور داشت
                        if (!_.isEmpty(errorList)) {
                            //ریست کرن فرم
                            form.resetFields(["inputTitle"]);
                            return;
                        }

                        // اگر ارور نداشت
                        handleOk();
                        UiToast.success({ id: "tag.submit.success" });
                    })
                    .catch((error) => {
                        UiToast.exceptions(error);
                    })
                    .then(() => {
                        form.resetFields(["inputTitle"]);
                    });
            }
        });
    };

    return (
        <>
            {/* عنوان هدر */}
            {typeWrapper !== "full" && (
                <header>
                    <FormattedSimpleMsg id={inlineTag ? "sourceSelect.add_tag" : "sourceSelect.add_alarm"} />
                </header>
            )}

            {inlineTag && (
                <div className="tag-form">
                    {/* نوع تگ */}
                    <Form.Item label={formatMessage({ id: "tag.type" })}>
                        {getFieldDecorator("radioType", {
                            initialValue: "department",
                        })(
                            // ذخیره دسته بندی منابع به صورت سریع در مودال
                            <UiRadioGroup name="radioType">
                                <UiRadio value="department"> {formatMessage({ id: "tag.type.team" })}</UiRadio>
                                <UiRadio value="user">{formatMessage({ id: "tag.type.single" })}</UiRadio>
                            </UiRadioGroup>,
                        )}
                    </Form.Item>

                    {/* عنوان دسته */}
                    <Form.Item label={formatMessage({ id: "tag.title" })}>
                        {getFieldDecorator("inputTitle", {
                            initialValue: "",
                            rules: [
                                {
                                    required: true,
                                    message: formatMessage({ id: "tag.title.message" }),
                                },
                            ],
                        })(
                            <UiInput
                                type="text"
                                theme="dark"
                                suffix={
                                    <UiTooltip title="انتخاب رنگ">
                                        <i className="icon icon-copy" style={{ backgroundColor: color }}>
                                            <Input
                                                className="color-picker"
                                                type="color"
                                                name="inputColor"
                                                onChange={(e) => {
                                                    setColor(e.target.value);
                                                }}
                                            />
                                        </i>
                                    </UiTooltip>
                                }
                                placeholder={formatMessage({ id: "tag.title.select" })}
                                name="inputTitle"
                            />,
                        )}
                    </Form.Item>
                </div>
            )}
            <div className="rescources">
                {inlineTag && (
                    <label>
                        <FormattedSimpleMsg id={`sourceSelect.choose_resources`} /> :{" "}
                    </label>
                )}
                {/* لیست پلتفرمها براساس پراپز */}
                {typeWrapper !== "full" && (
                    <Scrollbar noScrollY={false} className="gooli" style={{ width: "100%", height: "100%" }}>
                        {platforms.map((item, key) => (
                            <div
                                key={key}
                                onClick={() => selectPlatform(key, item)}
                                className={key === selectedPlatform ? "item selected" : "item"}
                            >
                                {typeWrapper !== "full" && <i className={`icon ${icons[item]}`}></i>}

                                <span>
                                    <FormattedSimpleMsg id={`global.${item}`} />
                                </span>

                                <span>
                                    {/* تعداد منابع انتخاب شده از هر پلتفرم */}
                                    {finalTotalResourceCount?.[item] || totalResourceCount?.[item] || 0}
                                </span>
                            </div>
                        ))}
                    </Scrollbar>
                )}

                {typeWrapper === "full" &&
                    platforms.map((item, key) => (
                        <div
                            key={key}
                            onClick={() => selectPlatform(key, item)}
                            className={key === selectedPlatform ? "item selected" : "item"}
                        >
                            {typeWrapper !== "full" && <i className={`icon ${icons[item]}`}></i>}

                            <span>
                                <FormattedSimpleMsg id={`global.${item}`} />
                            </span>

                            <span>
                                {/* تعداد منابع انتخاب شده از هر پلتفرم */}
                                {finalTotalResourceCount?.[item] || totalResourceCount?.[item] || 0}
                            </span>
                        </div>
                    ))}
            </div>
            {typeWrapper !== "full" && (
                <div className="buttons">
                    <button onClick={inlineTag ? handleAddTag : handleOk} className="button orange-filled">
                        <FormattedSimpleMsg id={inlineTag ? "sourceSelect.add" : "sourceSelect.register"} />
                    </button>

                    {showResetBtn && (
                        <button onClick={handleCancel} className="button white-border">
                            <FormattedSimpleMsg
                                id={editMode || selectedResources ? "sourceSelect.reset" : "sourceSelect.empty"}
                            />
                        </button>
                    )}

                    <button onClick={handleClose} className="button white-border">
                        <FormattedSimpleMsg id="sourceSelect.cancel" />
                    </button>
                </div>
            )}
        </>
    );
};

ResourcesViewRightPart = Form.create()(ResourcesViewRightPart);
export default injectIntl(ResourcesViewRightPart);
