export const filterAccess = {
	"sort" : "orderFilter",
	"sort_type" : "orderFilter",
	"min_view" : "visitsRangeFilter",
	"max_view" : "visitsRangeFilter",
	"min_like" : "likesRangeFilter",
	"max_like" : "likesRangeFilter",
	"min_comment" : "commentsRangeFilter",
	"max_comment" : "commentsRangeFilter" ,
	"min_copy" : "copyRangeFilter",
	"max_copy" : "copyRangeFilter",
	"min_influence" : "influenceRangeFilter",
	"max_influence" : "influenceRangeFilter",
	"min_post" : "postsRangeFilter" ,
	"max_post" : "postsRangeFilter",
	"min_follower" : "followersRangeFilter",
	"max_follower" : "followersRangeFilter",
	"min_following" : "followingsRangeFilter",
	"max_following" : "followingsRangeFilter",
	"min_media" : "mediaRangeFilter",
	"max_media" : "mediaRangeFilter",
	"min_rank" : "rankRangeFilter",
	"max_rank" : "rankRangeFilter",
	"min_price" : "priceRangeFilter",
	"max_price" : "priceRangeFilter",
	"min_member" : "memberRangeFilter",
	"max_member" : "memberRangeFilter",
	"exists" : "entityFilter",
};


