import React from "react";
import "./TagContent.scss";
import { UiMultiTreeSelect } from "ui-components";
const TagContent = (props) => {
    const { tags = [], handleSelect, platformParams, selectedTagId = "", theme } = props;
    const selected = platformParams?.content_tag_ids;

    const tagsList = tags.map((item) => {
        return {
            ...item,
            label: item.title,
            children: item?.children.map((tag) => {
                return {
                    ...tag,
                    label: tag.title,
                };
            }),
        };
    });
    return (
        <div className="tag-content">
            <UiMultiTreeSelect
                theme={theme}
                output="value"
                value={selected}
                list={tagsList}
                placeholder="تگ مورد نظر را انتخاب کنید"
                defaultExpandAll
                onChange={(value) => {
                    handleSelect(value, "content_tag_ids");
                }}
            />
        </div>
    );
};

export default TagContent;
