import React from "react";
import { Avatar } from "antd";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { FormattedDate, FormattedMessage } from "react-intl";
import { originUrl } from "GlobalFunctions";
import Loadings from "../../loadings/Loadings";
import { UiButton } from "ui-components";

const PostNotification = ({ itemData, removeNotification, notifId, loading = { [notifId]: false } }) => {
    let {
        // status,
        title = "بی نام",
        username,
        time,
        avatar,
        story_title,
        platform,
        api_path,
    } = itemData;

    avatar = itemData["relation.avatar"];
    username = itemData["username"] || itemData["relation.title"];
    itemData["relationid"] = itemData["relation.id"];

    const { info, source } = originUrl(api_path, itemData, itemData);

    let formattedTime = time;
    if (Number(formattedTime))
        formattedTime =
            new Date(Number(formattedTime)).toLocaleDateString("en-US") +
            " " +
            new Date(Number(formattedTime)).toLocaleTimeString("en-US");

    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className="post-notification">
                <div className="calendar">
                    <div className="right-section">
                        {!_.isEmpty(formattedTime) && (
                            <bdi>
                                {" "}
                                {<FormattedDate value={formattedTime} />} - {formattedTime.split(" ")[1]}{" "}
                            </bdi>
                        )}
                    </div>

                    <div className="left-section">
                        {notifId && (
                            <UiButton
                                type="icon"
                                size="xxs"
                                className="icon icon-close-icon "
                                onClick={() => {
                                    removeNotification(notifId);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="body">
                    <div className="avatar">
                        <NavLink className="body" target="_blank" to={source}>
                            <Avatar src={avatar} />

                            <span className="username"> {username}</span>
                        </NavLink>
                    </div>

                    <div className="other">
                        <i className="icon icon-alarm"></i>

                        <span>
                            {story_title} (<FormattedMessage id={`global.${platform}`} />)
                        </span>
                    </div>
                </div>

                <NavLink className="body" target="_blank" to={info}>
                    <div className="title">{title}</div>
                </NavLink>
            </div>
        </Loadings>
    );
};

export default PostNotification;
