import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { injectIntl } from 'react-intl';
import { UiInput} from "ui-components";

const InputTextField = (props) => {

	const {
		name,
		platformParams,
		onBlurInputText,
		size,
        intl,
        typeDescriptionFilter,
        theme
	} = props;

	const { formatMessage } = intl;

	let defaultValue = !_.isEmpty(platformParams[name]) ? platformParams[name] : "";

	return (
		<Row key={name} type="flex">
			<Col lg={6} xl={size === "md" ? 6 : 4}>
				<span className="row-label" >
					{formatMessage({ id: `strings.${name}`})}{typeDescriptionFilter}
				</span>

			</Col>
			<Col lg={size === "md" ? 18 : 9} xl={size === "md" ? 18 : 11}>
                <UiInput
                   name={name}
                   value={defaultValue}
                   placeholder={`${formatMessage({ id: `strings.${name}`})} ${formatMessage({ id: "filters.input_text" })}`}
                   onBlur={(value) => { onBlurInputText(name, value)}}
                   theme={theme}
                />
			</Col>
		</Row>
	)
};

export default injectIntl(InputTextField);