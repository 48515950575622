import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FormattedDate } from "react-intl";
import Loadings from "../../loadings/Loadings";
import { UiButton } from "ui-components";

const ResetPasswordNotification = ({ itemData, removeNotification, notifId, loading={[notifId]:false} }) => {
    const { time, days_to_expired } = itemData;

    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className="post-notification">
                <div className="calendar">
                    <div className="right-section">
                        <bdi>
                            <span style={{ whiteSpace: "nowrap" }}>
                                {<FormattedDate value={time.split(" ")[0]} />} - {time.split(" ")[1]}
                            </span>
                        </bdi>
                    </div>

                    <div className="left-section">
                        {notifId && (
                            <UiButton
                                type="icon"
                                size="xxs"
                                className="icon icon-close-icon "
                                onClick={() => {
                                    removeNotification(notifId);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="assign-notif-body">
                    <div className="row"/>
                    <div className="row">
                        <i className="icon">
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="lock"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 1 0-56 0z"></path>
                            </svg>
                        </i>
                        <span>
                            پسورد شما تا {days_to_expired} روز دیگر منقضی خواهد شد
                        </span>
                    </div>
                    <NavLink target="_blank" to="/reset-password">
                        <div className="view">تغییر رمز عبور</div>
                    </NavLink>
                </div>
            </div>
        </Loadings>
    );
};

ResetPasswordNotification.propTypes = {
    itemData:PropTypes.object, 
    removeNotification:PropTypes.func, 
    notifId:PropTypes.number, 
    loading:PropTypes.object
};

export default ResetPasswordNotification;
