import { isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import { nodesList } from "./UiTooltip";

const useTrigger = ({ nodeRef, trigger, portalName, openFunc, closeFunction }) => {
    /**
     * بستن پورتال
     * @param {*} e
     */
    const close = useCallback(
        (e, onlyClear) => {
            // این متغیر زمانی مقداردهی میشه که روی پورتال کلیک شده باشه
            // و نخواهیم پورتال بسته بشه
            let doNotClose = false;
            e &&
                e.path &&
                e.path.forEach((el) => {
                    if (el.className === "ui-popover" || el.className === "ui-menu") {
                        doNotClose = true;
                    }
                });
            if (doNotClose) return;

            // بعد از اعمال تاخیر بسته شود
            closeFunction(onlyClear);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nodeRef.current],
    );

    /**
     * باز کردن پورتال
     */
    const open = useCallback(
        (e) => {
            if (trigger === "contextmenu") {
                e.preventDefault();
            }
            if (trigger !== "contextmenu" && trigger !== "click") {
                closeAllOpenPortals();
            }

            if (trigger === "mouseenter") {
                setTimeout(() => {
                    openFunc();
                    document.body.addEventListener("mouseover", close);
                }, 100);
            } else {
                openFunc();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nodeRef.current],
    );

    /**
     * بستن تمام پورتال هایی که باز هستند
     */
    const closeAllOpenPortals = useCallback(() => {
        // اگر منو بود نیازی نیست که همه‌ی منو ها بسته شوند
        if (!portalName || portalName === "menu") return;
        nodesList &&
            nodesList.forEach((node) => {
                if (!isEqual(node.current, nodeRef.current)) {
                    node.current && node.current.addEventListener("mouseover", close);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesList]);

    /**
     * افزودن تریگر برای باز و بسته کردن پورتال
     */
    useEffect(
        () => {
            const node = nodeRef?.current;

            if (node) {
                if (trigger === "click") {
                    // click
                    node.addEventListener("click", open);
                    document.addEventListener("mousedown", close);
                } else if (trigger === "contextmenu") {
                    // contextmenu
                    node.addEventListener("contextmenu", open);
                    document.addEventListener("mousedown", close);
                } else if (trigger === "mouseover") {
                    // mouseover
                    node.addEventListener("mouseover", open);
                    node.addEventListener("mouseout", close);
                } else if (trigger === "mouseenter") {
                    // mouseenter
                    node.addEventListener("mouseenter", open);
                    node.addEventListener("mouseout", close);
                    
                } else {
                    // hover
                    node.addEventListener("mouseover", open);
                    if (portalName === "popover") {
                        document.addEventListener("mousedown", close);
                        node.addEventListener("mouseout", (e) => {
                            // در این حالت فقط تایم اوت رو کنسل کنه تا پاپ اوور باز نشه
                            close(e, true);
                        });
                    } else node.addEventListener("mouseout", close);
                }

                // در هر کجای صفحه که اسکلول شود تمام تولتیپ ها و پاپ اوور ها بسته شود
                var elements = document.querySelectorAll(".ScrollbarsCustom");
                elements.forEach(function (el) {
                    const element = el.querySelector(".wrapper .content");
                    element.addEventListener("scroll", close, { passive: false });
                });

                return () => {
                    if (trigger === "click") {
                        // click
                        node.removeEventListener("click", open);
                        document.removeEventListener("mousedown", close);
                    } else if (trigger === "contextmenu") {
                        // contextmenu
                        node.removeEventListener("contextmenu", open);
                        document.removeEventListener("mousedown", close);
                    } else if (trigger === "mouseover") {
                        // mouseover
                        node.removeEventListener("mouseover", open);
                        node.removeEventListener("mouseout", close);
                    } else if (trigger === "mouseenter") {
                        // mouseenter
                        node.removeEventListener("mouseenter", open);
                        node.removeEventListener("mouseout", close);
                    } else {
                        // mouseover
                        node.removeEventListener("mouseover", open);
                        if (portalName === "popover") {
                            document.removeEventListener("mousedown", close);
                            node.removeEventListener("mouseout", (e) => {
                                // در این حالت فقط تایم اوت رو کنسل کنه تا پاپ اوور باز نشه
                                close(e, true);
                            });
                        } else node.removeEventListener("mouseout", close);
                    }

                    // در هر کجای صفحه که اسکلول شود تمام تولتیپ ها و پاپ اوور ها بسته شود
                    elements.forEach(function (el) {
                        const element = el.querySelector(".wrapper .content");
                        element.removeEventListener("scroll", close, { passive: false });
                    });
                };
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [nodeRef.current],
    );
    return [nodeRef];
};

export default useTrigger;
