import React from "react";
import { Input, Row, Col, Slider } from "antd";
import PropTypes from "prop-types";
import { UiInputRange } from "ui-components";
class RangeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start: this.props.value[0],
            end: this.props.value[1],
        };
    }

    change = (value) => {
        this.setState(
            {
                start: value[0],
                end: value[1],
            },
            this.sendDataUp,
        );
    };

    changeInput = (e) => {
        e.target.name === "start"
            ? this.setState({ start: parseInt(e.target.value) }, this.sendDataUp)
            : this.setState({ end: parseInt(e.target.value) }, this.sendDataUp);
    };

    sendDataUp() {
        const { start, end } = this.state;

        const { field } = this.props;

        this.props.rangeChanged(start, end, field);
    }
    // در اسلایدر جدید این مورد به گونه دیگری هندل میشه که فعلا پیاده سازی نشده
    //   formatter = val => {
    //     return this.props.percentFormat ? `${val}%` : `${val}`;
    //   };

    componentWillReceiveProps(nextProps) {
        this.setState({
            start: nextProps.value[0],
            end: nextProps.value[1],
        });
    }

    render() {
        const { start, end } = this.state;

        const { disabled, minValue, maxValue, step, prefix, size } = this.props;

        return (
            <Row gutter={12} style={{ padding: 0 }}>
                <Col lg={size === "md" ? 7 : 6} xl={size === "md" ? 6 : 4}>
                    <Input
                        prefix={prefix}
                        type="number"
                        min={minValue}
                        max={maxValue}
                        name="end"
                        value={end}
                        onChange={this.changeInput}
                        disabled={!disabled}
                        className={disabled ? "" : "disabled"}
                    />
                </Col>

                <Col lg={size === "md" ? 7 : 6} xl={size === "md" ? 6 : 4}>
                    <Input
                        prefix={prefix}
                        type="number"
                        min={minValue}
                        max={maxValue}
                        name="start"
                        value={start}
                        onChange={this.changeInput}
                        disabled={!disabled}
                        className={disabled ? "" : "disabled"}
                    />
                </Col>

                <Col lg={size === "md" ? 10 : 12} xl={size === "md" ? 12 : 13}>
                    <UiInputRange
                        className="ui-range-slider-search-filter"
                        min={minValue}
                        max={maxValue}
                        step={step}
                        defaultValues={[start, end]}
                        onChange={this.change}
                        disabled={!disabled}
                        tooltipVisible={false}
                    />
                </Col>
            </Row>
        );
    }
}
export default RangeSlider;

// نوع و نام پراپ های ورودی
RangeSlider.propTypes = {
    // platform: PropTypes.string.isRequired, //Platform name
    platformChange: PropTypes.func,
    platformData: PropTypes.object, // Data to be rendered
};
