import React, { useState, useEffect } from "react";
import { UiModal, UiToast } from "ui-components";
import { api } from "services";
import "./AlertNewVersion.scss";

const AlertNewVersion = () => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [versionList, setVersionList] = useState([]);

    const closeModal=()=>{
        setVisible(false);
    }

    const versionSeen = (data) => {
        const ids = data?.map((item) => item.id);
        if (!ids?.length) {
            return;
        }

        const params = {
            ids,
        };

        api.post("/dashboard/version/seen", params)
            .catch((error) => {
                UiToast.exceptions(error);
            })
    };

    useEffect(() => {
        const checkVersion = () => {
            setLoading(true);

            api.get("/dashboard/version/check?type=platform")
                .then((response) => {
                    const { data } = response?.data?.result || {};
                    if (data?.length > 0) {
                        setVisible(true);
                        setVersionList(data);
                        versionSeen(data);
                    }
                })
                .catch((error) => {
                    UiToast.exceptions(error);
                })
                .then(() => {
                    setLoading(false);
                });
        };
        checkVersion();
    }, []);

    return (
        <div>
            <UiModal
                size="xl"
                icon="icon icon-analyze"
                loading={loading}
                visible={visible}
                title="تغییرات سامانه دیتاک"
                noFooter={true}
                onCancel={closeModal}
            >
                <div className="alert-new-version">
                    {versionList.map((version) => {
                        const { version_name, features, id } = version;
                        return (
                            <div className="alert-new-version-header" key={id}>
                                <h3>
                                    <bdi>نسخه {version_name}</bdi>
                                </h3>
                                <ul className="feature-list">
                                    {features?.map((feature) => {
                                        const { description, link } = feature;
                                        return (
                                            <li className="feature" key={description + link}>
                                                <div className="description">{description}</div>
                                                {link && (
                                                    <a className="link" href={link} target="_blank" alt="read more">
                                                        بیشتر بدانید
                                                    </a>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </UiModal>
        </div>
    );
};

export default AlertNewVersion;
