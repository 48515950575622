import React, { useState } from "react";
import { injectIntl } from "react-intl";
import "./UiErrors.scss";
import { facebookErrorHandling } from "components/planning/FacebookError";
import { UiToast } from "ui-components";
import PropTypes from "prop-types";

const uiErrors = (errors = {}) => {
    const { data, meta } = errors;

    // error, unAuthorize, unAuthenticate,
    let { message = "", status_code = 4000, external = false } = data || {};

    if (external) {
        const { code = 0, platform = "" } = meta || {};
        message = `${platform === "instagram" ? "facebook" : platform}.error_code.${
            platform === "instagram" ? facebookErrorHandling(meta, false) : code
        }`;
    }

    switch (status_code) {
        case 401:
            // عدم دسترسی
            return error401(message, status_code, external, meta);
        case 422:
            // اطلاعات ارسال شده صحیح نیست
            return error422(data);

        default:
            // خطای سمت سرور 500
            // 403 - 404
            // و تمام ارور های تعریف نشده که نیازی به ساختار خاص ندارند
            return exteraErrors(message, status_code, external);
    }
};

const error401 = (message = "", status_code = 4000, external = false, meta = {}) => {
    if (external) {
        // به کاربر خطا ی عدم دسترسی نمایش بده

        return {
            message: formatMessage(message, external),
            status_code,
            external,
        };
    } else {
        // اگر پسورد اکسپایر شده بود
        const { code } = meta;
        if (status_code === 401 && code === 406) {
            UiToast.error(formatMessage({ id: "user_login.change_password.message" }));
            window.location.href = "/reset-password";
        } else {
            // کاربر را از سایت لاگ اوت کند
            localStorage.clear();
            window.location.href = "/login";
        }
    }
};

const error422 = (data = {}) => {
    const { errors = {}, status_code = 4000, external = false } = data;
    if (errors) {
        const errorsObj = {};
        Object.entries(errors).forEach(([key, value]) => {
            const temp = value.map((msg) => {
                return formatMessage(msg);
            });
            errorsObj[formatMessage(key)] = temp;
        });
        return { errors: errorsObj, status_code, external };
    }
    return {};
};

const exteraErrors = (message = "", status_code = 4000, external = false) => {
    if (external) return { message: formatMessage(message, external), status_code, external };
    return { message: formatMessage(status_code), status_code, external };
};

const formatMessage = (msg = "", external = false) => {
    return `${external ? "" : "errors."}${msg?.toString()?.trim()?.toLowerCase()?.replace(/[ ]/g, "_")}`;
};

const UiErrors = ({ errors = {}, noCollapse = false }) => {
    // نرمال سازی خطای بک اند در فرانت
    const error = uiErrors(errors);
    //_revert
    const pathname = window?.location?.pathname || "";
    const isIrancellPatch = !!(
        pathname.includes("assignment") || pathname.includes("planning") | pathname.includes("direct")
    );

    switch (error.status_code) {
        case 422:
            return (
                <Collapse statusCode={error.status_code} noCollapse={noCollapse}>
                    {/* _revert */}

                    {isIrancellPatch ? (
                        <WrapperMessage error={{ message: "errors.422" }} />
                    ) : (
                        <Wrapper422 error={error} />
                    )}
                </Collapse>
            );

        default:
            // 401 , 500
            return (
                <Collapse statusCode={error.status_code} noCollapse={noCollapse}>
                    <WrapperMessage error={error} />
                </Collapse>
            );
    }
};

UiErrors.propTypes = {
    errors: PropTypes.object,
    noCollapse: PropTypes.bool,
};

const WrapperMessage = injectIntl(({ error = {}, intl }) => {
    return <div>{intl.formatMessage({ id: error.message })}</div>;
});

const Wrapper422 = injectIntl(({ error = {}, intl }) => {
    // خطا‌ها به صورت آبجکت
    const errorList = error?.errors;

    // کلیدهای خطا
    const errorKeys = Object.keys(errorList);

    // لیست خطاها به تفکیک هر کلید
    const errorComponent = errorKeys?.map((key) => {
        return (
            <ul className="ui-errors-list" key={key}>
                <li className="ui-errors-list-title">{intl.formatMessage({ id: key })}</li>
                {errorList?.[key]?.map((item, i) => {
                    return <li key={i}>{intl.formatMessage({ id: item })}</li>;
                })}
            </ul>
        );
    });

    if (error.status_code === 422) {
        return errorComponent;
    }

    return null;
});

const Collapse = ({ children, statusCode = 4000, noCollapse = false }) => {
    const [toggleCollapse, setToggleCollapse] = useState(true);

    // اگر بدون کولپس بود
    if (noCollapse) return children;

    // اگر کولپس بود
    return (
        <div className="ui-errors" onClick={() => setToggleCollapse((e) => !e)}>
            <span className="ui-errors-lable">خطای {statusCode} رخ داده است.</span>
            <span className="ui-errors-more">
                جزئیات
                <i className={`icon icon-arrow-${toggleCollapse ? "up" : "down"}`}></i>
            </span>
            {toggleCollapse && <div className="ui-errors-list">{children}</div>}
        </div>
    );
};

Collapse.propTypes = {
    children: PropTypes.node,
    statusCode: PropTypes.number,
    noCollapse: PropTypes.bool,
};

export { uiErrors, UiErrors };

/**
 * !نیاز ها
 * برای اروز 422 عناوین و لیست ارور ها مشخص شود
 * ارور های
 *
 */
