import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import _ from "lodash";
import moment from "moment-jalaali";
import PropTypes from "prop-types";
import { APIPaths, mapParamToView } from "GlobalVariables";
import { api } from "services";
import UserAccess from "../permission/UserAccess";
import FiltersEngineWrapper from "./FiltersEngineWrapper";
import DateTimePickerRangeField from "./fields/DateTimePickerRangeField";
import LangSelectField from "./fields/LangSelectField";
import MediaTypesField from "./fields/MediaTypesField";
import InputNumberField from "./fields/InputNumberField";
import CheckBoxField from "./fields/CheckBoxField";
import MultiCheckField from "./fields/MultiCheckField";
import InputTextField from "./fields/InputTextField";
import RadioBoxField from "./fields/RadioBoxField";
import MultiSelectField from "./fields/MultiSelectField";
import ResourceFilterField from "./fields/ResourceFilterField";
import TagContent from "./fields/TagContent";
import QueryFilterField from "./fields/QueryFilterField";
import CountField from "./fields/CountField";
import FaSelectField from "./fields/FaSelectField";
import SelectField from "./fields/SelectField";
import RangeSliderField from "./fields/RangeSliderField";
import DatePickerField from "./fields/DatePickerField";
import CheckListField from "./fields/CheckListField";
import { APIPathsOfPlatform } from "GlobalVariables";
import Resources from "shared/source-select/Resources";
import RadioBoxWithAll from "./fields/RadioBoxWithAll";
import MultiSelectFieldCustomData from "./fields/MultiSelectFieldCustomData";
import { Row, Col } from "antd";
import { UiToast } from "ui-components";
import InputStringField from "./fields/InputStringField";
import ContentCluster from "./fields/ContentCluster";
import GenderTypesField from "./fields/GenderTypesField";
import { getTypeDescriptionFilter } from "GlobalFunctions";
/**
 * @class FiltersEngine
 * An engine for manage filters over the all of app
 */
class FiltersEngine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiParams: this.props.apiParams,
            filtersPending: false,
            showDatePicker: false,
        };

        this.rangeChanged = this.rangeChanged.bind(this);
        this.handleActiveSlider = this.handleActiveSlider.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.handleClearDatePicker = this.handleClearDatePicker.bind(this);
        this.handleTimeDuration = this.handleTimeDuration.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleInputNumber = this.handleInputNumber.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.onChangeCheck = this.onChangeCheck.bind(this);
        this.onCheckAllChange = this.onCheckAllChange.bind(this);
        this.onBlurInputText = this.onBlurInputText.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.mapParamsToView = this.mapParamsToView.bind(this);
        this.getResources = this.getResources.bind(this);
        this.handleQueryField = this.handleQueryField.bind(this);
        this.handleMultiSelect = this.handleMultiSelect.bind(this);
        this.getPreSelected = this.getPreSelected.bind(this);
        this.handleCheckList = this.handleCheckList.bind(this);
        this.handleTagContentMultiSelect = this.handleTagContentMultiSelect.bind(this);
    }

    componentDidMount() {
        const apiParams = this.state.apiParams;

        if (apiParams) {
            this.mapParamsToView(apiParams);
        } else {
            this.getDynamicMappedParams();
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.limitedFilters !== this.props.limitedFilters ||
            prevProps.platform.api_path !== this.props.platform.api_path
        ) {
            this.getDynamicMappedParams();
        }

        if (prevProps.apiParams !== this.props.apiParams) {
            this.setState(
                {
                    apiParams: this.props.apiParams,
                },
                () => this.mapParamsToView(this.state.apiParams),
            );
        }
    }

    getDynamicMappedParams() {
        const { platform } = this.props;

        if (!platform.api_path) {
            return;
        }

        // گرفتن اطلاعات پارامترهای سرچ از لوکال استوریج
        let searchData = localStorage.getItem("search") ? JSON.parse(localStorage.getItem("search")) : {};
        const localApiParams = searchData.api_params || undefined;

        // اگر اطلاعات در لوکال استوریج وجود داشت، از همینجا بخوان
        if (localApiParams && localApiParams[platform.api_path]) {
            const data = localApiParams[platform.api_path];
            this.mapParamsToView(data);
            this.setState({
                filtersPending: false,
            });
        } else {
            this.setState({
                filtersPending: true,
            });

            const params = { paths: platform.api_path };

            api.post(APIPaths.searchMapping, params)
                .then((response) => response.data.result.data)
                .then((response) => {
                    this.mapParamsToView(response);
                })
                .catch((error) => {
                    UiToast.exceptions(error);
                })
                .then(() => {
                    // always executed
                    this.setState({
                        filtersPending: false,
                    });
                });
        }
    }

    /**
     * filter api params by user access
     * @param apiParams
     */
    userAccessFilter(apiParams) {
        let newApiParams = {};

        Object.keys(apiParams).forEach((key) => {
            if (UserAccess.canFilter(key)) {
                newApiParams[key] = apiParams[key];
            }
        });

        if (newApiParams.exists) {
            newApiParams.exists.items.enum = newApiParams.exists.items.enum.filter((entity) =>
                UserAccess.can(entity.replace("extracted_", ""), "EntityResources"),
            );
        }

        if (newApiParams.langs) {
            newApiParams.langs.items.enum = newApiParams.langs.items.enum.filter((lang) =>
                UserAccess.can(lang, "Languages"),
            );
        }

        return newApiParams;
    }

    /**
     * مپ کردن هر پارامتر با نحوه نمایش آن در فیلتر ها
     * @param {*} apiParams پارامترهای ای پی آی
     */
    mapParamsToView(apiParams) {
        const { limitedFilters } = this.props;

        for (let key in apiParams) {
            if (_.isArray(limitedFilters) && limitedFilters.indexOf(key) === -1) {
                delete apiParams[key];
                continue;
            }
            if (apiParams[key] && apiParams[key].type) {
                apiParams[key].type = mapParamToView[key];
            }
        }

        this.setState({
            apiParams: _.cloneDeep(this.userAccessFilter(apiParams)),
        });
    }

    /**
     * تعییر مقدار رینج اسلایدر
     * @param {*} min مقدار حداقل
     * @param {*} max مقدار حداکثر
     * @param {*} field نام پارامتری که تغییر کرده
     */
    rangeChanged(min, max, field) {
        const { platform, changePlatformParams } = this.props;

        const params = {
            ...platform.api_params,
            ["min_" + field]: min,
            ["max_" + field]: max,
        };

        changePlatformParams(params, platform.name);
    }

    /**
     * تعین وضعیت فعال بودن یا نبودن اسلایدرهای درون کامپوننت
     * @param {*} e ایونت
     * @param {*} item نام آیتم تغییر کرده
     */
    handleActiveSlider(checked, item) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [item]: checked,
        };
        changePlatformParams(params, platform.name);
    }

    /**
     * ارسال تایم ابندا و انتهای بازه زمانی
     * @param {*} formatted زمان با فرمت جلالی
     * @param {*} type مشخص کننده اینکه زمان ابتدا هست یا انتها
     */
    handleDatePicker(formatted, type) {
        const { platform, changePlatformParams, jalaliFormat, originalFormat } = this.props;
        formatted = moment(formatted, jalaliFormat).format(originalFormat);
        let params = {
            ...platform.api_params,
            [type]: formatted,
            time_duration: "",
        };
        // اگر تاریخ ابتدا انتخاب شده بود تاریخ انتخا باید خالی شود
        if (type === "from") {
            params = {
                ...params,
                to: null,
            };
        }
        changePlatformParams(params, platform.name);
    }

    /**
     * پاک کردن تایم ابندا و انتهای بازه زمانی
     * @param {*} formatted زمان با فرمت جلالی
     * @param {*} type مشخص کننده اینکه زمان ابتدا هست یا انتها
     */

    handleClearDatePicker(type) {
        const { platform, changePlatformParams } = this.props;
        let params = {
            ...platform.api_params,
            [type]: null,
            time_duration: "",
        };
        // اگر تاریخ ابتدا انتخاب شده بود تاریخ انتخا باید خالی شود
        if (type === "from") {
            params = {
                ...params,
                to: null,
            };
        }
        changePlatformParams(params, platform.name);
    }

    /**
     * ارسال تایم یک روز و یک هفته و یک ماه
     * @param {*} value مقدار فیلد
     * @param {*} type نام فیلد برای درج در فیلترهای عمومی
     */
    handleTimeDuration(value, type) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [type]: value,
            from: "",
            to: "",
        };

        changePlatformParams(params, platform.name);
    }

    /**
     *  تغییر داینامیک مقادیر دراپ داون ها
     * @param {*} val
     * @param {*} type
     */
    handleSelect(val, type) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [type]: val.value,
        };

        // ترتیب تصادفی انتخاب شده است
        if (type === "sort" && val.value === "random") {
            // تولید مقدار رندوم
            // const random = uuidv4();
            const random = Math.floor(Math.random() * 10000000);
           
            let searchConfig = JSON.parse(localStorage.getItem("search")) || null;

            searchConfig["random"] = random;

            localStorage.setItem("search", JSON.stringify(searchConfig));

        }

        changePlatformParams(params, platform.name);
    }

    /**
     *  تغییر داینامیک مقادیر دراپ داون ها
     * @param {*} val
     * @param {*} type
     */
    handleCheckList(val, type) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [type]: val,
        };
        changePlatformParams(params, platform.name);
    }

    /**
     *  تغییر داینامیک مقادیر دراپ داون ها
     * @param {*} val
     * @param {*} type
     */
    handleMultiSelect(val, type) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [type]: val.map((item) => item.value),
        };
        changePlatformParams(params, platform.name);
    }

    handleTagContentMultiSelect(val, type) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [type]: val.map((item) => item),
        };
        changePlatformParams(params, platform.name);
    }
    /**
     *  تغییر داینامیک مقادیر اینپوتها
     * @param {*} val
     * @param {*} name
     */
    handleInputNumber(val, name) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [name]: val,
        };
        changePlatformParams(params, platform.name);
    }

    /**
     *  تغییر داینامیک مقادیر استرینگ ها
     * @param {*} val
     * @param {*} name
     */
    handleStringField = (val, name) => {
        const reg = /[0-9]/g;

        if (val.length === 1 || reg.test(val)) {
            return;
        }
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [name]: val,
        };
        changePlatformParams(params, platform.name);
    };

    handleQueryField(val) {
        const { platform, changePlatformParams } = this.props;
        const { parsedQuery, selectedKeyword, hasCustomQuery } = val;

        const params = {
            ...platform.api_params,
            query_field: val,
            query: hasCustomQuery ? parsedQuery : selectedKeyword,
        };

        changePlatformParams(params, platform.name);
    }

    /**
     *  تغییر داینامیگ چک باکس ها
     * @param {*} e
     */
    handleCheckbox(checked, name) {
        const { platform, changePlatformParams } = this.props;
        const sendOneOreZero = ["top_rankings"];
        const value = sendOneOreZero.includes(name) ? (checked ? 1 : 0) : checked;
        const params = {
            ...platform.api_params,
            [name]: value,
        };
        changePlatformParams(params, platform.name);
    }

    /**
     * کنترل تیک خوردن چک باکسهای گروهی
     * @param {array} checkedList لیست مواردی که تیک خورده اند
     * @param {string} name نام فیلدی که موارد آن تیک میخورد
     */
    onChangeCheck(checkedList, name) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [name]: checkedList,
        };

        changePlatformParams(params, platform.name);

        this.setState({
            ["indeterminate_" + name]:
                !!checkedList.length && checkedList.length < this.state.apiParams[name].enum.length,
            ["checkAll_" + name]: checkedList.length === this.state.apiParams[name].enum.length,
        });
    }

    /**
     * کنترل تیک خوردن همه موارد یا هیچ مورد
     * @param {e} e ایونت تیک خوردن همه موارد
     * @param {string} name نام فیلدی که همه انتخاب همه موارد روی آن اعمال میشود
     */
    onCheckAllChange(checked, name) {
        const { platform, changePlatformParams } = this.props;
        const params = {
            ...platform.api_params,
            [name]: checked ? this.state.apiParams[name].enum : [],
        };

        changePlatformParams(params, platform.name);

        this.setState({
            ["indeterminate_" + name]: false,
            ["checkAll_" + name]: checked,
        });
    }

    /**
     * کمنرل مقدار اینپوت رشته ای
     * @param {event} e ایونت اینپوت
     */
    onBlurInputText(name, value) {
        const { platform, changePlatformParams } = this.props;

        const params = {
            ...platform.api_params,
            [name]: value,
        };

        changePlatformParams(params, platform.name);
    }

    /**
     * کنترل مقدار رادیو باتن
     * @param {e} e مقدار ایونت
     */
    handleRadio(key, value) {
        const { platform, changePlatformParams } = this.props;

        const params = {
            ...platform.api_params,
            [key]: value,
        };

        changePlatformParams(params, platform.name);
    }

    handleGlobalFilter = (val, type) => {
        const { platform, changePlatformParams } = this.props;

        const params = {
            ...platform.api_params,
            [type]: val,
        };

        changePlatformParams(params, platform.name);
    };

    /**
     * هندل کردن مقدار پیشش فرض دیت پیکر از میلادی به شمسی
     * @param {*} value
     */
    getPreSelected(value) {
        const { jalaliFormat, originalFormat } = this.props;

        return !_.isEmpty(value) ? moment(value, originalFormat).format(jalaliFormat).toString() : "";
    }

    /**
     * هندل کردن مقادیر منابع
     */
    getResources(sourceIds, flatIds, added, removed, objs, name) {
        if (_.isEmpty(objs) || _.isEmpty(flatIds)) return null;

        const { platform, changePlatformParams } = this.props;

        let finalFlatIds = [...flatIds];

        if (!_.isEmpty(removed)) finalFlatIds = finalFlatIds.filter((id) => !removed[0].ids.includes(id));

        let finalObjs = [...objs];

        finalObjs[0].objs = finalObjs[0].objs.filter((item) => finalFlatIds.includes(item.id));

        let params = {
            ...platform.api_params,
            //resource_ids: flatIds,
            [name]: finalFlatIds,
            [`resource_objects_${name}`]: finalObjs,
        };

        changePlatformParams(params, platform.name);
    }

    render() {
        const {
            platform,
            popupPlatform,
            hasCollapse,
            jalaliFormat,
            intl,
            queryFilter,
            countFilter,
            tags,
            tagContent,
            resetSource,
            hasMask,
            sourcePage,
            searchFunction,
            resetSearchFilters,
            size = "lg",
            externalData,
            accounts,
            showFields,
            changePlatformParams,
            closeOutside,
            theme,
        } = this.props;

        const platformName = platform.name;

        const platformParams = platform.api_params;

        const apiPath = platform.api_path;

        const { apiParams, filtersPending } = this.state;

        const { formatMessage } = intl;

        const { samane_is_assigned: samaneIsAssigned, samane_is_deleted: samaneIsDeleted } = platformParams
            ? platformParams
            : {};

        const noSelected = { value: "", label: formatMessage({ id: "filters.filter_engine.not_have" }) };

        return (
            <FiltersEngineWrapper
                className="search-opstions"
                hasMask={hasMask}
                popupPlatform={popupPlatform}
                hasCollapse={hasCollapse}
                platformName={platformName}
                searchFunction={searchFunction}
                resetSearchFilters={resetSearchFilters}
                filtersPending={filtersPending}
                closeOutside={closeOutside}
                sourcePage={sourcePage}
                theme={theme}
            >
                {apiParams &&
                    platformParams &&
                    Object.keys(apiParams).map((name, index) => {
                        const item = apiParams[name];
                        const typeDescriptionFilter = item?.for ? getTypeDescriptionFilter(item?.for) : null;

                        // بازه زمانی
                        if (name === "from" && !apiParams.is_resource) {
                            return (
                                <DateTimePickerRangeField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    platformParams={platformParams}
                                    handleClearDatePicker={this.handleClearDatePicker.bind(this)}
                                    handleDatePicker={this.handleDatePicker.bind(this)}
                                    handleTimeDuration={this.handleTimeDuration.bind(this)}
                                />
                            );
                        }

                        //زبان
                        if (name === "langs" && item && item.items && item.items.enum && !_.isEmpty(item.items.enum)) {
                            return (
                                <LangSelectField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    formatMessage={formatMessage}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleMultiSelect={this.handleMultiSelect}
                                />
                            );
                        }
                        //دسته بندی محتوا
                        if (name === "categories" && UserAccess.can("contentCategory", "FrontEnd")) {
                            return (
                                <ContentCluster
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    noSelected={noSelected}
                                    handleMultiSelect={this.handleMultiSelect}
                                    formatMessage={formatMessage}
                                    platformParams={platformParams}
                                    size={size}
                                    platform={platform}
                                    changePlatformParams={changePlatformParams}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                />
                            );
                        }

                        // محدوده جستجو
                        // <SearchAreaFild
                        // 	size={size}
                        // 	onChange={val => this.handleGlobalFilter(val, 'searchArea')}
                        // 	name='searchArea'
                        // />

                        //مدیا تایپ
                        if (name === "media_types" && !_.isEmpty(item)) {
                            return (
                                <MediaTypesField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    item={item}
                                    formatMessage={formatMessage}
                                    platformParams={platformParams}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleMultiSelect={this.handleMultiSelect}
                                />
                            );
                        }

                        //جنسیت
                        if (name === "gender" && !_.isEmpty(item)) {
                            return (
                                <GenderTypesField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    formatMessage={formatMessage}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleMultiSelect={this.handleMultiSelect}
                                />
                            );
                        }

                        //سینگل سلکت
                        if (item && item.type && item.type === "select" && name !== "sort_type") {
                            return (
                                <SelectField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    noSelected={noSelected}
                                    handleSelect={this.handleSelect}
                                    apiParams={this.state.apiParams}
                                    platform={platform}
                                />
                            );
                        }

                        // اسلایدر
                        if (
                            (name.includes("min_") || name.includes("_min")) &&
                            name !== "min_talking" &&
                            name !== "sentiment_objective_min"
                        ) {
                            let minValue = 0;
                            let maxValue = 16000000;
                            let step = 1;
                            let prefix = "";

                            if (name.includes("view")) {
                                minValue = 0;
                                maxValue = 16000000;
                                step = 1;
                                prefix = "";
                            }
                            if (name.includes("advert")) {
                                maxValue = 100;
                                step = 1;
                                prefix = "%";
                            }
                            if (name.includes("sentiment")) {
                                minValue = 0;
                                maxValue = 100;
                                step = 1;
                            }
                            if (name.includes("relation_influence") || name.includes("influence")) {
                                minValue = 0;
                                maxValue = 100;
                                step = 1;
                            }
                            if (name.includes("relation_ages") || name.includes("ages")) {
                                minValue = 10;
                                maxValue = 100;
                                step = 1;
                            }

                            return (
                                <RangeSliderField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    handleActiveSlider={this.handleActiveSlider}
                                    rangeChanged={this.rangeChanged}
                                    minValue={minValue}
                                    maxValue={maxValue}
                                    step={step}
                                    prefix={prefix}
                                />
                            );
                        }

                        // اینپوت نامبر
                        if (item && item.type && item.type === "input_number") {
                            return (
                                <InputNumberField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    platformParams={platformParams}
                                    handleInputNumber={this.handleInputNumber}
                                />
                            );
                        }

                        // اینپوت استرینگ
                        if (item && item.type && item.type === "query_string") {
                            return (
                                <InputStringField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    platformParams={platformParams}
                                    handleInputText={this.handleStringField}
                                    min={2}
                                />
                            );
                        }

                        // چک باکس
                        if ((item && item.type && item.type === "checkbox") || name === "top_rankings") {
                            return (
                                <CheckBoxField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    handleCheckbox={this.handleCheckbox}
                                />
                            );
                        }

                        // دیت پیکر
                        if (item && item.type && item.type === "datepicker") {
                            return (
                                <DatePickerField
                                    key={index}
                                    name={name}
                                    size={size}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    platformParams={platformParams}
                                    jalaliFormat={jalaliFormat}
                                    formatMessage={formatMessage}
                                    handleDatePicker={this.handleDatePicker}
                                    handleClearDatePicker={this.handleClearDatePicker}
                                    getPreSelected={this.getPreSelected}
                                />
                            );
                        }

                        // مالتی چک
                        if (item && item.type && item.type === "multicheck") {
                            return (
                                <MultiCheckField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    indeterminate={this.state["indeterminate_" + name]}
                                    onCheckAllChange={(e) => this.onCheckAllChange(e, name)}
                                    checked={this.state["checkAll_" + name]}
                                />
                            );
                        }

                        // اینپوت تکست
                        if (item && item.type && item.type === "inputtext") {
                            return (
                                <InputTextField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    platformParams={platformParams}
                                    onBlurInputText={this.onBlurInputText}
                                />
                            );
                        }

                        // رادیو باتن
                        if (item && item.type && item.type === "radio") {
                            return (
                                <RadioBoxField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleRadio={this.handleRadio}
                                />
                            );
                        }

                        //مالتی سلکت
                        if (item && item.type && item.type === "multiselect") {
                            return (
                                <MultiSelectField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleMultiSelect={this.handleMultiSelect}
                                />
                            );
                        }

                        //تگ محتوا
                        if (name === "content_tag_ids") {
                            return (
                                <div key="tag_resource">
                                    <Row align="middle" type="flex">
                                        <Col lg={6} xl={size === "md" ? 6 : 4}>
                                            <span className="row-label">تگ ‌محتوا {typeDescriptionFilter}</span>
                                        </Col>

                                        <Col span={18} className="mtb-20">
                                            <TagContent
                                                theme={theme}
                                                platformParams={platformParams}
                                                tags={tagContent}
                                                apiPath={apiPath}
                                                handleSelect={this.handleTagContentMultiSelect}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            );
                        }

                        // کامنت پست های تگ منابع
                        if (
                            item &&
                            item.type &&
                            (item.type === "resource" || item.type === "post_resource") &&
                            sourcePage !== "Resources" &&
                            (name !== "ids" ||
                                (name === "ids" && !apiParams["resource_ids"] && !apiParams["post_resource_ids"]))
                        ) {
                            const resourcePlatforms =
                                APIPathsOfPlatform[apiPath] && APIPathsOfPlatform[apiPath].resources
                                    ? APIPathsOfPlatform[apiPath].resources
                                    : false;

                            const selectedResources =
                                platformParams && !_.isEmpty(platformParams[`resource_objects_${name}`])
                                    ? platformParams[`resource_objects_${name}`]
                                    : [];

                            const editMode = !_.isEmpty(selectedResources) ? true : false;

                            const tempCount =
                                platformParams &&
                                platformParams[`resource_objects_${name}`] &&
                                platformParams[`resource_objects_${name}`][0]
                                    ? platformParams[`resource_objects_${name}`][0].objs.length
                                    : 0;

                            return (
                                <div key={`tag_resource_${name}`}>
                                    {item.type === "resource" && (
                                        <Row align="middle" type="flex">
                                            <Col lg={6} xl={size === "md" ? 6 : 4}>
                                                <span className="row-label">
                                                    {item.type === "resource"
                                                        ? formatMessage({ id: "filters.filter_engine.tags" })
                                                        : formatMessage({ id: "filters.filter_engine.tags_post" })}
                                                    {typeDescriptionFilter}
                                                </span>
                                            </Col>

                                            <Col
                                                span={size === "md" ? 9 : size === "lg" ? 6 : size === "sm" ? 18 : 9}
                                                className="mtb-20"
                                            >
                                                <ResourceFilterField
                                                    theme={theme}
                                                    platformParams={platformParams}
                                                    tags={tags}
                                                    apiPath={apiPath}
                                                    handleSelect={this.handleSelect}
                                                />
                                            </Col>
                                        </Row>
                                    )}

                                    {resourcePlatforms && (
                                        <Row key="resource" align="middle" type="flex">
                                            <Col lg={6} xl={size === "md" ? 6 : 4}>
                                                <span className="row-label">
                                                    {item.type === "resource"
                                                        ? formatMessage({ id: "filters.filter_engine.source" })
                                                        : formatMessage({ id: "filters.filter_engine.source_post" })}
                                                    {typeDescriptionFilter}
                                                </span>
                                            </Col>

                                            <Col lg={18} xl={size === "md" ? 18 : 20} className="search-resource">
                                                <Resources
                                                    name={
                                                        item.type === "resource" ? "resource_ids" : "post_resource_ids"
                                                    }
                                                    getResources={this.getResources}
                                                    platforms={resourcePlatforms}
                                                    selectedResourceCount={tempCount}
                                                    reset={resetSource}
                                                    limited={5}
                                                    selectedResources={selectedResources}
                                                    editMode={editMode}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            );
                        }

                        //کوئری اینپوت
                        if (item && item.type && item.type === "query" && queryFilter) {
                            return (
                                <QueryFilterField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleQueryField={this.handleQueryField}
                                />
                            );
                        }

                        //تعداد اینپوت
                        if (item && item.type && item.type === "count" && countFilter) {
                            return (
                                <CountField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleInputNumber={this.handleInputNumber}
                                />
                            );
                        }

                        //سینگل سلکت
                        if (item && item.type && item.type === "select-fa") {
                            return (
                                <FaSelectField
                                    theme={theme}
                                    key={index}
                                    name={name}
                                    size={size}
                                    platformParams={platformParams}
                                    item={item}
                                    noSelected={noSelected}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleSelect={this.handleSelect}
                                />
                            );
                        }

                        //لیست چک باکس
                        if (item && item.type && item.type === "checklist") {
                            return (
                                <CheckListField
                                    key={index}
                                    name={name}
                                    size={size}
                                    theme={theme}
                                    platformParams={platformParams}
                                    item={item}
                                    typeDescriptionFilter={typeDescriptionFilter}
                                    handleCheckList={this.handleCheckList}
                                />
                            );
                        }

                        if (showFields && showFields.indexOf(name) !== -1) {
                            if (name === "samane_post_status") {
                                return (
                                    // <MultiSelectField
                                    // 	key={index}
                                    // 	name={name}
                                    // 	size={size}
                                    // 	platformParams={platformParams}
                                    // 	item={item}
                                    // 	handleMultiSelect={this.handleMultiSelect}
                                    // />
                                    // <FaSelectField
                                    //     key={index}
                                    //     name={name}
                                    //     size={size}
                                    //     platformParams={platformParams}
                                    //     item={item}
                                    //     handleSelect={this.handleSelect}
                                    // />
                                    // <MultiSelectField
                                    //     key={index}
                                    //     name={name}
                                    //     size={size}
                                    //     platformParams={platformParams}
                                    //     item={item}
                                    //     noSelected={noSelected}
                                    //     handleMultiSelect={this.handleMultiSelect}
                                    //     apiParams={this.state.apiParams}
                                    // />

                                    <SelectField
                                        theme={theme}
                                        key={index}
                                        name={name}
                                        size={size}
                                        platformParams={platformParams}
                                        item={item}
                                        typeDescriptionFilter={typeDescriptionFilter}
                                        noSelected={noSelected}
                                        handleSelect={this.handleSelect}
                                        apiParams={this.state.apiParams}
                                        platform={platform}
                                    />
                                );
                            }

                            if (
                                name === "samane_is_assigned" ||
                                name === "samane_is_deleted" ||
                                name === "has_content_tag" ||
                                name === "samane_is_replied" ||
                                name === "seen" ||
                                name === "has_location"
                            ) {
                                return (
                                    <RadioBoxWithAll
                                        key={index}
                                        name={name}
                                        size={size}
                                        theme={theme}
                                        platformParams={platformParams}
                                        item={item}
                                        typeDescriptionFilter={typeDescriptionFilter}
                                        handleRadio={this.handleRadio}
                                        selected={name === "samane_is_replied" ? 0 : ""}
                                    />
                                );
                            }

                            if (name === "samane_assign_user_ids" || name === "samane_assigner_user_ids") {
                                return (
                                    <MultiSelectFieldCustomData
                                        theme={theme}
                                        key={index}
                                        name={name}
                                        size={size}
                                        platformParams={platformParams}
                                        data={externalData}
                                        typeDescriptionFilter={typeDescriptionFilter}
                                        handleMultiSelect={this.handleMultiSelect}
                                        isDisabled={samaneIsAssigned === 0 ? true : false}
                                    />
                                );
                            }
                            if (name === "account_ids") {
                                return (
                                    <MultiSelectFieldCustomData
                                        theme={theme}
                                        key={index}
                                        name={name}
                                        size={size}
                                        platformParams={platformParams}
                                        data={accounts}
                                        typeDescriptionFilter={typeDescriptionFilter}
                                        handleMultiSelect={this.handleMultiSelect}
                                        isDisabled={samaneIsAssigned === 0 ? true : false}
                                    />
                                );
                            }

                            if (name === "samane_delete_by_user_ids") {
                                return (
                                    <MultiSelectFieldCustomData
                                        theme={theme}
                                        key={index}
                                        name={name}
                                        size={size}
                                        typeDescriptionFilter={typeDescriptionFilter}
                                        platformParams={platformParams}
                                        data={externalData}
                                        handleMultiSelect={this.handleMultiSelect}
                                        isDisabled={samaneIsDeleted === 0 ? true : false}
                                    />
                                );
                            }
                        }

                        return null;
                    })}
            </FiltersEngineWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tags: state.globalFilter.tags,
        tagContent: state.globalFilter.tagContent,
    };
};

FiltersEngine.defaultProps = {
    hasMask: false,
    hasCollapse: true,
    jalaliFormat: "jYYYY-jMM-jDD HH:mm",
    originalFormat: "YYYY-M-D HH:mm:ss",
};

// نوع و نام پراپ های ورودی
FiltersEngine.propTypes = {
    platform: PropTypes.object.isRequired, //Platform data
    changePlatformParams: PropTypes.func,
    intl: intlShape.isRequired,
    externalData: PropTypes.array,
    accounts: PropTypes.array,
    // برای نمایش بعضی از فیلتر ها از این پراپ استفاده میشود، مثلا برای
    //نمایش فیلتر های پلنینگ نام های آنها بصورت آرایه ارسال میشود
    showFields: PropTypes.array,
};

FiltersEngine = injectIntl(FiltersEngine);

export default connect(mapStateToProps)(FiltersEngine);
