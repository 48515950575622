import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import usePrevious from "hooks/usePrevious";
import "./UiSelect.scss";
import { isEqual } from "lodash";

const UiSelect = (props) => {
    const {
        placeholder,
        list,
        width,
        size = "md",
        theme,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        isMarquee = true,
        disabled = false,
        output = "object",
        value,
        forceArrow,
    } = props;

    const selectedId = value;
    const wrapperRef = useRef(null);
    const prevProps = usePrevious({ list, selectedId });
    const [toggleList, setToggleList] = useState(false);
    const [marquee, setMarquee] = useState(false);

    // یافتن آیتمی که به عنوان انتخاب شده از ورودی به عنوان selectedId دریافت شده است
    // و به عنوان آیتم انتخاب شده به selected اختصاص می‌یابد
    // اگر آیتم دیفالت برای انتخاب وجود نداشت مقدار placeholder به حالت انتخاب شده پیشفرض در خواهد آمد
    const findSelected = list?.find((x) => x.value === selectedId) || { label: placeholder };

    const [dataList, setDataList] = useState(list);
    const [selected, setSelected] = useState(findSelected);

    /**
     *
     * افزودن ایونت بسته شدن سلکت بعد از کلیک کردن در خارج از سلکت
     * این ایونت به داکیومنت اضافه میشود
     *
     */
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    /**
     *
     * بررسی اینکه دیتا تغییر کرده است یا خیر
     *
     */
    useEffect(() => {
        if (!isEqual(prevProps?.list, list)) {
            setDataList(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    /**
     * آپدیت کردن آیتم انتخاب شده در لیست
     */
    useEffect(() => {
        if (!isEqual(prevProps?.selectedId, selectedId)) {
            setSelected(findSelected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    /**
     *
     * کنترل کردن کلیک خارج از سلکت برای بسته شدن آن
     *
     * @param {*event} event
     */
    const handleClickOutside = (event) => {
        if (toggleList && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            handleToggle(false);
        }
    };

    /**
     *
     * باز و بسته کردن سلکت
     *
     * @param {*bool} state
     */
    const handleToggle = (state) => {
        if (disabled) return;
        setToggleList(state);
    };

    /**
     *
     * رندر کردن محتوا با استفاده از متد
     *
     */
    return renderSelect(
        width,
        theme,
        wrapperRef,
        size,
        toggleList,
        handleToggle,
        selected,
        dataList,
        setSelected,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        placeholder,
        setMarquee,
        marquee,
        isMarquee,
        disabled,
        output,
        forceArrow,
    );
};

function renderSelect(
    width,
    theme,
    wrapperRef,
    size,
    toggleList,
    handleToggle,
    selected,
    dataList,
    setSelected,
    onChange,
    footer,
    style,
    className,
    withoutImage,
    placeholder,
    setMarquee,
    marquee,
    isMarquee,
    disabled,
    output,
    forceArrow,
) {
    if (width) {
        style = { ...style, ...{ width: width } };
    }
    return (
        <div
            className={["ui-select", theme, size, className, disabled ? "disabled" : ""].join(" ")}
            ref={wrapperRef}
            style={style}
        >
            <div className={[`ui-select-selection ${toggleList ? "open" : ""}`].join(" ")}>
                <div
                    className="ui-select-click-area"
                    onClick={() => handleToggle(!toggleList)}
                    onMouseEnter={() => isMarquee && setMarquee(true)}
                    onMouseLeave={() => isMarquee && setMarquee(false)}
                ></div>
                {selected?.img && !withoutImage && (
                    <img className="ui-select-image" src={selected.img} alt={selected.label} />
                )}
                <span className={"marquee"}>
                    <span className={`ui-select-title ${isMarquee && marquee ? "animate" : ""}`}>{selected.label}</span>
                </span>

                {!forceArrow && selected?.value && (
                    <i
                        className="icon icon-close-icon ui-select-close"
                        onClick={() => {
                            setSelected({ label: placeholder });
                            typeof onChange === "function" && onChange(output === "object" ? {} : null);
                        }}
                    />
                )}
                {(forceArrow || !selected?.value) && (
                    <i className={`ui-select-arrow icon ${toggleList ? "icon-arrow-up" : "icon-arrow-down"}`} />
                )}
            </div>
            {toggleList && (
                <ul className="ui-select-list">
                    {dataList?.map((item, index) => {
                        return (
                            <li
                                className={`ui-select-list-item ${
                                    selected.value === item.value ? "selected-item" : ""
                                }`}
                                key={index}
                                onClick={() => {
                                    setSelected(item);
                                    handleToggle(false);
                                    typeof onChange === "function" && onChange(output === "object" ? item : item.value);
                                }}
                            >
                                {item?.node ? (
                                    item.node
                                ) : (
                                    <>
                                        {item?.icon && !withoutImage && (
                                            <img
                                                className="ui-select-list-item-image-icon"
                                                src={item.icon}
                                                alt={item.label}
                                            />
                                        )}
                                        {item?.img && !withoutImage && (
                                            <img className="ui-select-list-item-img" src={item.img} alt={item.label} />
                                        )}
                                        <span> {item.label}</span>
                                    </>
                                )}
                            </li>
                        );
                    })}
                    {dataList?.length <= 0 && <li className="ui-select-data-empty">داده‌ای یافت نشد</li>}
                    {footer && <li className="ui-select-list-item-footer">{footer}</li>}
                </ul>
            )}
        </div>
    );
}

UiSelect.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    list: PropTypes.array,
    width: PropTypes.number,
    footer: PropTypes.node,
    withoutImage: PropTypes.bool,
    isMarquee: PropTypes.bool,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    output: PropTypes.oneOf(["object", "value"]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
    forceArrow: PropTypes.bool,
};

export default UiSelect;
