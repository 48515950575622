import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { injectIntl } from "react-intl";
import { UiCheckboxGroup, UiCheckbox } from "ui-components";

const MultiCheckField = (props) => {
    const { name, platformParams, item, size, intl, typeDescriptionFilter, theme } = props;

    const { formatMessage } = intl;

    const listOptions = item.enum.map((value) => {
        return { value: value, label: formatMessage({ id: `strings.${value}` }) };
    });

    return (
        <Row key={name} align="middle" type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: `strings.${name}` })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={18} xl={size === "md" ? 18 : 20}>
                <Col lg={6}>
                    <UiCheckbox
                        theme={theme}
                        indeterminate={this.state["indeterminate_" + name]}
                        onChange={(e) => this.onCheckAllChange(e, name)}
                        checked={this.state["checkAll_" + name]}
                    >
                        <FormattedSimpleMsg id="filters.multi_check.all" />
                    </UiCheckbox>
                </Col>
                <UiCheckboxGroup
                    theme={theme}
                    output="value"
                    list={listOptions}
                    className="ant-col ant-col-6"
                    value={_.isUndefined(platformParams[name]) ? [] : platformParams[name]}
                    onChange={(val) => this.onChangeCheck(val, name)}
                />
            </Col>
        </Row>
    );
};
export default injectIntl(MultiCheckField);
