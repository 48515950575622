import React from "react";
import moment from "moment-jalaali";
import DateTimePicker from "./DateTimePicker.js";

class DateTimeRangePicker extends React.Component {
	constructor(props) {
		super(props);
		this.change = this.change.bind(this);
		this.secondchange = this.secondchange.bind(this);
		this.state = {disableFromUnix: ""};
	}

	componentDidMount(){
		this.applyPreSelected();
	}

	componentDidUpdate(prevProps) {

		if (prevProps.preSelectedStart !== this.props.preSelectedStart) {

			this.applyPreSelected();

		}

	}

	applyPreSelected( ){

		const preSelected = this.props.preSelectedStart;

		if( preSelected ) {

			const { format } = this.props;

			let current = moment( preSelected, format );

			let selectedDay = current.format("jYYYYjMMjDD");

			let selectedTime = current.format("HH:mm");

			let unix = moment(selectedDay + " " + selectedTime, "jYYYYjMMjDD HH:mm").unix();

			this.setState({
				disableFromUnix: unix
			});

		}

	}

	change(unix, formatted) {
		const {onChangeStart} = this.props;
		this.setState({disableFromUnix: unix});
		if (!!onChangeStart) onChangeStart(unix, formatted);
	}

	secondchange(unix, formatted) {
		const {onChangeEnd} = this.props;
		if (!!onChangeEnd) onChangeEnd(unix, formatted);
	}

	render() {

		const {
			placeholderEnd = "",
			placeholderStart = "",
			idStart = "",
			idEnd = "",
			format,
			customClassStart,
			customClassEnd,
			containerClass,
			inputTextAlign,
			monthTitleEnable,
			preSelectedStart,
			preSelectedEnd ,
			disableStartDate ,
            disableEndDate ,
            onClearStart,
            onClearEnd,
			...rest
		} = this.props;

		const {disableFromUnix} = this.state;

		return (
			<div className="date-time-range-picker-container" style={{textAlign: "initial"}}>

				<DateTimePicker
					monthTitleEnable={monthTitleEnable}
					containerClass={containerClass}
					disableStartDate={disableStartDate}
					disableEndDate={disableEndDate}
					inputTextAlign={inputTextAlign}
					customClass={[customClassStart, "from"].join(" ")}
					placeholder={placeholderStart}
					preSelected={preSelectedStart}
                    format={format}
                    onClear={onClearStart}
					onChange={this.change}
					id={idStart}
					{...rest}
				/>

				{/*<span>تا</span>*/}

				<DateTimePicker
					monthTitleEnable={monthTitleEnable}
					containerClass={containerClass}
					disableStartDate={disableStartDate}
					disableEndDate={disableEndDate}
					inputTextAlign={inputTextAlign}
					customClass={[customClassEnd, "to"].join(" ")}
					placeholder={placeholderEnd}
					preSelected={preSelectedEnd}
					disableFromUnix={disableFromUnix}
					isDisabled={!disableFromUnix}
                    format={format}
                    onClear={onClearEnd}
					onChange={this.secondchange}
					id="datePicker"
					{...rest}
				/>

			</div>
		);
	}
}

DateTimeRangePicker.defualtProps = {
	preSelectedStart    : "" ,
	preSelectedEnd      : "" ,
	placeholderEnd      : "" ,
	placeholderStart    : "" ,
	idStart             : "" ,
	idEnd               : "" ,
	disableStartDate    : "" ,
	disableEndDate      : ""
};

export default DateTimeRangePicker;
