import React from "react";
import PropTypes from "prop-types";

/**
 * تقسیم بندی های روی خط افقی اسلایدر
 * @param {*shape} tick id, value, percent
 * @param {*number} count
 * @param {*func} format
 */
const Tick = (props) => {
    const { tick, count, format = (d) => d } = props;
    return (
        <div>
            <div className="ui-input-range-tick" style={{ left: `${tick.percent}%` }} />
            <div
                className="ui-input-range-tick-value"
                style={{ left: `${tick.percent}%`, width: `${100 / count}%`, marginLeft: `${-(100 / count) / 2}%` }}
            >
                {format(tick.value)}
            </div>
        </div>
    );
};

Tick.propTypes = {
    tick: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired,
};

export default Tick;
