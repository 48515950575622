import React from "react";
import { Row, Col } from "antd";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import { UiSelect } from "ui-components";

const SelectField = (props) => {
    const {
        name,
        platformParams,
        item,
        noSelected,
        handleSelect,
        apiParams,
        size,
        typeDescriptionFilter,
        intl,
        platform,
        theme
    } = props;

    const { formatMessage } = intl;

    const selectedSortType = platformParams.sort_type
        ? {
              value: platformParams.sort_type,
              label:
                  platformParams[name] !== "time"
                      ? formatMessage({ id: `strings.${platformParams.sort_type.toLowerCase()}` })
                      : formatMessage({ id: `strings.${(platformParams.sort_type + "_time").toLowerCase()}` }),
          }
        : noSelected;

    let options = item.enum.map((val, index) => {
        // پلتفرم هایی که نیاز دارن یک پریست برای تغییر ترجمه آنها بهشون اضافه بشه
        const apis = ["/news/posts"];
        // افزودن پریست برای ترجمه بعضی از فیلدها
        // در اینجا فقط فیلد influence نیاز به تغییر دارد
        const preset = apis.includes(platform?.api_path) ? (val === "influence" ? ".post" : "") : "";

        return {
            value: val,
            label: formatMessage({ id: `strings.${val.toLowerCase()}${preset}` }),
        };
    });

    options = [noSelected, ...options];

    const selected = isEmpty(platformParams)
        ? {}
        : platformParams[name]
        ? options.find((x) => x.value === platformParams[name])
        : noSelected;

    let sortOptions = [];

    if (name === "sort") {
        sortOptions = apiParams.sort_type.enum.map((val, index) => ({
            value: val,
            label:
                platformParams[name] !== "time"
                    ? formatMessage({ id: `strings.${val.toLowerCase()}` })
                    : formatMessage({ id: `strings.${(val + "_time").toLowerCase()}` }),
        }));

        sortOptions = [noSelected, ...sortOptions];
    }

    return (
        <Row key={name} align="middle" gutter={12} type="flex">
            <Col lg={size === "md" ? 6 : 4} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: `strings.${name}` })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col span={size === "lg" ? 6 : 9}>
                {/* مرتب سازی در فیلتر های سرچ */}
                <UiSelect
                    placeholder={formatMessage({ id: "filters.select_field.not_have" })}
                    value={selected.value}
                    onChange={(val) => {
                        handleSelect(val, name);
                    }}
                    list={options}
                    size="md"
                    theme={theme}
                    className="force"
                />
            </Col>

            {name === "sort" && (
                <Col span={size === "lg" ? 6 : 9}>
                    {/* مرتب سازی در فیلتر های سرچ */}
                    <UiSelect
                        disabled={!platformParams[name]}
                        placeholder={formatMessage({ id: "filters.select_field.not_have" })}
                        value={selectedSortType.value}
                        onChange={(val) => handleSelect(val, "sort_type")}
                        list={sortOptions}
                        size="md"
                        theme={theme}
                        className="force"
                    />
                </Col>
            )}
        </Row>
    );
};

export default injectIntl(SelectField);
