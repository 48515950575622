import React from "react";
import { Row, Col } from "antd";
import RangeSlider from "shared/RangeSlider";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { UiCheckbox } from "ui-components";
const RangeSliderField = ({
    name,
    size,
    platformParams,
    handleActiveSlider,
    rangeChanged,
    minValue,
    maxValue,
    step,
    prefix,
    preset,
    theme,
}) => {
    const tempField = name.replace("min_", "");

    let customLableSize = 4;
    let customContentSize = 19;
    switch (size) {
        case "md":
            customLableSize = 6;
            customContentSize = 18;
            break;
        case "sm":
            customLableSize = 6;
            customContentSize = 18;
            break;

        default:
            break;
    }

    return (
        <Row gutter={16} key={name} align="middle" type="flex" className="rangeslider-field">
            <Col lg={size === "md" ? 7 : 6} xl={customLableSize}>
                <UiCheckbox
                    theme={theme}
                    onChange={(checked) => handleActiveSlider(checked, tempField + "_checked")}
                    name={tempField + "_checked"}
                    checked={platformParams[tempField + "_checked"]}
                >
                    <FormattedSimpleMsg id={`${preset ? preset : "search."}${tempField}`} />
                </UiCheckbox>
            </Col>

            {/* <Col lg={5} xl={3}>

				<span className="row-label">


				</span>

			</Col> */}

            <Col lg={size === "md" ? 16 : 17} xl={customContentSize}>
                <RangeSlider
                    field={tempField}
                    disabled={platformParams[tempField + "_checked"]}
                    // platform={platformName}
                    value={[
                        platformParams["min_" + tempField] ? platformParams["min_" + tempField] : minValue,
                        platformParams["max_" + tempField] ? platformParams["max_" + tempField] : maxValue,
                    ]}
                    rangeChanged={rangeChanged}
                    minValue={minValue}
                    maxValue={maxValue}
                    step={step}
                    prefix={prefix}
                    size={size}
                />
            </Col>
        </Row>
    );
};

export default RangeSliderField;
