import React, { memo } from "react"
import { injectIntl ,FormattedMessage } from 'react-intl';
import _ from "lodash";
import moment from 'moment-jalaali';
import UserAccess from "../../permission/UserAccess";
import DateTimeRangePicker from "../../jalali-calender/DateTimeRangePicker";
import { Select } from "antd";

class DateTimeFromToFilter extends React.Component {

    constructor(props) {
        super(props)

        this.getPreSelected = this.getPreSelected.bind(this);
    }


    /**
     * هندل کردن مقدار پیشش فرض دیت پیکر از میلادی به شمسی
     * @param {*} value
     */
    getPreSelected = (value) => {

        const { jalaliFormat, originalFormat } = this.props;

        return !_.isEmpty(value) ? moment(value, originalFormat).format(jalaliFormat).toString() : "";

    }

    render() {
        const { Option } = Select;

        const {
            data,
            handleDatePicker,
            handleClearDatePicker,
            jalaliFormat,
            intl,
            handleTimeDuration
        } = this.props;


        const { formatMessage } = intl;
        const rangePickerProps = UserAccess.getTimeRangeAccess();

        const fromToList = [
            { key: "today", label: <FormattedMessage id="filters.date_time_range_general.today" /> },
            { key: "24h", label: <FormattedMessage id="filters.date_time_range_general.24h" /> },
            { key: "1w", label: <FormattedMessage id="filters.date_time_range_general.1w" /> },
            { key: "1m", label: <FormattedMessage id="filters.date_time_range_general.1m" /> },
          
        ];
      
        return (
            <>
             <DateTimeRangePicker
                format={jalaliFormat}
                placeholderStart={formatMessage({ id: 'global.from' })}
                placeholderEnd={formatMessage({ id: 'global.to' })}
                onChangeStart={(unix, formatted) => { handleDatePicker(formatted, "from") }}
                onChangeEnd={(unix, formatted) => handleDatePicker(formatted, "to")}
                onClearStart={() => {typeof handleClearDatePicker === "function" &&  handleClearDatePicker("from"); }}
                onClearEnd={() => { typeof handleClearDatePicker === "function" &&  handleClearDatePicker("to"); }}
                preSelectedStart={this.getPreSelected(data.from)}
                preSelectedEnd={this.getPreSelected(data.to)}
                containerClass="custom-input-datepicker"
                customClassStart="custom-datepicker"
                customClassEnd="custom-datepicker"
                disableStartDate={data.disableStartDate}
                disableEndDate={data.disableEndDate}
                {...rangePickerProps}
            />
            {
                handleTimeDuration &&       <Select
                onChange={(value) => handleTimeDuration(value, "time_duration")}
                dropdownClassName="report-dropdown"
                className="report-dropdown"
            >
                {fromToList?.map((item, key) => {
                        
                        return (
                            <Option key={key} value={item.key}>
                                {item.label}
                            </Option>
                        );
                    })}
            </Select>
            }
      
            </>

           
        );
    }
}


DateTimeFromToFilter.defaultProps = {
    jalaliFormat: "jYYYY-jMM-jDD HH:mm",
    originalFormat: "YYYY-M-D HH:mm:ss"
};

DateTimeFromToFilter = injectIntl(DateTimeFromToFilter);
export default memo(DateTimeFromToFilter)