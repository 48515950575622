import React, { useState } from "react";
import PropTypes from "prop-types";
import { isObject, isString } from "lodash";
import { UiErrors } from "ui-components";
import { FormattedMessage } from "react-intl";

/**
 * نمایش لیست ارور های بک اندی برای هر قسمتی از جدول که مورد نیاز بود
 * هم مودال ها - حذف و اشتراک گذاری و ...
 * هم داخل خود جدول - داده ای یافت نشد
 * @param {*object} errors
 */
const UiTableInlineError = (props) => {
    const { errors } = props;
    const [toggleCollapse, setToggleCollapse] = useState(false);
    const statusCode = errors?.response?.status;
    
    // اگر ارورها به صورت استاندارد از بک اند اومد به اینصورت برگردانده بشه
    if (errors?.response?.data?.errors?.data) {
        return <UiErrors errors={errors.response.data.errors}></UiErrors>;
    }

    // این پیغامی است که بک اند بر میگرداند و استرینگ است
    const message = errors?.response?.data?.message || "";
    if (!statusCode) return null;

    // لیست خطا ها به صورت آبجکت
    const errorList = errors?.response?.data?.errors;

    // اگر لیست خطاها از بک اند به اشتباه استرینگ آمده بود
    if (isString(errorList)) {
        const newError = errorList
            .replace(/[0-9]/g, "")
            .trim()
            .replace(/[!. -,;]/g, "_");
        return (
            <div className="ui-table-inline-error">
                <span className="ui-table-inline-error-lable">خطای {statusCode} رخ داده است.</span>
                <span className="ui-table-inline-error-more" onClick={() => setToggleCollapse((e) => !e)}>
                    جزئیات بیشتر
                    <i className={`icon icon-arrow-${toggleCollapse ? "up" : "down"}`}></i>
                </span>
                {toggleCollapse && (
                    <ul className="ui-table-error-list">
                        <li>
                            <FormattedMessage id={`errors.${newError}`}></FormattedMessage>
                        </li>
                    </ul>
                )}
            </div>
        );
    }

    // اگر لیست خطاها وجود نداشت
    if (!errorList || !isObject(errorList)) return null;

    // کلید های خطا
    const errorKeys = Object.keys(errorList);

    // لیست خطاها
    const errorComponent = errorKeys?.map((key) => {
        let newErrorItem = errorList?.[key];
        try {
            if (newErrorItem?.length)
                newErrorItem = newErrorItem
                    .replace(/[0-9]/g, "")
                    ?.trim()
                    ?.replace(/[!. -,;]/g, "_");
        } catch (error) {
            //
        }
        return (
            <ul className="ui-table-error-list" key={key}>
                <li className="ui-table-error-list-title">{key}</li>
                {isString(errorList?.[key]) ? (
                    <li>
                        <FormattedMessage id={`errors.${newErrorItem?.[key]}`}></FormattedMessage>
                    </li>
                ) : (
                    errorList?.[key]?.map((item, i) => {
                        const newError = item
                            .replace(/[0-9]/g, "")
                            .trim()
                            .replace(/[!. -,;]/g, "_");
                        return (
                            <li key={i}>
                                <FormattedMessage id={`errors.${newError}`}></FormattedMessage>
                            </li>
                        );
                    })
                )}
            </ul>
        );
    });

    return (
        <div className="ui-table-inline-error">
            <span className="ui-table-inline-error-lable">
                خطای {statusCode} رخ داده است. {message}
            </span>
            <span className="ui-table-inline-error-more" onClick={() => setToggleCollapse((e) => !e)}>
                جزئیات بیشتر
                <i className={`icon icon-arrow-${toggleCollapse ? "up" : "down"}`}></i>
            </span>
            {toggleCollapse && errorComponent}
        </div>
    );
};

UiTableInlineError.propTypes = {
    errors: PropTypes.object,
};

export default UiTableInlineError;
