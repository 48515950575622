import React from "react";
import PropTypes from "prop-types";

/**
 * قسمت بدنه اصلی مودال
 */
const UiModalBody = ({ children }) => {
    return <div className="ui-modal-body">{children}</div>;
};

UiModalBody.propTypes = {
    children: PropTypes.node,
};
export default UiModalBody;
