import React from "react";
import _ from "lodash";
import { FormattedDate, injectIntl } from "react-intl";
import Loadings from "../../loadings/Loadings";
import { UiButton } from "ui-components";

const WaveAlarmNotification = ({
    intl,
    queries,
    itemData,
    removeNotification,
    notifId,
    loading = { [notifId]: false },
}) => {
    const { formatMessage } = intl;

    let { time, query, level } = itemData;

    // itemData["relationid"] = itemData["relation.id"];

    const queryObj = queries?.find((x) => x.id === query);

    let formattedTime = time;

    if (Number(formattedTime))
        formattedTime =
            new Date(Number(formattedTime)).toLocaleDateString("en-US") +
            " " +
            new Date(Number(formattedTime)).toLocaleTimeString("en-US");

    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className="wave-alarm-notification">
                <div className="calendar">
                    <div className="right-section">
                        {!_.isEmpty(formattedTime) && (
                            <bdi>
                                {" "}
                                {<FormattedDate value={formattedTime} />} - {formattedTime.split(" ")[1]}{" "}
                            </bdi>
                        )}
                    </div>

                    <div className="left-section">
                        {notifId && (
                            <UiButton
                                type="icon"
                                size="xxs"
                                className="icon icon-close-icon"
                                onClick={() => {
                                    removeNotification(notifId);
                                }}
                            ></UiButton>
                        )}
                    </div>
                </div>
                <div className="wave-title">تشخیص موج</div>
                <div className="body">
                    <div className="wave-level">
                        <div>
                            {`  موج ${queryObj?.title || "نا مشخص"}`}{" "}
                            {formatMessage({ id: `alarm.wave.level.${level}` })}
                        </div>
                    </div>
                    <img src={require(`./../../../assets/img/wave-alarm-notification/${level}.svg`)} alt="" />
                </div>
            </div>
        </Loadings>
    );
};

export default injectIntl(WaveAlarmNotification);
