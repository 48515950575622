import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UiButton, UiModal } from "ui-components";
import useUiTableData from "../../hooks/useUiTableData";
import QueryRulesContent from "ui-components/ui-table/actions/share/QueryRulesContent";
import UiTableInlineError from "ui-components/ui-table/errors/UiTableInlineError";
import { UiTableDispatchContext, UiTableStateContext } from "ui-components/ui-table/context/UiTableContext";
/**
 * اکشن اشتراک یک رکورد
 * @param {*Object} action آبجکت اکشن
 * @param {*Number} itemId شناسه رکورد
 */
const UiActionShare = ({ action, itemId }) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [params, setParams] = useState([]);
    const tableState = useContext(UiTableStateContext);
    const tableDispatch = useContext(UiTableDispatchContext);
    const { shareWithDepartments, findByIds } = useUiTableData(false);
    const itemTitle = findByIds([itemId]);

    /**
     * باز و بست کردن مودال کانفرم
     */
    const handleToggleModal = () => {
        setVisibleModal((visible) => !visible);
        tableDispatch({ type: "errorShare", value: null });
    };

    /**
     * اشتراک آیتم
     */
    const handleShare = () => {
        shareWithDepartments(params);
    };

    /**
     * تغییر آیتم های انتخاب شده
     * @param {*object} params آیتم‌های انتخاب شده
     */
    const handleOnChange = (params) => {
        setParams(params);
    };

    // اگر با موفقیت به اشتراک گذاشته شود
    // مودال کانفرم بسته میشود
    useEffect(() => {
        if (tableState?.isShared) {
            setVisibleModal(false);
        }
    }, [tableState?.isShared]);

    return (
        <>
            <UiButton
                type="icon"
                className={`icon ${action.icon}`}
                size="xxs"
                tooltip={action.tooltip}
                disabled={action.disabled}
                onClick={handleToggleModal}
            />
            <UiModal
                loading={tableState?.loadingShare}
                visible={visibleModal}
                title="اشتراک‌گذاری"
                onOk={handleShare}
                onCancel={handleToggleModal}
            >
                <QueryRulesContent
                    itemId={itemId}
                    onChange={handleOnChange}
                    itemTitle={itemTitle}
                    tableState={tableState}
                ></QueryRulesContent>
                <UiTableInlineError errors={tableState?.errorsShare} />
            </UiModal>
        </>
    );
};
UiActionShare.propTypes = {
    action: PropTypes.object,
    itemId: PropTypes.number,
};

export default UiActionShare;
