import React from "react";
import { Row, Col } from "antd";
import { injectIntl } from "react-intl";
import { UiCheckbox } from "ui-components";
const CheckBoxField = (props) => {
    const { name, platformParams, handleCheckbox, size, intl, theme } = props;

    const { formatMessage } = intl;

    return (
        <Row key={name} type="flex">
            <Col lg={24} xl={24}>
                <UiCheckbox
                    theme={theme}
                    checked={!!platformParams[name]}
                    name={name}
                    onChange={(checked) => handleCheckbox(checked, name)}
                >
                    <span style={{ fontSize: "16px" }}>{formatMessage({ id: `strings.${name}` })}</span>
                </UiCheckbox>
            </Col>
        </Row>
    );
};

export default injectIntl(CheckBoxField);
