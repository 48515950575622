import React from "react";
import {
    Button,
} from 'antd';
import { FormattedSimpleMsg } from "GlobalFunctions";
import Loadings from 'shared/loadings/Loadings';
import {UiInput} from "ui-components"

const ResourcesViewLeftPartSearch = ({
    selectedSearchQ,
    inputChange,
    searchSelectdResource,
    searchSelectedLoading,
    placeholder
}) => {

    return (

        <>

            <UiInput onChange={(value)=>inputChange("selectedSearchQ",value)}
                onEnter={searchSelectdResource}
                value={selectedSearchQ}
                name="selectedSearchQ"
                placeholder={placeholder}
                prefix={<i className="icon icon-search" />} />

            {!searchSelectedLoading &&
                <Button
                    type="primary"
                    onClick={searchSelectdResource}>

                    {/* جستجو */}
                    <FormattedSimpleMsg id="sourceSelect.search" />

                </Button>
            }

            {searchSelectedLoading &&

                <Button type="default">

                <Loadings type="button" spinning={searchSelectedLoading}></Loadings>

                </Button>
            }

        </>
    )
};

export default ResourcesViewLeftPartSearch;