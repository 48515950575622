import React, { lazy, Suspense } from "react";
import Scrollbar from "react-scrollbars-custom";
import DateTimeRangePicker from "../jalali-calender/DateTimeRangePicker";
import { injectIntl } from "react-intl";
import moment from "moment-jalaali";
import { Select} from "antd";
import Resources from "../source-select/Resources";
import { resourcePlatforms, existsList } from "GlobalVariables";
import UserAccess from "../permission/UserAccess";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import Loadings from "shared/loadings/Loadings";
import ContentCluster from "shared/filters/fields/ContentCluster";
import RangeSlider from "shared/RangeSlider";
import { UiMultiSelect, UiCheckbox, UiRadio, UiRadioGroup } from "ui-components";
const QueryField = lazy(() => import("shared/queryBuilder/QueryField"));

const Option = Select.Option;

let finalPlatforms = null;

const SideFilterView = injectIntl(
    ({
        visible,
        setVisible,
        jalaliFormat,
        intl,
        originalFormat,
        globalFilter,
        handleDatePicker,
        getPreSelected,
        handleChange,
        handleChangeQuery,
        onChange,
        handleRangeChange,
        getResources,
        filterState,
        submit,
        handleChangeExists,
        location,
        handleMultiSelect,
        changePlatformParams,
        handleChangeRelationAges,
    }) => {
        const { formatMessage } = intl;

        const {
            from,
            to,
            time_duration,
            query,
            apply,
            exists,
            relation_ages_cls,
            min_relation_ages,
            max_relation_ages,
            tagId,
            resource_ids,
            resource_objects,
            totalResourceCount,
            selectedResourceCount,
        } = filterState;

        const agesList = ["teen", "young", "adult", "old"];

        const { rules, tags } = globalFilter;
        const rangePickerProps = UserAccess.getTimeRangeAccess();

        if (!finalPlatforms) {
            finalPlatforms = resourcePlatforms.filter((platform) => UserAccess.can(platform, "SearchPlatforms"));
        }

        const defaultQuery = {
            parsedQuery: {},
            rawQuery: "",
            selectedKeyword: "",
            hasCustomQuery: false,
        };

        const radioStyle = {
            // display: 'block',
            height: "30px",
            lineHeight: "30px",
            width: "155px",
        };
        const noSelected = { value: "", label: formatMessage({ id: "filters.filter_engine.not_have" }) };
        const { pathname } = location;

        const isSubjectProfile = pathname.includes("profile/subject");
        const isEntityProfile = pathname.includes("profile/entity");
        const isEntitiesTagProfile = pathname.includes("profile/entities-tag");
        const isSingleQueryFiles = pathname.includes("/query-files/single/");

        const selectedQuery = !isEmpty();

        const selectAgesItem = agesList?.map((item) => ({
            value: item,
            label: formatMessage({ id: `strings.${item}` }),
        }));

        const selectExistsItem = existsList
            .filter((entity) => UserAccess.can(entity.replace("extracted_", ""), "EntityResources"))
            .map((item) => ({
                value: item,
                label: formatMessage({ id: `filtersVal.${item}` }),
            }));
        return (
            <div className={`notification--sidebar general-settings--sidebar ${visible ? "visible" : "hidden"}`}>
                <div className="back-drop" onClick={setVisible} />
                <div className="notification--sidebar--contents">
                    <Scrollbar noScrollX={true} style={{ width: "100%", height: "91.5%" }}>
                        <div className="border-b">
                            <header className="title">
                                <i className="icon icon-filter" />
                                <span> {formatMessage({ id: "side_filter.system_filters" })} </span>
                            </header>
                        </div>

                        <div className="filter-container custom-date-picker-form-item">
                            {UserAccess.can("contentCategory", "FrontEnd") && (
                                <div className="content-cluster-global-filter border-b">
                                    <ContentCluster
                                        key={"1"}
                                        name={"categories"}
                                        noSelected={noSelected}
                                        handleMultiSelect={handleMultiSelect}
                                        formatMessage={formatMessage}
                                        platformParams={filterState}
                                        size={"sm"}
                                        isGlobalFilter={true}
                                        changePlatformParams={changePlatformParams}
                                        className=""
                                        theme="dark"
                                    />
                                </div>
                            )}
                            {/* انتخاب منبع */}
                            {finalPlatforms.length > 0 &&
                                (UserAccess.can("index", "SearchTag") || UserAccess.can("index", "SearchResources")) &&
                                !isSingleQueryFiles &&
                                !isEntityProfile &&
                                !isEntitiesTagProfile && (
                                    <>
                                        <div className="border-b">
                                            <div className="section-title">
                                                {formatMessage({ id: "side_filter.tag" })}
                                            </div>
                                            <UserAccess can="index" type="SearchTag">
                                                <div className="global-category">
                                                    <Select
                                                        value={tagId}
                                                        placeholder={formatMessage({ id: "side_filter.tag_select" })}
                                                        onChange={(val) => handleChange(val, "tagId")}
                                                        allowClear={true}
                                                    >
                                                        {tags &&
                                                            tags.map((item) => {
                                                                return (
                                                                    <Option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </div>
                                            </UserAccess>
                                        </div>
                                        <div className="border-b">
                                            <UserAccess can="index" type="SearchResources">
                                                {finalPlatforms.length > 0 && (
                                                    <div className="global-reource">
                                                        <div className="section-title">
                                                            {formatMessage({ id: "side_filter.source" })}
                                                        </div>
                                                        <Resources
                                                            //isClear={true}
                                                            getResources={getResources}
                                                            platforms={finalPlatforms}
                                                            //selectedResourceCount={resource_objects ? resource_objects[0].objs.length : 0}
                                                            limited={3}
                                                            selectedResources={resource_objects}
                                                            editMode={
                                                                resource_objects && !isEmpty(resource_objects)
                                                                    ? true
                                                                    : false
                                                            }
                                                            totalResourceCount={totalResourceCount}
                                                            selectedResourceCount={selectedResourceCount}
                                                        />
                                                    </div>
                                                )}
                                            </UserAccess>
                                        </div>
                                    </>
                                )}
                            {/* کلمات کلیدی */}
                            {!isSubjectProfile && !isSingleQueryFiles && (
                                <UserAccess can="index" type="QueryRules">
                                    <div className="border-b">
                                        <div className="section-title">
                                            {formatMessage({ id: "side_filter.query_rule" })}
                                        </div>

                                        <Suspense
                                            fallback={
                                                <Loadings
                                                    type="box"
                                                    style={{ height: "100px", width: "100px" }}
                                                    spinning={true}
                                                />
                                            }
                                        >
                                            <QueryField
                                                value={!query ? defaultQuery : query}
                                                // onChange={(val) => handleChange(val, "query")}
                                                onChange={handleChangeQuery}
                                                fieldType="block"
                                                theme="dark"
                                            />
                                        </Suspense>
                                    </div>
                                </UserAccess>
                            )}

                            {/* رده سنی */}
                            <div className="border-b ">
                                <div className="section-title">{formatMessage({ id: "strings.ages_cls" })}</div>
                                <div className="exists">
                                    <UiMultiSelect
                                        output="value"
                                        onChange={(val) => handleChangeExists("relation_ages_cls", val)}
                                        placeholder={formatMessage({ id: "query_rules.select_platform" })}
                                        list={selectAgesItem}
                                        value={relation_ages_cls}
                                        withoutImage
                                        theme="dark"
                                    />
                                </div>
                            </div>

                            {/* بازه سنی */}
                            <div className="border-b ">
                                <div className="section-title">{formatMessage({ id: "search.relation_ages" })}</div>
                                <div className="relation-ages">
                                    <RangeSlider
                                        field="relation_ages"
                                        disabled={true}
                                        value={[min_relation_ages || 0, max_relation_ages || 100]}
                                        rangeChanged={(min, max) => {
                                            handleChangeRelationAges(
                                                "min_relation_ages",
                                                min,
                                                "max_relation_ages",
                                                max,
                                            );
                                        }}
                                        minValue={0}
                                        maxValue={100}
                                        step={1}
                                    />
                                </div>
                            </div>

                            {/* بازه زمانی */}
                            <div className="section-title">{formatMessage({ id: "side_filter.time_duration" })}</div>
                            <DateTimeRangePicker
                                format={jalaliFormat}
                                placeholderStart={formatMessage({ id: "global.fromtime" })}
                                placeholderEnd={formatMessage({ id: "global.totime" })}
                                onChangeStart={(unix, formatted) =>
                                    handleDatePicker(moment(formatted, jalaliFormat).format(originalFormat), "from")
                                }
                                onChangeEnd={(unix, formatted) =>
                                    handleDatePicker(moment(formatted, jalaliFormat).format(originalFormat), "to")
                                }
                                onClearStart={() => handleDatePicker(null, "from")}
                                onClearEnd={() => handleDatePicker(null, "to")}
                                preSelectedStart={getPreSelected(from)}
                                preSelectedEnd={getPreSelected(to)}
                                containerClass="custom-input-datepicker global-filter-calendar"
                                customClassStart="custom-datepicker"
                                customClassEnd="custom-datepicker"
                                leftSide={true}
                                {...rangePickerProps}
                            />

                            {/*</UserAccess>*/}
                            <div className="border-b sidebar-time-duration">
                                {/* رادیو باتن بازه زمانی در فیلتر های عمومی */}
                                <UiRadioGroup name="groupSelect" onChange={handleRangeChange} value={time_duration}>
                                    <UiRadio
                                        style={radioStyle}
                                        disabled={!UserAccess.isAllowTimeDuration("today")}
                                        value="today"
                                    >
                                        {formatMessage({ id: "side_filter.today" })}
                                    </UiRadio>
                                    <UiRadio disabled={!UserAccess.isAllowTimeDuration("6h")} value="6h">
                                        {formatMessage({ id: "side_filter.6h" })}
                                    </UiRadio>
                                    <UiRadio
                                        style={radioStyle}
                                        disabled={!UserAccess.isAllowTimeDuration("24h")}
                                        value="24h"
                                    >
                                        {formatMessage({ id: "side_filter.24h" })}
                                    </UiRadio>
                                    <UiRadio disabled={!UserAccess.isAllowTimeDuration("1w")} value="1w">
                                        {formatMessage({ id: "side_filter.1w" })}
                                    </UiRadio>
                                    <UiRadio
                                        style={radioStyle}
                                        disabled={!UserAccess.isAllowTimeDuration("2w")}
                                        value="2w"
                                    >
                                        {formatMessage({ id: "side_filter.2w" })}
                                    </UiRadio>
                                    <UiRadio disabled={!UserAccess.isAllowTimeDuration("1m")} value="1m">
                                        {formatMessage({ id: "side_filter.1m" })}
                                    </UiRadio>
                                    <UiRadio
                                        style={radioStyle}
                                        disabled={!UserAccess.isAllowTimeDuration("6m")}
                                        value="6m"
                                    >
                                        {formatMessage({ id: "side_filter.6m" })}
                                    </UiRadio>
                                    <UiRadio disabled={!UserAccess.isAllowTimeDuration("12m")} value="12m">
                                        {formatMessage({ id: "side_filter.12m" })}
                                    </UiRadio>
                                    {UserAccess.isUnlimitedTime() && (
                                        <UiRadio style={radioStyle} value="-1">
                                            نامحدود
                                        </UiRadio>
                                    )}

                                    <UiRadio value="">هیچ کدام</UiRadio>
                                </UiRadioGroup>
                            </div>
                            {/*</UserAccess>*/}

                            {UserAccess.accessToEntities() && (
                                <div className="border-b ">
                                    <div className="section-title">
                                        {formatMessage({ id: "side_filter.extracted" })}
                                    </div>
                                    <div className="exists">
                                        <UiMultiSelect
                                            output="value"
                                            onChange={(val) => handleChangeExists("exists", val)}
                                            list={selectExistsItem}
                                            value={exists}
                                            withoutImage
                                            theme="dark"
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="apply-check">
                                {/* <Checkbox onChange={onChange} checked={apply}>این تنظیمات روی تنظیمات پیش‌فرض تمام باکس‌ها
								اعمال شود(نمایش با رنگ <span className="green-spot" />). </Checkbox> */}

                                <UiCheckbox onChange={onChange} checked={apply}>
                                    {formatMessage({ id: "side_filter.preset_all_box" })}
                                </UiCheckbox>
                            </div>
                        </div>
                    </Scrollbar>
                    <button className="button orange-filled apply-btn" onClick={submit}>
                        {formatMessage({ id: "side_filter.save" })}
                    </button>
                </div>
            </div>
        );
    },
);

export default withRouter(SideFilterView);
