import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomCol from 'shared/CustomCol';
import MessageData from "../messageData/MessageData";

/**
 * Boxes List Page View Component
 * @param props
 * @returns {*}
 * @constructor
 */
const NotAccessPageView = () => {

    return (
        <Fragment>
            <CustomCol span={3}>
                <div className="boxes-list-sidebar-container">

                    <div className="inner-menu defaults-menu" >

                        <h2 className="inner-menu-title">
                            <FormattedMessage id="global.not_access" />
                        </h2>

                    </div>

                </div>
            </CustomCol>
            <CustomCol span={20}>
                <section className="app-page-not-access">
                    <div className="page-inner">
                        <MessageData
                            icon="follower"
                            description={<FormattedMessage id="global.access_page_forbidden" />}
                        />
                    </div>
                </section>
            </CustomCol>
        </Fragment>
    );

};

export default NotAccessPageView;