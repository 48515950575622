import { EXCEL_ADD, EXCEL_LIST } from '../actions/types'


const initialState = {
  status: false,
  data: []
};

const excelReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXCEL_ADD:
      state = {
        ...state,
      };
      break;

    case EXCEL_LIST:
      state = {
        ...state,
        status: payload.status,
        data: [...payload.data]
      };
      break;


    default:
      break;
  }
  return state;
};

/*"status": true,
"data": [
  {
      "excel_queue_id": 1,
      "user_id": 2,
      "api_path": "/telegram/posts/deep",
      "status": 1,
      "created_at": 1546334525,
      "last_updated_at": 1546334525,
      "terminate_at": 0,
      "read_count": 0,
      "total_count": 0
  },
*/

export default excelReducer;