import React from "react";
import { Icon, Button, Row, Col } from "antd";
import { api } from "services";
import _ from "lodash";
import FiltersEngine from "../filters/FiltersEngine";
import { APIPaths, ValidNewPlatformSearch } from "GlobalVariables";
import { sanitizeSearchParams, handleRangeParameters } from "GlobalFunctions";
import ResourceSmartSearch from "./smart-search/ResourceSmartSearch";
import Loadings from "shared/loadings/Loadings";
import { UiInput, UiTab, UiToast } from "ui-components";
const searchLoadingIcon = <Icon type="loading" style={{ fontSize: 20, color: "#fff" }} spin />;

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            q: "",
            searchLoading: "",
            apiParams: {},
            // برای آیتم های دریافتی از سرچ هوشمند و فینگلیش
            smartQueryItems: [],
        };

        this.inputChange = this.inputChange.bind(this);

        this.doSearch = this.doSearch.bind(this);

        this.enterPressed = this.enterPressed.bind(this);

        this.onChangeSmartSearch = this.onChangeSmartSearch.bind(this);
    }

    /**
     * تغییر ورودی در اینپوت ها
     * @param {*} e آبجکت اینپوت تغییر کرده
     */
    inputChange(name, value) {
        this.setState({
            [name]: value,
        });
    }

    /**
     *  هندل کردن دکمه اینتر
     * @param {*} e آبجکت اینپوت
     */
    enterPressed() {
        this.doSearch();
    }

    /**
     * انجام سرچ براساس پارامترهای تعیین شده
     */
    doSearch() {
        let {
            selectedPlatform,
            getUnCategoryResources,
            platforms,
            getUnCategoryResourcesCount,
            currentPage,
            handleSearchLoading,
            searchType,
            intl,
        } = this.props;

        let { apiParams, smartQueryItems, searchLoading } = this.state;

        if (searchLoading) {
            return;
        }

        const { formatMessage } = intl;

        // اگر پلتفرمی انتخاب نشده بود
        if (selectedPlatform === -1) {
            const alert = { id: "resource.search.error" };

            UiToast.info(alert, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        // لودینگ دکمه سرچ
        this.setState({
            searchLoading: true,
        });

        // فعال کردن لودینگ هنگام عملیات سرچ
        handleSearchLoading(true);

        // گرفتن همه پارامترها
        let objToSend = { ...this.state };

        // حذف کردن پارامترهای خالی
        Object.keys(objToSend).forEach((item) => {
            if (objToSend[item] === "") delete objToSend[item];
        });

        // پاک کردن پارامترهای فرانت
        delete objToSend["searchLoading"];
        delete objToSend["apiParams"];
        delete objToSend["smartQueryItems"];

        // ایجاد پارامتر های جستجو
        let params = {
            ...apiParams[platforms[selectedPlatform]],
            ...objToSend,
            page: currentPage,
            api_path:
                searchType === "subjectResource"
                    ? APIPaths[platforms[selectedPlatform]] + "/by_subject"
                    : APIPaths[platforms[selectedPlatform]],
        };

        if (smartQueryItems.length) {
            let extraQuery = smartQueryItems.join("+");
            params["q"] = params["q"].length ? params["q"] + "+" + extraQuery : extraQuery;
        }

        let searchVersionPath="/search";
        if (ValidNewPlatformSearch?.some(platformItem => params?.api_path?.includes(platformItem)))
        {
            // باید بعد از ورژن دادن کامل سرچ اصلاح شود
            searchVersionPath="/search/api/v1/search";
        }
        // ارسال درخواست جستجو
        api.get(searchVersionPath, sanitizeSearchParams(handleRangeParameters(params)), null, {
            cancelToken: this.props.source.token,
        })
            .then((result) => {
                let { data, meta } = result.data.result;

                if (data) {
                    // جایگزین کردن منابع بدون دسته بندی
                    getUnCategoryResources(data);

                    // تعداد کل منابع ریسپانس
                    getUnCategoryResourcesCount(meta.total);
                }
            })
            .catch((error) => {
                UiToast.exceptions(error);
            })
            .then(() => {
                this.setState({
                    searchLoading: false,
                });

                // غیر فعال کردن لودینگ هنگام عملیات سرچ
                handleSearchLoading(false);
            });
    }

    componentDidUpdate(prev) {
        // اگر صفحه پجینیت عوض شود
        if (this.props.currentPage !== prev.currentPage) {
            // خالی کردن لیست خروجی
            this.props.getUnCategoryResources([]);

            // سرچ برای بدست آوردن مقادیر جدید
            this.doSearch();
        }

        // اگر پلتفرم عوض شود
        if (this.props.selectedPlatform !== prev.selectedPlatform) {
            this.setState({
                q: "",
                title: "",
                id: "",
                firstname: "",
                lastname: "",
                username: "",
                email: "",
                phone: "",
                mobile: "",
                description: "",
            });
        }
         // اگر کامپوننت ریست شود
         if (this.props?.reset !== prev?.reset) {
            this.setState({
                q: "",
                title: "",
                id: "",
                firstname: "",
                lastname: "",
                username: "",
                email: "",
                phone: "",
                mobile: "",
                description: "",
                q: "",
                searchLoading: "",
                apiParams: {},
                smartQueryItems: [],
            });
        }
    }

    /**
     * آپدیت کردن پارامترهای سرچ توسط کامپوننت فیلتر انجین که اینپورت شده و بصورت آماده استفاده میشود
     * @param {*} params پارامترهای سرچ
     * @param {*} platformName  پلتفرم
     */
    updateSearchFields(params, platformName) {
        let { apiParams } = this.state;

        apiParams[platformName] = { ...params };

        this.setState({
            apiParams,
        });
    }

    // دریافت لیست آیتم ها از سرچ هوشمند
    onChangeSmartSearch(data) {
        this.setState({
            smartQueryItems: data,
        });
    }

    render() {
        const {
            searchLoading,
            apiParams,
            q,
            title,
            id,
            firstname,
            lastname,
            username,
            email,
            phone,
            mobile,
            description,
        } = this.state;

        const { platforms, selectedPlatform, searchType, intl, reset } = this.props;

        const { formatMessage } = intl;

        const currentPlatform = platforms[selectedPlatform];
        const selectedApiPath = APIPaths[currentPlatform];

        const isResourceTagType = searchType === "subjectResource" ? true : false;

        let tabList = [];

        isResourceTagType &&
            tabList.push({
                key: 1,
                title: formatMessage({ id: "resource.search.smart_search" }),
                content: (
                    <div className="">
                        <ResourceSmartSearch
                            searchValue={q}
                            type="smartSearch" // smartSearch | finglish
                            onChangeSmartSearch={this.onChangeSmartSearch}
                            platform={selectedApiPath}
                            reset={reset}
                        />
                    </div>
                ),
            });

        !isResourceTagType &&
            tabList.push({
                key: 2,
                title: formatMessage({ id: "resource.search.related_phrases" }),
                content: (
                    <div className="">
                        <ResourceSmartSearch
                            searchValue={q}
                            type="finglish" // smartSearch | finglish
                            onChangeSmartSearch={this.onChangeSmartSearch}
                            platform={selectedApiPath}
                            reset={reset}
                        />
                    </div>
                ),
            });

        tabList.push({
            key: 3,
            title: formatMessage({ id: "resource.search.filter" }),
            content: (
                <div className="small-filters search-container">
                    <FiltersEngine
                        changePlatformParams={(params, platformName) => this.updateSearchFields(params, platformName)}
                        platform={{
                            api_path: selectedApiPath,
                            name: platforms[selectedPlatform],
                            api_params: apiParams[platforms[selectedPlatform]]
                                ? apiParams[platforms[selectedPlatform]]
                                : {},
                        }}
                        hasCollapse={false}
                        size="md"
                        sourcePage="Resources"
                    />
                </div>
            ),
        });

        !isResourceTagType &&
            tabList.push({
                key: 4,
                title: formatMessage({ id: "resource.search.centered_filter" }),
                content: (
                    <div className="">
                        <div className="advanced visible">
                            <Row gutter={[8, 8]}>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("id", value)}
                                        value={id}
                                        name="id"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_id",
                                        })}
                                    />
                                </Col>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("title", value)}
                                        value={title}
                                        name="title"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_title",
                                        })}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={[16, 24]}>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("firstname", value)}
                                        value={firstname}
                                        name="firstname"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_firstname",
                                        })}
                                    />
                                </Col>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("lastname", value)}
                                        value={lastname}
                                        name="lastname"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_family",
                                        })}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("username", value)}
                                        value={username}
                                        name="username"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_username",
                                        })}
                                    />
                                </Col>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("email", value)}
                                        value={email}
                                        name="email"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_email",
                                        })}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("phone", value)}
                                        value={phone}
                                        name="phone"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_phone",
                                        })}
                                    />
                                </Col>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("mobile", value)}
                                        value={mobile}
                                        name="mobile"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_mobile",
                                        })}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16, 24]}>
                                <Col span={12}>
                                    <UiInput
                                        onChange={(value) => this.inputChange("description", value)}
                                        value={description}
                                        name="description"
                                        placeholder={formatMessage({
                                            id: "resource.search.centered_filter_desc",
                                        })}
                                    />
                                </Col>
                                <Col span={12}></Col>
                            </Row>
                        </div>
                    </div>
                ),
            });

        return (
            <>
                <div className="search resource-search flex">
                    <form autoComplete="off">
                        <UiInput
                            onChange={(value) => this.inputChange("q", value)}
                            onEnter={this.enterPressed}
                            value={q}
                            name="q"
                            autoComplete="off"
                            placeholder={
                                isResourceTagType
                                    ? formatMessage({ id: "sourceSelect.must_write_q" })
                                    : formatMessage({ id: "sourceSelect.write_q" })
                            }
                            prefix={<i className="icon icon-search" />}
                        />
                    </form>

                    <Button
                        type="primary"
                        onClick={this.doSearch}
                        disabled={_.isEmpty(q) && isResourceTagType ? true : false}
                    >
                        {!searchLoading && formatMessage({ id: "sourceSelect.search" })}

                        {searchLoading && (
                            <Loadings type="button" spinning={searchLoading} indicator={searchLoadingIcon}></Loadings>
                        )}
                    </Button>
                </div>

                <div className="advanced-search">
                    <UiTab type="card" items={tabList} defaultSelectedTabKey={isResourceTagType ? 1 : 2} />
                </div>
            </>
        );
    }
}

export default Search;
