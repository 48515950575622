import React from "react";
import { Spin } from "antd";
import LoadPulse from "./../load-pulse/LoadPulse";
import PropTypes from "prop-types";
import { UiSpin } from "ui-components";

const Loadings = (props) => {
    const { spinning, type, className, style } = props;

    switch (type) {
        case "button":
        case "circle":
            return (
                <UiSpin loading={spinning} className={`${className ?? ""}`} style={style}>
                    {props.children}
                </UiSpin>
            );
        case "table":
        case "page":
        case "box":
        case "modal":
            return (
                <UiSpin
                    loading={spinning}
                    className={`${className ?? ""}`}
                    style={style}
                    indicator={<LoadPulse type="dots" />}
                >
                    {props.children}
                </UiSpin>
            );

        default:
            return (
                <Spin spinning={spinning} className={className}>
                    {props.children}
                </Spin>
            );
    }
};

Loadings.propType = {
    spinning: PropTypes.bool, // لودینگ
    className: PropTypes.string, // کلاس استایل
    type: PropTypes.oneOf(["button", "table", "page", "box", "modal"]), // نوع‌های ورودی
    style: PropTypes.object,
};

export default Loadings;
