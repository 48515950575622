import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { difference, cloneDeep } from "lodash";
import RangeSliderField from "../fields/RangeSliderField";
import { categorySubCategory } from "GlobalVariables";
import "./ContentCluster.scss";
import { UiCollapse, UiMultiSelect } from "ui-components";

const ContentCluster = (props) => {
    const {
        name,
        formatMessage,
        platformParams,
        size,
        handleMultiSelect,
        platform,
        changePlatformParams,
        isGlobalFilter,
        typeDescriptionFilter,
        theme,
    } = props;

    const translate = (key) => {
        return formatMessage({ id: `multi_level_chart.${key}` });
    };
    const categoryList = Object.keys(categorySubCategory)?.map((x) => ({ value: x, label: translate(x) }));
    /**
     * تعییر مقدار رینج اسلایدر
     * @param {*} min مقدار حداقل
     * @param {*} max مقدار حداکثر
     * @param {*} field نام پارامتری که تغییر کرده
     */
    const rangeChanged = (min, max, field, index) => {
        const oldParams = isGlobalFilter ? platformParams : platform.api_params;
        const params = {
            ...oldParams,
            ["min_" + field]: min,
            ["max_" + field]: max,
            [`prob_categories[${index}][title]`]: field,
            [`prob_categories[${index}][from]`]: min,
            [`prob_categories[${index}][to]`]: max,
        };

        changePlatformParams(params, isGlobalFilter ? null : platform.name);
    };

    /**
     * تعین وضعیت فعال بودن یا نبودن اسلایدرهای درون کامپوننت
     * @param {*} e ایونت
     * @param {*} item نام آیتم تغییر کرده
     */
    const handleActiveSlider = (checked, item) => {
        const oldParams = isGlobalFilter ? platformParams : platform.api_params;
        if (!checked) {
            oldParams[item] = undefined;
            const key = item.replace("_checked", "");
            const index = oldParams.categories.indexOf(key);
            oldParams[`prob_categories[${index}][title]`] = undefined;
            oldParams[`prob_categories[${index}][from]`] = undefined;
            oldParams[`prob_categories[${index}][to]`] = undefined;
            oldParams[`min_${key}`] = undefined;
            oldParams[`max_${key}`] = undefined;
        } else {
            const key = item.replace("_checked", "");
            const index = oldParams.categories.indexOf(key);
            oldParams[`min_${key}`] = 0;
            oldParams[`max_${key}`] = 100;
            oldParams[`prob_categories[${index}][title]`] = key;
            oldParams[`prob_categories[${index}][from]`] = 0;
            oldParams[`prob_categories[${index}][to]`] = 100;
        }
        const params = {
            ...oldParams,
            [item]: checked,
        };

        changePlatformParams(params, isGlobalFilter ? null : platform.name);
    };

    const clearCategories = (value) => {
        let params = isGlobalFilter ? platformParams : platform.api_params;
        if (value.length === 0) {
            delete params["categories"];
            delete params["sub_categories"];
            const keys = Object.keys(params);
            keys.forEach((key) => {
                if (key.includes("prob_categories")) {
                    delete params[key];
                } else {
                    categoryList.forEach((cat) => {
                        if (key.includes(cat.value)) {
                            delete params[key];
                        }
                    });
                }
            });
            setSelectedSubCategory([]);
            changePlatformParams(params, isGlobalFilter ? null : platform.name);
        } else {
            if (params.categories?.length > value.length) {
                const cats = value.map((x) => x.value);
                const diff = difference(params.categories, cats)?.[0];
                if (diff) {
                    const index = params.categories.indexOf(diff);
                    const PC = cloneDeep(params.categories);
                    params.categories = params.categories.filter((x) => x !== diff);
                    const keys = Object.keys(params);
                    keys.forEach((key) => {
                        if (key.includes(diff)) {
                            params[key] = undefined;
                        } else {
                            params[`prob_categories[${index}][title]`] = undefined;
                            params[`prob_categories[${index}][from]`] = undefined;
                            params[`prob_categories[${index}][to]`] = undefined;
                        }
                    });

                    params.categories.forEach((key, i) => {
                        if (i >= index) {
                            params[`prob_categories[${i}][title]`] = params[`prob_categories[${i + 1}][title]`];
                            params[`prob_categories[${i + 1}][title]`] = undefined;

                            params[`prob_categories[${i}][from]`] = params[`prob_categories[${i + 1}][from]`];
                            params[`prob_categories[${i + 1}][from]`] = undefined;

                            params[`prob_categories[${i}][to]`] = params[`prob_categories[${i + 1}][to]`];
                            params[`prob_categories[${i + 1}][to]`] = undefined;
                        }
                    });

                    clearSubCategoriesWithCategory(value, PC);
                    changePlatformParams(params, isGlobalFilter ? null : platform.name);
                }
            }
        }
    };

    const clearSubCategories = (value) => {
        let params = isGlobalFilter ? platformParams : platform.api_params;

        if (value.length === 0) {
            delete params["sub_categories"];
        } else {
            if (params.sub_categories?.length > value.length) {
                const subCcats = value.map((x) => x.value);
                const diff = difference(params.sub_categories, subCcats)?.[0];
                params.sub_categories = params.sub_categories.filter((x) => x !== diff);
            }
        }
        changePlatformParams(params, isGlobalFilter ? null : platform.name);
    };

    const clearSubCategoriesWithCategory = (value, PC) => {
        let params = isGlobalFilter ? platformParams : platform.api_params;

        if (value.length === 0) {
            delete params["sub_categories"];
            setSelectedSubCategory([]);
        } else {
            if (PC?.length > value.length) {
                const cats = value.map((x) => x.value);
                const diff = difference(PC, cats)?.[0];
                const subCategories = categorySubCategory[diff];
                subCategories.forEach((subCat) => {
                    params.sub_categories = params?.sub_categories?.filter((x) => x !== subCat);
                });
                const selected = params.sub_categories?.map((x) => ({ value: x, label: translate(x) }));
                setSelectedSubCategory(selected);
            }
        }
        changePlatformParams(params, isGlobalFilter ? null : platform.name);
    };

    const [selectedCategory, setSelectedCategory] = useState();

    useEffect(() => {
        if (platformParams?.categories) {
            const selectedCategoryTemp = platformParams?.categories?.map((x) => ({
                value: x,
                label: translate(x),
            }));
            setSelectedCategory(selectedCategoryTemp);
        }
    }, [platformParams?.categories]);

    const [selectedSubCategory, setSelectedSubCategory] = useState();

    useEffect(() => {
        if (platformParams?.sub_categories) {
            const selectedSubCategoryTemp = platformParams?.sub_categories?.map((x) => ({
                value: x,
                label: translate(x),
            }));
            setSelectedSubCategory(selectedSubCategoryTemp);
        }
    }, [platformParams?.sub_categories]);

    const subCategory = (key) => {
        if (!key) return [];
        let list = [];
        key &&
            key.forEach((x) => {
                list = [...list, ...Object.values(categorySubCategory[x.value])];
            });

        const subCategoryList = list?.map((x) => ({ value: x, label: translate(x) })) || "";

        return subCategoryList;
    };

    let customLableSize = 4;
    let customContentSize = 17;
    let customAdvanceSettingPadding = 10;
    switch (size) {
        case "md":
            customLableSize = 6;
            break;
        case "sm":
            customLableSize = 24;
            customContentSize = 24;
            customAdvanceSettingPadding = 20;
            break;

        default:
            break;
    }
    const RangeSliders = (
        <div className="body">
            {selectedCategory?.map((item, index) => {
                return (
                    <RangeSliderField
                        key={item?.value}
                        name={item?.value}
                        size={size}
                        platformParams={platformParams}
                        handleActiveSlider={handleActiveSlider}
                        rangeChanged={(min, max, field) => rangeChanged(min, max, field, index)}
                        minValue={0}
                        maxValue={100}
                        step={1}
                        preset={"multi_level_chart."}
                        // prefix={"%"}
                    />
                );
            })}
        </div>
    );
    return (
        <>
            <Row align="middle" gutter={24}>
                <Col lg={customLableSize} xl={customLableSize}>
                    <span className="row-label">
                        {formatMessage({ id: `strings.${name}` })}
                        {typeDescriptionFilter}
                    </span>
                </Col>
                <Col span={customContentSize}>
                    <UiMultiSelect
                        output="object"
                        allowClear={true}
                        placeholder={formatMessage({ id: "filters.select_field.not_have" })}
                        value={selectedCategory?.map((i) => i.value) || []}
                        withoutImage
                        onChange={(val) => {
                            clearCategories(val);
                            setSelectedCategory(val);
                            handleMultiSelect(val, "categories");
                        }}
                        list={categoryList}
                        theme={theme}
                    />
                </Col>
            </Row>
            <Row align="middle" gutter={24}>
                <Col lg={customLableSize} xl={customLableSize}></Col>
                <Col span={customContentSize}>
                    {selectedCategory?.length > 0 && (
                        <div className="custom-collapse" style={{ padding: `${customAdvanceSettingPadding}px 0` }}>
                            {/* دسته بندی محتوا در فیلتر های سرچ */}
                            <UiCollapse
                                data={[
                                    {
                                        title: <span style={{ width: 130 }}>تنظیمات پیشرفته</span>,
                                        content: RangeSliders,
                                    },
                                ]}
                                mode="dotted"
                                className="header"
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <Row align="middle" gutter={12}>
                <Col lg={customLableSize} xl={customLableSize}>
                    <span className="row-label">
                        {formatMessage({ id: `strings.sub_category` })}
                        {typeDescriptionFilter}
                    </span>
                </Col>

                <Col span={customContentSize}>
                    <UiMultiSelect
                        output="object"
                        allowClear={true}
                        placeholder={formatMessage({ id: "filters.select_field.not_have" })}
                        value={selectedSubCategory?.map((i) => i.value) || []}
                        withoutImage
                        onChange={(val) => {
                            clearSubCategories(val);
                            setSelectedSubCategory(val);
                            handleMultiSelect(val, "sub_categories");
                        }}
                        list={subCategory(selectedCategory)}
                        disabled={!selectedCategory}
                        theme={theme}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ContentCluster;
