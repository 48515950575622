import React from "react";
import { toast } from "react-toastify";
import { UiErrors } from "ui-components/ui-errors/UiErrors";
import UiToastWrapper from "./ui-toast-wrapper/UiToastWrapper";

const UiToast = {
    success: (content, ...args) => {
        if (content.component) {
            return toast.success(content.component, ...args);
        }
        return toast.success(<UiToastWrapper content={content} type="success" />, ...args);
    },
    error: (content, ...args) => {
        if (content.component) {
            return toast.error(content.component, ...args);
        }
        return toast.error(<UiToastWrapper content={content} type="error" />, ...args);
    },
    info: (content, ...args) => {
        return toast.info(<UiToastWrapper content={content} type="info" />, ...args);
    },
    warn: (content, ...args) => {
        return toast.warn(<UiToastWrapper content={content} type="warning" />, ...args);
    },
    update: (...args) => {
        return toast.update(...args);
    },
    exceptions: (error) => {
        let content;

        if (
            (error?.response?.status === 401 || error?.response?.status === 500) &&
            !error?.response?.data?.errors?.data?.status_code
        ) {
            error.response.data = {
                ...error.response.data,
                errors: {
                    data: {
                        status_code: error.response.status,
                    },
                },
            };
        }

        if (!error?.response?.data?.errors) {
            content = error?.message || "ساختار ارور اشتباه است";
            if (content === "Operation canceled by the user change item.") return;
        } else {
            content = <UiErrors errors={error?.response?.data?.errors || {}} />;
        }
        return toast.error(content);
    },
};

export default UiToast;
