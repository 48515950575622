import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// REDUX IMPORTS
import { Provider } from "react-redux";
import Store from "./store";
import CatchWrapper from "CatchWrapper";

let store = Store();

ReactDOM.render(
    <Provider store={store}>
        <CatchWrapper>
            <App />
        </CatchWrapper>
    </Provider>,
    document.getElementById("root"),
);
serviceWorker.unregister();
