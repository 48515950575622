import React from "react";
import Scrollbar from "react-scrollbars-custom";
import { Avatar } from "antd";
import { FormattedNumber } from "react-intl";
import _ from "lodash";
import { resourcesTableTitles } from "./ResourcesFunctions";
import AccountInfoPopover from "../account-info-popover/AccountInfoPopover";
import { getUrlFromAvatarImage } from "GlobalFunctions";
import { UiTooltip } from "ui-components";

const getGenderProperty = (gender) => {
    let title = "";
    let icon = "";
    switch (gender) {
        case "man":
            title = "جنسیت: مرد";
            icon = "icon-male-1";
            break;
        case "woman":
            title = "جنسیت: زن";
            icon = "icon-female-1";
            break;
        case null:
            title = "جنسیت: نامشخص";
            icon = "icon-unknown-1";
            break;
        default:
            title = "جنسیت: نامشخص";
            icon = "icon-unknown-1";
            break;
    }
    return (
        <UiTooltip title={title}>
            <i className={`icon ${icon}`} />
        </UiTooltip>
    );
};
const ResourcesViewTable = ({
    platform,
    userSelect,
    data = [],
    withHeader,
    removeUnCategoryResource,
    addToSelectionUnCategoryList,
    deleteIcon,
    editMode,
    scrollbarStyle = { width: "100%", height: 550 },
    selectedItems,
}) => {
    const { title, titleField, resultTitle, resultField } = resourcesTableTitles(platform);

    const selectedItemsIds = selectedItems
        ? selectedItems.map((item) => {
              if (item.isRemoved !== true) return item.id;
          })
        : [];

    return (
        <div className="resource-result-table">
            {/* ردیف هدر جدول */}
            {withHeader && (
                <div className="table-row header">
                    <div className="text header">{title}</div>

                    {resultTitle && <div className="text-result header">{resultTitle}</div>}
                </div>
            )}

            <Scrollbar noScrollX={true} style={scrollbarStyle}>
                {data.map((item, index) => {
                    const url = getUrlFromAvatarImage(item.avatar, item.image, "avatar");

                    // قسمت بالایی برای موارد از قبل ذخیره شده و جدید انتخاب نشده باشد
                    const leftTop = !editMode || (!item.isRemoved && !item.isAdded);

                    // قسمت پایینی
                    const leftBottom = item.isAdded;

                    if ((userSelect == "leftTop" && leftTop) || (userSelect == "leftBottom" && leftBottom)) {
                        return (
                            <AccountInfoPopover
                                data={item}
                                platformName={platform}
                                trigger="hover"
                                mouseEnterDelay={1}
                                className="flex"
                                key={index}
                            >
                                <div
                                    className={`table-row resource-result-item ${
                                        selectedItemsIds.includes(item.id) ? "active-item" : ""
                                    }`}
                                    onClick={() => addToSelectionUnCategoryList(item, index)}
                                >
                                    {/* ستون اول شامل آواتار */}
                                    <div>
                                        <Avatar size="large" src={url} className="image" />
                                    </div>

                                    {getGenderProperty(item.gender)}

                                    {/* ستون دوم شامل اسم منبع */}
                                    <div className="text">
                                        {item[titleField] && item[titleField].length > 30
                                            ? item[titleField].substr(0, 30) + " ..."
                                            : item[titleField]}
                                    </div>
                                    {/* ستون سوم شامل فیلد عددی */}
                                    {resultField && item[resultField] && (
                                        <div className="text-result">
                                            <FormattedNumber value={item[resultField]} />
                                        </div>
                                    )}
                                    {/* ستون چهارم شامل آیکون سطل آشغال برای حذف */}
                                    {deleteIcon && (
                                        <div onClick={() => removeUnCategoryResource(item.id, index, true)}>
                                            <i className="icon icon-delete"></i>
                                        </div>
                                    )}
                                </div>
                            </AccountInfoPopover>
                        );
                    }
                })}
            </Scrollbar>
        </div>
    );
};
export default ResourcesViewTable;
