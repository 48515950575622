import React, { useContext } from "react";
import PropTypes from "prop-types";
import UiTableActions from "../actions/UiTableActions";
import UiCheckbox from "ui-components/ui-checkbox/UiCheckbox";
import UiTableCell from "../cell/UiTableCell";
import { UiTableStateContext } from "../context/UiTableContext";
import useUiTableSelect from "../hooks/useUiTableSelect";
import UiTableCollapse from "../expend/UiTableCollapse";
import UiTableExpended from "../expend/UiTableExpended";
import UiTableValueNormalizer from "./UiTableValueNormalizer";

/**
 * پیاده سازی یک ردیف از جدول
 * @param {*} item هر رکورد یا آیتم
 */
const UiTableRow = ({ item }) => {
    const tableState = useContext(UiTableStateContext);
    const { setSelect, findSelected } = useUiTableSelect();

    // اگر رکورد تیک خورده باشد
    const isChecked = findSelected(item.id);

    // شناسه رکوردی که کولپس آن باز است
    const expendedId = tableState?.expendedIds?.[item.id];
    return (
        <>
            <div className={`ui-table-row ${expendedId ? "collapse" : ""}`} style={tableState?.collWidth}>
                {/* ستون ها بر اساس فیلد هایی که درخواست داده شده ایجاد میشوند */}
                {/* showFields قبلا در اولین بخش تشخیص و ساخته شده */}
                {tableState?.showFields?.map((field, key) => {
                    // اگر فیبد اکشن تعریف شده بود، اکشن‌بار نمایش داده شود
                    if (field === "actions")
                        return (
                            <UiTableCell key={key} field={field} item={item}>
                                <UiTableActions itemId={item.id} item={item}/>
                            </UiTableCell>
                        );

                    // اگر فیلد مالتی‌سلکت تعریف شده بود چک‌باکس نمایش داده شود
                    if (field === "multiSelect")
                        return (
                            <UiTableCell key={key} field={field} item={item}>
                                <UiCheckbox
                                    id={item.id + ""}
                                    checked={isChecked}
                                    onChange={(checked) => setSelect(checked, item.id)}
                                />
                            </UiTableCell>
                        );

                    // اگر فیلد اکسپندبل تعریف شده بود کولپس نمایش داده شود
                    if (field === "expandable")
                        return (
                            <UiTableCell key={key} className="expandable" field={field} item={item}>
                                <UiTableCollapse item={item} />
                            </UiTableCell>
                        );

                    // پیاده سازی ستون‌های اصلی جدول
                    return (
                        <UiTableCell key={key} field={field} item={item}>
                            <UiTableValueNormalizer field={field} value={item[field]} />
                        </UiTableCell>
                    );
                })}
            </div>

            {/* اگر رکورد هاوی تنظیمات کولپس بود */}
            {expendedId && <UiTableExpended item={item} />}
        </>
    );
};
UiTableRow.propTypes = {
    item: PropTypes.object,
};
export default UiTableRow;
