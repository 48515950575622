import React from "react";
import { sanitize } from "dompurify";
import _ from "lodash";
import "./MessageData.scss";
import { img_rock } from "ui-components/ui-images";

const MessageData = ({ icon, img, description, style, type, className }) => {
    return (
        <div className={`message-box-container ${className}`} style={style}>
            <div className="box">
                {img && (
                    <>
                        <img className="image" src={require(`../../assets/img/image-icon/${img}`)} alt="" />

                        {type && type === "warning" && (
                            <div>
                                <img className="rock1" src={img_rock} alt="" />
                                <img className="rock2" src={img_rock} alt="" />
                                <img className="rock3" src={img_rock} alt="" />
                            </div>
                        )}
                    </>
                )}
                {icon && <i className={"icon icon-" + icon}></i>}
            </div>
            {!_.isString(description) && <p className="description">{description}</p>}
            {_.isString(description) && (
                <p className="description" dangerouslySetInnerHTML={{ __html: sanitize(description) }}></p>
            )}
        </div>
    );
};

export default MessageData;
