import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import UserAccess from "../../permission/UserAccess";
import { UiMultiSelect } from "ui-components";
const LangSelectField = (props) => {
    const { name, formatMessage, platformParams, item, typeDescriptionFilter, handleMultiSelect, size , theme } = props;

    const allowedLanguage = UserAccess.getAllowedParams("Languages");

    let options = allowedLanguage.map((val, index) => ({ value: val, label: formatMessage({ id: "search." + val }) }));

    const selectedX =
        _.isEmpty(platformParams) || _.isEmpty(platformParams[name])
            ? []
            : options.filter((x) => platformParams[name].includes(x.value)).map((i) => i.value);

    return (
        <Row key={name} type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: "search." + name })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={18} xl={size === "md" ? 18 : 17}>
                <UiMultiSelect
                    output="object"
                    onChange={(val) => handleMultiSelect(val, name)}
                    placeholder={formatMessage({ id: "filters.lang_field.not_have" })}
                    list={options}
                    value={selectedX}
                    withoutImage
                    theme={theme}
                />
            </Col>
        </Row>
    );
};

export default LangSelectField;
