import React from "react";
import { Row, Col } from "antd";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { injectIntl } from "react-intl";
import { UiRadio, UiRadioGroup } from "ui-components";

const RadioBoxField = (props) => {
    const { name, platformParams, item, handleRadio, size, intl, theme, typeDescriptionFilter } = props;

    const { formatMessage } = intl;

    return (
        <Row key={name} align="middle" type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: `strings.${name}` })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={18} xl={size === "md" ? 18 : 20}>
                <UiRadioGroup
                    name={name}
                    theme={theme}
                    onChange={(e) => handleRadio(name, e)}
                    value={platformParams[name]}
                    className="ant-col-22"
                >
                    {/* <Col span={6} ><Radio value="">همه موارد</Radio></Col> */}
                    {item?.enum?.map((val, index) => {
                        return (
                            <Col span={6} key={index}>
                                <UiRadio theme={theme} value={val}>
                                    {formatMessage({ id: `strings.${val}` })}
                                </UiRadio>
                            </Col>
                        );
                    })}
                    <Col span={6}>
                        <UiRadio value="" theme={theme}>
                            <FormattedSimpleMsg id="filters.radio_box.no_setting" />
                        </UiRadio>
                    </Col>
                </UiRadioGroup>
            </Col>
        </Row>
    );
};

export default injectIntl(RadioBoxField);
