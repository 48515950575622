import React, { memo } from 'react';
import PropTypes from "prop-types";
import "./LoadPulse.scss";

const LoadPulse = ({ type }) => {
    return (
        <>
            {type === "circular" &&
                <div id="ajaxloader2">
                    <div className="outer"></div>
                    <div className="inner"></div>
                </div>

            }


            {type === "pulse" &&

                <div id="loadpulse">
                    <div className="segment">
                        <div id="layer1" className="ball"></div>
                        <div id="layer7" className="pulse"></div>
                    </div>
                    <div className="segment">
                        <div id="layer2" className="ball"></div>
                        <div id="layer8" className="pulse"></div>
                    </div>
                    <div className="segment">
                        <div id="layer3" className="ball"></div>
                        <div id="layer9" className="pulse"></div>
                    </div>
                    <div className="segment">
                        <div id="layer4" className="ball"></div>
                        <div id="layer10" className="pulse"></div>
                    </div>
                    <div className="segment">
                        <div id="layer5" className="ball"></div>
                        <div id="layer11" className="pulse"></div>
                    </div>
                </div>
            }

            {type === "dots" &&

                <div className="loader">
                    <div className="duo duo1">
                        <div className="dot dot-a"></div>
                        <div className="dot dot-b"></div>
                    </div>
                    <div className="duo duo2">
                        <div className="dot dot-a"></div>
                        <div className="dot dot-b"></div>
                    </div>
                </div>
            }
        </>
    )
}

LoadPulse.propType = {
    type: PropTypes.oneOf(["circular", "pulse", "dots"])
};

export default memo(LoadPulse);