import React from "react";
import PropTypes from "prop-types";
import useTrigger from "./useTrigger";

const PortalContent = ({
    nodeRef,
    delay,
    openPortal,
    clearDelay,
    closePortal,
    children,
    trigger,
    portalName,
}) => {
    useTrigger({
        nodeRef: nodeRef,
        trigger: trigger,
        portalName: portalName,
        openFunc: () => {
            delay(() => {
                openPortal();
            });
        },
        closeFunction: (onlyClear) => {
            clearDelay(() => {
                closePortal();
            }, onlyClear);
        },
    });
    return (
        <span
            style={{
                display: "contents",
            }}
            ref={nodeRef}
        >
            {children}
        </span>
    );
};
PortalContent.propTypes = {
    nodeRef: PropTypes.any,
    delay: PropTypes.func,
    openPortal: PropTypes.func,
    clearDelay: PropTypes.func,
    closePortal: PropTypes.func,
    children: PropTypes.node,
    trigger: PropTypes.string,
    portalName: PropTypes.string,
};
export default PortalContent;