import React, { useContext, useEffect, useState } from "react";
import { UiTableDispatchContext, UiTableStateContext } from "../context/UiTableContext";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";

/**
 * دکمه باز کننده کولپس در هر ردیف
 * @param {*Object} item  رکورد
 */
const UiTableCollapse = ({ item }) => {
    const tableDispatch = useContext(UiTableDispatchContext);
    const tableState = useContext(UiTableStateContext);
    const [expend, setExpend] = useState(false);

    useEffect(() => {
        let expendedIds = tableState?.expendedIds;
        expendedIds = { ...expendedIds, ...{ [item.id]: expend } };
        tableDispatch({ type: "setExpendedIds", value: expendedIds });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expend]);

    // اگر تمام کولپس ها بسته شده بود
    useEffect(() => {
        if (isEqual(tableState?.expendedIds, []) && expend) {
            setExpend(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState?.expendedIds]);

    // فیلدهایی که باید کولپس شوند
    const expandableFields = tableState?.expandableFields;

    // اگر تمام فیلد هایی که کولپس شده اند مقدار خالی داشتند، آیکن کولپس در آن رکورد نمایش داده نشود
    let empty = true;
    expandableFields.forEach((field) => {
        if (item[field] && !isEmpty(item[field]) && !!item[field]) {
            empty = false;
        }
    });
    if (empty) return null;

    // اگر فیلد مقدار داشت - (کامنت قبل خوانده شود)
    return <i onClick={() => setExpend((prev) => !prev)} className={`icon icon-arrow-${expend ? "up" : "down"}`}></i>;
};
UiTableCollapse.propTypes = {
    item: PropTypes.object,
};
export default UiTableCollapse;
