import React, { useReducer, createContext } from "react";
import PropTypes from "prop-types";
let initialState = {
    data: [],
    errors: {},
    selectedItems: [],
    expendedIds: {},
    searchParams: {},
    loading: false,
    totalCountData: 0,
    oldSearchParams: {},
};

export const UiTableStateContext = createContext(undefined);
export const UiTableDispatchContext = createContext(undefined);

const uiTableReducer = (state, action) => {
    switch (action.type) {
        // دریافت دیتا
        case "getData":
            return { ...state, errors: {}, loading: true, oldSearchParams: action.oldSearchParams, selectedItems: [] };
        case "success":
            return { ...state, data: action.value, errors: {}, loading: false, totalCountData: action.totalCount };
        case "error":
            return { ...state, errors: action.value, loading: false };
        // حذف آیتم
        case "remove":
            return { ...state, errorsRemove: {}, loadingRemove: true, isRemoved: false };
        case "successRemove":
            return {
                ...state,
                data: action.value,
                errorsRemove: {},
                loadingRemove: false,
                selectedItems: [],
                isRemoved: true,
            };
        case "errorRemove":
            return { ...state, errorsRemove: action.value, loadingRemove: false, isRemoved: false };
        // اشتراک گذاری
        case "share":
            return { ...state, errorsShare: {}, loadingShare: true, isShared: false };
        case "successShare":
            return { ...state, errorsShare: {}, loadingShare: false, isShared: true };
        case "errorShare":
            return { ...state, errorsShare: action.value, loadingShare: false, isShared: false };
        // سایر
        case "setData":
            return { ...state, data: action.value };

        case "setSelectedItems":
            return { ...state, selectedItems: action.value };
        case "setExpendedIds":
            return { ...state, expendedIds: action.value };

        case "setSearchParams":
            return { ...state, searchParams: action.value };
        default:
            throw new Error(`Unexpected action: ${action.type}`);
    }
};

const UiTableProvider = ({ values, children }) => {
    initialState = { ...initialState, ...values };
    const [uiTableState, DispatchUiTableState] = useReducer(uiTableReducer, initialState);
    return (
        <UiTableStateContext.Provider value={uiTableState}>
            <UiTableDispatchContext.Provider value={DispatchUiTableState}>{children}</UiTableDispatchContext.Provider>
        </UiTableStateContext.Provider>
    );
};
UiTableProvider.propTypes = {
    values: PropTypes.object,
    children: PropTypes.node,
};

export { UiTableProvider };
