import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import { hot } from "react-hot-loader/root";
import { UiToast } from "ui-components";
/**
 * for query builder, need to fix style bugging
 */
import { Query, Builder, Utils as QbUtils, Widgets, Operators } from "react-awesome-query-builder";
// COMPONENTS
import AppHeader from "shared/AppHeader";
import AppHeaderOffline from "shared/AppHeaderOffline";
import NotificationSidebar from "shared/notification/NotificationSidebar";
import Sidebar from "shared/Sidebar";
import CustomRouter from "./CustomRouter";
import { api } from "services";
import SideFilter from "shared/side-filter/sideFilter";
import KeyboardEventHandler from "react-keyboard-event-handler";
import AlertNewVersion from "components/alert-new-version/AlertNewVersion";
/*
 * @react-intl package
 * Add React Intl for localization and many other useful features
 * Internationalize React apps. This library provides React components and an API to format dates, numbers,
 * and strings, including pluralization and handling translations.
 */
import { IntlProvider } from "react-intl";
import AppLocale from "./languageProvider";
//import { language } from 'GlobalVariables';

// ANTD IMPORTS
import { Layout, Row, ConfigProvider } from "antd";
import faIR from "antd/lib/locale-provider/fa_IR";
import Counter from "shared/counter/Counter";
// استایل
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import { DomainSettings } from "./DomainSettings";
import WrapperAppContext from "context/app/WrapperAppContext";
import "./App.scss";
import NewVersionBar from "shared/new-version-bar/NewVersionBar";
import { ReactGAM } from "google-analytics/googleAnalytics";
import DebugLocationHistory from "shared/DebugLocationHistory";


const { Header, Content } = Layout;
//React Intl current language
const language = DomainSettings("language");
const languageList = DomainSettings("language_list");

/**
 * for query builder
 */
export const QueryF = Query;
export const BuilderF = Builder;
export const QbUtilsF = QbUtils;
export const WidgetsF = Widgets;
export const OperatorsF = Operators;

// get parent class of theme
let themeName = DomainSettings("theme");
let themeClass = "theme-" + themeName;
let themeList = DomainSettings("theme_list");

ReactGAM().init();
ReactGAM().trackPageView(window.location.pathname);



class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: {
                setting: false,
                download: false,
            },
            isCache: window.location.pathname.includes("/profile/cache"),
            themeName,
            themeClass,
            language,
        };

        this.setVisible = this.setVisible.bind(this);
        this.getDomainSettings = this.getDomainSettings.bind(this);
        this.updateNotifNumber = this.updateNotifNumber.bind(this);
    }

    changeTheme = () => {
        let { themeName } = this.state;

        if (themeName === "dark") {
            themeName = "light";
        } else {
            themeName = "dark";
        }

        if (!themeList.includes(themeName)) {
            return;
        }

        // add theme to localstorage
        localStorage.setItem("theme", themeName);

        const themeClass = `theme-${themeName}`;

        document.body.classList.remove(`theme-${themeName === "dark" ? "light" : "dark"}`);
        document.body.classList.add(themeClass);

        this.setState({
            themeName,
            themeClass,
        });
    };

    componentDidMount() {
        // if (themeList.length > 1 && !localStorage.getItem("themeSeen")) {
        //     UiToast.success(
        //         "با‌استفاده از کلید F4 می‌توانید قالب سامانه را تغییر دهید",
        //         {
        //             autoClose: false,
        //             draggable: false,
        //             onClose: () => {
        //                 localStorage.setItem("themeSeen", true);
        //             },
        //         },
        //     );
        // }

        document.body.classList.add(themeClass);

        this.setState({
            sidebar: {
                active: 0,
                goToLogin: false,
            },
        });
        this.getDomainSettings();
    }

    setVisible(type) {
        const notification = this.state.notification;

        Object.keys(notification).forEach((item) => {
            if (item !== type) notification[item] = false;
        });

        if (type) notification[type] = !notification[type];

        this.setState({ notification });
    }

    updateNotifNumber(notifNumber) {
        this.setState({
            notifNumber,
        });
    }

    /**
     * افزودن آیکن و عنوان سامانه
     */
    getDomainSettings() {
        document.querySelector('[rel="shortcut icon"]').href = DomainSettings("fav_icon"); // set fav-icon
        document.querySelector("title").innerText = DomainSettings("title"); // set browser title
    }

    /**
     * فعال کردن قابلیت انتخاب کردن متن به صورت سلکت
     */
    activeuserSelect = () => {
        document.getElementById("root").style.userSelect = "auto";
        UiToast.success("ویژگی انتخاب متن از محتوا فعال شد");
    };

    handleKeys = (key) => {
        switch (key) {
            case "f4":
                this.changeTheme();
                break;
            case "f8":
                this.activeuserSelect();
                break;
            case "f7":
                this.changeLanguage();
                break;
            case "f2":
                window.location.href = "/";
                break;
            default:
                break;
        }
    };

    changeLanguage = () => {
        let { language } = this.state;

        language = language === "fa" ? "ar" : "fa";

        if (!languageList.includes(language)) {
            return null;
        }

        // add language to localstorage
        localStorage.setItem("language", language);

        this.setState({
            language,
        });
    };

    render() {
        // با یک اینتروال چک میکنیم که کاربر لاگین شده یا نه
        // برای اینکه اپ جی اس تو این مرحله آپدیت نمیشه از این روش استفاده شده

        let isAuthorize = api.isAuthorize();
        if (!isAuthorize) {
            let interval = setInterval(() => {
                isAuthorize = api.isAuthorize();
                if (isAuthorize === true) {
                    this.setState({ isAuthorize });
                    clearInterval(interval);
                }
            }, 1000);
        }

        const pathname = window.location.pathname;

        const showGlobalFilter =
            (pathname.includes("/profile/") && !pathname.includes("/profile/content")) ||
            (pathname.includes("/dashboard") && !pathname.includes("/dashboards")) ||
            pathname.includes("/query-files/single/") ||
            pathname.includes("/searchplatform") ||
            pathname.includes("/search/result") ||
            pathname === "/";
        const { download, setting } = this.state.notification;
        const { notifNumber, isCache, language } = this.state;

        const currentAppLocale = AppLocale[language];
        const Zoom = cssTransition({
            enter: "zoomIn",
            exit: "zoomOut",
            duration: 5000,
            appendPosition: false,
            collapse: true,
            collapseDuration: 300,
        });
        return (
            <WrapperAppContext>
                {/* <button className="btn-custom" onClick={this.changeTheme}>change '{themeName}' theme</button> */}
                <KeyboardEventHandler
                    handleKeys={["f4", "f2", "f7", "f8"]}
                    onKeyEvent={(key, e) => this.handleKeys(key)}
                />
                <ConfigProvider locale={faIR}>
                    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <Router>
                            <>
                                <Layout>
                                   
                                    {/* Main Layout */}
                                     {isAuthorize && (  <AlertNewVersion/>)}
                                     {isAuthorize && (  <NewVersionBar/>)}
                                    <Row>
                                        {isAuthorize ? (
                                            <Header>
                                                {/* Top Header Manshour Icon and Date */}
                                                <AppHeader setVisible={this.setVisible} notifNumber={notifNumber} />

                                                {/* کانتر */}
                                                <Counter />
                                            </Header>
                                        ) : (
                                            isCache && (
                                                <Header>
                                                    {/* Top Header Manshour Icon and Date */}
                                                    <AppHeaderOffline />

                                                    {/* کانتر */}
                                                    <Counter />
                                                </Header>
                                            )
                                        )}
                                    </Row>
                                    <Row>
                                        <Content className={isCache ? "is-cache" : ""}>
                                            {/* ساید بار */}
                                            {isAuthorize && (
                                                <>
                                                    <Sidebar />
                                                </>
                                            )}
                                            {/* ستاپ گوگل انالیتیکز برای ایونت تغییر صفحه */}
                                            <DebugLocationHistory/>
                                            {/* لودینگ صفحه */}
                                            <Suspense fallback={<div></div>}>
                                                <Switch>
                                                    {/* روت های سایت */}
                                                    <CustomRouter />
                                                </Switch>
                                            </Suspense>
                                        </Content>

                                        {/* نوتیفیکیشن های سایدبار */}
                                        {isAuthorize && (
                                            <NotificationSidebar
                                                visible={download}
                                                setVisible={this.setVisible}
                                                updateNotifNumber={this.updateNotifNumber}
                                            />
                                        )}

                                        {/* فیلتر های ساید بار */}
                                        {isAuthorize && showGlobalFilter && (
                                            <SideFilter visible={setting} setVisible={this.setVisible} />
                                        )}
                                    </Row>
                                </Layout>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={5000}
                                    hideProgressBar
                                    newestOnTop={true}
                                    closeOnClick={false}
                                    rtl
                                    pauseOnVisibilityChange={false}
                                    pauseOnFocusLoss={false}
                                    draggable={false}
                                    pauseOnHover={true}
                                    // transition={Zoom}
                                    className="customized-toastify"
                                />
                            </>
                        </Router>
                    </IntlProvider>
                </ConfigProvider>
            </WrapperAppContext>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sidebar: state.sidebar,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        activateSidebar: (e) => {
            dispatch({
                type: "ACTIVATE_SIDEBAR",
                payload: e,
            });
        },
    };
};
App = hot(App);
export default connect(mapStateToProps, mapDispatchToProps)(App);
