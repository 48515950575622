import React, { useState } from "react";
import PropTypes from "prop-types";
import "./UiInputNumber.scss";
import UiInput from "./../ui-input/UiInput";
import { useEffect } from "react";

/**
 * اینپوت نامبر
 * @param {*func} onChange
 * @param {*func} onEnter
 * @param {*func} onKeyUp
 * @param {*func} onBlur
 * @param {*string} value
 * @param {*string} theme oneOf(["dark", "light"]),
 * @param {*string} name
 * @param {*string} className
 * @param {*string} placeholder
 * @param {*bool} disabled
 * @param {*number} id
 * @param {*string} size oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
 * @param {*bool} allowClear
 * @param {*bool} loading
 * @param {*any} width
 * @param {*bool} hasBorder
 * @param {*object} style
 * @param {*any} background
 * @param {*bool} autoFocus
 * @param {*bool} autoSelect
 * @param {*number} min
 * @param {*number} max
 * @param {*number} defaultValue
 */
const UiInputNumber = (props) => {
    const { disabled, value, defaultValue, width = 80, onChange = () => {}, min, max, ...other } = props;

    // اولویت با ولیو بعد با دیفالت ولیو بعد با کمترین مقدار
    const val = value ? value : defaultValue ? defaultValue : min ? min : 0;

    const [currentValue, setCurrentValue] = useState(val);

    /**
     * افزایش
     */
    const handleIncrement = () => {
        if (disabled) return;
        if (currentValue >= max) return;
        const count = parseInt(currentValue || min) + 1;
        setCurrentValue(count);
        handleChange(count);
    };

    /**
     * کاهش
     */
    const handleDecrement = () => {
        if (disabled) return;
        if (currentValue <= min) return;
        const count = parseInt(currentValue || min) - 1;
        setCurrentValue(count);
        handleChange(count);
    };

    useEffect(() => {
        setCurrentValue(val);
    }, [val]);
    
    const handleChange = (count) => {
        typeof onChange === "function" && onChange(parseInt(count));
    };

    return (
        <div className="ui-input-number">
            <div className="ui-input-number-btns">
                <i className="icon icon-arrow-up" onClick={handleIncrement}></i>
                <i className="icon icon-arrow-down" onClick={handleDecrement}></i>
            </div>
            <UiInput
                width={width}
                type="number"
                value={currentValue}
                onChange={handleChange}
                disabled={disabled}
                {...other}
            />
        </div>
    );
};

UiInputNumber.propTypes = {
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    theme: PropTypes.oneOf(["dark", "light"]),
    name: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    allowClear: PropTypes.bool,
    loading: PropTypes.bool,
    width: PropTypes.any,
    hasBorder: PropTypes.bool,
    style: PropTypes.object,
    background: PropTypes.any,
    autoFocus: PropTypes.bool,
    autoSelect: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.number,
};

export default UiInputNumber;
