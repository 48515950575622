import React from 'react';

import { FormattedSimpleMsg } from "GlobalFunctions";

export const resourcesTableTitles = (platform) => {

    let title,
        titleField,
        resultTitle,
        resultField = "";

    switch (platform) {

        case "newspaperagencies":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "name";
            resultTitle = null;
            resultField = null;
            break

        case "newsagencies":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.alexa" />;
            resultField = "alexa_rank";
            break

        case "instagramusers":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.member" />;
            resultField = "follower_count";
            break

        case "twitterusers":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.member" />;
            resultField = "follower_count";
            break

        case "telegramusers":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = null;
            resultField = null;
            break

        case "telegramchannels":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.member" />;
            resultField = "member_count";
            break

        case "telegramgroups":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.member" />;
            resultField = "member_count";
            break

        case "facebookusers":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = null;
            resultField = null;
            break

        case "facebookpages":
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.like" />;
            resultField = "like_count";
            break

        default:
            title = <FormattedSimpleMsg id="resource.function.title" />;
            titleField = "title";
            resultTitle = <FormattedSimpleMsg id="resource.function.member" />;
            resultField = "follower_count";
            break
    }

    return {
        title,
        titleField,
        resultTitle,
        resultField
    }

}