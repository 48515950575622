import React, { memo } from "react";
import PropTypes from "prop-types";
import { Empty } from "antd";
import "./Warning.scss";
import { currentTheme } from "DomainSettings";
import { img_light_planing_no_data, img_dark_planing_no_data } from "ui-components/ui-images";

const Warning = (props) => {
    const { description, img, noAbsolute, className, style = {}, noData } = props;

    return (
        <div className={`warning ${className}`}>
            <Empty
                style={style}
                className={["planning-warning", noAbsolute ? "no-absolute" : ""].join(" ")}
                image={
                    noData ? (currentTheme() === "light" ? img_light_planing_no_data : img_dark_planing_no_data) : img
                }
                description={description}
            />
        </div>
    );
};

// نوع و نام پراپ های ورودی
Warning.propTypes = {
    description: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    noAbsolute: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    noData: PropTypes.bool,
};

export default memo(Warning);
