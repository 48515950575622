import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import PropTypes from "prop-types";
import usePrevious from "hooks/usePrevious";
import "./UiTreeSelect.scss";
import { isEqual, cloneDeep } from "lodash";

const onImageError = (ev) => {
    ev.target.src = "/images/no_image.jpg";
};
const flatten = (tree) => {
    let arr = tree.reduce((accumulator, item) => {
        return item?.children?.length > 0
            ? accumulator.concat([item, ...flatten(item?.children)])
            : accumulator.concat(item);
    }, []);
    return arr;
};
//ایجاد کانتکست برای نگه داری متود ها و استیت ها - پاس دادن به کامپوننت های بازگشتی
export const UiTreeSelectContext = createContext(null);

const UiTreeSelect = (props) => {
    const {
        placeholder = "",
        list = [],
        width,
        size = "md",
        theme,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        canSelectParent = false,
        disabled = false,
        output = "object",
        value = [],
        withoutShowAllSelected,
        defaultExpandAll = false,
    } = props;

    //remove Additional characters
    const additionalCharactersRegex = /[\u2000-\u200E\u2028-\u202F]/gm;

    const selectedId = value || [];
    const wrapperRef = useRef(null);
    let inputRef = useRef(null);

    const prevProps = usePrevious({ list, selectedId });
    const [toggleList, setToggleList] = useState(false);
    const [query, setQuery] = useState("");
    const [inputWidth, setInputWidth] = useState("");

    const focusInput = () => {
        inputRef?.current && inputRef.current.focus();
    };
    // یافتن آیتمی که به عنوان انتخاب شده از ورودی به عنوان selectedId دریافت شده است
    // و به عنوان آیتم انتخاب شده به selected اختصاص می‌یابد
    const listTemp = flatten(list || []);

    const findSelected = listTemp?.find((x) => x.value.toString() === selectedId?.toString());

    const [dataList, setDataList] = useState(list || []);
    const [selected, setSelected] = useState(findSelected);
    const [expanded, setExpanded] = useState([]);

    /**
     * برگرداندت لیست ایتم هایی که باید به صورت باز شده باشند
     *
     * @param {*array} nodes
     * @param {*bool} firstLevel
     */

    const getAllValuesFromNodes = (nodes, firstLevel) => {
        if (firstLevel) {
            const values = [];
            for (let n of nodes) {
                values.push(n.value);
                if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                }
            }
            return values.map((i) => i.toString());
        } else {
            const values = [];
            for (let n of nodes) {
                values.push(n.value);
                if (n.children) {
                    values.push(...getAllValuesFromNodes(n.children, false));
                }
            }
            return values.map((i) => i.toString());
        }
    };

    useEffect(() => {
        setInputWidth(query.length);

        if (query && query.trim() !== "") {
            setExpanded(getAllValuesFromNodes(filteredList, true));
        } else {
            setExpanded([]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    //باز شدن تمام زیر شاخه ها اگر مقدار  defaultExpandAll true  باشد
    useEffect(() => {
        if (defaultExpandAll && dataList.length > 0) {
            setExpanded(listTemp?.map((x) => x.value.toString()));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultExpandAll, dataList]);

    /**
     *
     * افزودن ایونت بسته شدن سلکت بعد از کلیک کردن در خارج از سلکت
     * این ایونت به داکیومنت اضافه میشود
     *
     */
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    /**
     *
     * بررسی اینکه دیتا تغییر کرده است یا خیر
     *
     */
    useEffect(() => {
        if (!isEqual(prevProps?.list, list)) {
            setDataList(list);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    /**
     * آپدیت کردن آیتم انتخاب شده در لیست
     */
    useEffect(() => {
        if (selectedId && !isEqual(prevProps?.selectedId, selectedId)) {
            setSelected(findSelected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    /**
     *
     * کنترل کردن کلیک خارج از سلکت برای بسته شدن آن
     * وقتی این ایونت فراخوانی شود دیتا به کامپوونت پدر انتقال داده  می شود
     *
     * @param {*event} event
     */
    const handleClickOutside = (event) => {
        if (toggleList && wrapperRef?.current && !wrapperRef?.current?.contains(event.target)) {
            handleToggle(false);
        }
    };

    /**
     * باز و بسته کردن سلکت
     *
     * @param {*bool} state
     */
    const handleToggle = (state) => {
        if (disabled) return;
        setToggleList(state);
        focusInput();
    };
    /**
     * هنگام اینتر زدن داخل اینپوت اولین ایتم در صورت وجود سلکت شه
     *
     * @param {*bool} state
     */
    const handelEnter = () => {
        if (canSelectParent && filteredList[0] && !selected?.value === filteredList[0].value) {
            addItem(filteredList[0]);
            setQuery("");
        }
    };

    /**
     * حذف یک ایتم از اینم های انتخاب شده
     *
     * @param {*object} item
     * @param {*bool} canChange در لحظه دیتا رو به کامپوننت پدر ارسال کند
     */

    const removeItem = (item, canChange) => {
        setSelected();

        if (canChange) {
            typeof onChange === "function" && onChange();
        }
    };
    /**
     * افزودن یک ایتم به ایتم های انتخاب شده
     *
     * @param {*object} item
     */

    const addItem = (item) => {
        setSelected(item);
        handleToggle(false);
        typeof onChange === "function" && onChange(output === "object" ? item : item?.value);
    };

    /**
     * جستجو در لیست یا کمه کلیدی
     *
     * @param {*string} value
     */

    const handleSearch = (value) => {
        !toggleList && handleToggle(true);

        const text = value?.replace(additionalCharactersRegex, " ");

        setQuery(text);
    };
    /**
     * آیا ایتم انتخاب شده یا خیر
     *
     * @param {*object} item
     */
    const checkSelected = (item) => {
        if (selected?.value == item?.value) return true;
        else return false;
    };

    /**
     * تصمیم به این که ایتم باید حذف شود یا اضافه شود
     *
     * @param {*object} item
     */
    const handleSelected = (item) => {
        if (checkSelected(item)) removeItem(item, false);
        else addItem(item);
        focusInput();
    };
    /**
     * برگرداندت لیست با توجه به کلمه کلیدی
     *
     * @param {*array} nodes
     * @param {*sting} keyword
     */

    const keywordFilter = (nodes, keyword) => {
        let newNodes = [];
        for (let n of nodes) {
            n.label = n.label?.toString();
            n.label = n.label?.replace(additionalCharactersRegex, " ");

            if (n.children) {
                const nextNodes = keywordFilter(n.children, keyword);
                if (nextNodes?.length > 0) {
                    if (
                        !n.label
                            ?.toLowerCase()
                            .trim()
                            .replace(/\s/g, "")
                            .includes(keyword?.toLowerCase().trim().replace(/\s/g, ""))
                    ) {
                        n.children = nextNodes;
                    }
                }
                if (
                    nextNodes.length > 0 ||
                    n.label
                        ?.toLowerCase()
                        .trim()
                        .replace(/\s/g, "")
                        .includes(keyword.toLowerCase().trim().replace(/\s/g, ""))
                ) {
                    newNodes.push(n);
                }
                if (nextNodes?.length <= 0) {
                    n.children = [];
                }
            } else {
                if (
                    n.label
                        ?.toLowerCase()
                        .trim()
                        .replace(/\s/g, "")
                        .includes(keyword.toLowerCase().trim().replace(/\s/g, ""))
                ) {
                    newNodes.push(n);
                }
            }
        }
        return newNodes;
    };

    const filteredList = keywordFilter(cloneDeep(dataList), query);

    /**
     *
     * رندر کردن محتوا با استفاده از متد
     *
     */
    return renderSelect(
        width,
        theme,
        wrapperRef,
        inputRef,
        size,
        toggleList,
        handleToggle,
        selected,
        setSelected,
        onChange,
        footer,
        style,
        className,
        withoutImage,
        canSelectParent,
        placeholder,
        disabled,
        query,
        handelEnter,
        handleSearch,
        handleSelected,
        checkSelected,
        filteredList,
        withoutShowAllSelected,
        inputWidth,
        expanded,
        focusInput,
    );
};

function renderSelect(
    width,
    theme,
    wrapperRef,
    inputRef,
    size,
    toggleList,
    handleToggle,
    selected,
    setSelected,
    onChange,
    footer,
    style,
    className,
    withoutImage,
    canSelectParent,
    placeholder,
    disabled,
    query,
    handelEnter,
    handleSearch,
    handleSelected,
    checkSelected,
    filteredList,
    withoutShowAllSelected,
    inputWidth,
    expanded,
    focusInput,
) {
    if (width) {
        style = { ...style, ...{ width: width } };
    }
    const state = {
        expanded,
        footer,
        canSelectParent,
        withoutImage,
        handleSelected,
        checkSelected,
    };
    return (
        <UiTreeSelectContext.Provider value={state}>
            <div
                className={["ui-tree-select", theme, size, className, disabled ? "disabled" : ""].join(" ")}
                ref={wrapperRef}
                style={style}
            >
                <div className={[`ui-tree-select-selection ${toggleList ? "open" : ""}`].join(" ")}>
                    <div className="ui-select-click-area"></div>

                    <ul
                        onClick={() => {
                            !toggleList && handleToggle(!toggleList);
                            focusInput();
                        }}
                        className={[
                            `ui-tree-select-selected ${!withoutShowAllSelected ? "showMultiSelected" : ""}`,
                        ].join(" ")}
                    >
                        {selected && (
                            <li className={"ui-tree-select-selected-item"}>
                                {selected?.img && !withoutImage && (
                                    <img
                                        onClick={() => handleToggle(!toggleList)}
                                        className="ui-tree-select-image"
                                        src={selected?.img}
                                        alt={selected?.label}
                                        onError={onImageError}
                                    />
                                )}
                                <span onClick={() => handleToggle(!toggleList)}>{selected?.label}</span>
                            </li>
                        )}

                        <li
                            style={
                                selected || query.length > 0
                                    ? { width: `calc(${inputWidth}ch + 5px)` }
                                    : { width: "100%" }
                            }
                            className={"ui-tree-select-input"}
                            onClick={() => handleToggle(true)}
                        >
                            <input
                                ref={inputRef}
                                value={query}
                                onChange={(e) => {
                                    handleSearch(e.target.value);
                                }}
                                onPaste={(e) => {
                                    handleSearch(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    const key = e.key || e.keyCode;
                                    if (key === "Enter") {
                                        e.preventDefault();
                                    }
                                }}
                                onKeyUp={(e) => {
                                    const key = e.key || e.keyCode;
                                    if (key === "Enter") {
                                        const val = e.target.value;
                                        handelEnter(val);
                                    }
                                }}
                                disabled={disabled}
                                placeholder={!selected ? placeholder : ""}
                            />
                        </li>
                    </ul>

                    {selected && (
                        <i
                            className="icon icon-close-icon ui-tree-select-close"
                            onClick={() => {
                                setSelected();
                                typeof onChange === "function" && onChange();
                            }}
                        />
                    )}
                    {!selected && (
                        <i
                            onClick={() => handleToggle(!toggleList)}
                            className={`ui-tree-select-arrow icon ${toggleList ? "icon-arrow-up" : "icon-arrow-down"}`}
                        />
                    )}
                </div>
                {toggleList && <RenderNodeList list={filteredList} isFirstLevel={true} />}
            </div>
        </UiTreeSelectContext.Provider>
    );
}

UiTreeSelect.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    list: PropTypes.array,
    width: PropTypes.number,
    footer: PropTypes.node,
    withoutImage: PropTypes.bool,
    canSelectParent: PropTypes.bool,
    defaultExpandAll: PropTypes.bool,
    withoutShowAllSelected: PropTypes.bool, //بعد از این که تعداد ایتم انتخاب شده بیشتر از 1 شد همه را نمایش دهد یا فقط تعداد رو بنویسد
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    output: PropTypes.oneOf(["object", "value"]),
    disabled: PropTypes.bool,
    value: PropTypes.array,
};

export default UiTreeSelect;

//کامپوننت رتدر کردن لیست به صورت درختی
// eslint-disable-next-line react/prop-types
export const RenderNodeList = ({ list = [], isFirstLevel = false }) => {
    const { footer } = useContext(UiTreeSelectContext);
    return (
        <ul className={["ui-tree-select-list", isFirstLevel ? "first" : ""].join(" ")}>
            {list?.map((item, index) => {
                return <RenderNodeItem key={index} item={item} isFirstLevel={false} />;
            })}
            {isFirstLevel && list?.length <= 0 && <li className="ui-tree-select-data-empty">داده‌ای یافت نشد</li>}
            {isFirstLevel && footer && <li className="ui-tree-select-list-item-footer">{footer}</li>}
        </ul>
    );
};

//کامپوننت رند کردن هر ایتم درخت و ایجاد درخت زیرین به صورت بازگشتی
// eslint-disable-next-line react/prop-types
export const RenderNodeItem = ({ item = {}, index }) => {
    const [marquee, setMarquee] = useState(false);
    const { checkSelected, handleSelected, withoutImage, canSelectParent, expanded } = useContext(UiTreeSelectContext);
    const [inlineToggleList, setInlineToggleList] = useState(expanded.includes(item.value.toString()) ? true : false);
    useEffect(() => {
        setInlineToggleList(expanded.includes(item.value.toString()) ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    /**
     * باز و بسته کردن سلکت
     *
     * @param {*bool} state
     */
    const handleInlineToggle = (state) => {
        setInlineToggleList(state);
    };

    return (
        <li key={index} style={!item?.children?.length > 0 ? { paddingRight: "14px" } : {}}>
            <div
                className={`ui-tree-select-list-item ${checkSelected(item) ? "selected-item" : ""}`}
                onClick={() =>
                    item?.children && item?.children?.length > 0
                        ? !canSelectParent
                            ? handleInlineToggle(!inlineToggleList)
                            : null
                        : handleSelected(item)
                }
                onMouseEnter={() => setMarquee(true)}
                onMouseLeave={() => setMarquee(false)}
            >
                {item?.children?.length > 0 && (
                    <span className="ui-tree-select-child-icons">
                        <i
                            onClick={() => canSelectParent && handleInlineToggle(!inlineToggleList)}
                            className={`ui-tree-select-arrow icon ${
                                inlineToggleList ? "icon-arrow-up" : "icon-arrow-down"
                            }`}
                        />
                    </span>
                )}
                <div className="ui-tree-select-list-item-title" onClick={() => canSelectParent && handleSelected(item)}>
                    {item?.node ? (
                        item.node
                    ) : (
                        <span className={"marquee"}>
                            {item?.icon && !withoutImage && (
                                <img className="ui-tree-select-list-item-image-icon" src={item.icon} alt={item.label} />
                            )}
                            {item?.img && !withoutImage && (
                                <img
                                    className="ui-tree-select-list-item-img"
                                    onError={onImageError}
                                    src={item.img}
                                    alt={item.label}
                                />
                            )}
                            <span className={` ${marquee ? "animate" : ""}`}>{item.label}</span>
                        </span>
                    )}
                    {checkSelected(item) && (
                        <span className="ui-tree-select-list-item-title-checked">
                            <i className="icon icon-checkbox-tick" />
                        </span>
                    )}
                </div>
            </div>
            {inlineToggleList && item?.children && <RenderNodeList list={item?.children} isFirstLevel={false} />}
        </li>
    );
};
