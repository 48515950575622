import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { ReactGAM } from "google-analytics/googleAnalytics";

const DebugLocationHistory = ({ history }) => {
  useEffect(() => {
    ReactGAM().trackPageView(history?.location?.pathname+history?.location?.search);
  }, [history.location.key])

  return null
}



export default withRouter(DebugLocationHistory)