import React from "react";
import Scrollbar from 'react-scrollbars-custom'
import Search from './Search';
import {
    Spin,
    Radio,
} from 'antd';
import { FormattedSimpleMsg } from "GlobalFunctions";
import _ from 'lodash'
import ReactPaginate from 'react-paginate';
import ResourcesViewTable from "./ResourcesViewTable";
import ResourcesViewMiddlePartSearchType from "./ResourcesViewMiddlePartSearchType";
import Loadings from 'shared/loadings/Loadings';

const ResourcesViewMiddlePart = ({
    platforms,
    categories,
    selectedPlatform,
    advancedSearch,
    setCategoriesFromSearchResult,
    getUnCategoryResources,
    unCategoryResources,
    addToSelectionUnCategoryList,
    currentPage,
    getUnCategoryResourcesCount,
    unCategoryResourcesSelected,
    unCategoryResourcesCount,
    perPage,
    pageChange,
    handleSearchLoading,
    searchLoading,
    source,
    searchSelectedLoading,
    intl,
    inputChange,
    searchType,
    reset
}) => {

    const { formatNumber } = intl;
    const unCategoryResourcesSelectedInPlatform = unCategoryResourcesSelected[selectedPlatform];

    let pageCount = Math.ceil(unCategoryResourcesCount / perPage);

    const searchResultCount = unCategoryResourcesCount ? formatNumber(unCategoryResourcesCount) : 0

    return (
        <>
            <div className="source-box-wrapper">
                <header>
                    {" "}
                    <FormattedSimpleMsg id="sourceSelect.resources" />{" "}
                </header>

                <Scrollbar noScrollX={true} style={{ width: "100%", height: 580 }}>
                    <div className="source-box">
                        {/* نوع جستجو */}
                        <ResourcesViewMiddlePartSearchType
                            inputChange={inputChange}
                            platforms={platforms}
                            selectedPlatform={selectedPlatform}
                            searchType={searchType}
                        />

                        {/* جستجو */}
                        <Search
                            categories={categories}
                            platforms={platforms}
                            selectedPlatform={selectedPlatform}
                            advancedSearch={advancedSearch}
                            setCategoriesFromSearchResult={setCategoriesFromSearchResult}
                            getUnCategoryResources={getUnCategoryResources}
                            currentPage={currentPage}
                            getUnCategoryResourcesCount={getUnCategoryResourcesCount}
                            handleSearchLoading={handleSearchLoading}
                            source={source}
                            apiPath="/search/categories/platform_search"
                            intl={intl}
                            searchType={searchType}
                            reset={reset}
                        />

                        {/* لودینگ */}
                        {searchLoading && (
                            <div className="search-loading-container">
                                <Loadings type="button" spinning={searchLoading}></Loadings>
                            </div>
                        )}

                        {/* منابع دسته بندی نشده */}
                        {unCategoryResources && !_.isEmpty(unCategoryResources) && !searchLoading && (
                            <div className="un-category-resource">
                                <div className="search-result-count">
                                    <FormattedSimpleMsg id="sourceSelect.search_result" />

                                    {searchResultCount}

                                    <FormattedSimpleMsg id="sourceSelect.search_thing" />
                                </div>

                                <ResourcesViewTable
                                    userSelect="leftTop"
                                    withHeader={true}
                                    data={unCategoryResources}
                                    removeUnCategoryResource={() => {}}
                                    addToSelectionUnCategoryList={addToSelectionUnCategoryList}
                                    selectedItems={unCategoryResourcesSelectedInPlatform}
                                    deleteIcon={false}
                                    editMode={false}
                                    platform={platforms[selectedPlatform]}
                                    scrollbarStyle={{ width: "100%", height: 450 }}
                                />
                            </div>
                        )}
                    </div>
                </Scrollbar>

                {pageCount > 1 && !searchSelectedLoading && (
                    <div className="source-select-pagination pagination-wrapper ">
                        <ReactPaginate
                            pageCount={pageCount}
                            containerClassName="pagination"
                            activeClassName="active"
                            previousClassName="previous"
                            nextClassName="next"
                            previousLabel=""
                            nextLabel=""
                            pageRangeDisplayed={3}
                            onPageChange={(curr) => pageChange(curr.selected + 1)}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
export default ResourcesViewMiddlePart;