import React, { Component } from 'react';
import _ from "lodash";
import {connect} from "react-redux";
import "./Carousel.scss";

class Carousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            position: 0
        };

        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

	componentDidUpdate(prevProps){

		const { updateKey, widgetResizeStopKey, type } = this.props;

		/**
		 * TODO: we need to understand what widget is resizing and if current widget is then we set width again.
		 */
		if( prevProps.updateKey !== updateKey || ( prevProps.widgetResizeStopKey !== widgetResizeStopKey && type === "widget-builder" ) ){
			this.setWidth();
		}

	}

    next() {

        this.setWidth();

        const { scroll, position } = this.state;

        if (position >= scroll) {
            return;
        }
        this.setState({
            position: position + 100
        });

    }

    prev() {
        if (this.state.position <= 0) return;
        this.setState({
            position: this.state.position - 100
        });
    }

    componentDidMount() {
        this.setWidth();

	    window.addEventListener("resize", this.refreshDelay, false );
    }

	// remove event listener for scape key to prevent memory leaks
	componentWillUnmount() {
		window.removeEventListener("resize", this.refreshDelay, false);
	}

    setWidth() {

        let scrollWidth = this.scrollElement ?  this.scrollElement.scrollWidth : 0;

        let width = this.scrollElement ? this.scrollElement.offsetWidth : 0;
        // console.log( "---scrollWidth , width---" , scrollWidth , width );

        this.setState({
            scroll: scrollWidth - width,
            noBtn: width < scrollWidth
        });

    }

	refreshDelay = _.debounce(() => {
		this.setWidth();
	}, 50);

    render() {
        let { scroll, noBtn } = this.state;

        return (
            <div className="carousel">

                <div className={(this.state.position <= 0 || !noBtn ? 'disable right' : 'right')} onClick={this.prev} >
                    <i className="icon icon-arrow-right" />
                </div>

                <div className="main">
                    <div ref={el=> this.scrollElement=el} className="scroll" style={{ transform: `translateX(${this.state.position}px)` }}>
                        {this.props.children}
                    </div>
                </div>

                <div className={(this.state.position >= scroll || !noBtn ? 'disable left' : 'left')} onClick={this.next}>
                    <i className="icon icon-arrow-left" />
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
	widgetResizeStopKey: state.box.widgetResizeStopKey
});

export default connect(
	mapStateToProps
)(Carousel);