import React, { useState, useMemo } from "react";
import Loadings from "shared/loadings/Loadings";
import { injectIntl } from "react-intl";
import { UiCollapse } from "ui-components";
import "./FiltersEngineWrapper.scss";

let activePlatformList = [];
const FiltersEngineWrapper = (props) => {
    const {
        popupPlatform,
        hasCollapse,
        children,
        platformName,
        filtersPending,
        hasMask,
        intl,
        // closeOutside, به صورت موقتی حذف شد
        sourcePage,
    } = props;
    const { formatMessage } = intl;
    const [showMask, setShowMask] = useState(false);
    const [forceClose, setForceClose] = useState(false);

    /**
     * مخفی کردن وضعیت ماسک
     */
    const hideMask = () => {
        setShowMask(false);
    };

    /**
     * اعمال فیلتر های جدید
     */
    const applyfilter = () => {
        props.searchFunction();
        hideMask();
        handleForceClose();
    };

    /**
     *  لغو فیلتر های انتخاب شده
     */
    const cancelFilters = () => {
        props.resetSearchFilters && props.resetSearchFilters();
        hideMask();
        handleForceClose();
    };

    /**
     * بستن کولپس بالاجبار
     */
    const handleForceClose = () => {
        setForceClose((e) => !e);
        setTimeout(() => {
            setForceClose(false);
        }, 200);
    };

    const collapseData = useMemo(
        () => (
            <>
                {/* در صفحه ریزالت سرچ - فیلتر های بستر انتخاب شده */}
                <Loadings type="page" spinning={filtersPending}>
                    <div className="search-filter-options">
                        <div className="search-filter-options-container">{children}</div>
                    </div>
                </Loadings>
                {/* در صفحه ریزالت سرچ - دکمه های اعمال فیلتر و لغو تغییرات */}
                {popupPlatform && (
                    <div className="search-inner-collapse-buttons">
                        <button className="button large-button blue-filled" onClick={applyfilter}>
                            {formatMessage({ id: "filters.filter_engine_wrapper.apply_filter" })}{" "}
                        </button>
                        <button className="button large-button gray-border" onClick={cancelFilters}>
                            {formatMessage({ id: "filters.filter_engine_wrapper.cancel" })}{" "}
                        </button>
                    </div>
                )}
            </>
        ),
        [children],
    );

    // در برخی جاها که نیازی به کولپس ندارند
    // مانند فیلترهایی که در مودال باز می‌شوند و فیلترهای ساخت باکس‌های آیو
    if (!hasCollapse) {
        return (
            <div className={`search-inner-collapse ${popupPlatform ? "popup-platform" : ""}`}>
                {hasMask && <div className={`mask-back ${showMask ? "visible" : "hidden"}`} onClick={hideMask} />}
                <Loadings type="page" spinning={filtersPending}>
                    {children}
                </Loadings>
            </div>
        );
    }

    const platformNameTemp = platformName.toLowerCase();

    return (
        <div className={`search-inner-collapse ${popupPlatform ? "popup-platform" : ""}`}>
            {hasMask && <div className={`mask-back ${showMask ? "visible" : "hidden"}`} onClick={hideMask} />}
            {/* هم در ریزالت سرچ و هم داخل پلتفررم های سرچ صفحه اصلی */}
            <UiCollapse
                // closeOutside={closeOutside} موقتی حذف شد
                data={[
                    {
                        active: sourcePage !== "result" && activePlatformList.includes(platformNameTemp) ? true : false,
                        value: platformNameTemp,
                        title: (
                            <span>
                                {(popupPlatform ? formatMessage({ id: "filters.filter_engine_wrapper.filter" }) : "") +
                                    formatMessage({ id: `strings.${platformNameTemp}` })}
                            </span>
                        ),
                        content: collapseData,
                    },
                ]}
                className="ui-collapse-custom"
                functionToBeCalled={(value, collapse) => {
                    if (!collapse) {
                        activePlatformList = activePlatformList.filter((x) => x !== value);
                    } else {
                        activePlatformList.push(value);
                    }
                }}
                forceClose={forceClose}
            />
        </div>
    );
};

export default injectIntl(FiltersEngineWrapper);
