import React, { Component } from "react"

const AppContext = React.createContext();

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;
export default AppContext;

export const withAppContext = WrappedComponent => {
    return class extends Component {

        render() {

            return (
                <AppContextConsumer>
                    {props => {
                        const { state, methods, ...otherProps } = this.props;
                        return (
                            <WrappedComponent
                                state={{
                                    ...props.state,
                                    ...state
                                }}

                                methods={{
                                    ...props.methods,
                                    ...methods
                                }}

                                {...otherProps}
                            />
                        )
                    }

                    }
                </AppContextConsumer>
            )
        }
    }
}


