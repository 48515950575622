import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import "./UiTab.scss";
import usePrevious from "hooks/usePrevious";

/**
 * @param {*string} className
 * @param {*object} style
 * @param {*array} items list of tab item
 * @param {*string} theme oneOf(["dark", "light"])
 * @param {*string} defaultSelectedTabKey Initial active tab item's key
 * @param {*func} onSelectedItem Callback executed when tab item is clicked function(activeItem) {}
 * @param {*func} onRemoveItem Callback executed when tab is  removed. function(item) => void
 * @param {*func} onAddItem Callback executed when tab is added . function() => void
 * @param {*bool} canAdd show plus icon or not. Only works while type="card" and have  onAddItem props
 * @param {*string} type Basic style of tabs oneOf(["line", "card"])
 */

const UiTab = (props) => {
    const {
        className,
        style,
        items = [],
        theme,
        onSelectedItem,
        defaultSelectedTabKey,
        onRemoveItem,
        onAddItem,
        canAdd = false,
        type = "line",
    } = props;
    const prevProps = usePrevious({ defaultSelectedTabKey });
    const [selectedTab, setSelectedTab] = useState(
        defaultSelectedTabKey ? items.findIndex((i) => i.key === defaultSelectedTabKey) : 0,
    );
    useEffect(() => {
        //ایتم دیفالت تغییر کرد آیتم دوباره انتخاب شود
        if (!isEqual(defaultSelectedTabKey, prevProps?.defaultSelectedTabKey)) {
            setSelectedTab(defaultSelectedTabKey ? items.findIndex((i) => i.key === defaultSelectedTabKey) : 0);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelectedTabKey, items]);
    const handleClick = (item, index) => {
        if (index !== selectedTab) {
            setSelectedTab(index);
            typeof onSelectedItem === "function" && onSelectedItem(item);
        }
    };
    return (
        <div className={["ui-tab", type, className, theme].join(" ")} style={style}>
            <ul className={`ui-tab-list  ${className ?? ""}`}>
                {items?.map((item, index) => {
                    return (
                        <li
                            key={item.key || index}
                            disabled={item?.disabled}
                            className={[
                                "ui-tab-item",
                                index === selectedTab ? "ui-tab-item-active" : "",
                                item?.disabled ? "disabled" : "",
                            ].join(" ")}
                        >
                            <div
                                className="ui-tab-item-title"
                                onClick={() => !item?.disabled && handleClick(item, index)}
                            >
                                {item.title}
                            </div>
                            {type === "card" && onRemoveItem && (
                                <div className="ui-tab-item-remove" onClick={() => onRemoveItem(item)}>
                                    <i className=" icon icon-close-icon" />
                                </div>
                            )}
                        </li>
                    );
                })}
                {canAdd && type === "card" && onAddItem && (
                    <li className="ui-tab-add" onClick={onAddItem}>
                        <i className="icon icon-new" aria-hidden="true"></i>
                    </li>
                )}
            </ul>

            <div className="ui-tab-content">{items[selectedTab]?.content}</div>
        </div>
    );
};
UiTab.propTypes = {
    items: PropTypes.arrayOf(Object),
    style: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.oneOf(["dark", "light"]),
    type: PropTypes.oneOf(["line", "card"]),
    onSelectedItem: PropTypes.func,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    canAdd: PropTypes.bool,
    defaultSelectedTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default UiTab;
