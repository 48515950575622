import React, { useState, useRef, useEffect } from "react";
import { Layout, Col, Menu } from "antd";
import { NavLink } from "react-router-dom";
import UserAccess from "shared/permission/UserAccess";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { withRouter } from "react-router";
import "./Sidebar.scss";
import { api } from "services";
import { UiToast } from "ui-components";
const { Sider } = Layout;
const { SubMenu } = Menu;

let Sidebar = (props) => {
    const { location } = props;
    let rootSubmenuKeys = ["sub0", "sub1", "sub2", "sub5", "sub6", "sub9", "sub10", "sub11"];
    let _state = {
        collapsed: true,
        openKeys: [],
        pinDashboardListItems: [],
    };

    var time = 0;
    const [state, setState] = useState(_state);
    const [_interval, _setInterval] = useState();
    const [dashboardList, setDashboardList] = useState([]);
    const container = useRef(null);
    const toggleCollapsed = (type) => {
        if (type !== state.collapsed) {
            setState({
                collapsed: type,
                openKeys: [],
            });
        }
    };
    const handleMouseEnter = () => {
        _setInterval(
            setInterval(() => {
                time = time + 0.5;
                if (time >= 0.5 && time < 1) {
                    toggleCollapsed(false);
                    container.current.scrollTo({
                        top: 0,
                        behavior: "smooth", // Optional, adds animation
                    });
                }
            }, 500),
        );
    };
    const handleMouseLeave = () => {
        time = 0;
        clearInterval(_interval);
        toggleCollapsed(true);
        // container.current.scrollTo({
        //     top: 0,
        //     behavior: "smooth"   // Optional, adds animation
        // })
        // setTimeout(() => {
        //     toggleCollapsed(true);
        // }, 100)
    };

    const onOpenChange = (openKeys) => {
        time = 0;
        clearInterval(_interval);
        const latestOpenKey = openKeys.find((key) => state.openKeys.indexOf(key) === -1);
        let mustToOpen = [];
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setState({ openKeys, collapsed: false });
        } else {
            if (latestOpenKey === "sub5") {
                mustToOpen = ["sub5", "sub12", "sub13"];
            } else if (latestOpenKey === "sub6") {
                mustToOpen = ["sub6", "sub14", "sub15"];
            } else if (latestOpenKey === "sub9") {
                mustToOpen = ["sub9", "sub16", "sub17"];
            }
            setState({
                openKeys: mustToOpen.length > 0 ? mustToOpen : latestOpenKey ? [latestOpenKey] : [],
                collapsed: false,
            });
        }
    };

    const isActiveRoute = (routes) => {
        let isActive = false;
        for (const route of routes) {
            if (location.pathname.startsWith("/" + route)) {
                isActive = true;
            }
        }
        return isActive;
    };

    useEffect(() => {
        const getPinDashboardList = () => {
            api.get("/dashboard/dashboard", { no_paginate: 1 })
                .then((response) => {
                    const dashboardList = response.data.result.data;
                    const pinDashboardList = dashboardList.filter((dashboard) => dashboard.dashboard_pin !== null);
                    setDashboardList(pinDashboardList);
                })
                .catch((error) => {
                    UiToast.exceptions(error);
                })
                .then();
        };
        getPinDashboardList();
    }, []);

    return (
        <Col span={1} className="">
            <Sider>
                <div
                    ref={container}
                    onMouseEnter={() => handleMouseEnter()}
                    onMouseLeave={() => handleMouseLeave()}
                    className={`sidebar-menu ${!state.collapsed ? "open-side" : "closes-sidebar"}`}
                >
                    <Menu
                        openKeys={state.openKeys}
                        onOpenChange={onOpenChange}
                        onSelect={() => toggleCollapsed(true)}
                        mode="inline"
                        theme="dark"
                        selectable="false"
                        inlineIndent="0"
                        // inlineCollapsed={state.collapsed}
                    >
                        {/* صفحه خانه */}
                        {(UserAccess.can("index", "Dashboard") || UserAccess.can("landingPage", "FrontEnd")) && (
                            <SubMenu
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                key="sub0"
                                title={
                                    <>
                                        <a className={isActiveRoute(["dashboard"]) ? "active" : ""}>
                                            <i className="icon icon-home" />
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.home" />
                                            </span>
                                        </a>
                                    </>
                                }
                            >
                                {UserAccess.can("landingPage", "FrontEnd") && (
                                    <Menu.Item key="1" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/landing" isActive={() => isActiveRoute(["dashboard", "landing"])}>
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.home.landing.page" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {UserAccess.can("index", "Dashboard") &&
                                    dashboardList?.map((item, index) => {
                                        return (
                                            <Menu.Item key={`100${index}`} style={{ paddingRight: 40, paddingLeft: 0 }}>
                                                <NavLink to={`/dashboard/${item.dashboard_id}/preview`}>
                                                    <span>{item.dashboard_name}</span>
                                                </NavLink>
                                            </Menu.Item>
                                        );
                                    })}
                            </SubMenu>
                        )}
                        {/* صفحه جستجو */}
                        {(UserAccess.can("searchByQuery", "FrontEnd") ||
                            UserAccess.can("searchByPhrase", "FrontEnd") ||
                            UserAccess.can("searchByEntity", "FrontEnd")) && (
                            <Menu.Item key="2" style={{ paddingRight: 0, paddingLeft: 0 }}>
                                <NavLink to="/searchplatform" isActive={() => isActiveRoute(["searchplatform"])}>
                                    <i className="icon icon-search" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.search" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* صفحه قوانین کلمات کلیدی */}
                        {(UserAccess.can("index", "QueryRules") || UserAccess.can("store", "QueryRules")) && (
                            <SubMenu
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                key="sub1"
                                title={
                                    <>
                                        <a className={isActiveRoute(["query-rules"]) ? "active" : ""}>
                                            <i className="icon icon-word-key" />
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.query_hashtag" />
                                            </span>
                                        </a>
                                    </>
                                }
                            >
                                {UserAccess.can("index", "QueryRules") && (
                                    <Menu.Item key="3" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/query-rules">
                                            <i
                                                className="icon icon-mini-box-list"
                                                style={{ paddingLeft: 5, paddingRight: 0 }}
                                            />
                                            <span>
                                                <FormattedSimpleMsg id="query_rules.list_keywords" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {UserAccess.can("store", "QueryRules") && (
                                    <Menu.Item key="4" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/query-rules/add">
                                            <i
                                                className="icon icon-add-1"
                                                style={{ paddingLeft: 5, paddingRight: 0 }}
                                            />
                                            <span>
                                                <FormattedSimpleMsg id="query_rules.add_keyword" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                            </SubMenu>
                        )}

                        {/* پروفایل 360 */}
                        {/* {UserAccess.can("", "") && (
                                <SubMenu
                                    key="sub2"
                                    style={{ paddingRight: 0, paddingLeft: 0 }}
                                    title={
                                        <>
                                        <a
                                            href
                                            className={
                                                isActiveRoute(["profile"]) ? "active" : ""
                                            }
                                        >
                                            <i className="icon icon-360" />
                                            <span>پروفایل 360</span>
                                         </a>
                                        </>
                                    }
                                >
                                    <Menu.Item key="5" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                            <NavLink to="/profile/360">
                                            <span>  <FormattedSimpleMsg id="profile.360" /></span>
                                            </NavLink>
                                        </Menu.Item>
                                     <Menu.Item key="6" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                     <NavLink to="/profile/entity">
                                            <span>  <FormattedSimpleMsg id="profile.entity" /></span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="7" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/profile/resourceCategories">
                                            <span>  <FormattedSimpleMsg id="sourceCatList.resourceCategories" /></span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="8" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                    <NavLink to="/profile/subject">
                                            <span>  <FormattedSimpleMsg id="profile.subject" /></span>
                                            </NavLink>
                                        </Menu.Item>
                                   
                                </SubMenu>
                            )} */}

                        {/* صفحه تگ   */}
                        {(UserAccess.can("index", "SearchTag") || UserAccess.can("index", "TagCategory")) && (
                            <SubMenu
                                key="sub5"
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                title={
                                    <>
                                        <a
                                            className={
                                                isActiveRoute(["tags", "tag-categories", "tag-category"])
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <i className="icon icon-tag" />
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.tag" />
                                            </span>
                                        </a>
                                    </>
                                }
                            >
                                {UserAccess.can("index", "SearchTag") && (
                                    <SubMenu
                                        key="sub12"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}
                                        title={
                                            <>
                                                <a style={{ paddingRight: 40 }}>
                                                    <span>
                                                        {" "}
                                                        <FormattedSimpleMsg id="mainSidebar.resource_tag" />
                                                    </span>
                                                </a>
                                            </>
                                        }
                                    >
                                        {UserAccess.can("index", "SearchTag") && (
                                            <Menu.Item key="12" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/tags">
                                                    <i
                                                        className="icon icon-mini-box-list"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="mainSidebar.resources_tag.list" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                        {UserAccess.can("store", "SearchTag") && (
                                            <Menu.Item key="13" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/tags/add">
                                                    <i
                                                        className="icon icon-add-1"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="mainSidebar.resources_tag.add" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}
                                {UserAccess.can("index", "TagCategory") && (
                                    <SubMenu
                                        key="sub13"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}
                                        title={
                                            <>
                                                <a style={{ paddingRight: 40 }}>
                                                    <span>
                                                        {" "}
                                                        <FormattedSimpleMsg id="mainSidebar.content_tag" />
                                                    </span>
                                                </a>
                                            </>
                                        }
                                    >
                                        {UserAccess.can("index", "TagCategory") && (
                                            <Menu.Item key="14" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/tag-categories">
                                                    <i
                                                        className="icon icon-mini-box-list"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="mainSidebar.content_tag.list" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                        {UserAccess.can("store", "TagCategory") && (
                                            <Menu.Item key="15" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/tag-category/add">
                                                    <i
                                                        className="icon icon-add-1"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="mainSidebar.contentTag.add" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}
                            </SubMenu>
                        )}

                        {/* صفحه مقایسه موضوع و هشتگ */}
                        {UserAccess.can("diffPage", "FrontEnd") && (
                            <Menu.Item key="16" style={{ paddingRight: 0, paddingLeft: 0 }}>
                                <NavLink to="/comparison?type=subject" isActive={() => isActiveRoute(["diff"])}>
                                    <i className="icon icon-comparison" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.diff" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* بولتن */}
                        {(UserAccess.can("index", "Bulletin") || UserAccess.can("index", "BulletinTypes")) && (
                            <SubMenu
                                key="sub6"
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                title={
                                    <>
                                        <a
                                            className={
                                                isActiveRoute([
                                                    "bulletins",
                                                    "Bulletin",
                                                    "archives",
                                                    "BulletinType",
                                                    "analyzer",
                                                ])
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <i className="icon icon-boltan" />
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.boltan" />
                                            </span>
                                        </a>
                                    </>
                                }
                            >
                                {UserAccess.can("index", "Bulletin") && (
                                    <SubMenu
                                        key="sub14"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}
                                        title={
                                            <>
                                                <a style={{ paddingRight: 40 }}>
                                                    <span>
                                                        {" "}
                                                        <FormattedSimpleMsg id="mainSidebar.boltan.generator" />
                                                    </span>
                                                </a>
                                            </>
                                        }
                                    >
                                        {UserAccess.can("index", "Bulletin") && (
                                            <Menu.Item key="17" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/bulletins">
                                                    <i
                                                        className="icon icon-mini-box-list"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        {" "}
                                                        <FormattedSimpleMsg id="bulletin.sidebar.bulletin_list" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                        {UserAccess.can("store", "Bulletin") && (
                                            <Menu.Item key="18" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/Bulletin/add">
                                                    <i
                                                        className="icon icon-add-1"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id={`sourceCatList.add`} />{" "}
                                                        <FormattedSimpleMsg id="bulletin.bulletin" />{" "}
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}

                                {/* <Menu.Item key="19" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                    <NavLink to="/archives">
                                        <span>
                                            <FormattedSimpleMsg id="bulletin.sidebar.archive" />{" "}
                                        </span>
                                    </NavLink>
                                </Menu.Item> */}

                                {UserAccess.can("index", "BulletinTypes") && (
                                    <SubMenu
                                        key="sub15"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}
                                        title={
                                            <>
                                                <a style={{ paddingRight: 40 }}>
                                                    <span>
                                                        {" "}
                                                        <FormattedSimpleMsg id="bulletin.sidebar.bulletin_type" />
                                                    </span>
                                                </a>
                                            </>
                                        }
                                    >
                                        {UserAccess.can("index", "BulletinTypes") && (
                                            <Menu.Item key="20" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/BulletinType/list">
                                                    <i
                                                        className="icon icon-mini-box-list"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="bulletin.sidebar.bulletin_type.list" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                        {UserAccess.can("store", "BulletinTypes") && (
                                            <Menu.Item key="21" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/bulletin/type/add">
                                                    <i
                                                        className="icon icon-add-1"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="bulletin.sidebar.bulletin_types.add" />{" "}
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}

                                <SubMenu
                                    key="sub7"
                                    style={{ paddingRight: 0, paddingLeft: 0 }}
                                    title={
                                        <>
                                            <a style={{ paddingRight: 40 }}>
                                                <span>
                                                    {" "}
                                                    <FormattedSimpleMsg id="bulletin.sidebar.analyzer" />
                                                </span>
                                            </a>
                                        </>
                                    }
                                >
                                    <Menu.Item key="22" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.news_group" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="23" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#subject">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.subject" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="24" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#subject2">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.subject2" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="25" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#person">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.news" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="26" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#viewpoint">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.view_point" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="27" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#tone">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.tone" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="28" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#technique">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.technique" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="29" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/analyzer/list#template_content">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.content.template_content" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu
                                    key="sub8"
                                    style={{ paddingRight: 0, paddingLeft: 0 }}
                                    title={
                                        <>
                                            <a style={{ paddingRight: 40 }}>
                                                <span>
                                                    {" "}
                                                    <FormattedSimpleMsg id="bulletin.sidebar.settings" />
                                                </span>
                                            </a>
                                        </>
                                    }
                                >
                                    <Menu.Item key="30" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/Bulletins/settings/public-settings">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.setting.sidebar.public" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="31" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                        <NavLink to="/Bulletins/settings/statistics-charts">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="bulletin.setting.sidebar.statistics" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                </SubMenu>
                            </SubMenu>
                        )}

                        {/* صفحه آلارم و گوش سپار   */}
                        {(UserAccess.can("index", "AlarmStory") || UserAccess.can("telegramChannel", "FrontEnd")) && (
                            <SubMenu
                                key="sub9"
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                title={
                                    <>
                                        <a
                                            className={
                                                isActiveRoute(["alarms", "alarm", "telegram-bot"]) ? "active" : ""
                                            }
                                        >
                                            <i className="icon icon-alarm-side" />
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.alarm" />
                                            </span>
                                        </a>
                                    </>
                                }
                            >
                                {UserAccess.can("index", "AlarmStory") && (
                                    <Menu.Item key="34" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/alarms">
                                            <i
                                                className="icon icon-mini-box-list"
                                                style={{ paddingLeft: 5, paddingRight: 0 }}
                                            />
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.alarm.list" />{" "}
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {UserAccess.can("index", "AlarmStory") && (
                                    <Menu.Item key="36" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/alarm/add/">
                                            <i
                                                className="icon icon-add-1"
                                                style={{ paddingLeft: 5, paddingRight: 0 }}
                                            />
                                            <span>
                                                <FormattedSimpleMsg id="alarm.button.create" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {UserAccess.can("index", "AlarmStory") && (
                                    <Menu.Item key="35" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/alarm/wave/add">
                                            <i
                                                className="icon icon-add-1"
                                                style={{ paddingLeft: 5, paddingRight: 0 }}
                                            />
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="alarm.button.add_wave_detection" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {UserAccess.can("telegramChannel", "FrontEnd") && (
                                    <SubMenu
                                        key="sub16"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}
                                        title={
                                            <a style={{ paddingRight: 40 }}>
                                                {" "}
                                                <span>
                                                    <FormattedSimpleMsg id="telegram_bot.sidebar.telephone" />
                                                </span>
                                            </a>
                                        }
                                    >
                                        {UserAccess.can("telegramChannel", "FrontEnd") && (
                                            <Menu.Item key="37" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/telegram-bot/contact/list">
                                                    <i
                                                        className="icon icon-mini-box-list"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="mainSidebar.alarm.mobile.list" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                        {/* افزودن شماره تلفن  */}
                                        {UserAccess.can("telegramChannel", "FrontEnd") && (
                                            <Menu.Item key="38" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/telegram-bot/contact/add">
                                                    <i
                                                        className="icon icon-add-1"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="telegram_bot.sidebar.contact_channel" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}
                                {UserAccess.can("telegramChannel", "FrontEnd") && (
                                    <SubMenu
                                        key="sub17"
                                        style={{ paddingRight: 0, paddingLeft: 0 }}
                                        title={
                                            <a style={{ paddingRight: 40 }}>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.telegramBot" />
                                            </a>
                                        }
                                    >
                                        {/* بات تلگرام  */}
                                        {UserAccess.can("telegramChannel", "FrontEnd") && (
                                            <Menu.Item key="39" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/telegram-bot/channel/list">
                                                    <i
                                                        className="icon icon-mini-box-list"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="mainSidebar.telegramBot.list" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                        {/* افزودن کانال  */}
                                        {UserAccess.can("telegramChannel", "FrontEnd") && (
                                            <Menu.Item key="40" style={{ paddingRight: 60, paddingLeft: 0 }}>
                                                <NavLink to="/telegram-bot/channel/add">
                                                    <i
                                                        className="icon icon-add-1"
                                                        style={{ paddingLeft: 5, paddingRight: 0 }}
                                                    />
                                                    <span>
                                                        <FormattedSimpleMsg id="telegram_bot.sidebar.create_channel" />
                                                    </span>
                                                </NavLink>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}
                            </SubMenu>
                        )}

                        {/* داشبورد AIO*/}
                        {UserAccess.can("dashboardSocialActions", "FrontEnd") && (
                            <Menu.Item key="41" style={{ padding: 0 }}>
                                <NavLink to="/planning" isActive={() => isActiveRoute(["planning"])}>
                                    <i className="icon icon-all-in-one" selenium-id="sideBarAIOIcon" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.dashboard_AIO" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* اساینمنت */}
                        {UserAccess.can("assignmentSocialActions", "FrontEnd") && (
                            <Menu.Item key="43" style={{ padding: 0 }}>
                                <NavLink
                                    to="/assignment/instagram-comment"
                                    isActive={() => isActiveRoute(["assignment"])}
                                >
                                    <i className="icon icon-assign" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.assignment" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* لیست دایرکت */}
                        {UserAccess.can("index", "SocialActionDirect") && (
                            <Menu.Item key="44" style={{ padding: 0 }}>
                                <NavLink to="/direct" isActive={() => isActiveRoute(["direct"])}>
                                    <i className="icon icon-email" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.direct" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* لیست اسکجول */}
                        {UserAccess.can("index", "SocialActionPost") && (
                            <Menu.Item key="42" style={{ padding: 0 }}>
                                <NavLink to="/schedule/posts" isActive={() => isActiveRoute(["schedule"])}>
                                    <i className="icon icon-calendar" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.schedule_calendar" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* گراف */}
                        {UserAccess.can("index", "Graph") && (
                            <Menu.Item key="45" style={{ padding: 0 }}>
                                <NavLink to="/graph/networks" isActive={() => isActiveRoute(["graph"])}>
                                    <i className="icon icon-graph" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.graph" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}
                        {/* صفحه کیوسک */}
                        {/* {UserAccess.can("newspaper", "FrontEnd") && (
                            <Menu.Item key="46" style={{ padding: 0 }}>
                                <NavLink to="/kiosk" isActive={() => isActiveRoute(["kiosk"])}>
                                    <i className="icon icon-newspaper" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.kiosk" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )} */}
                        {/* صفحه دسته بندی منابع */}
                        {UserAccess.can("index", "SearchCategory") && (
                            <Menu.Item key="47" style={{ padding: 0 }}>
                                <NavLink to="/source-categories" isActive={() => isActiveRoute(["source-categories"])}>
                                    <i className="icon icon-resources" />
                                    <span>
                                        <FormattedSimpleMsg id="mainSidebar.resources" />
                                    </span>
                                </NavLink>
                            </Menu.Item>
                        )}

                        {/* ابزار مدیریت   */}
                        {(UserAccess.can("index", "TargetedScraping") ||
                            UserAccess.can("index", "User") ||
                            UserAccess.can("index", "BoxGenerator") ||
                            UserAccess.can("index", "Departments") ||
                            UserAccess.can("index", "Dashboard")) && (
                            <SubMenu
                                key="sub10"
                                style={{ paddingRight: 0, paddingLeft: 0 }}
                                title={
                                    <>
                                        <a
                                            className={
                                                isActiveRoute(["target", "users", "boxes", "departments", "dashboards"])
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <i className="icon icon-tools" />
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.Management_tools" />{" "}
                                            </span>
                                        </a>
                                    </>
                                }
                            >
                                {UserAccess.can("index", "TargetedScraping") && (
                                    <Menu.Item key="48" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/target">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.target" />{" "}
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {UserAccess.can("index", "User") && (
                                    <Menu.Item key="49" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/users">
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.user" />{" "}
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {/* صفحه باکس‌ها */}
                                {UserAccess.can("index", "BoxGenerator") && (
                                    <Menu.Item key="50" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/boxes">
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.box.generator" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {/* صفحه داشبورد ساز */}
                                {UserAccess.can("index", "Dashboard") && (
                                    <Menu.Item key="52" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/dashboards">
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.dashbord.generator" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {/* صفحه نقش‌های کاربر */}
                                {UserAccess.can("index", "Departments") && (
                                    <Menu.Item key="51" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/departments">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.rules" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                {/* صفحه مدیریت نسخه ها */}
                                {UserAccess.can("index", "Version") && (
                                    <Menu.Item key="53" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/version/gpr">
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.version.management" />
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                )}
                                </SubMenu>
                        )}
                        {/* پشتیبانی   */}
                        {/* 
                            {UserAccess.can(" ", " ") && (
                                <SubMenu
                                    key="sub11"
                                    style={{ paddingRight: 0, paddingLeft: 0 }}
                                    title={
                                        <>
                                        <a
                                            href
                                            className={
                                                isActiveRoute(["support"]) ? "active" : ""
                                            }
                                        >
                                            <i className="icon icon-support" />
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.support" />{" "}
                                            </span>
                                         </a>
                                        </>
                                    }
                                >
                                    <Menu.Item key="53" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/">
                                            <span>
                                                {" "}
                                                <FormattedSimpleMsg id="mainSidebar.ticketing" />{" "}
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                    <Menu.Item key="54" style={{ paddingRight: 40, paddingLeft: 0 }}>
                                        <NavLink to="/">
                                            <span>
                                                <FormattedSimpleMsg id="mainSidebar.Customer_relationship" />{" "}
                                            </span>
                                        </NavLink>
                                    </Menu.Item>
                                </SubMenu>
                            )} */}
                    </Menu>
                </div>
            </Sider>
        </Col>
    );
};
Sidebar = withRouter(Sidebar);
export default Sidebar;
