import CryptoJS from "crypto-js";
let helperService = {
  // بسته بندی کردن محتوا
  encode: (data, name) => {
    if (!data) return;
    var ciphertext = CryptoJS.AES.encrypt(data, name);
    return ciphertext.toString()
  },
  // بازکردن کردن محتوای بسته بندی شده
  decode: (data, name) => {
    try {
      if (!data) return;
      var bytes = CryptoJS.AES.decrypt(data, name);
      var plaintext = bytes.toString(CryptoJS.enc.Utf8);
      return plaintext;
    } catch (e) {
      return null;
    }

  }
}
export default helperService;

