import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import "./UiCheckbox.scss";
import { GroupContext } from "../ui-checkbox-group/UiCheckboxGroup";

const UiCheckbox = (props) => {
    const {
        children,
        checked: checkedTemp,
        defaultChecked,
        onChange,
        value,
        id,
        className,
        style,
        name,
        disabled,
        size = "md",
        theme,
        indeterminate,
    } = props;
    const [checked, setChecked] = useState(checkedTemp);
    const checkboxGroup = useContext(GroupContext);

    useEffect(() => {
        setChecked(checkedTemp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedTemp]);

    useEffect(() => {
        setChecked(defaultChecked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultChecked]);

    //یک کانتست در گروپ چک باکس تعریف شذه تا تغییر هر ایتم در انجا ذخیره شود
    //در صورت تغیبر در ایتم های انتخاب شده ذخیره شده در کانتکست مقدار هر چک باکس تغییر کند

    useEffect(() => {
        if (checkboxGroup && checkboxGroup?.selectedIds) {
            if (checkboxGroup && checkboxGroup?.selectedIds?.includes(value?.toString())) {
                setChecked(true);
            } else {
                setChecked(false);
            }
        }
    }, [checkboxGroup, checkboxGroup?.selectedIds, value]);
    return (
        <label
            className={[
                "ui-checkbox",
                theme,
                className,
                disabled ? "disabled" : "",
                indeterminate ? "indeterminate" : "",
            ].join(" ")}
            style={style}
        >
            <input
                id={id}
                name={name}
                type="checkbox"
                disabled={disabled}
                defaultChecked={defaultChecked}
                className={["ui-checkbox-input", size].join(" ")}
                checked={checked}
                value={value}
                onChange={(e) => {
                    setChecked(e.target.checked);
                    typeof onChange === "function" && onChange(e.target.checked);
                    // اگر چک باکس داخل چیلدرن گروپ چک باکس بود مقدار به گروپ چک باکس پاس داده شود
                    checkboxGroup && checkboxGroup.handleChange(e.target.checked, value);
                }}
            />
            <span className="ui-checkbox-checked"></span>
            {children && <span className={"ui-checkbox-label"}>{children}</span>}
        </label>
    );
};

UiCheckbox.propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]), //
    theme: PropTypes.oneOf(["dark", "light"]), //
    className: PropTypes.string,
    disabled: PropTypes.bool, //
    onChange: PropTypes.func,
    children: PropTypes.node,
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    value: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    indeterminate: PropTypes.bool,
};

export default UiCheckbox;
