import React from "react";
import { APIPaths, rootIoSocket, rootSocketPath } from "GlobalVariables";
import { api } from "services";
import Scrollbar from "react-scrollbars-custom";
import { UiToast } from "ui-components";
import UserAccess from "../permission/UserAccess";
import io from "socket.io-client";
import ExcelNotification from "./contents/ExcelNotification";
import BulletinNotification from "./contents/BulletinNotification";
import WordNotification from "./contents/WordNotification";
import PostNotification from "./contents/PostNotification";
import RepostNotification from "./contents/RepostNotification";
import ResetPasswordNotification from "./contents/ResetPasswordNotification";
import { cloneDeep, isUndefined, isEmpty } from "lodash";
import { FormattedSimpleMsg } from "GlobalFunctions";
import AssignedNotification from "./contents/AssignedNotification";
import { withAppContext } from "context/app/AppContext";
import ShareDashboardToDepartmentNotification from "./contents/ShareDashboardToDepartmentNotification";
import PostScheduleExecute from "./contents/PostScheduleExecute";
import { set } from "d3";
import WaveAlarmNotification from "./contents/WaveAlarmNotification";
import ConfirmBox from "shared/ConfirmBox";
import { injectIntl, FormattedMessage } from "react-intl";

class NotificationSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            confirmation: false,
        };

        this.setVisible = this.setVisible.bind(this);
        this.cancelDownload = this.cancelDownload.bind(this);
        this.resetDownload = this.resetDownload.bind(this);
    }

    /**
     * عوض کردن حالت نمایش سایدبار نوتیفیکیشن
     */
    toggle() {
        this.setState({
            visible: !this.state.visible,
        });
    }

    componentDidMount() {
        // no
        this.connectNotifSocket();

        // planning assigned
        this.connectPlanningSocket();

        this.fetchQueries();
    }

    /**
     * connect to Notification socket
     */
    connectNotifSocket() {
        if (!UserAccess.can("index", "Excel")) return;

        let userInfo = api.userInfo();

        const userId = userInfo.user.id;

        const token = userInfo.access_token;

        // const PRIVATE_CHANNEL = 'manshour_backend_micro'
        const PRIVATE_CHANNEL = "notifications";

        // اتصال سوکت
        var socket = io(rootIoSocket, {
            query: {
                token,
            },
            path: rootSocketPath,
        });

        // دریافت نوتیفیکیشن های سیستم
        socket.on("connect", () => {
            // پی دی اف بولتن
            socket.on(`bulletin_export_pdf.${userId}`, (data) => {
                this.updateNotification(data, "pdf");
            });

            // ورد بولتن
            socket.on(`bulletin_word.${userId}`, (data) => {
                this.updateNotification(data, "word");
            });

            // سرچ اکسل
            socket.on(`search_excel.${userId}`, (data) => {
                this.updateNotification(data, "search_excel");
            });

            // ارسال پست
            socket.on(`post_created.${userId}`, (data) => {
                this.updateNotification(data, "post_created");
            });

            // ارسال اسکجول
            socket.on(`post_schedule_execute.${userId}`, (data) => {
                this.updateNotification(data, "post_schedule_execute");
            });

            // اسکجول تایید شده است
            socket.on(`post_schedule_approve.${userId}`, (data) => {
                this.updateNotification(data, "post_schedule_approve");
            });

            // اسکجول نیاز به تایید دارد
            socket.on(`post_schedule_need_approve.${userId}`, (data) => {
                this.updateNotification(data, "post_schedule_need_approve");
            });

            // آلارم
            socket.on(`alarm_story.${userId}`, (data) => {
                this.updateNotification(data, "alarm_story");
            });

            //  آلارم موج
            socket.on(`alarm_wave.${userId}`, (data) => {
                this.updateNotification(data, "alarm_wave");
            });

            // گزارش هیستوگرام
            socket.on(`aio_report.${userId}`, (data) => {
                this.updateNotification(data, "report");
            });

            // منقضی شدن رمز عبور
              socket.on(`password_expired_notification.${userId}`, (data) => {
                this.updateNotification(data, "password_expired_notification");
            });

            socket.on("disconnect", (data) => {
                console.log("disconnect");
            });

            socket.emit("subscribe-to-channel", { channel: PRIVATE_CHANNEL });
        });
    }

    /**
     * connect to Live Post Assigned socket
     */
    connectPlanningSocket = () => {
        const { methods } = this.props;
        let userInfo = api.userInfo();
        const token = userInfo.access_token;
        const PRIVATE_CHANNEL = "notifications"; // Contains: ['notifications', 'lives']
        const userId = userInfo.user.id;

        // اتصال سوکت
        var socket = io(rootIoSocket, {
            query: { token },
            path: rootSocketPath,
        });

        // دریافت نوتیفیکیشن های سیستم
        socket.on("connect", () => {
            // اشتراک داشبورد به دپارتمان
            socket.on(`dashboard_shared_to_departments.global`, (data) => {
                if (!data.data.department_ids.includes(userInfo.user.department_id)) {
                    return;
                }
                this.updateNotification(data, "dashboard_shared_to_departments");
            });

            // اشتراک محتوا به من
            socket.on(`assign_content_to_me.global`, (data) => {
                if (data.data.assign_user_id === userId) {
                    this.updateNotification(data, "assign_content_to_me");
                }
            });

            // اشتراک محتوا با خودم
            socket.on(`assign_content_to_myself.global`, (data) => {
                if (data.data.assign_user_id === userId) {
                    this.updateNotification(data, "assign_content_to_myself");
                }
            });

            socket.on("disconnect", (data) => {
                console.log("disconnect", data);
            });

            socket.emit("subscribe-to-channel", { channel: PRIVATE_CHANNEL, global: true });
        });

        socket.on("connect", () => {
            // پست های اساین شده
            socket.on("assign.global", (response) => {
                const { data } = response;

                if (isEmpty(data.ids)) {
                    UiToast.warn("هیچ محتوای جدیدی برای اساین شدن به شما یافت نشد");

                    return;
                }

                const socketParams = data?.socket_params?.length ? JSON.parse(data.socket_params) : null;

                if (
                    socketParams &&
                    data.assigner_user_id === data.assign_user_id &&
                    data.assigner_user_id === userInfo.user.id
                ) {
                    methods.setReceivedPlanBoxesItem(socketParams.box_id, data.ids, { id: data.id });
                } 
                 if (data.assign_user_id === userId) {
                    this.updateNotification(data, "assign");
                }

                // update count of PlanningBox Msg Count on header
                // const boxId = 1302;

                // methods.updatePlanningBoxMsgCount(boxId);
            });

                // دریافت دایرکت های جدید
                socket.on("direct.global", (response) => {
                    const { data } = response;
                    //پیام های ارسالی باید با کمپانی یوزر یکسان باشد و اگر
                    if (
                        Number(userInfo?.user?.department?.company_id) === data?.company_id &&
                        Number(data?.last_direct?.account_id) !== Number(data?.last_direct?.sender_id)
                    ) {
                        methods.setReceivedNewDirect(data)
                    }
                });

            socket.on("disconnect", (data) => {
                console.log("disconnect", data);
            });

            socket.emit("subscribe-to-channel", { channel: PRIVATE_CHANNEL, global: true });
        });
    };

    /**
     * آپدیت کردن نوتیفیکیشن ها بعد از دریافت اطلاعات از سوکت
     * @param {*} data اطلاعات دریافت شده
     * @param {*} notifType نوع نوتیفیکیشن دریافت شده
     */
    updateNotification(data, notifType) {
        if (data.data && !data.data.type) {
            data.data["type"] = notifType;
        } else if (!data.type) {
            data["type"] = notifType;
        }

        // دریافت آخرین نوتیفیکیشن ها از لوکال استوریج
        const notification = localStorage.getItem("notification")
            ? JSON.parse(localStorage.getItem("notification"))
            : [];
        const finalData = data.data ?? data;
        let newNotif = {};
        newNotif.data = {};
        let isNew = true;
        // اعمال تغییرات جدید روی آبجکت نوتیفیکشن
        notification.forEach((item, key) => {
            // اگر نوتیفیکیشن قبلا در لیست وجود داشت
            if (notifType !== "assign" && item.data.id === finalData.id && item.data.type === notifType) {
                notification[key].data = cloneDeep(finalData);
                isNew = false;
            }
        });

        // اگر نوتیفیکیشن جدید بود
        if (isNew) {
            newNotif.data = cloneDeep(finalData);
            newNotif.id = newNotif.data?.id;
            newNotif["visible"] = 1;

            if (newNotif.data?.percent !== undefined) {
                // ارسال نوتیفیکیشن به صدر لیست در صورتی که پروگرس داشت
                notification.unshift(newNotif);
            } else {
                // بررسی جدید بودن نوتیفیکیشن
                if (!notification.find((item) => item.id === newNotif.id)) {
                    //  ارسال نوتیفیکیشن جدید به صدر لیست
                    notification.unshift(newNotif);
                }
            }
        }

        localStorage.setItem("notification", JSON.stringify(notification));

        let temp = {};
        // محاسبه تعداد نوتیفیکیشن های خوانده نشده
        let notifNo = 0;
        // شمارش نوتیفها
        if (isNew) {
            notification &&
                notification.forEach((item) => {
                    if (isUndefined(item.status)) {
                        notifNo = notifNo + 1;
                    } else {
                        notifNo = notifNo + ((item.status + 1) % 2);
                    }
                });

            if (notifNo > 99) notifNo = "+99";

            localStorage.setItem("notificationNo", notifNo);

            temp["notifNo"] = notifNo;
        }

        localStorage.setItem("notification", JSON.stringify(notification));

        temp["downloads"] = data;

        this.setState(
            {
                temp,
            },
            () => {
                this.props.updateNotifNumber(notifNo);
            },
        );
    }

    /**
     * کنسل کردن دانلود خروجی اکسل
     * @param {*} id  آی دی فایل دانلود اکسل
     */
    cancelDownload(id) {
        api.get(APIPaths.excelCancel + "/" + id)
            .then((res) => res.data)
            .then((data) => {})
            .catch((error) => {
                UiToast.exceptions(error);
                UiToast.error("دانلود شما کنسل نشد");
            });
    }

    /**
     * ری استارت کردن دانلود خروجی اکسل
     * @param {*} id  آی دی فایل دانلود اکسل
     */
    resetDownload(id) {
        api.get(APIPaths.excelRestart + "/" + id)
            .then((res) => res.data)
            .then((data) => {})
            .catch((error) => {
                UiToast.exceptions(error);
                UiToast.error("دانلود شما ری استارت نشد");
            });
    }

    /**
     * تابع نمایش و عدم نمایش ساید بار نوتیفیکیشن
     */
    setVisible() {
        this.props.setVisible();
    }

    /**
     * حذف نوتیفیکیشن
     */
    removeNotification = (id) => {
        if (!id) return;
        this.setState({
            removeNotifLoading: { [id]: true },
        });

        const params = {
            ids: [id + ""],
            visible: false,
        };

        api.put("/user/notifications/visible", params)
            .then((result) => {
                const notification = localStorage.getItem("notification");
                let temp = JSON.parse(notification);
                temp = temp.filter((x) => (x.id || x.data.id) !== id);
                localStorage.setItem("notification", JSON.stringify(temp));
                this.setState({
                    hotReload: !this.state.hotReload,
                });
                UiToast.success("نوتیفیکیشن مورد نظر با موفقیت حذف شد");
            })
            .catch((error) => {
                UiToast.exceptions(error);
            })
            .then(() => {
                this.setState({
                    removeNotifLoading: { [id]: false },
                });
            });
    };

    /**
     * حذف همه نوتیفیکیشن
     */
    removeAllNotification = () => {
        this.setState({ confirmation: false });
        const notification = localStorage.getItem("notification");
        let temp = JSON.parse(notification);

        const params = {
            ids: temp.filter((x) => x.visible).map((x) => x.id || x.data.id),
            visible: false,
        };

        api.put("/user/notifications/visible", params)
            .then((result) => {
                const _notification = localStorage.getItem("notification");
                let _temp = JSON.parse(_notification);
                _temp = _temp.filter((x) => !x.visible);
                localStorage.setItem("notification", JSON.stringify(_temp));
                this.setState({
                    hotReload: !this.state.hotReload,
                });
                UiToast.success({ id: "notification.delete_all_successfull" });
            })
            .catch((error) => {
                UiToast.exceptions(error);
            });
    };

    /**
     * Fetch Queries from api
     */
    fetchQueries = () => {
        // const { queries } = this.state;
        const params = {
            no_paginate: 1,
        };
        api.get("/search/queries", params)
            .then((response) => response.data.result.data)
            .then((data) => {
                this.setState({
                    queries: data,
                });
            });
    };

    render() {
        let userInfo = api.userInfo();
        const sendNewPostPermission = userInfo?.user?.permissions?.SocialActions?.sendNewPost?.permission;
        const { removeNotifLoading, queries } = this.state;
        const notification = localStorage.getItem("notification")
            ? JSON.parse(localStorage.getItem("notification"))
            : [];
        notification.forEach((item) => {
            if (!item.data["type"]) item.data["type"] = item.namespace;
        });

        return (
            <div className={`notification--sidebar ${this.props.visible ? "visible" : "hidden"}`}>
                <div className="back-drop" onClick={this.setVisible} />

                <div className="notification--sidebar--contents">
                    <Scrollbar noScrollX={true} style={{ width: "100%", height: "100%" }}>
                        <header className="title">
                            <i className="icon icon-alarm" />

                            <span>
                                {" "}
                                <FormattedSimpleMsg id="notification.messages" />{" "}
                            </span>

                            {notification && notification.filter((x) => x.visible).length > 0 && (
                                <i
                                    className="icon icon-delete"
                                    onClick={() => {
                                        this.setState({ confirmation: true });
                                    }}
                                />
                            )}
                            <i className="icon icon-arrow-left" onClick={this.setVisible} />
                        </header>

                        <div className="downloads-list">
                            {notification &&
                                notification.length &&
                                notification
                                    ?.filter((x) => x.visible)
                                    ?.map((item, key) => {
                                        if (item?.data?.type === "search_excel") {
                                            return (
                                                <UserAccess can="index" type="Excel" showMessage>
                                                    <ExcelNotification //کامپوننت نمایش باکس نوتیفیکیشن های اکسل
                                                        key={key}
                                                        notifId={item?.id || item.data.id}
                                                        itemData={item.data}
                                                        cancelDownload={this.cancelDownload}
                                                        resetDownload={this.resetDownload}
                                                        removeNotification={this.removeNotification}
                                                        loading={removeNotifLoading}
                                                    />
                                                </UserAccess>
                                            );
                                        } else if (item?.data?.type === "pdf" || item?.data?.type === "bulletin_export_pdf") {
                                            return (
                                                <BulletinNotification //کامپوننت نمایش باکس نوتیفیکیشن های پی دی اف بولتن
                                                    key={key}
                                                    inotifIdd={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                />
                                            );
                                        } else if (item?.data?.type === "word") {
                                            return (
                                                <WordNotification //کامپوننت نمایش باکس نوتیفیکیشن های ورد بولتن
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                />
                                            );
                                        } else if (item?.data?.type === "alarm_story") {
                                            return (
                                                <PostNotification //کامپوننت نمایش باکس نوتیفیکیشن های پی دی اف بولتن
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                />
                                            );
                                        } else if (item?.data?.type === "alarm_wave") {
                                            return (
                                                <WaveAlarmNotification //کامپوننت نمایش باکس نوتیفیکیشن های آلارم موج
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                    queries={queries}
                                                />
                                            );
                                        } else if (item?.data?.type === "report") {
                                            return (
                                                <RepostNotification // نمایش گزارش ها
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                />
                                            );
                                        } else if (
                                            item?.data?.type === "assign" ||
                                            item?.data?.type === "assign_content_to_me" ||
                                            item?.data?.type === "assign_content_to_myself"
                                        ) {
                                            return (
                                                <AssignedNotification
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                />
                                            );
                                        } else if (item?.data?.type === "dashboard_shared_to_departments") {
                                            return (
                                                <ShareDashboardToDepartmentNotification
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    generateDate={item?.generate_date}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                />
                                            );
                                        } else if (
                                            item?.data?.type === "post_created" ||
                                            item?.data?.type === "post_schedule_execute" ||
                                            item?.data?.type === "post_schedule_approve" ||
                                            item?.data?.type === "post_schedule_need_approve"
                                        ) {
                                            return (
                                                <PostScheduleExecute
                                                    key={key}
                                                    notifId={item?.id || item.data.id}
                                                    itemData={item.data}
                                                    removeNotification={this.removeNotification}
                                                    loading={removeNotifLoading}
                                                    sendNewPostPermission={sendNewPostPermission}
                                                />
                                            );
                                        } else if(item?.data?.type === "password_expired_notification"){
                                          return (
                                            <ResetPasswordNotification
                                                key={key}
                                                notifId={item?.id || item.data.id}
                                                itemData={item.data}
                                                removeNotification={this.removeNotification}
                                                loading={removeNotifLoading}
                                            
                                            />
                                          )
                                        }
                                    })}

                            {(!notification || !notification.length) && (
                                <div className="message-container">
                                    <span className="empty-messages">
                                        <FormattedSimpleMsg id="notification.empty_messages" />
                                    </span>
                                </div>
                            )}
                        </div>
                    </Scrollbar>
                </div>
                <ConfirmBox
                    type="danger"
                    display={this.state.confirmation}
                    title={<FormattedMessage id="notification.delete_all_notification_title" />}
                    desc={<FormattedMessage id="notification.delete_all_notification_confirm" />}
                    icon="delete"
                    functionToBeCalled={() => {
                        this.setState((state) => ({ confirmation: !state.confirmation }));
                    }}
                    changeSaveMode={() => {
                        this.setState((state) => ({ confirmation: !state.confirmation }));
                    }}
                    confirmAction={() => {
                        this.removeAllNotification();
                        this.setState({ confirmation: false });
                    }}
                    confirmText={"حذف"}
                    cancelText={<FormattedMessage id="global.cancel" />}
                />
            </div>
        );
    }
}

NotificationSidebar = withAppContext(NotificationSidebar);
NotificationSidebar = injectIntl(NotificationSidebar);
export default NotificationSidebar;
