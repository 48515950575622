import { FILTER_CHANGE, UPDATE_RULES, UPDATE_TAGS, GET_TAG_CONTENT } from "./types";
import { api, helper } from "services";
import { sanitizeSearchParams } from "GlobalFunctions";
import { cloneDeep } from "lodash";

export const filterChange = (data, flag) => {
    if (flag) {
        // ذخیره در ای پی آی
        let newData = cloneDeep(data);

        if (newData.rules) {
            delete newData.rules;
        }
        if (newData.tagContent) {
            delete newData.tagContent;
        }
        if (newData.tags) {
            delete newData.tags;
        }
        
        api.post("/user/settings", { newData }).then(() => {
            // تغییر مفدار لوکال استوریج
            let userInfo = api.userInfo();
            if (userInfo.user) {
                userInfo.user.profile_data.global_settings = data;
                const token = helper.encode(JSON.stringify(userInfo), "token");
                localStorage.setItem("token", token);
            }
        });
    }

    return {
        type: FILTER_CHANGE,
        payload: data,
    };
};

export const updateRules = (status) => {
    if (status) {
        return (dispatch) => {
            api.get("/search/queries", { no_paginate: 1 })
                .then((response) => response?.data?.result?.data)
                .then((data) => {
                    dispatch({
                        type: UPDATE_RULES,
                        payload: data,
                    });
                    // تغییر مفدار لوکال استوریج
                    let userInfo = api.userInfo();
                    let globalSetting =
                        userInfo.user.profile_data && userInfo.user.profile_data.global_settings
                            ? userInfo.user.profile_data.global_settings
                            : {};
                    globalSetting.rules = data;
                    const token = helper.encode(JSON.stringify(userInfo), "token");
                    localStorage.setItem("token", token);
                });
        };
    }
};

export const updateTags = (status) => {
    if (status) {
        return (dispatch) => {
            api.get("/search/tags", { no_paginate: 1 })
                .then((response) => {
                    return response.data.result.data;
                })
                .then((data) => {
                    dispatch({
                        type: UPDATE_TAGS,
                        payload: data,
                    });
                    // تغییر مفدار لوکال استوریج
                    let userInfo = api.userInfo();
                    let globalSetting =
                        userInfo.user.profile_data && userInfo.user.profile_data.global_settings
                            ? userInfo.user.profile_data.global_settings
                            : {};
                    globalSetting.tags = data;
                    const token = helper.encode(JSON.stringify(userInfo), "token");
                    localStorage.setItem("token", token);
                });
        };
    }
};

export const getTagsContent = (status) => {
    if (status) {
        return (dispatch) => {
            api.get("/dynamic_index_analyzer/api/v1/tagCategory", { no_paginate: 1, with: ["tags"] })
                .then((response) => response.data.result.data)
                .then((response) => {
                    const temp = response.map((item) => {
                        return {
                            title: item.title,
                            value: item.id,
                            key: item.id,
                            disabled: true,
                            children: item.tags.map((tag) => {
                                return {
                                    title: tag.value,
                                    value: tag.id,
                                    key: tag.id,
                                };
                            }),
                        };
                    });
                    dispatch({
                        type: GET_TAG_CONTENT,
                        payload: temp,
                    });
                    // تغییر مفدار لوکال استوریج
                    let userInfo = api.userInfo();
                    let globalSetting =
                        userInfo.user.profile_data && userInfo.user.profile_data.global_settings
                            ? userInfo.user.profile_data.global_settings
                            : {};
                    globalSetting.tagContent = temp;
                    const token = helper.encode(JSON.stringify(userInfo), "token");
                    localStorage.setItem("token", token);
                });
        };
    }
};
