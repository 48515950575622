const initialState = {};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER:_GET_USER_INFO":
      state = {
        ...payload
      }
      break;
      case "USER:DELETE_PRESET":
      state = {
        ...state,
        presets : state.presets.filter(x => x.preset_id !== payload[0])
      }
      break;
      case "USER:ADD_PRESET":
      state = {
        ...state,
        presets : [
          ...state.presets ,
          {...payload}
        ]
      }
      break;
    default:
      break;
  }
  return state;
};

export default userReducer;
