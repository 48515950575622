import React from "react";
import { Col } from "antd";

/**
 * ستون کاستومایز شده گرید
 */
const CustomCol = ({ children, span, className }) => {
    switch (span) {
        case 3:
            return (
                <Col md={4} lg={4} xl={4} xxl={3} className={className}>
                    {children}
                </Col>
            );
        case 4:
            return (
                <Col md={5} lg={5} xl={5} xxl={4} className={className}>
                    {children}
                </Col>
            );
        case 8:
            return (
                <Col md={8} lg={8} xl={8} xxl={8} className={className}>
                    {children}
                </Col>
            );
        case 12:
            return (
                <Col md={12} lg={12} xl={12} xxl={12} className={className}>
                    {children}
                </Col>
            );
        case 17:
            return (
                <Col md={17} lg={17} xl={17} xxl={17} className={className}>
                    <div>{children}</div>
                </Col>
            );
        case 20:
            return (
                <Col md={19} lg={19} xl={19} xxl={20} className={className}>
                    <div>{children}</div>
                </Col>
            );
        case 23:
            return (
                <Col md={23} lg={23} xl={23} xxl={23} className={className}>
                    <div>{children}</div>
                </Col>
            );
        case 24:
            return (
                <Col md={24} lg={24} xl={24} xxl={24} className={className}>
                    <div>{children}</div>
                </Col>
            );

        default:
            break;
    }
};

export default CustomCol;
