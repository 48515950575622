import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash-es";

const Row = ({ children, option = {}, invalidFields = [], tableStyle = {} }) => {
    return (
        <div className="ui-form-row">
            <div className="ui-form-label" style={{ width: tableStyle?.label?.width || 200 }}>
                {/* اگر اجباری بود */}
                {option.isRequired && <i className="ui-form-equired">*</i>}
                {option.label}
            </div>
            <div className="ui-form-col">
                {children}
                {/* اگر اجباری بود */}
                {option?.isRequired &&
                    // اگر پیغام خطا در ورودی مشخص شده بود
                    option?.errorMessage &&
                    // اگر مقدار خالی بود
                    isEmpty(option?.value) &&
                    // اگر کلید فیلد در آرایه‌ی مقادیر اینولید قرار داشت
                    invalidFields?.includes(option?.key) && (
                        <div className="ui-form-error-message">
                            <i className="icon icon-warning"></i>
                            {option.errorMessage}
                        </div>
                    )}
            </div>
        </div>
    );
};

Row.propTypes = {
    children: PropTypes.node,
    option: PropTypes.object,
    tableStyle: PropTypes.object,
    invalidFields: PropTypes.array,
};

export default Row;
