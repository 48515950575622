import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { UiInputNumber } from "ui-components";

const CountField = (props) => {
    const { name, platformParams, handleInputNumber, size, intl, theme, typeDescriptionFilter } = props;

    const { formatMessage } = intl;

    const defaultValue = _.isNumber(platformParams[name] || 20) ? platformParams[name] || 20 : "";

    return (
        <Row key={name} type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: `strings.${name}` })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={9} xl={size === "md" ? 15 : 11}>
                {/* اینپوت تعداد در مودال فیلترها */}
                <UiInputNumber
                    theme={theme}
                    min={10}
                    max={200}
                    defaultValue={defaultValue}
                    onChange={(val) => handleInputNumber(val, name)}
                />
            </Col>
        </Row>
    );
};
export default injectIntl(CountField);
