import React from "react";
import { UiToast } from "ui-components";
import { injectIntl } from "react-intl";
import "./Counter.scss";
// const timestamp = Date.now();

class Counter extends React.Component {
    constructor(props) {
        super(props);

        this.timestamp = Math.round(Date.now() / 52);
    }

    componentWillUnmount() {
        this.clear();
    }

    clear = () => {
        clearInterval(this.interval);
    };

    componentDidMount() {
        if (localStorage.getItem("hasDataCounter")) return;

        setTimeout(() => {
            this.notify();

            this.interval = setInterval(() => this.update(), 10);

            setTimeout(() => this.clear(), 10000);

            localStorage.setItem("hasDataCounter", true);
        }, 3000);
    }

    notify = () =>
        (this.toastId = UiToast.info(this.timestamp, {
            autoClose: 50,
            position: "bottom-left",
            closeOnClick: true,
            draggable: false,
            onClose: () => {
                this.clear();
            },
        }));

    update = () => {
        this.timestamp = this.timestamp + 1;

        const { intl } = this.props;

        const { formatNumber } = intl;

        UiToast.update(this.toastId, {
            render: (
                <div className="counter-box">
                    <b className="counter">{formatNumber(this.timestamp)}</b>

                    <p className="text">تعداد داده های یونیک دریافت شده</p>
                </div>
            ),
        });
    };

    render() {
        return null;
    }
}

Counter = injectIntl(Counter);
export default Counter;
