import React, { useContext } from "react";
import { UiTableDispatchContext, UiTableStateContext } from "../context/UiTableContext";
import UiTableRow from "../row/UiTableRow";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

/**
 * ساخت ردیف‌های جدول
 */
const UiTableRows = () => {
    const tableState = useContext(UiTableStateContext);
    const tableDispatch = useContext(UiTableDispatchContext);
    // اگر دیتا خالی بود
    if (tableState?.data?.length <= 0) return null;

    /**
     * اتمام درگ یا به عبارتی دراپ شدن
     * آرایه جدید ایجاد و جدول بروز شود
     * @param {*} oldIndex
     * @param {*} newIndex
     */
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newArray = arrayMove(tableState?.data, oldIndex, newIndex);
        tableDispatch({ type: "setData", value: newArray });
        typeof tableState.onSort === "function" && tableState.onSort(newArray);
    };

    /**
     * شروع درگ شدن
     * تمام کولپس ها باید بسته شوند
     */
    const onSortStart = () => {
        tableDispatch({ type: "setExpendedIds", value: [] });
    };

    return (
        <SortableRows
            items={tableState?.data}
            helperClass={"ui-table"}
            pressDelay={300}
            onSortEnd={onSortEnd}
            onSortStart={onSortStart}
        />
    );
};
export default UiTableRows;

// ردیف های قابل جابه جاکردن
const SortableRow = SortableElement(({ children }) => children);

// جدول با قابلیت جابه جا کردن ردیف ها
const SortableRows = SortableContainer(({ items }) => {
    return (
        <div className="ui-table-rows">
            {items?.map((item, key) => (
                <SortableRow helperClass={"ui-table-rows"} key={`item-${key}`} index={key}>
                    <UiTableRow item={item} />
                </SortableRow>
            ))}
        </div>
    );
});
