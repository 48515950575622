import React from "react";
import { UiButton } from "ui-components";
import PropTypes from "prop-types";

/**
 * قسمت فوتر (نمایش دکمه ها) در مودال
 * @param {*bool} draggable
 * @param {*func} onCancel
 * @param {*func} closePortal
 * @param {*func} onOk
 * @param {*func} okConfig
 * @param {*func} cancelConfig
 * @param {*bool} loading
 * @param {*string} type
 * @param {*bool} noBuler
 */
const UiModalFooter = ({ onOk, onCancel, closePortal, draggable, loading, type, noBuler, okConfig, cancelConfig }) => {
    /**
     * دکمه لغو مودال
     */
    const handleClose = () => {
        // متد اصلی بستن پورتال
        closePortal();
        // کالبکی که بعد از کنسل کردن موال صدا زده میشه
        onCancel();
        // اگر در حالت درگیبل باشه استایل شیشه‌ای هم وجود نداره که بخوایم حذفش کنیم. پس بقیه کد اجرا نمیشه
        if (draggable || noBuler) return;
        // حذف استایل شیشه‌ای ‌بک‌‌گراند
        const body = document.querySelector("#root>section");
        body.style = "filter: unset";
    };

    // بررسی و ایجاد استایل دکمه ها
    let btnsClass = {};
    switch (type) {
        case "danger":
            btnsClass = {
                ok: "btn radius btn-outline-secondary",
                cancel: "btn btn-danger radius",
            };
            break;
        case "success":
            btnsClass = {
                ok: "btn btn-primary radius",
                cancel: "btn radius btn-outline-secondary",
            };
            break;

        default:
            break;
    }

    return (
        <div className="ui-modal-footer">
            <UiButton
                width={100}
                size="md"
                className={okConfig.className ? okConfig.className : btnsClass.ok}
                onClick={onOk}
                style={okConfig?.style}
                loading={loading}
                disabled={loading || okConfig?.disabled}
                selenium-id={okConfig?.seleniumId}
            >
                {okConfig?.text}
            </UiButton>
            <UiButton
                width={100}
                size="md"
                className={cancelConfig.className ? cancelConfig.className : btnsClass.cancel}
                style={cancelConfig?.style}
                onClick={handleClose}
            >
                {cancelConfig?.text}
            </UiButton>
        </div>
    );
};
UiModalFooter.propTypes = {
    draggable: PropTypes.bool,
    onCancel: PropTypes.func,
    closePortal: PropTypes.func,
    onOk: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    noBuler: PropTypes.bool,
    okConfig: PropTypes.object,
    cancelConfig: PropTypes.object,
};
export default UiModalFooter;
