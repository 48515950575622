import useOnClickOutside from "hooks/useOnClickOutside";
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

const UiCollapseItem = ({
    index,
    closeOutside,
    expandIconPosition,
    expandIcon,
    mode,
    functionToBeCalled,
    obj,
    forceClose,
}) => {
    const { title = "", value = "", content = "", inactive = false, active = false, prewrap = false } = obj;
    const [collapse, setCollapse] = useState(active ? true : false);
    const wrapperRef = useRef(null);
    useOnClickOutside(wrapperRef, () => collapse && handleClick());

    /**
     * @param {bool} inactive در صورت غیر فعال بودن کولپس این مقدار true ارسال میشود
     * و کولپس باز و بسته نمیشود
     */
    const handleClick = () => {
        if (inactive) return;
        // مقدار آیتم انتخاب شده به همراه وضعیت باز یا بسته بودن آن به بیرون ارسال میشود
        functionToBeCalled && typeof functionToBeCalled === "function" && functionToBeCalled(value, !collapse);
        setCollapse(!collapse);
    };

    /**
     * بستن بالاجبار گولپس ها
     * فقط در حالت true بسته میشوند
     * توجه شود که از ییرون باید بعد از فورس کردن بعد از مثلا 100 میلی ثانیه مجددا فالس شود تا برای فورس کردن بعدی آماده باشد
     * علت استفاده نکردن از  true , false برای این است که با توجه به رندر های متفاوت در فیلتر سرچ به مشکل میخوردیم
     */
    useEffect(() => {
        if (forceClose) {
            setCollapse(false);
        }
    }, [forceClose]);

    return (
        <div className="ui-collapse-panel" key={index} ref={closeOutside ? wrapperRef : null}>
            <div className={`ui-collapse-header ${collapse ? "active" : ""}`} onClick={handleClick}>
                {!inactive && expandIconPosition === "right" && (
                    <i className={`icon ${expandIcon ? expandIcon : "icon-arrow-down"}`} />
                )}
                {title}
                {mode && <span className="dotted"></span>}
                {!inactive && expandIconPosition === "left" && (
                    <i className={`icon ${expandIcon ? expandIcon : "icon-arrow-down"}`} />
                )}
            </div>
            {collapse && (
                <div className={`ui-collapse-body ${prewrap ? "prewrap" : ""}`} dir="auto">
                    {content}
                </div>
            )}
        </div>
    );
};

UiCollapseItem.propTypes = {
    index: PropTypes.number,
    obj: PropTypes.object,
    functionToBeCalled: PropTypes.func,
    mode: PropTypes.oneOf(["dotted"]),
    expandIcon: PropTypes.string,
    closeOutside: PropTypes.bool,
    forceClose: PropTypes.bool,
    expandIconPosition: PropTypes.oneOf(["left", "right"]),
};
export default UiCollapseItem;
