import React from "react";
import PropTypes from "prop-types";
import { api } from "services";
import { Checkbox } from "antd";
import Loadings from "shared/loadings/Loadings";
import { injectIntl } from "react-intl";
import "./QueryRulesContent.scss";
import { UiCollapse, UiToast } from "ui-components";

class QueryRulesContent extends React.Component {
    state = {
        departments: [],
        usersLoading: false,
        departmentsLoading: false,
        departmentsSelectedLoading: false,
        department_ids: [],
        confirmationShareLoading: false,
    };

    componentDidMount() {
        this.getSharedContent();
        this.getDepartments();
        this.getUsers();
    }

    /**
     * انتخاب دپارتمان‌ها
     * @param {*} value
     * @param {*} key
     */
    handleChange = (value, key) => {
        let state = {
            [key]: value,
        };
        this.setState(state);
        const { departments } = this.state;
        const { itemId } = this.props;
        const shared_departments = state?.department_ids?.map((departmentID) => ({
            id: departmentID,
            name: departments.filter((department) => department.department_id === departmentID)[0].department_name,
        }));
        const params = {
            query_id: itemId,
            departments: shared_departments,
        };
        this.props.onChange(params);
    };

    /**
     * دریافت لیست دپارتمان ها
     */
    getDepartments = () => {
        this.setState({
            departmentsLoading: true,
        });

        api.get("/user/department", { no_paginate: 1, by_same_company: 1, exclude: ["parents"] })
            .then((result) => result.data.result.data)
            .then((result) => {
                this.setState({
                    departments: result,
                });
            })
            .catch((error) => {
                UiToast.exceptions(error);
            })
            .then(() => {
                this.setState({
                    departmentsLoading: false,
                });
            });
    };

    /**
     * دریافت لیست کاربران
     */
    getUsers = () => {
        api.get("/user", { no_paginate: 1 })
            .then((response) => response.data.result.data)
            .then((response) => {
                this.setState({
                    users: response,
                });
            })
            .catch((error) => {
                UiToast.exceptions(error);
            })
            .then(() => {});
    };

    /**
     * دریافت لیست دپارتمان های از قبل انتخاب شده
     */
    getSharedContent() {
        const { itemId, tableState } = this.props;
        const params = {
            no_paginate: 1,
        };
        const path = tableState.apiPaths.getOptions.path;
        api.get(path, params)
            .then((response) => response.data.result.data)
            .then((response) => {
                let shared_department_ids = [];
                let currentQueryRule = response?.filter((item) => item.id === itemId);
                currentQueryRule &&
                    currentQueryRule[0].shared_with.forEach(function (item) {
                        shared_department_ids.push(Number(item.shared_department_id));
                    });
                this.setState({ departmentsSelectedLoading: false, department_ids: shared_department_ids });
            })
            .catch(() => {
                this.setState({ departmentsSelectedLoading: false, department_ids: [] });
            })
            .then(() => {});
    }

    /**
     * ساختن محتوای کولپس
     */
    collapseTitle = (item) => (
        <span
            onClick={(event) => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
            }}
        >
            <Checkbox value={item.department_id}>{item.department_name}</Checkbox>
        </span>
    );

    render() {
        const {
            departmentsLoading,
            departmentsSelectedLoading,
            departments,
            department_ids,
            users,
            errors,
        } = this.state;

        if (!departments) return null;

        let { itemTitle } = this.props;

        // محتوای کولپس
        const collapseData = departments?.map((department) => {
            const usersTemp = users?.filter((x) => x.department_id === department.department_id);
            return {
                title: this.collapseTitle(department),
                content: (
                    <>
                        {usersTemp?.map((user, index) => (
                            <div key={index} className="item">
                                {`${user.name} ${user.last_name}`}
                            </div>
                        ))}

                        {usersTemp?.length <= 0 && <div disabled>کاربری برای این دپارتمان وجود ندارد</div>}
                    </>
                ),
            };
        });

        const Title = () => <span>اشتراک گذاری کلمه کلیدی [ {itemTitle} ] بین دپارتمان‌ها</span>;
        return (
            <div className="share-query">
                <div className="share-query-title">
                    <Title />
                </div>
                <Loadings type="modal" spinning={departmentsLoading || departmentsSelectedLoading}>
                    <div className="scroll">
                        <Checkbox.Group value={department_ids} onChange={(e) => this.handleChange(e, "department_ids")}>
                            {/* مودال اشتراک گذاری کلمات کلیدی در /query-rules */}
                            <UiCollapse data={collapseData} />
                        </Checkbox.Group>
                    </div>

                    {errors && (
                        <div className="share-query-errors">
                            <i className="icon icon-warning" />
                            {errors}
                        </div>
                    )}
                </Loadings>
            </div>
        );
    }
}

QueryRulesContent.propTypes = {
    onChange: PropTypes.func,
    itemId: PropTypes.number,
    QueryRoleName: PropTypes.string,
    itemTitle: PropTypes.string,
    tableState:  PropTypes.object
};

export default injectIntl(QueryRulesContent);
