import React, { Component } from "react";
import SelectedSourcesView from "./SelectedSourcesView";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import _ from "lodash";
import ResourcesViewRightPart from "./ResourcesViewRightPart";
import ResourcesViewMiddlePart from "./ResourcesViewMiddlePart";
import ResourcesViewLeftPart from "./ResourcesViewLeftPart";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { UiModal } from "ui-components";
class ResourcesView extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const {
            showModal,
            handleOk,
            modalVisible,
            // selectedPlatform,
            unCategoryResourcesSelected,
            selectedCount,
            editMode,
            typeWrapper,
            inlineTag,
            customContent,
            handleClose,
            selectedResourceCount,
            isRequired,
            className,
            disabled,
            buttonTitle,
            selectedResources,
        } = this.props;

        return (
            <>
                {(inlineTag || customContent) && <span onClick={showModal}>{this.props?.children}</span>}
                {typeWrapper !== "full" && !inlineTag && !customContent && (
                    <button
                        className={`button blue-filled medium-button ${className} ${disabled ? "disabled" : ""}`}
                        onClick={showModal}
                        type="button"
                    >
                        {_.isEmpty(buttonTitle) ? (
                            <span>
                                {" "}
                                <FormattedSimpleMsg id="resource.view.button_source" />{" "}
                                {selectedCount ? selectedCount : ""}
                            </span>
                        ) : (
                            <span>{buttonTitle}</span>
                        )}
                    </button>
                )}
                {!editMode && isRequired && selectedCount === 0 && (
                    <span className="validate-source">
                        <FormattedSimpleMsg id="resource.view.error" />
                    </span>
                )}

                {/* نمایش حداکثر 5 منبع اول انتخاب شده به علاوه دکمه بیشتر*/}
                {unCategoryResourcesSelected && !inlineTag && !customContent && (
                    <SelectedSourcesView
                        showModal={showModal}
                        className={className}
                        editMode={editMode}
                        unCategoryResourcesSelected={
                            unCategoryResourcesSelected ? unCategoryResourcesSelected : undefined
                        }
                        selectedResourceCount={selectedResourceCount}
                        selectedResources={selectedResources}
                    />
                )}

                {typeWrapper === "full" ? (
                    <div className="sources-wrapper full">
                        <div className="topPart">
                            <ResourcesViewRightPart {...this.props} />
                        </div>

                        <div className="leftPart">
                            <ResourcesViewMiddlePart {...this.props} />
                            <ResourcesViewLeftPart {...this.props} />
                        </div>
                    </div>
                ) : (
                    <UiModal
                        visible={modalVisible}
                        onOk={handleOk}
                        onCancel={handleClose}
                        size="xxl"
                        icon=""
                        noFooter
                        noHeader
                        noBuler
                        onClickOutside={handleClose}
                        className="modal-shadow larg-modal white-modal filters-modal"
                    >
                        <div className="sources-wrapper">
                            <div className="rightPart">
                                <ResourcesViewRightPart {...this.props} />
                            </div>

                            <div className="leftPart">
                                <ResourcesViewMiddlePart {...this.props} />
                                <ResourcesViewLeftPart {...this.props} />
                            </div>
                        </div>
                    </UiModal>
                )}
            </>
        );
    }
}

ResourcesView.propTypes = {
    showModal: PropTypes.func.isRequired /* نمایش مودال*/,
    handleOk: PropTypes.func.isRequired /* هندل کلیک روی اوکی*/,
    handleCancel: PropTypes.func.isRequired /* هندل کلیک روی کنسل*/,
    selectPlatform: PropTypes.func.isRequired /* انتخاب منبع*/,
    // onChangeCollapse: PropTypes.func.isRequired,     /* تغییر منبع*/
    // renderResources: PropTypes.func.isRequired,     /* رندر منابع و نمایش دکمه های مربوط به منابع*/
    // renderResourcesSelected: PropTypes.func.isRequired,     /* انتخاب منابع انتخاب شده*/
    searchOnLoadedResources: PropTypes.func /* سرچ روی منابع لود شده */,
    // reduceResources: PropTypes.func.isRequired,     /* جمع آوری منابع مربوط به یک بستر توی یک آرایه به صورت فلت*/
    platforms: PropTypes.array.isRequired /* لیست بسترها*/,
    modalVisible: PropTypes.bool.isRequired /* وضعیت مودال*/,
    categories: PropTypes.array.isRequired /* دسته بندیها*/,
    // removeFullyAddedCategory: PropTypes.func.isRequired,     /* حذف دسته ای که کامل اضافه شده*/
    fullySelectedCategoriesIDs: PropTypes.array.isRequired /* آیدی دسته هایی که کامل انتخاب شده اند*/,
    selectedPlatform: PropTypes.number /* بستر فعلی*/,
    categoryloading: PropTypes.bool /* وضعیت دسته ها- درحال لود دسته ها یا نه*/,
    getResources: PropTypes.func /* با استفاده از این متد میتوان مقادیر انتخاب شده را از این کامپوننت دریافت کرد */,
};

export default injectIntl(ResourcesView);
