import React from "react";
import PropTypes from "prop-types";
import "./UiMenu.scss";
import { UiTooltip } from "ui-components";

/**
* منو
* @param {*node} children
* @param {*array} items
* @param {*string} className
* @param {*number} mouseEnterDelay
* @param {*} theme oneOf(["dark", "light"]),
* @param {*} trigger oneOf(["hover", "focus", "click", "contextmenu"]),
* @param {*} placement oneOf([
       "top",
       "topLeft",
       "topRight",
       "bottom",
       "bottomLeft",
       "bottomRight",
       "left",
       "leftTop",
       "leftBottom",
       "right",
       "rightTop",
       "rightBottom",
   ]),
*/
const UiMenu = (props) => {
    const {
        items,
        children,
        theme,
        onClickItem,
        trigger = "click",
        placement = "bottomLeft",
        className,
        ...other
    } = props;

    if(items?.length <= 0) return children;

    //ارسال ایتم انتخاب شده به کامپوونت پدر
    const handleClickItem = (item) => {
        if (typeof onClickItem === "function") {
            onClickItem(item);
        } else {
            typeof item.onClick === "function" && item.onClick();
        }
    };

    //نمایش لیست منو
    const titleTemp = (
        <div className="ui-menu">
            <ul className={["ui-menu-content"].join(" ")}>
                {items?.map((item, key) => {
                    return (
                        <li
                            id={item?.id}
                            selenium-id={item?.id}
                            disabled={item?.disabled}
                            key={"ui-menu-item-" + key}
                            onClick={() => !item?.disabled && handleClickItem(item)}
                            className="ui-menu-item-content"
                        >
                            {item?.icon && <i className={item?.icon} />}
                            {item?.title}
                        </li>
                    );
                })}
            </ul>
        </div>
    );

    return (
        <UiTooltip
            theme={theme}
            title={titleTemp}
            trigger={trigger}
            placement={placement}
            className={["ui-menu", className].join(" ")}
            portalName="menu"
            {...other}
        >
            <span>{children}</span>
        </UiTooltip>
    );
};

UiMenu.propTypes = {
    theme: PropTypes.oneOf(["dark", "light"]),
    className: PropTypes.string,
    onClickItem: PropTypes.func,
    items: PropTypes.array,
    children: PropTypes.node,
    trigger: PropTypes.oneOf(["hover", "click", "contextmenu"]),
    placement: PropTypes.oneOf([
        "top",
        "topLeft",
        "topRight",
        "bottom",
        "bottomLeft",
        "bottomRight",
        "left",
        "leftTop",
        "leftBottom",
        "right",
        "rightTop",
        "rightBottom",
    ]),
};

export default UiMenu;
