import { WindowLocation } from "GlobalVariables";
import qs from 'qs';
import _ from "lodash";
import scssVariables from "./assets/css/style.scss";
import { getProjectOptions } from "GlobalFunctions";
// import scssVariables from "./assets/css/static.css";

export function DomainSettings(field) {

    // get domain address
    const domain = WindowLocation.host;

    // get domain address origin
    const origin = WindowLocation.origin;

    // get query string in url
    const search = WindowLocation.search.replace("?", "");

    // parse query string to object
    const queryString = qs.parse(search);

    // theme name : set theme name from query string, or localstorage, or static theme name
    const theme = queryString.theme || localStorage.getItem("theme");


    // language : set language from query string, or localstorage, or static language
    const language = queryString.language || localStorage.getItem("language");

    // generate option
    const currentOption = getProjectOptions();
    const defaultOption = getProjectOptions("default");
    let finalObj = { ...defaultOption.customSetting, ...currentOption.customSetting }

    if (theme)
        finalObj = { ...finalObj, ...{ theme } }

    if (language)
        finalObj = { ...finalObj, ...{ language } }

    // save theme and lang in localstorage
    finalObj?.theme && localStorage.setItem("theme", finalObj.theme);
    finalObj?.language && localStorage.setItem("language", finalObj.language);

    // allow fields for concat with path
    const imageField = ["fav_icon", "background", "logo", "head_logo", "login_bg_img"]

    // add path to image address
    finalObj = _.reduce(finalObj, function (result, value, key) {
        const url = imageField.includes(key) ? require(`./assets/img/domain-settings/${finalObj.theme}_${value}`) : value;
        result[key] = url
        return result;
    }, {});

    return field ? finalObj[field] : finalObj;
}

export function getScssVariable(item) {
    return scssVariables[`${DomainSettings("theme")}_${item}`]
}


export const currentTheme = () => {
    return localStorage.getItem("theme");
};

export default { DomainSettings, getScssVariable, currentTheme };
