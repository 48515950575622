import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { FormattedDate } from "react-intl";
import Loadings from "../../loadings/Loadings";
import { UiButton } from "ui-components";

const AssignedNotification = ({ itemData, removeNotification, notifId, loading={[notifId]:false} }) => {
    const { assigner_user_name, assigner_user_last_name, assign_time, description, ids, type } = itemData;

    const count = ids.map((x) => x.ids.length);
    const platform = ids?.[0]?.platform; // platform === twitter || instagramcomments || twitterconversation
    let link = type === "assign_content_to_me.global" ? "/direct/twitter-direct" : "/assignment/twitter-post";
    if ( platform === "twitterconversation" ) {
        link = "/direct?type=mine";
    }
    if ( platform === "instagramcomments" ) {
        link = "/assignment/instagram-comment";
    }
    const notifName = type === "assign_content_to_me.global" ? "دایرکت" : "محتوای";
    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className="post-notification">
                <div className="calendar">
                    <div className="right-section">
                        <bdi>
                            <span style={{ whiteSpace: "nowrap" }}>
                                {<FormattedDate value={assign_time.split(" ")[0]} />} - {assign_time.split(" ")[1]}
                            </span>
                        </bdi>
                    </div>

                    <div className="left-section">
                        {notifId && (
                            <UiButton
                                type="icon"
                                size="xxs"
                                className="icon icon-close-icon "
                                onClick={() => {
                                    removeNotification(notifId);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="assign-notif-body">
                    <div>
                        <i className="icon icon-assign" />
                        <span>
                            {count} {notifName} جدید به شما اساین شده
                        </span>
                    </div>

                    <div className="row">
                        <span className="label"> توسط: </span>
                        <span> {assigner_user_name + " " + assigner_user_last_name} </span>
                    </div>

                    <div className="row">
                        <span className="label"> توضیحات: </span> <span className="prewrap" dir="auto">{description}</span>
                    </div>

                    <NavLink target="_blank" to={link}>
                        <div className="view">مشاهده اساینمنت</div>
                    </NavLink>
                </div>
            </div>
        </Loadings>
    );
};

AssignedNotification.propTypes = {
    platform: PropTypes.string,
    avatar: PropTypes.string,
};

export default AssignedNotification;
