import React from "react";
import PropTypes from "prop-types";
import { UiTooltip } from "ui-components";

/**
 * دکمه های مربوط به حرکت اسلایدر
 * @param {*array} domain
 * @param {*shape} handle id, value, percent
 * @param {*bool} disabled
 * @param {*func} getHandleProps
 * @param {*bool} tooltipVisible
 */
const Handle = (props) => {
    const {
        domain: [min, max],
        handle: { id, value, percent },
        disabled,
        getHandleProps,
        tooltipVisible,
    } = props;
    return (
        <>
            <UiTooltip title={tooltipVisible ? <span>{value}</span> : null}>
                <div
                    className={["ui-input-range-handle", disabled ? "disabled" : ""].join(" ")}
                    style={{ left: `${percent}%` }}
                    {...getHandleProps(id)}
                />
            </UiTooltip>
            <div
                role="slider"
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                style={{ left: `${percent}%` }}
                className={["ui-input-range-handle-slider", disabled ? "disabled" : ""].join(" ")}
            />
        </>
    );
};

Handle.propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getHandleProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    tooltipVisible: PropTypes.bool,
};

export default Handle;
