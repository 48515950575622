import React, { useContext, useState } from "react";
import ReactPaginate from "react-paginate";
import { FormattedMessage } from "react-intl";
import UiInput from "ui-components/ui-input/UiInput";
import { isEqual, isNumber } from "lodash";
import { UiTableStateContext } from "../context/UiTableContext";
import useUiTableData from "../hooks/useUiTableData";
import { useEffect } from "react";
import usePrevious from "hooks/usePrevious";

/**
 * صفحه بندی جدول
 */
const Uitablepagination = () => {
    const tableState = useContext(UiTableStateContext);
    const { getData } = useUiTableData();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const perPage = 10;
    const prevProps = usePrevious({ searchParams: tableState?.searchParams });
    let pageCount = Math.ceil(totalCount / perPage);

    useEffect(() => {
        setTotalCount(tableState?.totalCountData);
    }, [tableState?.totalCountData]);

    useEffect(() => {
        if (!isEqual(prevProps?.searchParams, tableState?.searchParams)) {
            setCurrentPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState?.searchParams]);

    /**
     * تغییر شماره صفحه از روی شماره‌ها
     * @param {*} page
     */
    const pageChange = (page) => {
        getData(page, tableState?.searchParams);
        setCurrentPage(page);
        // dispatch : setSelectedItems
    };

    /**
     * تغییر شماره صفحه از اینپوت
     * @param {*} value
     */
    const pageForceChange = (value) => {
        value = parseInt(value);

        if (isNumber(value) && value > 0 && value <= pageCount && value !== currentPage) {
            pageChange(value);
        }
    };

    if (totalCount < 10 || totalCount === "empty") return null;

    return (
        <div className="ui-table-pagination">
            {/* استفاده از کامپوننت پجینیشن */}
            <ReactPaginate
                pageCount={pageCount}
                containerClassName="pagination"
                activeClassName="active"
                previousClassName="previous"
                nextClassName="next"
                previousLabel=""
                nextLabel=""
                pageRangeDisplayed={3}
                forcePage={currentPage - 1}
                onPageChange={(curr) => pageChange(curr.selected + 1)}
            />

            {/* برو به */}
            <span className="ui-table-pagination-jump-text">
                <FormattedMessage id="pagination.go_to" />
            </span>

            {/* اینپوت وارد کردن شماره صفحه */}
            <UiInput value={currentPage} className="ui-table-pagination-jump-input" onEnter={pageForceChange} />
        </div>
    );
};

export default Uitablepagination;
