import React from "react";
import { FormattedSimpleMsg } from "GlobalFunctions";
import _ from "lodash";
import { UiRadio, UiRadioGroup } from "ui-components";

const ResourcesViewMiddlePartSearchType = ({ inputChange, platforms, selectedPlatform, searchType }) => {
    return (
        <div className="search-type">
            <div className="search-type-title">
                <FormattedSimpleMsg id="sourceSelect.search_type" />
            </div>
            {/* رادیو باتن نوع جستجو در مودال انتخاب منبع */}
            <UiRadioGroup
                name="searchTypeResource"
                onChange={(e) => inputChange("searchType", e)}
                value={searchType}
                defaultValue="resource"
            >
                <UiRadio key={0} className="radio-item" value="resource">
                    <FormattedSimpleMsg id="sourceSelect.search_sources" />
                </UiRadio>

                {platforms[selectedPlatform] != "newspaperagencies" && (
                    <UiRadio key={1} className="radio-item" value="subjectResource">
                        <FormattedSimpleMsg id="sourceSelect.search_sources_subject" />
                        {/*  استفاده شده در مودال انتخاب منبع(جستجوی موضوعی منابع)*/}

                        <i className="icon icon-info" />
                    </UiRadio>
                )}
            </UiRadioGroup>
        </div>
    );
};

export default ResourcesViewMiddlePartSearchType;
