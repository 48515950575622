import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import UiTableActions from "../actions/UiTableActions";
import { UiTableStateContext } from "../context/UiTableContext";
import PropTypes from "prop-types";

/**
 * نمایش پاپ آپ اوپریشن در زیر جدول
 * @param {*string} boxSize oneOf(["md", "sm"])
 */
const UiTablePopupOperation = (props) => {
    const { boxSize } = props;
    const tableState = useContext(UiTableStateContext);

    // تعداد رکوردهای انتخاب شده در جدول
    const count = tableState?.selectedItems?.length || 0;
    if (count <= 0) {
        return <div className="ui-table-popup-operation" />;
    }

    // اگر در حالت باکسی باز شد که کوچک بود
    const isSmall = boxSize === "sm" || boxSize === "md" ? true : false;

    return (
        <div className={`ui-table-popup-operation vis ${isSmall ? "ui-table-popup-operation-sm" : ""}`}>
            <div className="information-area">
                {/* مورد انتخاب شده است n */}
                <FormattedMessage
                    id="global.selected_items"
                    values={{
                        count,
                    }}
                />
            </div>
            {/* نمایش اکشن ها - از همان اکشن های رکورد ها به صورت مشترک استفاده شده */}
            <div className="buttons-area">
                <UiTableActions btnType="button" />
            </div>
        </div>
    );
};

UiTablePopupOperation.propTypes = {
    boxSize: PropTypes.oneOf(["md", "sm"]),
};

export default UiTablePopupOperation;
