import React from "react";
import PropTypes from "prop-types";

/**
 * قسمت هدر مودال
 * @param {*node} children
 * @param {*string} icon
 * @param {*func} onCancel
 * @param {*func} closePortal
 * @param {*bool} draggable
 * @param {*bool} noBuler
 */
const UiModalHeader = ({ closePortal, icon, children, draggable, onCancel, noBuler }) => {
    /**
     * کلیک روی دکمه بستن مودال
     */
    const handleClose = () => {
        // متد اصلی بستن پورتال
        closePortal();
        // کالبکی که بعد از بستن موال صدا زده میشه
        onCancel();
        // اگر در حالت درگیبل باشه استایل شیشه‌ای هم وجود نداره که بخوایم حذفش کنیم. پس بقیه کد اجرا نمیشه
        if (draggable || noBuler) return;
        // حذف استایل شیشه‌ای ‌بک‌‌گراند
        const body = document.querySelector("#root>section");
        body.style = "filter: unset";
    };
    return (
        <div className={`ui-modal-header ${draggable ? "draggable" : ""}`}>
            <i className={`ui-modal-icon icon ${icon}`}></i>
            <div className="ui-modal-title">{children}</div>
            <i className="icon icon-close-icon ui-modal-close-icon" onClick={handleClose}></i>
        </div>
    );
};
UiModalHeader.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string,
    onCancel: PropTypes.func,
    closePortal: PropTypes.func,
    draggable: PropTypes.bool,
    noBuler: PropTypes.bool,
};
export default UiModalHeader;
