import React from "react";
import { UiCollapse } from "ui-components";
import { isNil, isObject } from "lodash";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";

const UiToastWrapper = ({ content, type, intl }) => {
    const { formatMessage } = intl;
    let text = content;
    //اگر کانتنت به صورت آبجکت بود برای ترجمه کردن و پیدا کردن لنگتش باید از فرمت مسیج استفاده کنیم
    if (isObject(content)) {
        const { id, ...other } = content;
        text = formatMessage({ id }, other);
    }

    let iconType = "";
    switch (type) {
        case "success":
            iconType = "icon icon-checkbox-active";
            break;
        case "error":
            iconType = "icon icon-warning";
            break;
        case "warning":
            iconType = "icon icon-exclamation";
            break;
        case "info":
            iconType = "icon icon-exclamation";
            break;
        default:
            iconType = "icon icon-exclamation";
            break;
    }

    const length = text?.length;
    if (length < 40 || isNil(length))
        return (
            <Wrapper>
                {type !== "success" && <i className={iconType} />} <span className="text">{text}</span>
            </Wrapper>
        );
    return (
        <Wrapper>
            <UiCollapse
                data={[
                    {
                        title: <span className="text">{text.substring(0, 35)}</span>,
                        content: (
                            <>
                                {type !== "success" && <i className={iconType} />}
                                <span>{text}</span>
                            </>
                        ),
                    },
                ]}
            />
        </Wrapper>
    );
};
UiToastWrapper.propTypes = {
    content: PropTypes.string,
    type: PropTypes.oneOf(["error", "success", "warning", "info"]),
    intl: intlShape.isRequired,
};
export default injectIntl(UiToastWrapper);

const Wrapper = ({ children }) => {
    return <div className="ui-toast-wrapper">{children}</div>;
};
Wrapper.propTypes = {
    children: PropTypes.node,
};
