import React from "react";
import { FormattedDate } from "react-intl";

/**
 * اگر داده ها نیاز به نرمال شدن داشته باشند در این بخش انجام میشود
 * @param {*} field نام فیلد جاری
 * @param {*} value مقدار فیلد
 */
const UiTableValueNormalizer = ({ field, value }) => {
    if(!value) return "-";
    // اگر نوع داده از نوع تاریخ بود
    if (["created_at", "updated_at"].includes(field)) {
        const [date, time] = value?.split(" ");
        return (
            <bdi style={{ whiteSpace: "nowrap" }}>
                {<FormattedDate value={date} />} - {time}
            </bdi>
        );
    }
    return value?.toString() || "-";
};
export default UiTableValueNormalizer;
