import React from "react";
import CustomCol from './CustomCol';
import { DomainSettings } from "../DomainSettings";


class AppHeaderOffline extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            reloadProfilePopup: false
        }

    }


    componentDidMount() {

    }



    render() {

    


        return (

            <>

                <CustomCol span={4}>

                    <div id="header" className="logo-container">

                        <img src={DomainSettings("head_logo")} className="logo" alt="logo" />

                        <span className="brand">{DomainSettings("sidebar_title")}</span>

                    </div>

                </CustomCol>

                <CustomCol span={20}>

                </CustomCol>

            </>

        );
    }
}


export default AppHeaderOffline;
