import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UiButton, UiModal } from "ui-components";
import useUiTableData from "../hooks/useUiTableData";
import UiTableInlineError from "../errors/UiTableInlineError";
import { UiTableDispatchContext, UiTableStateContext } from "../context/UiTableContext";
/**
 * اکشن حذف یک رکورد
 * @param {*Object} action آبجکت اکشن
 * @param {*Number} itemId شناسه رکورد
 * @param {*string} btnType نوع آیکن oneOf(["button", "icon"])
 */
const UiActionDelete = ({ action, itemId, btnType }) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const { removeItem, findByIds } = useUiTableData(false);
    const tableState = useContext(UiTableStateContext);
    const tableDispatch = useContext(UiTableDispatchContext);
    const selectedItemsId = tableState?.selectedItems;
    const itemsTitle = findByIds(itemId ? [itemId] : selectedItemsId);

    /**
     * باز و بست کردن مودال کانفرم
     */
    const handleToggleModal = () => {
        setVisibleModal((visible) => !visible);
        if (visibleModal) {
            tableDispatch({ type: "errorRemove", value: null });
        }
    };

    /**
     * حذف آیتم
     */
    const handleRemove = () => {
        // اگر انتخاب آیتم برای حذف تکی یا دسته ای بود
        removeItem(itemId ?? selectedItemsId, () => {
            handleToggleModal();
        });
    };

    // اگر با موفقیت حذف شود
    // مودال کانفرم بسته میشود
    useEffect(() => {
        if (tableState?.isRemoved) {
            setVisibleModal(false);
        }
    }, [tableState?.isRemoved]);

    let option = {
        className: `icon ${action.icon}`,
        size: "xxs",
        modalText: <span className="text"> آیا از حذف رکورد [ {itemsTitle} ] اطمینان دارید؟ حذف قابل بازگشت نیست</span>,
    };

    // اگر درخواست از popup operation آمده بود
    if (btnType === "button") {
        option = {
            className: "btn radius btn-outline-light",
            size: "md",
            prefix: <i className={`icon ${action.icon}`}></i>,
            modalText: (
                <span className="text">آیا از حذف رکورد(های) [ {itemsTitle} ] اطمینان دارید؟ حذف قابل بازگشت نیست</span>
            ),
            btnText: action.tooltip,
        };
    }
    return (
        <i>
            <UiButton
                type={btnType}
                className={option?.className}
                size={option?.size}
                prefix={option?.prefix}
                tooltip={action.tooltip}
                disabled={action.disabled}
                onClick={handleToggleModal}
            >
                {option?.btnText}
            </UiButton>
            <UiModal
                loading={tableState?.loadingRemove}
                visible={visibleModal}
                title="حذف"
                onOk={handleRemove}
                onCancel={handleToggleModal}
                type="danger"
            >
                {/* توضیحات داخل مودال */}
                {option?.modalText}

                {/* نمایش پیغام خطا */}
                <UiTableInlineError errors={tableState?.errorsRemove} />
            </UiModal>
        </i>
    );
};
UiActionDelete.propTypes = {
    action: PropTypes.object,
    itemId: PropTypes.number,
    btnType: PropTypes.oneOf(["button", "icon"]),
};

export default UiActionDelete;
