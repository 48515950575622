import React from "react";
import PropTypes from "prop-types";
import "./UiCollapse.scss";
import UiCollapseItem from "./UiCollapseItem.js";

const UiCollapse = (props) => {
    const {
        className,
        data = [],
        functionToBeCalled,
        mode,
        expandIcon = "",
        accordion = false,
        expandIconPosition = "left",
        closeOutside = false,
        forceClose,
    } = props;

    return (
        <div className={`ui-collapse  ${className ?? ""} ${accordion ? "accordion" : ""}`}>
            {data?.map((obj, index) => {
                return (
                    <UiCollapseItem
                        obj={obj}
                        key={index}
                        index={index}
                        className={className}
                        accordion={accordion}
                        closeOutside={closeOutside}
                        expandIconPosition={expandIconPosition}
                        expandIcon={expandIcon}
                        mode={mode}
                        functionToBeCalled={functionToBeCalled}
                        forceClose={forceClose}
                    />
                );
            })}
        </div>
    );
};
UiCollapse.propTypes = {
    data: PropTypes.arrayOf(Object),
    className: PropTypes.string,
    functionToBeCalled: PropTypes.func,
    mode: PropTypes.oneOf(["dotted"]),
    active: PropTypes.bool,
    expandIcon: PropTypes.string,
    accordion: PropTypes.bool,
    closeOutside: PropTypes.bool,
    forceClose: PropTypes.bool,
    expandIconPosition: PropTypes.oneOf(["left", "right"]),
};
export default UiCollapse;
