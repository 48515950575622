import React, { useContext, useEffect } from "react";
import useUiTableData from "../hooks/useUiTableData";
import UiTableHeader from "../head/UiTableHeader";
import { UiTableDispatchContext, UiTableStateContext } from "../context/UiTableContext";
import Uitablepagination from "../pagination/UiTablePagination";
import PropTypes from "prop-types";
import UiTableErrors from "../errors/UiTableErrors";
import UiTableRows from "../rows/UiTableRows";
import UiTablePopupOperation from "../popup-operation/UiTablePopupOperation";
import useUiTablePermissions from "../hooks/useUiTablePermissions";
import BreadcrumbView from "shared/breadcrumb/BreadcrumbView";

// انتخاب ستون ها
// بخش فیلتر کردن دیتا
// permissions
// cancel request
// popup operation

const UiTableBody = ({ searchParams, header }) => {
    const tableState = useContext(UiTableStateContext);
    const tableDispatch = useContext(UiTableDispatchContext);
    const { getData } = useUiTableData();
    const { tablePermission } = useUiTablePermissions(tableState);

    useEffect(() => {
        // بررسی اینکه کاربر به لیست دسترسی دارد یا نه
        if (!tablePermission?.list?.permission) {
            tableDispatch({
                type: "error",
                value: {
                    response: {
                        status: 401,
                        data: {
                            errors: "شما به این بخش دسترسی ندارید",
                        },
                    },
                },
            });
            return;
        }

        // ذخیره اطلاعات سرچ برای استفاده در بخش‌های مختلف مانند صفحه‌بندی
        tableDispatch({ type: "setSearchParams", value: searchParams });

        // دریافت دیتای جدول
        getData(null, searchParams);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const classes = ["ui-table", tableState?.theme, tableState?.className, tableState?.loading ? "loading" : ""].join(
        " ",
    );

    return (
        <>
            <div id={tableState?.id} className={classes}>
                {/* هدر جدول */}
                {header && header}
                {/* نوار بردکرامب */}
                {tableState?.breadcrumbData && <BreadcrumbView items={tableState?.breadcrumbData} />}
                {/* عنوان ستون‌های جدول */}
                <UiTableHeader />

                {/* ردیف‌های جدول */}
                <UiTableRows />

                {/* خطاها و پیغام‌ها */}
                <UiTableErrors />

                {/* صفحه بندی */}
                <Uitablepagination />

                {/* نوار اکشنهایی که به صورت مالتی انجام میشوند - popup operation */}
                <UiTablePopupOperation></UiTablePopupOperation>
            </div>
        </>
    );
};
UiTableBody.propTypes = {
    searchParams: PropTypes.object,
    header: PropTypes.element,
};
export default UiTableBody;
