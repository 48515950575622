import React, { Component } from "react";
import SideFilterView from "./SideFilterView";
import _ from "lodash";
import moment from "moment-jalaali";
import { filterChange, updateRules, updateTags, getTagsContent } from "../../actions/globalFilterAction";
import { connect } from "react-redux";
import { api } from "services";
import { dateValidate } from "GlobalFunctions";
import { UiToast } from "ui-components";
import "./SideFilter.scss";

class SideFilter extends Component {
    constructor(props) {
        super(props);

        // this.state = {
        //     filterState: {...this.props.globalFilter},
        // };

        const {
            from,
            to,
            time_duration,
            query,
            apply,
            tagId,
            resource_ids,
            resourceIdsDetails,
            resource_objects,
            totalResourceCount,
            selectedResourceCount,
            exists,
            relation_ages_cls,
            min_relation_ages,
            max_relation_ages,
        } = this.props.globalFilter;

        this.state = {
            filterState: {
                from,
                to,
                time_duration,
                query,
                apply,
                tagId,
                resource_ids,
                resourceIdsDetails,
                resource_objects,
                totalResourceCount,
                selectedResourceCount,
                exists,
                relation_ages_cls,
                min_relation_ages,
                max_relation_ages,
            },
        };

        this.setVisible = this.setVisible.bind(this);
        this.handleDatePicker = this.handleDatePicker.bind(this);
        this.submit = this.submit.bind(this);
        this.getPreSelected = this.getPreSelected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getResources = this.getResources.bind(this);
        this.handleChangeExists = this.handleChangeExists.bind(this);
        this.handleChangeRelationAges = this.handleChangeRelationAges.bind(this);
    }

    /**
     * نمایش یا عدم نمایش ساید بار
     */
    setVisible() {
        this.props.setVisible();
    }

    /**
     *  تغییر داینامیک مقادیر دراپ داون ها
     * @param {*} val
     * @param {*} type
     */
    handleMultiSelect = (val, type) => {
        this.setState({
            filterState: {
                ...this.state.filterState,
                [type]: val.map((item) => item.value),
            },
        });
    };

    changePlatformParams = (params) => {
        this.setState({
            filterState: params,
        });
    };

    /**
     * ارسال تایم ابندا و انتهای بازه زمانی
     * @param {*} unix  مقدار تایم استمپ
     * @param {*} formatted زمان با فرمت جلالی
     * @param {*} type مشخص کننده اینکه زمان ابتدا هست یا انتها
     */
    handleDatePicker(formatted, type) {
        this.setState({
            filterState: {
                ...this.state.filterState,
                [type]: formatted,
                time_duration: "",
            },
        });
    }

    /**
     * هندل کردن مقدار پیشش فرض دیت پیکر از میلادی به شمسی
     * @param {*} value
     */
    getPreSelected(value) {
        const { jalaliFormat, originalFormat } = this.props;
        return !_.isEmpty(value) ? moment(value, originalFormat).format(jalaliFormat).toString() : "";
    }

    /**
     * اعمال تغییرات
     */
    submit() {
        let { filterState } = this.state;

        let { resource_objects } = filterState;

        if (_.isArray(resource_objects) && !_.isEmpty(resource_objects)) {
            filterState.resource_objects = resource_objects.map((oItem) => {
                const objs = oItem.objs.map((oItemO) => {
                    delete oItemO.isAdded;
                    delete oItemO.isRemoved;
                    return oItemO;
                });
                return {
                    ...oItem,
                    objs,
                };
            });
        }

        filterState["resource_objects"] = resource_objects;

        const validate = dateValidate(filterState);

        if (validate === true) {
            // دخیره اطلاعات در ریداکس
            this.props.filterChange(filterState, true);
            // بستن ساید بار
            this.setVisible();
        } else {
            UiToast.error(validate, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        }
    }

    /**
     * نمایش کانفریم
     */
    // showPropsConfirm() {
    //     let globalThis = this;
    //     confirm({
    //         title: 'تنظیمات تمام قسمت های سامانه از این قسمت خوانده شود؟',
    //         okButtonProps: { shape: "round" },
    //         cancelButtonProps: { shape: "round" },
    //         closable: false,
    //         className: "apply-modal",
    //         onOk() {
    //             globalThis.submit(true);
    //         },
    //         onCancel() {
    //             globalThis.submit(false);
    //         },
    //     });
    // }

    /**
     * تغییر کلمات کلیدی
     */
    handleChange(value, name) {
        this.setState({
            filterState: {
                ...this.state.filterState,
                [name]: value,
            },
        });
    }

    /**
     * تغییر موجودیت
     */
    handleChangeExists(key, value) {
        this.setState({
            filterState: {
                ...this.state.filterState,
                [key]: value,
            },
        });
    }

    /**
     * تغییر رنج سنی
     */
    handleChangeRelationAges(keyMin, min, keyMax, max) {
        this.setState({
            filterState: {
                ...this.state.filterState,
                [keyMin]: min,
                [keyMax]: max,
            },
        });
    }

    /**
     * تغییر کلمات کلیدی
     */
    handleChangeQuery = (data) => {
        this.setState({
            filterState: {
                ...this.state.filterState,
                query: data.selectedKeyword,
                query_field: data,
            },
        });
    };

    /**
     * انتخاب رینج زمانی
     */
    handleRangeChange(timeDuration) {
        this.setState({
            filterState: {
                ...this.state.filterState,
                time_duration: timeDuration,
                from: "",
                to: "",
            },
        });
    }

    componentDidMount() {
        // گرفتن فیلترهای پیش‌فرض‌
        this.getFilters();

        // گرفتن لیست کلمات کلیدی
        this.props.updateRules(true);

        // گرفتن لیست دسته بندی ها
        this.props.updateTags(true);

        // دریافت لیست تگهای محتوا
        this.props.getTagsContent(true);
    }

    /**
     * گرفتن فیلترهای پیش‌فرض‌
     */
    getFilters() {
        // گرفتن مقادیر اولیه از لوکال استوریج
        const loginSetting =
            api.userInfo().user && api.userInfo().user.profile_data && api.userInfo().user.profile_data.global_settings
                ? api.userInfo().user.profile_data.global_settings
                : {};
        this.setState(
            {
                filterState: { ...loginSetting },
            },
            () => {
                this.props.filterChange(this.state.filterState, false);
            },
        );
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.globalFilter, prevProps.globalFilter)) {
            const temp = {
                filterState: {
                    ...this.props.globalFilter,
                },
            };
            this.setState(temp);
        }
    }

    /**
     * تغییر مقدار فورس  بودن تنظیمات
     */
    onChange(checked) {
        this.setState({
            filterState: {
                ...this.state.filterState,
                apply: checked,
            },
        });
    }

    /**
     * انتخاب منابع
     */
    getResources(sourceIds, flatIds, added, removed, Objcs) {
        let totalResourceCount = {};
        let selectedResourceCount = 0;
        let remainedObjs = [];
        let finalIds = [];

        //  بررسی برای آبجکت های موجود در لیست ریموو
        removed &&
            removed.forEach((rItem) => {
                // لوپ تو در تو با آبجکت های موجود در لیست آبجکت
                Objcs.forEach((oItem, index) => {
                    // در صورتی که هر دو آبجکت منابع برای یک پلتفرم باشد
                    if (rItem.platform === oItem.platform) {
                        // لیست جدید از آبجکت پلتفرم های منابع
                        remainedObjs = [];

                        // گشتن در آیدی های موجود در آبجکت بستر آبجکت
                        oItem.objs.forEach((oItemO, key) => {
                            // اگر آیدی موجود در این آبجکت با لیست آیدی های حذف شده در آبجکت ریموو با پلتفرم برابر بود
                            if (rItem["ids"].includes(oItemO["id"])) {
                                // حذف آیدی در لیست فلت آیدی در صورتی که جزو حذف شده ها باشد
                                const keyIndex = flatIds.findIndex((id) => id === oItemO["id"]);
                                keyIndex > -1 && flatIds.splice(keyIndex, 1);
                            }
                            // اگر آیدی جزو حذف شده ها نبود، اون منبع رو به لیست جدید اضافه کن
                            else {
                                remainedObjs.push(oItemO);
                            }
                        });

                        oItem.objs = remainedObjs;
                        // if (remainedObjs.length === 0) {
                        //     Objcs = Objcs.splice(index, 1)
                        // }
                    }
                });

                // حذف پلتفرم هایی که حاوی هیچ آیدی منبع نیستن
                Objcs = Objcs.filter((oItem) => oItem.objs.length > 0);

                sourceIds.forEach((sItem, key) => {
                    if (rItem.platform === sItem.platform) {
                        finalIds = [];

                        sItem.ids.forEach((sItemS, index) => {
                            if (rItem["ids"].includes(sItemS)) {
                                //delete sourceIds[key].ids[index]
                            } else {
                                finalIds.push(sItemS);
                            }
                        });

                        sourceIds[key].ids = finalIds;
                    }
                });

                // حذف پلتفرم هایی که حاوی هیچ آیدی منبع نیستن
                sourceIds = sourceIds.filter((oItem) => oItem.ids.length > 0);
            });

        Objcs &&
            Objcs.forEach((item) => {
                totalResourceCount[item.platform] = item.objs.length;
                selectedResourceCount = selectedResourceCount + item.objs.length;
            });

        let finalData = {
            resource_ids: flatIds,
            resource_objects: Objcs,
            resourceIdsDetails: sourceIds,
            totalResourceCount: Objcs ? totalResourceCount : undefined,
            selectedResourceCount,
        };

        if (Objcs.length === 0) {
            // finalData = {
            //     resource_ids: [],
            //     resource_objects: [],
            //     resourceIdsDetails: [],
            //     totalResourceCount: 0,
            //     selectedResourceCount: 0
            // }
        }

        this.setState({
            filterState: {
                ...this.state.filterState,
                ...finalData,
            },
        });
    }

    render() {
        return (
            <SideFilterView
                {...this.props}
                {...this.state}
                handleDatePicker={this.handleDatePicker}
                handleMultiSelect={this.handleMultiSelect}
                changePlatformParams={this.changePlatformParams}
                getPreSelected={this.getPreSelected}
                handleOk={this.handleOk}
                handleCancel={this.handleCancel}
                handleRangeChange={this.handleRangeChange}
                handleChange={this.handleChange}
                onChange={this.onChange}
                submit={this.submit}
                getResources={this.getResources}
                handleChangeExists={this.handleChangeExists}
                handleChangeRelationAges={this.handleChangeRelationAges}
                handleChangeQuery={this.handleChangeQuery}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        globalFilter: state.globalFilter,
        rules: state.rules,
        tags: state.tags,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterChange: (data, localFlag, apiFlag) => dispatch(filterChange(data, localFlag, apiFlag)),
        updateRules: (boolean) => dispatch(updateRules(boolean)),
        updateTags: (boolean) => dispatch(updateTags(boolean)),
        getTagsContent: (boolean) => dispatch(getTagsContent(boolean)),
    };
};

SideFilter.defaultProps = {
    hasCollapse: true,
    jalaliFormat: "jYYYY-jMM-jDD HH:mm",
    originalFormat: "YYYY-M-D HH:mm:ss",
};

export default connect(mapStateToProps, mapDispatchToProps)(SideFilter);
