import React, { createContext } from "react";
import PropTypes from "prop-types";
import "./UiRadioGroup.scss";
import UiRadio from "ui-components/ui-radio/UiRadio";

// ساخت کانتکست برای این که نگه داشتن استیت های هر رادیو
export const RadioContext = createContext(null);

const UiRadioGroup = ({ children, ...props }) => {
    const {
        options,
        onChange = () => {},
        mode = "horizontal",
        name,
        defaultValue,
        theme,
        className,
        size = "md",
        disabled = false,
        value
    } = props;

    const handleChange = (value) => {
        onChange(value);
    };

    const context = {
        handleChange,
        name: name,
        defaultValue: defaultValue,
        size: size,
        theme: theme,
        value: value,
    };

    return (
        <RadioContext.Provider value={context}>
            <div className={["ui-radio-group", mode, className].join(" ")}>
                {children
                    ? children
                    : options?.map((item, index) => {
                          return (
                              <UiRadio
                                  key={index}
                                  value={item?.value}
                                  name={name}
                                  size={size}
                                  theme={theme}
                                  disabled={disabled}
                                  defaultChecked={defaultValue === item?.value}
                              >
                                  {item?.label}
                              </UiRadio>
                          );
                      })}
            </div>
        </RadioContext.Provider>
    );
};

UiRadioGroup.propTypes = {
    children: PropTypes.node,
    options: PropTypes.array,
    selectedId: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    mode: PropTypes.oneOf(["horizontal", "vertical"]),
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
};

export default UiRadioGroup;
