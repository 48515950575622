import React from "react";
import { APIPathsOfPlatform } from "GlobalVariables";
import UserAccess from "shared/permission/UserAccess";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { UiSelect } from "ui-components";
import { img_marked_tag } from "ui-components/ui-images";

const ResourceFilterField = (props) => {
    const { platformParams, tags = [], apiPath, handleSelect, selectedTagId = "", fieldName = "tag_id",theme } = props;

    const resourcePlatforms =
        apiPath && APIPathsOfPlatform[apiPath] && APIPathsOfPlatform[apiPath].resources
            ? APIPathsOfPlatform[apiPath].resources
            : false;

    // فیلتر کردن تگ هایی که برای پلتفرم دریافتی ساخته شده است
    let platformTags = resourcePlatforms
        ? tags.filter((item) => {
              let find = false;

              Object.keys(item.platforms).forEach((pItem) => {
                  if (resourcePlatforms.includes(pItem) && item.platforms[pItem]) {
                      find = true;
                  }
              });

              return find;
          })
        : tags;

    if (platformTags) {
        platformTags.forEach((item) => {
            if (item.marked === 1) item["icon"] = img_marked_tag;
            item["value"] = item.id;
            item["label"] = item.name;
        });
    }

    const tempTagId = platformParams ? platformParams["tag_id"] : selectedTagId;

    const selected =
        !tempTagId || !tags.find((x) => x.id === tempTagId)
            ? undefined
            : tags.find((x) => x.id === tempTagId)
            ? tempTagId
            : undefined;

    return (
        <UserAccess key="tag" can="index" type="SearchTag">
            {/* تگ منابع در فیلتر های جستجو */}
            <UiSelect
                placeholder={<FormattedSimpleMsg id="global.select_tag" />}
                value={selected}
                onChange={(val) => handleSelect({ value: val }, fieldName)}
                list={platformTags}
                size="md"
                output="value"
                footer={
                    <a className="add" href="/tags/add" target="_blank">
                        <i className="icon icon-new"></i>
                        افزودن تگ منابع
                    </a>
                }
                theme={theme}
                className="force"
            />
        </UserAccess>
    );
};

export default ResourceFilterField;
