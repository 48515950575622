import React from "react";
import { Row, Col } from "antd";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";
import { UiMultiSelect } from "ui-components";
const MultiSelectFieldCustomData = (props) => {
    const { name, platformParams, handleMultiSelect, size, intl, data, isDisabled ,theme} = props;

    const { formatMessage } = intl;

    const options = data ? data : [];

    let selectedX =
        isEmpty(platformParams) || isEmpty(platformParams[name])
            ? []
            : options.filter((x) => platformParams[name].includes(x.value)).map((i) => i.value);

    if (isDisabled) {
        selectedX = [];
    }

    return (
        <Row key={name} align="middle" type="flex" style={{ opacity: isDisabled ? "0.5" : "1" }}>
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">{formatMessage({ id: `strings.${name}` })}</span>
            </Col>
            <Col lg={size === "md" ? 18 : 15} xl={size === "md" ? 18 : 17}>
                <UiMultiSelect
                    output="object"
                    onChange={(val) => handleMultiSelect(val, name)}
                    placeholder={formatMessage({ id: "filters.multi_select.not_have" })}
                    list={options}
                    value={selectedX}
                    withoutImage
                    disabled={isDisabled}
                    theme={theme}
                />
            </Col>
        </Row>
    );
};

export default injectIntl(MultiSelectFieldCustomData);
