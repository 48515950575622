import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import { api } from "services";
import UserAccess from "shared/permission/UserAccess";

const PrivacyPolicy = lazy(() => import("./components/privacy-policy/PrivacyPolicy"));
const Search = lazy(() => import("./components/search/Search"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const Result = lazy(() => import("./components/result/Result"));
const Entity = lazy(() => import("./components/search/entity/Entity"));
const ResetPassword = lazy(() => import("./components/user/reset-password/ResetPassword"));// lazy(() => import("./components/user/reset-password/ResetPassword"));
const Login = lazy(() => import("./components/user/login/Login"));

const MobileBulletin = lazy(() => import("./components/bulletin/mobile-bulletin/MobileBulletin"));
const Pdf = lazy(() => import("./components/bulletin/pdf/Pdf"));
const HtmlBulletin = lazy(() => import("./components/bulletin/html-bulletin/HtmlBulletin"));
const Cache = lazy(() => import("./components/bulletin/cache/Cache"));
const BulletinContent = lazy(() => import("./components/bulletin/content/list/BulletinContent"));
const EditContent = lazy(() => import("./components/bulletin/content/edit/EditContent"));
const Duplicate = lazy(() => import("./components/bulletin/content/list/duplicate/Duplicate"));

const BulletinsList = lazy(() => import("./components/bulletin/list/BulletinsList"));
const PublicSettings = lazy(() => import("./components/bulletin/settings/public-settings/PublicSettings"));
const BulletinsStatisticsChartsSettings = lazy(() =>
    import("./components/bulletin/settings/statistics-charts/StatisticsChartsSettings"),
);
const BulletinType = lazy(() => import("./components/bulletin/type/list/BulletinType"));
const NetworkList = lazy(() => import("./components/graph/network/list/NetworkList"));

const ChannelList = lazy(() => import("./components/telegram-bot/channel-list/list/ChannelList"));
const ContactList = lazy(() => import("./components/telegram-bot/contact-list/list/ContactList"));

const ArchivesList = lazy(() => import("./components/bulletin/archive/ArchivesList"));

const BoxesList = lazy(() => import("./components/boxes/list/BoxesList"));
const BoxGenerator = lazy(() => import("./components/boxes/add/Generator"));
const BoxesTest = lazy(() => import("./components/boxes/Test"));

const AnalyzerList = lazy(() => import("./components/analyzer/list/AnalyzerList"));
const AddAnalyzer = lazy(() => import("./components/analyzer/add/AddAnalyzer"));
const AnalyzerTest = lazy(() => import("./components/analyzer/Test"));
const AnalyzerDashboard = lazy(() => import("./components/analyzer/dashboard/AnalyzerDashboard"));

const TagCategoryList = lazy(() => import("./components/tag-category/list/TagCategoryList"));
const AddTagCategory = lazy(() => import("./components/tag-category/add/AddTagCategory"));
const TagList = lazy(() => import("./components/tag-category/tag/list/TagList"));
const AddTag = lazy(() => import("./components/tag-category/tag/add/AddTag"));
const ScheduleList = lazy(() => import("./components/schedule-list/ScheduleList"));

const SourceCategory = lazy(() => import("./components/source-category/add/SourceCategory"));
const Department = lazy(() => import("./components/department/add/Department"));
const SourceCategoryList = lazy(() => import("./components/source-category/list/SourceCategoryList"));
const TagsRegister = lazy(() => import("./components/tags/add/TagsRegister"));
const TagsList = lazy(() => import("./components/tags/list/TagsList"));
const MyTeamsAdd = lazy(() => import("./components/my-teams/add/MyTeamsAdd"));
const MyTeamsList = lazy(() => import("./components/my-teams/list/MyTeamsList"));
const AddTarget = lazy(() => import("./components/target/add/AddTarget"));
const TargetList = lazy(() => import("./components/target/list/TargetList"));
const Details = lazy(() => import("./components/target/details/Details"));
const ObjectiveTargetDetails = lazy(() => import("./components/objective-target/details/ObjectiveTargetDetails"));
const AddObjectiveTarget = lazy(() => import("./components/objective-target/add/AddObjectiveTarget"));
const ObjectiveTargetList = lazy(() => import("./components/objective-target/list/ObjectiveTargetList"));
const DepartmentList = lazy(() => import("./components/department/list/List"));
const GrandientAreaMap = lazy(() => import("shared/chart/AreaChart/GrandientAreaMap/GrandientAreaMap"));
const DonutChart = lazy(() => import("shared/chart/DonutChart/DonutChart"));
const Test = lazy(() => import("./components/test/Test"));
const Page404 = lazy(() => import("./components/page404/Page404"));
const CommingSoon = lazy(() => import("./comming-soon/CommingSoon"));
const NotAccessPageView = lazy(() => import("shared/permission/NotAccessPageView"));
const DashboardList = lazy(() => import("./components/dashboard/list/Dashboard"));
const DashboardAdd = lazy(() => import("./components/dashboard/add/Dashboard"));
const QueryRulesManagement = lazy(() => import("./components/query-rules/QueryRulesManagement"));
const QueryFilesManagement = lazy(() => import("./components/query-files/QueryFilesManagement"));
const AddBulletin = lazy(() => import("./components/bulletin/add/AddBulletin"));
const AddBulletinType = lazy(() => import("./components/bulletin/type/add/AddBulletinType"));
const AddUser = lazy(() => import("./components/users/add/AddUser"));
const UsersList = lazy(() => import("./components/users/list/UsersList"));

const VersionManagementList = lazy(()=> import("./components/version-management/list/VersionManagementList"));
const AddVersionManagement = lazy(()=> import("./components/version-management/add/AddVersionManagement"))

const AddAlarm = lazy(() => import("./components/alarm/add/AddAlarm"));
const AddWaveAlarm = lazy(() => import("./components/alarm/add-wave/AddWaveAlarm"));
const AlarmList = lazy(() => import("./components/alarm/list/AlarmList"));

const AddNetwork = lazy(() => import("./components/graph/network/add/AddNetwork"));

const AddChannel = lazy(() => import("./components/telegram-bot/channel-list/add/AddChannel"));
const AddContact = lazy(() => import("./components/telegram-bot/contact-list/add/AddContact"));

const Kiosk = lazy(() => import("./components/kiosk/list/Kiosk"));

const Arcpolar = lazy(() => import("shared/chart/polar/PolarChart"));
const LargeScaleAreaMap = lazy(() => import("shared/chart/largeScaleArea/LargeScaleAreaMap"));
const QueryBuilder = lazy(() => import("shared/queryBuilder/QueryBuilder"));
const ContentProfileStructure = lazy(() => import("./components/profile/content/ProfileStructure"));
const EntityProfileStructure = lazy(() => import("./components/profile/entity/ProfileStructure"));
const EntitiesTagProfileStructure = lazy(() => import("./components/profile/entities-tag/ProfileStructure"));
const SubjectProfileStructure = lazy(() => import("./components/profile/subject/ProfileStructure"));
const BoxWidget = lazy(() => import("shared/widget-builder/Widget"));
const ProfileChache = lazy(() => import("./components/profile/chache/ProfileStructure"));

const NetworkGraph = lazy(() => import("shared/network-graph/NetworkGraph"));

const Planning = lazy(() => import("./components/planning/Planning"));
const UserProfile = lazy(() => import("./components/user/profile/Profile"));
const UserProfileAccounts = lazy(() => import("./components/user/profile/accounts/Accounts"));
const UserProfileMessages = lazy(() => import("./components/user/profile/messages/Messages"));
const UserProfileFavorites = lazy(() => import("./components/user/profile/favorites/Favorites"));
const UserProfileSettings = lazy(() => import("./components/user/profile/settings/Settings"));

const Assignment = lazy(() => import("./components/assignment/Assignment"));
const Direct = lazy(() => import("./components/direct/Direct"));
const Comparison = lazy(() => import("./components/comparison/Comparison"));
const LandingPage = lazy(() => import("./components/landing-page/LandingPage"));
// Social add account callback
const SocialCallback = lazy(() => import("./components/user/auth/SocialCallback"));
const SNTDashboard = lazy(() => import("./components/social-network/telegram/dashboard/SNTDashboard"));
const SNTAdd = lazy(() => import("./components/social-network/telegram/add/SNTAdd"));
const SNTList = lazy(() => import("./components/social-network/telegram/list/SNTList"));
const SNTOfficeList = lazy(() => import("./components/social-network/telegram/office/list/SNTOfficeList"));
const SNTOfficeAdd = lazy(() => import("./components/social-network/telegram/office/add/SNTOfficeAdd"));


const RestrictedRoute = ({ can, AccessType, component: Component, props, ...rest }) => (
    <Route
        render={(rootProps) => (
            <UserAccess can={can} type={AccessType} showMessage messageType="full-page">
                <Component {...props} {...rootProps} />
            </UserAccess>
        )}
        {...rest}
    />
);
const redirectToLogin = (location) => {
    return <Redirect push={location.pathname} to="/login" />;
};
const CustomRouter = (props) => {
    const { location } = props;
    if (location.pathname !== "/login") {
        localStorage.setItem("latestPath", location.pathname);
    }
    // const cacheUrls = ["/bulletin/cache/","/profile/cache/"]

    const userInfo = api.userInfo();
    const departmentName = userInfo && userInfo.user.department.department_name;
    const departmentAccess = departmentName === "گراف" || departmentName === "سلمان اوسینت" ? true : false;
    const accessEmail = [
        "amiresmaiil@yahoo.com",
        "darbandi.craft@gmail.com",
        "goodarzi@dataak.com",
        "h.goudarzi@dataak.com",
        "meysam@dataak.com",
        "meisam@dataak.com",
    ];
    return (
        <>
            {/* <Redirect push to="/comming-soon" /> */}

            {/* اگر در صفحه کش بولتن بود */}
            {!api.isAuthorize() && location.pathname.includes("/bulletin/cache/") && (
                <Route exact path="/bulletin/cache/:hash" render={(props) => <Cache {...props} />} />
            )}

            {api.isAuthorize() && location.pathname.includes("/bulletin/cache/") && (
                <Route exact path="/bulletin/cache/:hash" render={(props) => <Cache {...props} />} />
            )}

            {/* اگر در صفحه کش پروفایل بود */}
            {!api.isAuthorize() && location.pathname.includes("/profile/cache/") && (
                <Route exact path="/profile/cache/:id" render={(props) => <ProfileChache {...props} />} />
            )}

            {api.isAuthorize() && location.pathname.includes("/profile/cache/") && (
                <Route exact path="/profile/cache/:id" render={(props) => <ProfileChache {...props} />} />
            )}

            <Route exact path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />

            {/* اگر لاگین نبود 
            و اگر در صفحه های کش پروفایل و بولتن نبود
            */}
            {!api.isAuthorize() &&
                !location.pathname.includes("/bulletin/cache/") &&
                !location.pathname.includes("/profile/cache/") &&
                !location.pathname.includes("/privacy-policy") &&
                redirectToLogin(location)}

            <Route exact path="/" render={(props) => <Search {...props} />} />
            <Route exact path="/searchplatform" render={(props) => <Search {...props} />} />

            <Route exact path="/search/result" render={(props) => <Result {...props} />} />
            <Route exact path="/search/entity" render={(props) => <Entity {...props} />} />
            <Route exact path="/login" render={(props) => <Login {...props} />} />
            <Route exact path="/reset-password" render={(props)=><ResetPassword {...props} /> } />
            <Route exact path="/test" render={(props) => <Test {...props} />} />
            <Route exact path="/404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/comming-soon" render={(props) => <CommingSoon {...props} />} />
            <Route exact path="/not-access" render={(props) => <NotAccessPageView {...props} />} />
            <RestrictedRoute
                exact
                path="/boxes"
                render={(props) => <BoxesList {...props} />}
                can="index"
                AccessType="BoxGenerator"
            />
            <RestrictedRoute
                exact
                path="/boxes/add"
                render={(props) => <BoxGenerator {...props} />}
                can="store"
                AccessType="BoxGenerator"
            />
            <RestrictedRoute
                exact
                path="/boxes/edit/:id"
                render={(props) => <BoxGenerator {...props} />}
                can="update"
                AccessType="BoxGenerator"
            />
            <Route exact path="/boxes/test" render={(props) => <BoxesTest {...props} />} />

            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/analyzer/list"
                render={(props) => <AnalyzerList {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/analyzer/:type/add"
                render={(props) => <AddAnalyzer action="add" {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/analyzer/:id/:type/edit"
                render={(props) => <AddAnalyzer action="edit" {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/analyzer/test"
                render={(props) => <AnalyzerTest {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/bulletin/:id/analyzer"
                render={(props) => <AnalyzerDashboard {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="BulletinTypes"
                exact
                path="/BulletinType/list"
                render={(props) => <BulletinType {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="BulletinTypes"
                exact
                path="/bulletin/type/add"
                render={(props) => <AddBulletinType {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="BulletinTypes"
                exact
                path="/bulletin/type/:id/edit"
                render={(props) => <AddBulletinType action="edit" {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="Graph"
                exact
                path="/graph/networks"
                render={(props) => <NetworkList {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="Graph"
                exact
                path="/graph/network/add"
                render={(props) => <AddNetwork {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="Graph"
                exact
                path="/graph/network/:id/edit"
                render={(props) => <AddNetwork action="edit" {...props} />}
            />

            <Route exact path="/telegram-bot/channel/list" render={(props) => <ChannelList {...props} />} />
            <Route exact path="/telegram-bot/channel/add" render={(props) => <AddChannel {...props} />} />
            <Route
                exact
                path="/telegram-bot/channel/:id/edit"
                render={(props) => <AddChannel action="edit" {...props} />}
            />

            <Route exact path="/bulletin/:id/html" render={(props) => <HtmlBulletin {...props} />} />
            <Route exact path="/bulletin/:id/mobile" render={(props) => <MobileBulletin {...props} />} />
            <Route exact path="/bulletin/:id/pdf/:bulletinTitle" render={(props) => <Pdf {...props} />} />

            <Route exact path="/telegram-bot/contact/list" render={(props) => <ContactList {...props} />} />
            <Route exact path="/telegram-bot/contact/add" render={(props) => <AddContact {...props} />} />
            <Route
                exact
                path="/telegram-bot/contact/:id/edit"
                render={(props) => <AddContact action="edit" {...props} />}
            />

            <Route
                exact
                path="/bulletin/:id/content-duplicate/:platform"
                render={(props) => <Duplicate {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/archives"
                render={(props) => <ArchivesList {...props} />}
            />

            <RestrictedRoute
                can="contentsOfBulletin"
                AccessType="Bulletin"
                exact
                path="/bulletin/:id/content"
                render={(props) => <BulletinContent {...props} />}
            />
            <RestrictedRoute
                can="contentsOfBulletin"
                AccessType="Bulletin"
                exact
                path="/bulletin/:bulletinId/content/:id/:selectedPlatform"
                render={(props) => <EditContent action="edit" {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/bulletins"
                render={(props) => <BulletinsList {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="Bulletin"
                exact
                path="/bulletin/add"
                render={(props) => <AddBulletin {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="Bulletin"
                exact
                path="/bulletin/:id/edit"
                render={(props) => <AddBulletin action="edit" {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/bulletins/settings/public-settings"
                render={(props) => <PublicSettings {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Bulletin"
                exact
                path="/bulletins/settings/statistics-charts"
                render={(props) => <BulletinsStatisticsChartsSettings {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="QueryRules"
                exact
                path="/query-rules"
                render={(props) => <QueryRulesManagement {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="QueryRules"
                exact
                path="/query-rules/add"
                render={(props) => <QueryRulesManagement {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="QueryRules"
                exact
                path="/query-rules/edit/:id"
                render={(props) => <QueryRulesManagement {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="QueryFiles"
                exact
                path="/query-files"
                render={(props) => <QueryFilesManagement {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="QueryFiles"
                exact
                path="/query-files/add"
                render={(props) => <QueryFilesManagement {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="QueryFiless"
                exact
                path="/query-files/edit/:id"
                render={(props) => <QueryFilesManagement {...props} />}
            />

            <Route exact path="/query-files/single/:id" render={(props) => <QueryFilesManagement {...props} />} />

            <RestrictedRoute
                can="store"
                AccessType="SearchCategory"
                exact
                path="/source-category/add"
                render={(props) => <SourceCategory {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="SearchCategory"
                exact
                path="/source-categories"
                render={(props) => <SourceCategoryList {...props} />}
            />
            <Route
                exact
                path="/source-category/:id/add"
                render={(props) => <SourceCategory action="add" {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="SearchCategory"
                exact
                path="/source-category/:id/edit"
                render={(props) => <SourceCategory action="edit" {...props} />}
            />

            <RestrictedRoute
                can="store"
                AccessType="SearchTag"
                exact
                path="/tags/add"
                render={(props) => <TagsRegister {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="SearchTag"
                exact
                path="/tags"
                render={(props) => <TagsList {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="SearchTag"
                exact
                path="/tags/edit/:id"
                render={(props) => <TagsRegister action="edit" {...props} />}
            />

            <RestrictedRoute
                can="store"
                AccessType="SearchTag"
                exact
                path="/myTeams/add"
                render={(props) => <MyTeamsAdd {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="SearchTag"
                exact
                path="/myTeams"
                render={(props) => <MyTeamsList {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="SearchTag"
                exact
                path="/myTeams/edit/:id"
                render={(props) => <MyTeamsAdd action="edit" {...props} />}
            />

            <Route path="/source-category/list" render={(props) => <SourceCategoryList {...props} />} />

            <RestrictedRoute
                can="index"
                AccessType="Departments"
                path="/departments"
                render={(props) => <DepartmentList {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="Departments"
                exact
                path="/department/add"
                render={(props) => <Department action="add" {...props} />}
            />
            <Route
                exact
                path="/department/:id/add"
                render={(props) => <Department action="addChildren" {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="Departments"
                exact
                path="/department/:id/edit"
                render={(props) => <Department action="edit" {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="User"
                exact
                path="/users"
                render={(props) => <UsersList {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="User"
                exact
                path="/users/add"
                render={(props) => <AddUser {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="User"
                exact
                path="/users/:id/edit"
                render={(props) => <AddUser action="edit" {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="Version"
                exact
                path="/version/:platform"
                render={(props) => <VersionManagementList {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="Version"
                exact
                path="/version/edit/:id"
                render={(props) => <AddVersionManagement {...props} action={"edit"} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="Version"
                exact
                path="/version/add/:platform"
                render={(props) => <AddVersionManagement {...props} action={"add"} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="AlarmStory"
                exact
                path="/alarms"
                render={(props) => <AlarmList {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="AlarmStory"
                exact
                path="/alarm/add"
                render={(props) => <AddAlarm {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="AlarmStory"
                exact
                path="/alarm/:id/edit"
                render={(props) => <AddAlarm action="edit" {...props} />}
            />
            <RestrictedRoute
                can="store"
                AccessType="AlarmStory"
                exact
                path="/alarm/wave/add"
                render={(props) => <AddWaveAlarm {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="AlarmStory"
                exact
                path="/alarm/wave/:id/edit"
                render={(props) => <AddWaveAlarm action="edit" {...props} />}
            />

            {/*alireza test for charts*/}
            <Route exact path="/polar" render={(props) => <Arcpolar {...props} />} />
            <Route exact path="/largescaleareamap" render={(props) => <LargeScaleAreaMap {...props} />} />
            {/*<Route exact path="/query-builder" render={(props) => <QueryBuilder />} />*/}
            <Route
                exact
                path="/profile/entity/:platform/:id"
                render={(props) => <EntityProfileStructure {...props} />}
            />
            <Route
                exact
                path="/profile/entities-tag/:tagID"
                render={(props) => <EntitiesTagProfileStructure {...props} />}
            />
            <Route
                exact
                path="/profile/content/:platform/:id"
                render={(props) => <ContentProfileStructure {...props} />}
            />
            <Route
                exact
                path="/profile/subject/:input"
                render={(props) => <SubjectProfileStructure type="text" {...props} />}
            />
            <Route
                exact
                path="/profile/subject"
                render={(props) => <SubjectProfileStructure type="hashtag" {...props} />}
            />
            <Route
                exact
                path="/profile/subject/query/:input"
                render={(props) => <SubjectProfileStructure type="query" {...props} />}
            />
            <Route exact path="/box-widget" render={(props) => <BoxWidget {...props} />} />

            <Route exact path="/target" render={(props) => <TargetList {...props} />} />
            <Route exact path="/target/:id/details" render={(props) => <Details {...props} />} />
            <Route exact path="/target/add" render={(props) => <AddTarget {...props} />} />

            <Route exact path="/objective-target/add" render={(props) => <AddObjectiveTarget {...props} />} />
            <Route exact path="/objective-target" render={(props) => <ObjectiveTargetList {...props} />} />
            <Route
                exact
                path="/objective-target/edit/:id"
                render={(props) => <AddObjectiveTarget action="edit" {...props} />}
            />
            <Route
                exact
                path="/objective-target/:id/details"
                render={(props) => <ObjectiveTargetDetails {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="Dashboard"
                exact
                path="/dashboards"
                render={(props) => <DashboardList {...props} />}
            />
            <Route exact path="/dashboard" render={(props) => <Dashboard {...props} />} />
            <RestrictedRoute
                can="store"
                AccessType="Dashboard"
                exact
                path="/dashboard/add"
                render={(props) => <DashboardAdd {...props} />}
            />
            <RestrictedRoute
                can="index"
                AccessType="Dashboard"
                exact
                path="/dashboard/:id/preview"
                render={(props) => <Dashboard {...props} />}
            />
            <RestrictedRoute
                can="update"
                AccessType="Dashboard"
                exact
                path="/dashboard/:id/edit"
                render={(props) => <DashboardAdd action="edit" {...props} />}
            />

            <RestrictedRoute
                can="index"
                AccessType="kiosk"
                exact
                path="/kiosk"
                render={(props) => <Kiosk {...props} />}
            />

            <Route
                exact
                path="/network/:id"
                render={(props) => {
                    if (accessEmail.includes(userInfo.user.email) || departmentAccess) {
                        return <NetworkGraph {...props} />;
                    } else {
                        return <Redirect push to="/searchplatform" />;
                    }
                }}
            />

            <Route
                exact
                path="/network/:id/cache/:cacheId"
                render={(props) => {
                    if (accessEmail.includes(userInfo.user.email) || departmentAccess) {
                        return <NetworkGraph {...props} />;
                    } else {
                        return <Redirect push to="/searchplatform" />;
                    }
                }}
            />
            <Route
                exact
                path="/network"
                render={(props) => {
                    if (accessEmail.includes(userInfo.user.email) || departmentAccess) {
                        return <NetworkGraph {...props} />;
                    } else {
                        return <Redirect push to="/searchplatform" />;
                    }
                }}
            />

            <Route exact path="/planning" render={(props) => <Planning {...props} />} />
            <Route exact path="/planning/:id" render={(props) => <Planning {...props} />} />

            {/* User  */}
            <Route exact path="/user/profile" render={(props) => <UserProfile {...props} />} />
            <Route exact path="/user/profile/settings" render={(props) => <UserProfileSettings {...props} />} />
            <Route exact path="/user/profile/messages" render={(props) => <UserProfileMessages {...props} />} />
            <Route exact path="/user/profile/favorites" render={(props) => <UserProfileFavorites {...props} />} />
            <Route exact path="/user/profile/accounts" render={(props) => <UserProfileAccounts {...props} />} />

            <Route exact path="/users/auth/:socialName" render={(props) => <SocialCallback {...props} />} />

            <Route exact path="/assignment" render={(props) => <Assignment {...props} />} />
            <Route exact path="/assignment/:id" render={(props) => <Assignment {...props} />} />

            <Route exact path="/direct" render={(props) => <Direct {...props} />} />
            <Route exact path="/direct/:id" render={(props) => <Direct {...props} />} />

            {/* Should add permision */}
            <Route exact path="/comparison" render={(props) => <Comparison {...props} />} />
            <Route exact path="/comparison/:id" render={(props) => <Comparison {...props} action="edit" />} />
            <RestrictedRoute exact path="/schedule/posts" render={(props) => <ScheduleList {...props} />} />
            <RestrictedRoute exact path="/tag-categories" render={(props) => <TagCategoryList {...props} />} />
            <RestrictedRoute
                exact
                path="/tag-category/edit/:id"
                render={(props) => <AddTagCategory {...props} action={"edit"} />}
            />
            <RestrictedRoute
                exact
                path="/tag-category/add"
                render={(props) => <AddTagCategory {...props} action={"add"} />}
            />
            <RestrictedRoute
                exact
                path="/tag-category/:categoryId/tags/:categoryTitle"
                render={(props) => <TagList {...props} action={"add"} />}
            />
            <RestrictedRoute
                exact
                path="/tag-category/:categoryId/tag/:id/edit"
                render={(props) => <AddTag {...props} action={"edit"} />}
            />
            <RestrictedRoute
                exact
                path="/tag-category/:categoryId/:categoryTitle/tag/add"
                render={(props) => <AddTag {...props} action={"add"} />}
            />
            <RestrictedRoute exact path="/landing" render={(props) => <LandingPage {...props} />} />
            {/* social-network */}
            <RestrictedRoute exact path="/social-network/telegram/dashboard" render={(props) => <SNTDashboard {...props} />} />
            <RestrictedRoute exact path="/social-network/telegram" render={(props) => <SNTList {...props} />} />
            <RestrictedRoute exact path="/social-network/telegram/add" render={(props) => <SNTAdd {...props} action={"add"} />} />
            <RestrictedRoute exact path="/social-network/telegram/:id/edit" render={(props) => <SNTAdd {...props} action={"edit"}/>} />
            <RestrictedRoute exact path="/social-network/telegram/offices" render={(props) => <SNTOfficeList {...props} />} />
            <RestrictedRoute exact path="/social-network/telegram/office/add" render={(props) => <SNTOfficeAdd {...props} action={"add"}/>} />
            <RestrictedRoute exact path="/social-network/telegram/office/:id/edit" render={(props) => <SNTOfficeAdd {...props} action={"edit"} />} />
        </>
    );
};

export default CustomRouter;
