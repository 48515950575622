import React, { Component } from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { UiCheckbox, UiCheckboxGroup } from "ui-components";

// کامپوننت لیست چک باکس
class CheckListField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemList: [], // آیتم های قابل چک زدن
            checkedList: [], // آیتم های چک زده شده
            indeterminate: false, // تعدادی از آِتم ها چک زده شده هستند
            checkAll: false, // همه آیتم ها چک زده شده هستند
        };

        this.onChange = this.onChange.bind(this);
        this.onCheckAllChange = this.onCheckAllChange.bind(this);
    }

    // در زمان مونت شدنت، لیست چک شده و لیست آیتم های چک لیست در استیت ذخیره میشوند.
    componentDidMount() {
        const { platformParams, name, item } = this.props;

        // اگر لیست پارامترها تودرتو بود، داخلی را انتخاب کند.
        const itemList = item.items && _.isArray(item.items.enum) ? item.items.enum : item.enum;

        this.setState({
            itemList: itemList,
            checkedList: platformParams[name] || [],
            indeterminate:
                !!platformParams[name] && platformParams[name].length && platformParams[name].length < itemList.length
                    ? true
                    : false,
            checkAll:
                !!platformParams[name] && platformParams[name].length && platformParams[name].length === itemList.length
                    ? true
                    : false,
        });
    }

    componentDidUpdate(prevProps) {
        const { platformParams, name } = this.props;

        // درصورت تغییر کردن لیست چک زده شده ها
        if (!_.isEqual(prevProps, this.props)) {
            this.setState({
                checkedList: platformParams[name] || [],
            });
        }
    }

    // تغییر در چک باکس
    onChange = (checkedList) => {
        const { handleCheckList, name } = this.props;

        const { itemList } = this.state;

        this.setState(
            {
                checkedList,
                indeterminate: !!checkedList.length && checkedList.length < itemList.length,
                checkAll: checkedList.length === itemList.length,
            },
            () => handleCheckList(this.state.checkedList, name),
        );
    };

    // انتخاب همه آیتم ها
    onCheckAllChange = (checked) => {
        const { handleCheckList, name } = this.props;

        const { itemList } = this.state;

        this.setState(
            {
                checkedList: checked ? itemList : [],
                indeterminate: false,
                checkAll: checked,
            },
            () => handleCheckList(this.state.checkedList, name),
        );
    };

    render() {
        const { itemList, checkedList, size } = this.state;
        const { name, intl, typeDescriptionFilter, theme } = this.props;

        const { formatMessage } = intl;
        const itemListOptions = itemList.map((value) => {
            return { value: value, label: formatMessage({ id: `strings.${value}` }) };
        });

        return (
            <Row key={name} align="middle" type="flex" className="checklist-field">
                <Col lg={6} xl={size === "md" ? 6 : 4}>
                    <span className="row-label">
                        {formatMessage({ id: `strings.${name}` })}
                        {typeDescriptionFilter}
                    </span>
                </Col>
                <Col lg={18} xl={size === "md" ? 18 : 20}>
                    <UiCheckbox
                        theme={theme}
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        همه
                    </UiCheckbox>

                    <UiCheckboxGroup
                        theme={theme}
                        output="value"
                        list={itemListOptions}
                        className="ant-row"
                        value={checkedList}
                        onChange={this.onChange}
                    />
                </Col>
            </Row>
        );
    }
}
export default injectIntl(CheckListField);
