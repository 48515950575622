import React from "react";
import { root } from "GlobalVariables";
import UserAccess from "../../permission/UserAccess";
import { FormattedSimpleMsg } from "GlobalFunctions";
import Loadings from "../../loadings/Loadings";
import { UiButton } from "ui-components";

const BulletinNotification = ({ itemData, removeNotification, notifId, loading = { [notifId]: false } }) => {
    const url = `${root}/bulletin/v2/export/download?file_name=${itemData.file_name}`;

    const { status, title, percent } = itemData;

    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className={`download-status-box ${(status === 3 || status === 5) && "canceled"}`}>
                <div className="progress-header">
                    <i className="icon icon-pdf" />

                    <div className="text">{title}</div>
                    {notifId && (
                        <UiButton
                            type="icon"
                            size="xxs"
                            className="icon icon-close-icon "
                            onClick={() => {
                                removeNotification(notifId);
                            }}
                        />
                    )}
                </div>

                <div className="progress-wrapper">
                    <div className="progress-bg">
                        {/* نمایش مستطیل پیشرفت پروگرس بار
                    در حالت 1 برابر 1% و
                    در حالت 2-3-5 عدد واقعی و
                    در حالت 4 - 100 درصد است*/}

                        {status === 1 && <div className="progress-fg" style={{ width: "1%" }} />}

                        {(status === 2 || status === 3 || status === 5) && (
                            <div
                                className={status === 3 || status === 5 ? "progress-fg grey" : "progress-fg"}
                                style={{ width: percent ? percent + "%" : "0%" }}
                            />
                        )}

                        {status === 4 && <div className="progress-fg full" />}
                    </div>

                    <span>
                        {/* نمایش درصد پروگرس بار
                    در حالت 1 خالی و
                    در حالت 2-3-5 عدد واقعی و
                    در حالت 4 - 100 درصد است*/}
                        {percent ? Math.round(percent) + "%" : "0%"}
                    </span>
                </div>

                <div className="progress-footer">
                    <div className="text">
                        {(status === 1 || status === 2) && (
                            <p>
                                <FormattedSimpleMsg id="notification.bulletin.in_progress" />
                            </p>
                        )}

                        {status === 3 && (
                            <p>
                                <FormattedSimpleMsg id="notification.bulletin.error" />
                            </p>
                        )}

                        {status === 4 && (
                            <p>
                                <FormattedSimpleMsg id="notification.bulletin.finish" />
                            </p>
                        )}

                        {status === 5 && (
                            <p>
                                <FormattedSimpleMsg id="notification.bulletin.canceled" />
                            </p>
                        )}
                    </div>

                    <div className="actions">
                        {/* نمایش لینک دانلود برای وضعیت 4 و امکان کنسل برای وضعیت 1 یا 2 */}

                        {status === 4 && (
                            <UserAccess can="cancel" type="Excel">
                                <UiButton
                                    type="icon"
                                    size="xxs"
                                    target="_blank"
                                    href={url}
                                    className="icon icon-import"
                                />
                                <span className="actions-text noti-dl-compl">
                                    <FormattedSimpleMsg id="notification.bulletin.download" />
                                </span>
                            </UserAccess>
                        )}

                        {/* {(status === 1 || status === 2) && (
                        <UserAccess can="download" type="Excel"> */}

                        {/* <span className="actions-text noti-dl-cancel">
                                <FormattedSimpleMsg id="notification.bulletin.cancel" />
                            </span> */}
                        {/* </UserAccess> */}
                        {/* )} */}

                        {status === 3 && (
                            <UserAccess can="download" type="Excel">
                                <span className="actions-text noti-dl-reload">
                                    <FormattedSimpleMsg id="notification.bulletin.error" />
                                </span>
                            </UserAccess>
                        )}

                        {status === 5 && (
                            <UserAccess can="download" type="Excel">
                                <span className="actions-text noti-dl-reload">
                                    <FormattedSimpleMsg id="notification.bulletin.retry" />
                                </span>
                            </UserAccess>
                        )}
                    </div>
                </div>
            </div>
        </Loadings>
    );
};

export default BulletinNotification;
