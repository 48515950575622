import React from "react";
import { WindowLocation } from "GlobalVariables";
import CustomCol from './CustomCol';
import { filterChange } from '../actions/globalFilterAction';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { api } from "services";
import AppliedFilters from "./filters/AppliedFilters";
import _ from "lodash";
import UserAvatar from "./user-avatar/UserAvatar";
import GlobalKeywordsFilter from "./filters/fields/custom/GlobalKeywordsFilter"
import { DomainSettings } from "../DomainSettings";
import UserAccess from "shared/permission/UserAccess";
import { UiPopover, UiToast  ,UiButton } from "ui-components";

const confirm = Modal.confirm

let userInfo = api.userInfo();

let userAvatar = userInfo ? userInfo.user.avatar : "";

let userName = userInfo ? (userInfo.user.name + " " + userInfo.user.last_name) : "وارد شوید";

class AppHeader extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            reloadProfilePopup: false
        }

        this.remove = this.remove.bind(this);
        this.logOut = this.logOut.bind(this);
        this.content = this.content.bind(this);
        this.resetAllGlobalFilters = this.resetAllGlobalFilters.bind(this);
        this.fetahNotifications = this.fetahNotifications.bind(this);
        this.seenNotifications = this.seenNotifications.bind(this);  //نوتیفیکیشن های خوانده شده
    }

    /**
    * خروج
    */
    logOut() {

        const avatar = localStorage.getItem("avatar");
        const userName = localStorage.getItem("userName");
        localStorage.clear();
        localStorage.setItem("avatar", avatar);
        localStorage.setItem("userName", userName);
        window.location.href = "/login";
    }

    componentDidMount() {

        const to = Math.round(Date.now() / 1000);

        const days = 30;

        const from = to - days * 24 * 60 * 60;

        // یک بازه یک ماهه بصورت یونیکس
        const params = {
            from,
            to,
        }

        this.fetahNotifications(params);
    }

    fetahNotifications(params) {

        api.get("/user/notifications", params)
            .then((result) => result.data.result)
            .then((result) => {

                const notifNo = result.meta.unseen;

                localStorage.setItem('notification', JSON.stringify(result.data))

                localStorage.setItem('notificationNo', notifNo)

                this.setState({
                    notifNo
                })
            });
    }

    content() {

        return (

            <div className="user" >

                <UserAvatar cache={true} size={100} className="avatar" /> <br />

                <p className="user-name">{userName}</p>

                {UserAccess.can("userProfileSocialActions", "FrontEnd") &&

                    <Link
                        to="/user/profile/accounts"
                        className="button profile" >

                        <i className="icon icon-profile" ></i>

                        <label>پروفایل</label>

                    </Link>

                }

                <button className="button log-out" onClick={this.logOut} selenium-id="logout">

                    <i className="icon icon-sign-out" ></i>

                    <label>خروج از حساب</label>

                </button>

            </div>
        )
    }

    setVisible(type) {

        this.props.setVisible(type)

        if (type == "download") {

            document.querySelector('.notification--icons').classList.remove('has-new');

            this.seenNotifications();
        }
    }

    /**
    * نوتیفیکیشن های خوانده شده
    */
    seenNotifications() {

        this.notifNo = parseInt(localStorage.getItem("notificationNo"))
        if (this.notifNo <= 0) return;

        const params = {
            all: 1
        };

        api.patch("/user/notifications", params)
            .then((result) => {

                localStorage.setItem("notificationNo", 0);

                this.setState({
                    notifNo: 0
                })

                const notif = JSON.parse(localStorage.getItem('notification'))

                notif.map((item) => {
                    item.status = 1;
                })

                localStorage.setItem('notification', JSON.stringify(notif))

            }).catch((error) => {
                UiToast.exceptions(error);
            })
    }

    componentDidUpdate(e, a) {

        let userInfo = api.userInfo();

        const { reloadProfilePopup } = this.state;

        if (userInfo && !reloadProfilePopup) {

            userAvatar = userInfo ? userInfo.user.avatar : "";

            userName = userInfo ? (userInfo.user.name + " " + userInfo.user.last_name) : "وارد شوید";

            this.setState({
                reloadProfilePopup: true
            })

        }
    }

	/**
	 * types:
	 * time_duration
	 * from
	 * to
	 * query
	 * tag_id
	 * exists
	 * resource_ids
	 * @param params
	 */
    remove(params) {

        const {
            filterChange,
            globalFilter
        } = this.props;

        let filters = _.isString(params) ? [params] : params;

        let finalGlobalFilters = _.cloneDeep(globalFilter);

        delete finalGlobalFilters.rules;

        filters.forEach(type => {

            if (type === "query") {

                finalGlobalFilters = {
                    ...finalGlobalFilters,
                    query: null
                };

            } else if (["time_duration", "from"].includes(type)) {

                finalGlobalFilters = {
                    ...finalGlobalFilters,
                    from: "",
                    to: "",
                    time_duration: ""
                };

            } else if (type === "to") {

                finalGlobalFilters = {
                    ...finalGlobalFilters,
                    to: "",
                    time_duration: ""
                };

            } else if (type === 'tag_id') {

                finalGlobalFilters = {
                    ...finalGlobalFilters,
                    tagId: null
                };

            } else if (type === 'exists') {
                finalGlobalFilters = {
                    ...finalGlobalFilters,
                    exists: null
                };
            } else if (type === 'resource_ids') {
                finalGlobalFilters = {
                    ...finalGlobalFilters,
                    resource_ids: null,
                    resource_objects: null,
                    resourceIdsDetails: null,
                    totalResourceCount: null,
                };
            }

        });

        filterChange(finalGlobalFilters, true);

    }

    resetAllGlobalFilters(filters) {

        this.remove(filters);

    }

    getFinalFilters() {

        const {
            globalFilter
        } = this.props;

        const {
            from,
            to,
            time_duration,
            query, tagId,
            exists,
            resource_ids,
            resource_objects,
            resourceIdsDetails
        } = globalFilter;

        const {
            pathname
        } = this.props.location;

        const isSubjectProfile = pathname.includes('profile/subject');

        const isEntityProfile = pathname.includes('profile/entity');

        const isEntitiesTagProfile = pathname.includes('profile/entities-tag');

        const finalFilters = {};

        if (time_duration && !_.isEmpty(time_duration)) {

            finalFilters.time_duration = time_duration;

        } else {

            if (!_.isEmpty(from)) {
                finalFilters.from = from;
            }

            if (!_.isEmpty(to)) {
                finalFilters.to = to;
            }

        }

        if (query && !isSubjectProfile) {

            if (query.hasCustomQuery) {

                if (query.parsedQuery) {

                    finalFilters.query = query.parsedQuery;

                }

            } else if (!query.hasCustomQuery && query.selectedKeyword) {

                finalFilters.query = query.selectedKeyword;
            }

        }

        if (tagId && !isEntityProfile && !isEntitiesTagProfile) {

            finalFilters.tag_id = tagId;
        }

        if (exists && !_.isEmpty(exists)) {

            finalFilters.exists = exists;
        }

        if (!isEntityProfile && !isEntitiesTagProfile) {

            if (resource_ids) {

                finalFilters.resource_ids = resource_ids;
            }

            if (resourceIdsDetails) {

                finalFilters.resourceIdsDetails = resourceIdsDetails;
            }

            if (resource_objects && resource_objects.length > 0) {

                finalFilters.resource_objects = resource_objects;
            }
        }

        return finalFilters;

    }


    fireSearchWithFilter = (params) => {

        console.log(params)

        const {
            filterChange,
            globalFilter
        } = this.props;

        let finalGlobalFilters = _.cloneDeep(globalFilter);

        delete finalGlobalFilters.rules;

        finalGlobalFilters = {
            ...finalGlobalFilters,
            ...params
        };

        filterChange(finalGlobalFilters, true);
    }

    render() {

        const {
            notification
        } = this.props;

        const notifNo = notification || localStorage.getItem("notificationNo");

        const {
            pathname
        } = this.props.location;

        const showGlobalFilter = (pathname.includes('/profile/') && !pathname.includes('/profile/content')  && !pathname.includes('/user/profile'))  && !pathname.includes('/profile/entity/linkedin')
           
            || (pathname.includes('/dashboard') && !pathname.includes('/dashboards') && !pathname.includes('/dashboard/add'))
            || pathname.includes('/query-files/single/') ;

        const showGlobalKeyword = !(pathname.includes('/profile/subject/query/') || pathname.includes('/profile/entity/linkedin'));

        
        const domain = WindowLocation?.origin;
        const reportDomainAaccess = domain.includes("dashboard.dataak.com") || domain.includes("pr.dataak.com") || domain.includes("release.dataak.com") || domain.includes("develop.dataak.com") || domain.includes("localhost");

        return (

            <>

                <CustomCol span={4}>

                    <div id="header" className="logo-container">

                        <img src={DomainSettings("head_logo")} className="logo" alt="logo" />

                        <span className="brand">{DomainSettings("sidebar_title")}</span>

                    </div>

                </CustomCol>

                <CustomCol span={20}>

                    <div className="global-filter-header">

                        <div className="global-filter-slider" style={{ flex: showGlobalFilter ? 0.9 : 0.9333 }}>


                            {showGlobalFilter && showGlobalKeyword &&
                                <GlobalKeywordsFilter theme="light" size="sm" />
                            }

                            {showGlobalFilter &&
                                <AppliedFilters
                                    theme="light"
                                    filtersValues={this.getFinalFilters()}
                                    resetAllFilters={this.resetAllGlobalFilters}
                                    resetFilter={this.remove}
                                    setVisible={this.setVisible.bind(this, "setting")}
                                    type="global-filters"
                                    extraIgnore={["query"]}
                                    fireSearch={this.fireSearchWithFilter}
                                />
                            }

                        </div>

                        <div className="left-top-icons" style={{ flex: showGlobalFilter ? 0.1 : 0.0667 }}>

                            {showGlobalFilter &&
                                <div className="g-settings-icon">

                                    <span onClick={this.setVisible.bind(this, "setting")}>

                                        <i className="icon icon-clock" />

                                    </span>

                                </div>
                            }

                            <div className="notification--icons">

                                <div className="sidebar-notif" onClick={this.setVisible.bind(this, "download")}>

                                    <i className="icon icon-alarm"></i>

                                    {!!notifNo && notifNo > 0 &&
                                        <div className="badge"><span>{notifNo}</span></div>
                                    }

                                </div>

                            </div>

                            <div className="user-icon">
                                {/* استفاده شده در هدر در قسمت اطلاعات پروفایل */}
                                <UiPopover placement="bottom"
                                    content={this.content()}>

                                    <span className="hover-user">

                                        <UserAvatar cache={true} size={40} className="avatar-icon" />

                                        <span className="user-name" selenium-id="accountUsername">{userName}</span>

                                    </span>

                                </UiPopover>

                            </div>
                            {reportDomainAaccess &&   <UiButton
                               type="button"
                               outline={true}
                               className="btn radius btn-outline-primary btn-report-call-to-action"
                               target="_blank"
                               href="https://analytica.dataak.com/"
                               size="lg"
                                  >
                              سفارش گزارش تحلیل
                             </UiButton>}
                           

                        </div>

                    </div>

                </CustomCol>

            </>

        );
    }
}

const mapStateToProps = (store) => {
    return {
        globalFilter: store.globalFilter
    }
};
const mapDispatchToProps = dispatch => {
    return {
        filterChange: (data, flag) => dispatch(filterChange(data, flag)),
    }
};

AppHeader = withRouter(AppHeader);
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
