import { useState } from "react";

const usePosition = (portalRef, nodeRef, placement, trigger) => {
    const [position, setPosition] = useState();
    const [currentPlacement, setCurrentPlacement] = useState(placement);

    const onOpen = () => {
        if (trigger === "contextmenu") {
            //اگر تریگر کلیک راست بود محتصات رو همون نقطه موس برگردونه
            document.addEventListener("contextmenu", (e) => {
                setPosition({ top: e?.clientY, left: e?.clientX });
            });
        } else {
            // انداره تولتیپ
            const { width: portalWidth, height: portalHeight } = portalRef?.current?.getBoundingClientRect() || {};

            // مختصات المانی که تولتیپ دارد به نسبت اسکرین
            const {
                width: nodeWidth,
                height: nodeHeight,
                top: nodeTop,
                left: nodeLeft,
                right: nodeRight,
                bottom: nodeBottom,
            } =
                nodeRef?.current?.children[0]?.getBoundingClientRect() ||
                nodeRef?.current?.getBoundingClientRect() ||
                {};

            // فاصله تولتیپ تا نود
            const space = 10;

            // دریافت طول و عرض اسکرین
            const innerWidth = window.innerWidth;
            const innerHeight = window.innerHeight;

            // محاسبه محل نمایش تولتیپ
            let currentPlacementTemp = currentPlacement;

            // از بالا جا نداشت
            if (nodeTop < portalHeight) {
                if (["topRight", "top", "topLeft"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("top", "bottom");
                }
                if (["left", "right"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace(currentPlacement, currentPlacement + "Top");
                }
                if (["leftBottom", "rightBottom"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("Bottom", "Top");
                }
            }

            // از پایین جا نداشت
            if (nodeBottom + portalHeight > innerHeight) {
                if (["bottomRight", "bottom", "bottomLeft"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("bottom", "top");
                }
                if (["left", "right"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace(currentPlacement, currentPlacement + "Bottom");
                }
                if (["leftTop", "rightTop"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("Top", "Bottom");
                }
            }

            // از چپ جا نداشت
            if (nodeLeft < portalWidth) {
                if (["leftTop", "left", "leftBottom"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("left", "right");
                }
                if (["bottomRight", "topRight"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("Right", "Left");
                }
                if (["top", "bottom"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp + "Left";
                }
            }

            //  از راست جا نداشت
            if (nodeRight + portalWidth > innerWidth) {
                if (["rightTop", "right", "rightBottom"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("right", "left");
                }
                if (["bottomLeft", "topLeft"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp.replace("Left", "Right");
                }
                if (["top", "bottom"].includes(currentPlacement)) {
                    currentPlacementTemp = currentPlacementTemp + "Right";
                }
            }

            setCurrentPlacement(currentPlacementTemp);

            // مختصات نمایش تولتیپ از چپ و بالای اسکرین
            let positionTemp = {};

            // محاسبه محل نمایش تولتیپ در اسکرین
            switch (currentPlacementTemp) {
                case "top":
                    positionTemp = {
                        left: nodeLeft + nodeWidth / 2 - portalWidth / 2,
                        top: nodeTop - portalHeight - space,
                    };
                    break;
                case "topRight":
                    positionTemp = {
                        left: nodeRight - portalWidth,
                        top: nodeTop - portalHeight - space,
                    };
                    break;
                case "topLeft":
                    positionTemp = {
                        left: nodeLeft,
                        top: nodeTop - portalHeight - space,
                    };
                    break;
                case "bottom":
                    positionTemp = {
                        left: nodeLeft + nodeWidth / 2 - portalWidth / 2,
                        top: nodeBottom + space,
                    };
                    break;
                case "bottomRight":
                    positionTemp = {
                        left: nodeRight - portalWidth,
                        top: nodeBottom + space,
                    };
                    break;
                case "bottomLeft":
                    positionTemp = {
                        left: nodeLeft,
                        top: nodeBottom + space,
                    };
                    break;
                case "left":
                    positionTemp = {
                        left: nodeLeft - portalWidth - space,
                        top: nodeTop - portalHeight / 2 + nodeHeight / 2,
                    };
                    break;
                case "leftTop":
                    positionTemp = {
                        left: nodeLeft - portalWidth - space,
                        top: nodeTop,
                    };
                    break;
                case "leftBottom":
                    positionTemp = {
                        left: nodeLeft - portalWidth - space,
                        top: nodeBottom - portalHeight,
                    };
                    break;
                case "right":
                    positionTemp = {
                        left: nodeRight + space,
                        top: nodeTop - portalHeight / 2 + nodeHeight / 2,
                    };
                    break;
                case "rightTop":
                    positionTemp = {
                        left: nodeRight + space,
                        top: nodeTop,
                    };
                    break;
                case "rightBottom":
                    positionTemp = {
                        left: nodeRight + space,
                        top: nodeBottom - portalHeight,
                    };
                    break;
                default:
                    break;
            }
            setPosition(positionTemp);
        }
    };
    return [position, currentPlacement, onOpen];
};

export default usePosition;
