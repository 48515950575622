/**
 * بررسی ولید بودن ورودی های فرم
 * @param {*} options
 * @returns
 */
export const uiFormValidation = (options) => {
    let isValid = true;
    let invalidKeys = [];
    const params = options.reduce((acc, curr) => {
        // اگر اجباری بود و مقداری نداشت
        if (curr.isRequired && !curr.value) {
            isValid = false;
            invalidKeys.push(curr.key);
        }
        // اگر اجباری بود و آرایه خالی بود
        if (curr.isRequired && curr.type === "array" && typeof curr.value === "object" && !curr.value.length) {
            isValid = false;
            invalidKeys.push(curr.key);
        }
        // اگر ایگنور نشده بود به عنوان فیلد در پارامتر های خروجی قرار گیرد
        if (curr.value && !curr.ignore) acc[curr.key] = curr.value;

        return acc;
    }, {});
    return {
        isValid, // اگر فرم ولید باشد
        invalidKeys, // اگر ولید نباشد لیست پارامتر های اینولید را بر میگرداند
        params, // تمام پارامتر هایی که قرار است به بک اند ارسسال گردد
    };
};

/**
 * حذف مقادیر از آرایه اصلی
 * @param {*} options
 */
export const clearValue = (options) => {
    options.forEach((option) => {
        delete option.value;
    });
};
