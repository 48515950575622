import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./UiModal.scss";
import { PortalWithState } from "react-portal";
import Draggable from "react-draggable";
import UiModalFooter from "./UiModalFooter";
import UiModalBody from "./UiModalBody";
import UiModalHeader from "./UiModalHeader";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useLayoutEffect } from "react";

/**
 * مودال + کانفرم
 * @param {*node} children
 * @param {*node} title
 * @param {*string} theme oneOf(["dark", "light"])
 * @param {*string} className
 * @param {*bool} visible
 * @param {*string} icon
 * @param {*func} onOk
 * @param {*func} onCancel
 * @param {*func} okConfig
 * @param {*func} cancelConfig
 * @param {*bool} draggable
 * @param {*bool} loading
 * @param {*string} size oneOf(["sm", "md", "lg", "xl", "full"])
 * @param {*string} type oneOf(["success", "danger"])
 * @param {*func} onClickOutside
 * @param {*bool} noBuler
 * @param {*bool} noHeader
 * @param {*bool} noFooter
 * @param {*node} footer
 * @param {*node} header
 */
const UiModal = (props) => {
    const nodeOpenRef = useRef(null);
    const nodeCloseRef = useRef(null);
    const uiModalRef = useRef(null);
    const func = () => {};
    const {
        children,
        title = "",
        theme,
        icon = "icon-exclamation",
        className = "",
        onOk = func,
        onCancel = func,
        draggable = false,
        visible,
        size = "md",
        loading = false,
        type = "success",
        noBuler = false,
        noHeader = false,
        noFooter = false,
        footer,
        header,
        onClickOutside = func,
        okConfig = {
            text: "تایید",
            style: {},
        },
        cancelConfig = {
            text: "لغو",
            style: {},
        },
    } = props;

    // بستن مودال با کلیک خارج از مودال
    useOnClickOutside(uiModalRef, onClickOutside);

    // اگر وضعیت نمایش مودال به حالت باز و بسته شدن تغییر کرد
    // از روی رف اکشن کلیک رو صدا میزنیم تا مودال باز و بسته بشه
    // این نحوه کد نویسی برای محدودیت های پرتال انتخاب شده
    useEffect(() => {
        if (visible) {
            // باز کردن مودال
            nodeOpenRef.current.click();
        } else {
            // بستن مودال
            nodeCloseRef.current.click();
            if (draggable || noBuler) return;
            const body = document.querySelector("#root>section");
            body.style = "filter: unset";
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    /**
     * اگر ارتفاع بده اصلی مودال تغییر کرد، مودال در وسط صفحه قرار بگیرد
     */
    useLayoutEffect(() => {
        setTimeout(() => {
            const height = uiModalRef?.current?.getBoundingClientRect()?.height;
            if (height) uiModalRef.current.style.top = `calc(50% - ${height / 2}px)`;
        }, 50);
    });

    /**
     * وقتی پورتال باز میشه یه کالبکی رو صدا میزنه
     * اینجا عملیات بلر کردن بکگراند صفحه انجام میشه
     */
    const onOpen = () => {
        if (draggable || noBuler) return;
        const body = document.querySelector("#root>section");
        body.style = "filter: blur(4px)";
    };

    return (
        <PortalWithState onOpen={onOpen}>
            {({ openPortal, closePortal, portal, isOpen }) => (
                <>
                    <span onClick={openPortal} ref={nodeOpenRef}></span>
                    <span onClick={closePortal} ref={nodeCloseRef}></span>
                    {portal(
                        <div className={`ui-modal-root ${className} ${theme}`}>
                            {!draggable && <div className="ui-modal-mask"></div>}
                            {/* اگر مدال بیش از اندازه بزرگ بود اسکرول داشته باشد */}
                            <div className="ui-modal-scroll">
                                {/* قابلیت حرکت دادن مودال روی صفحه */}
                                <Draggable disabled={draggable ? false : true}>
                                    <div
                                        ref={uiModalRef}
                                        className={`ui-modal ${isOpen ? "ui-modal-open" : ""} ${size} ${
                                            noHeader && noFooter ? "no-padding" : ""
                                        }`}
                                    >
                                        {noHeader ? null : header ? (
                                            header
                                        ) : (
                                            <UiModalHeader
                                                closePortal={closePortal}
                                                onCancel={onCancel}
                                                icon={icon}
                                                draggable={draggable}
                                                noBuler={noBuler}
                                            >
                                                {title}
                                            </UiModalHeader>
                                        )}
                                        <UiModalBody>{children}</UiModalBody>
                                        {noFooter ? null : footer ? (
                                            <div className="ui-modal-footer">{footer}</div>
                                        ) : (
                                            <UiModalFooter
                                                draggable={draggable}
                                                closePortal={closePortal}
                                                onCancel={onCancel}
                                                onOk={onOk}
                                                okConfig={okConfig}
                                                cancelConfig={cancelConfig}
                                                loading={loading}
                                                type={type}
                                                noBuler={noBuler}
                                            />
                                        )}
                                    </div>
                                </Draggable>
                            </div>
                        </div>,
                    )}
                </>
            )}
        </PortalWithState>
    );
};

UiModal.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    type: PropTypes.oneOf(["success", "danger"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl", "full"]),
    className: PropTypes.string,
    visible: PropTypes.bool,
    icon: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    draggable: PropTypes.bool,
    loading: PropTypes.bool,
    onClickOutside: PropTypes.func,
    footer: PropTypes.node,
    header: PropTypes.node,
    noBuler: PropTypes.bool,
    noHeader: PropTypes.bool,
    noFooter: PropTypes.bool,
    okConfig: PropTypes.object,
    cancelConfig: PropTypes.object,
};

export default UiModal;
