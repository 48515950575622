import { usePermission } from "hooks/usePermission";
import { useMemo } from "react";

const useUiTablePermissions = (tableState) => {
    const { permission } = usePermission();
    const tablePermission = useMemo(
        () => ({
            delete: permission(tableState?.permissions?.["delete"]),
            edit: permission(tableState?.permissions?.["edit"]),
            list: permission(tableState?.permissions?.["list"]),
            share: permission(tableState?.permissions?.["share"]),
            view: permission(tableState?.permissions?.["view"]),
            refresh: permission(tableState?.permissions?.["refresh"]),
            analyzer: permission(tableState?.permissions?.["analyzer"]),
            add: permission(tableState?.permissions?.["add"]),
        }),
        [permission, tableState?.permissions],
    );
    return { tablePermission };
};
export default useUiTablePermissions;
