import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { UiInputNumber } from "ui-components";

const InputNumberField = ({ name, platformParams, handleInputNumber, size, typeDescriptionFilter, intl, theme }) => (
    <Row key={name}>
        <Col lg={6} xl={size === "md" ? 6 : 5}>
            <span className="row-label">
                {intl.formatMessage({ id: `strings.${name}` })}
                {typeDescriptionFilter}
            </span>
        </Col>
        <Col lg={14} xl={size === "md" ? 14 : 14}>
            <UiInputNumber
                theme={theme}
                min={1}
                defaultValue={_.isUndefined(platformParams[name]) ? "" : platformParams[name]}
                onChange={(val) => handleInputNumber(val, name)}
            />
        </Col>
    </Row>
);

export default injectIntl(InputNumberField);
