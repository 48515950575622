import React from "react";
import PropTypes from "prop-types";

/**
 * بازه بین نقاط انتخاب شده
 * @param {*shape} source id, value, percent
 * @param {*shape} target id, value, percent
 * @param {*func} getTrackProps
 * @param {*bool} disabled
 */
const Track = (props) => {
    const { source, target, getTrackProps, disabled } = props;
    return (
        <div
            className={["ui-input-range-track", disabled ? "disabled" : ""].join(" ")}
            style={{ left: `${source.percent}%`, width: `${target.percent - source.percent}%` }}
            {...getTrackProps()}
        />
    );
};

Track.propTypes = {
    source: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    target: PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        percent: PropTypes.number.isRequired,
    }).isRequired,
    getTrackProps: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default Track;
