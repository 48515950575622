import React from "react";
import { Row, Col } from "antd";
import DateTimePicker from "shared/jalali-calender/DateTimePicker";

const DatePickerField = (props) => {
    const {
        name,
        platformParams,
        jalaliFormat,
        formatMessage,
        handleDatePicker,
        handleClearDatePicker,
        getPreSelected,
        size,
    } = props;

    return (
        <Row key={name} type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">{formatMessage({ id: `strings.${name}` })}</span>
            </Col>
            <Col lg={18} xl={size === "md" ? 18 : 20}>
                <DateTimePicker
                    placeholder={formatMessage({ id: "global.enter_date" })}
                    format={jalaliFormat}
                    onChange={(unix, formatted) => handleDatePicker(formatted, name)}
                    onClear={() => handleClearDatePicker(name)}
                    preSelected={getPreSelected(platformParams.name)}
                    containerClass="custom-input-datepicker"
                    customClass="custom-datepicker"
                />
            </Col>
        </Row>
    );
};

export default DatePickerField;
