import React from "react";
import { FormattedSimpleMsg } from "GlobalFunctions";

/**
 * هندل کردن پیام های خطای اینستاگرام (فیس بوک)
 * @param {*} error  آبجکت ارور از بک اند
 * @param {*} wantTranslate  آیا نیاز به ترجمه هست یا کد ارور
 */
export const facebookErrorHandling = (error, wantTranslate = true) => {
    const facebookErrorCodes = [100, 102, 1, 2, 10, 190, 200, 341, 368, 506, 1609005, 4, 17, 32, 613];

    const facebookErrorSubCodes = [458, 459, 460, 463, 464, 467, 492 , 1772179];

    let finalCode = "not_found";

    const code = error?.code;
    const subCode = error?.sub_code || error?.error_subcode;

    if (subCode && facebookErrorSubCodes.indexOf(subCode) > -1) finalCode = subCode;

    if (code && facebookErrorCodes.indexOf(code) > -1) finalCode = code;

    if (finalCode > 200 && finalCode < 300) finalCode = 200;

    if (wantTranslate) return <FormattedSimpleMsg id={`facebook.error_code.${finalCode}`} />;

    return finalCode;
};
