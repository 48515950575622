import React, { useEffect, useState, createContext } from "react";
import PropTypes from "prop-types";
import "./UiCheckboxGroup.scss";
import UiCheckbox from "../ui-checkbox/UiCheckbox";
import usePrevious from "hooks/usePrevious";
import { isEqual } from "lodash";

//ساخت کانتکست برای این که نگه داشتن استیت های هر چک باکس وقتی که چک باکس ها داخل چیلدرن گروپ چک باکس باشند
export const GroupContext = createContext(null);

const UiCheckboxGroup = ({ children, ...props }) => {
    const { list, value, onChange, className, theme, style, disabled, output = "object", mode } = props;

    const selectedIds = value || [];
    const prevProps = usePrevious({ list, selectedIds });

    // یافتن آیتمی که به عنوان انتخاب شده از ورودی به عنوان value دریافت شده است
    // و به عنوان آیتم انتخاب شده به selectedIds اختصاص می‌یابد
    const findSelectedList = !children
        ? selectedIds?.map((item) => {
              return {
                  ...list?.find((x) => x.value.toString() === item?.toString()),
              };
          })
        : selectedIds.map((item) => {
              if (typeof item === "string" || typeof item === "number") {
                  return {
                      label: item,
                      value: item,
                  };
              }
              return item;
          });
    // آیتم های انتخاب شده
    const [dataList, setDataList] = useState(list || []);
    const [selectedList, setSelectedList] = useState(findSelectedList);

    /**
     *
     * بررسی اینکه دیتا تغییر کرده است یا خیر
     *
     */
    useEffect(() => {
        if (!isEqual(prevProps?.list, list)) {
            const listTemp = list.map((item) => {
                if (typeof item === "string" || typeof item === "number") {
                    return {
                        label: item,
                        value: item,
                    };
                }
                return item;
            });
            setDataList(listTemp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);

    /**
     * آپدیت کردن آیتم انتخاب شده در لیست
     */
    useEffect(() => {
        if (selectedIds && !isEqual(prevProps?.selectedIds, selectedIds)) {
            setSelectedList(findSelectedList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIds]);

    // این کالبک بعد از تغییر هر یک از چک باکس ها فراخوانی میگردد
    const handleChange = (checked, value) => {
        let item = {};
        if (dataList && dataList.length > 0 && !children) {
            item = dataList.find((i) => i?.value?.toString() === value?.toString());
        } else {
            item = { label: value, value: value };
        }

        let trueListItems = null;
        if (!checked) {
            // فیلتر کردن آیتم های true
            trueListItems = selectedList.filter((x) => x?.value?.toString() !== item?.value?.toString());
        } else {
            trueListItems = [...selectedList, item];
        }
        setSelectedList(trueListItems);
        // ارسال آیتم های انتخاب شده به پرنت
        typeof onChange === "function" &&
            onChange(output === "object" ? trueListItems : trueListItems?.map((x) => x.value));
    };

    const context = {
        handleChange,
        selectedIds: selectedList.map((i) => i.value?.toString()),
    };

    return (
        <GroupContext.Provider value={context}>
            <div className={["ui-checkbox-group", mode].join(" ")}>
                {children
                    ? children
                    : dataList?.map((item, index) => {
                          return (
                              <UiCheckbox
                                  className={className}
                                  theme={theme}
                                  disabled={disabled || item?.disabled}
                                  style={style}
                                  value={item.value}
                                  id={item.value}
                                  key={index}
                              >
                                  {item?.node ? item.node : item.label}
                              </UiCheckbox>
                          );
                      })}
            </div>
        </GroupContext.Provider>
    );
};

UiCheckboxGroup.propTypes = {
    list: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    mode: PropTypes.oneOf(["horizontal", "vertical"]),
    theme: PropTypes.oneOf(["dark", "light"]),
    style: PropTypes.object,
    disabled: PropTypes.bool,
    output: PropTypes.oneOf(["object", "value"]),
    children: PropTypes.node,
};

export default UiCheckboxGroup;
