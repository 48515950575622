import { useContext } from "react";
import { UiTableDispatchContext, UiTableStateContext } from "./../context/UiTableContext";

/**
 * تمامی عملیات مربوط به انتخاب رکورد های جدول. مانند:
 * انتخاب یک رکورد
 * انتخاب همه رکورد ها
 * یافتن رکوردی که انتخاب شده (تکی)
 * یافتن رکوردهای انتخاب شده
 */
const useUiTableSelect = () => {
    const tableState = useContext(UiTableStateContext);
    const tableDispatch = useContext(UiTableDispatchContext);

    let selectedItems = tableState?.selectedItems;

    /**
     * انتخاب تکی آیتم ها
     * @param {*Bool} checked
     * @param {*Number} id
     */
    const setSelect = (checked, id) => {
        selectedItems = tableState?.selectedItems || [];
        if (checked) {
            selectedItems.push(id);
        } else {
            selectedItems = selectedItems.filter((x) => x !== id);
        }
        tableDispatch({ type: "setSelectedItems", value: selectedItems });
    };

    /**
     * انتخاب همه آیتم ها
     * @param {*Bool} checked
     */
    const setSelectAll = (checked) => {
        if (checked) {
            selectedItems = tableState?.data?.map((x) => x.id);
        } else {
            selectedItems = [];
        }
        tableDispatch({ type: "setSelectedItems", value: selectedItems });
    };

    /**
     * پیدا کردن اینکه آیا آیتم از قبل انتخاب شده یا نه
     * @param {*Number} id شناسه آیتم انتخاب شده
     */
    const findSelected = (id) => {
        let selectedItem = selectedItems?.find((x) => x === id);
        if (selectedItem) return true;
        return false;
    };

    /**
     * بررسی اینکه آیا همه آیتم ها انتخاب شده اند یا خیر
     * خروجی دارای 3 وضعیت است
     * هیچکدام - همه - نامشخص
     */
    const findSelectedAll = () => {
        const { length } = tableState?.data;
        if (selectedItems?.length === 0) {
            return false;
        } else if (selectedItems?.length === length) {
            return true;
        } else {
            return "indeterminate";
        }
    };

    return { setSelect, setSelectAll, findSelected, findSelectedAll };
};

export default useUiTableSelect;
