import user from "./userReducer";
import searchResults from "./resultsReducer";
import excel from "./excelReducer";
import box from "./boxReducer";
import globalFilter from './globalFilterReducer';

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user,
  searchResults,
  excel,
  box,
  globalFilter,
});
export default rootReducer;
