import React, { useContext } from "react";
import PropTypes from "prop-types";
import "./UiRadio.scss";
import { RadioContext } from "ui-components/ui-radio-group/UiRadioGroup";

const UiRadio = ({ children, ...props }) => {
    const { defaultChecked, value, onClick, disabled = false, name = "", size = "md", theme = "", checked } = props;

    const radioGroupState = useContext(RadioContext);

    return (
        <label className={["ui-radio", disabled ? "disabled" : "", theme ? theme : radioGroupState?.theme].join(" ")}>
            <input
                className={"ui-radio-input"}
                name={name ? name : radioGroupState?.name}
                type="radio"
                disabled={disabled}
                checked={
                    checked ||
                    radioGroupState?.value === value ||
                    defaultChecked ||
                    radioGroupState?.defaultValue === value
                }
                value={value}
            />
            <span
                onClick={() => {
                    if (disabled) return;
                    radioGroupState && radioGroupState.handleChange(value);
                    typeof onClick === "function" && onClick(value);
                }}
                className={["ui-radio-checked", size ? size : radioGroupState?.size].join(" ")}
            ></span>
            <label htmlFor={name ? name : radioGroupState?.name} className={"ui-radio-label"}>
                {children}
            </label>
        </label>
    );
};

UiRadio.propTypes = {
    defaultChecked: PropTypes.bool,
    value: PropTypes.any,
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    theme: PropTypes.oneOf(["dark", "light"]),
};

export default UiRadio;
