import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import Loadings from "./loadings/Loadings";
import { isEmpty } from "lodash";
import { UiModal, UiInput } from "ui-components";

const { TextArea } = Input;

const ConfirmBox = (props) => {
    const { display, seleniumId, type = null } = props;

    const [inputText, setInputText] = useState(props.textarea?.trim() ?? "");
    const [isRequired, setIsRequired] = useState(false);

    const handleChange = (value) => {
        setInputText(value);
    };

    const handleSave = (status) => {
        if (status && props.isRequired && isEmpty(inputText)) {
            setIsRequired(true);
            return;
        }
        if (!status) {
            setIsRequired(false);
            setInputText("");
        }
        props.functionToBeCalled(status, inputText);
    };

    const enterPressed = () => {
        handleSave(true);
    };

    const { title, icon, desc, input, placeholder, confirmAction, confirmText, cancelText, loading, textarea } = props;

    if (!display) {
        return null;
    }

    return (
        <UiModal
            visible={display}
            size="md"
            title={title}
            icon={"icon-" + icon}
            noBuler
            className="confirmBox"
            onCancel={() => handleSave(false)}
            onOk={confirmAction ? confirmAction : () => handleSave(true)}
            okConfig={{
                text: confirmText,
                seleniumId,
                className: confirmText === "حذف" ? "btn btn-outline-secondary radius" : "btn btn-primary radius",
            }}
            cancelConfig={{
                text: cancelText,
                className: confirmText === "حذف" ? "btn btn-danger radius" : "btn btn-outline-secondary radius",
            }}
            loading={loading}
        >
            <div className="confirmBox">
                <div className="confirmBox-container">
                    <Loadings type="button" spinning={loading}>
                        {desc && <p>{desc}</p>}
                        {input && (
                            <UiInput
                                type="text"
                                placeholder={input}
                                onChange={handleChange}
                                onEnter={enterPressed}
                                name="inputText"
                            />
                        )}
                        {textarea && (
                            <TextArea
                                className="introduction"
                                placeholder={placeholder}
                                autoSize={{ maxRows: 8, minRows: 8 }}
                                name="inputText"
                                onChange={(e) => handleChange(e.target.value)}
                                defaultValue={textarea?.trim()}
                            />
                        )}
                        {isRequired && <span className="isRequired">* توضیحات اجباری است</span>}
                    </Loadings>
                </div>
            </div>
        </UiModal>
    );
};
export default ConfirmBox;

//
// نوع و نام پراپ های ورودی
ConfirmBox.propTypes = {
    display: PropTypes.bool.isRequired,
    title: PropTypes.string,
    inputText: PropTypes.string,
    icon: PropTypes.string,
    functionToBeCalled: PropTypes.func.isRequired,
    confirmText: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    input: PropTypes.string,
    textarea: PropTypes.string,
    confirmAction: PropTypes.func,
    loading: PropTypes.bool,
    isRequired: PropTypes.bool,
    seleniumId: PropTypes.string,
    type: PropTypes.string,
};

ConfirmBox.defaultProps = {
    loading: false,
};
