import React from "react";
import PropTypes from "prop-types";
import "./UiPopover.scss";
import { UiTooltip } from "ui-components";

/**
 * پاپ‌اوور
 * @param {*node} children
 * @param {*string} icon
 * @param {*node} content
 * @param {*node} title
 * @param {*string} className
 * @param {*bool} unVisible
 * @param {*number} mouseEnterDelay
 * @param {*} theme oneOf(["dark", "light"]),
 * @param {*} trigger oneOf(["hover", "focus", "click", "contextmenu"]),
 * @param {*} placement oneOf([
        "top",
        "topLeft",
        "topRight",
        "bottom",
        "bottomLeft",
        "bottomRight",
        "left",
        "leftTop",
        "leftBottom",
        "right",
        "rightTop",
        "rightBottom",
    ]),
 */
const UiPopover = (props) => {
    const { title, content, icon, children, theme = "light", trigger = "click", ...other } = props;
    // ایجاد یک قسمت واحد برای تولتیپ با توجه به ساختار دو بخشی پاپ‌اوور
    const titleTemp = (
        <div className="ui-popover">
            {title && (
                <div>
                    {icon && <i className={`${icon} ui-popover-icon`}></i>}
                    {title}
                </div>
            )}
            {title && content && <hr className="ui-popover-seprate" />}
            {content}
        </div>
    );

    return (
        <UiTooltip theme={theme} title={titleTemp} trigger={trigger} portalName="popover" {...other}>
            {children}
        </UiTooltip>
    );
};
UiPopover.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string,
    content: PropTypes.node,
    title: PropTypes.node,
    className: PropTypes.string,
    unVisible: PropTypes.bool,
    mouseEnterDelay: PropTypes.number,
    theme: PropTypes.oneOf(["dark", "light"]),
    trigger: PropTypes.oneOf(["hover", "focus", "click", "contextmenu"]),
    placement: PropTypes.oneOf([
        "top",
        "topLeft",
        "topRight",
        "bottom",
        "bottomLeft",
        "bottomRight",
        "left",
        "leftTop",
        "leftBottom",
        "right",
        "rightTop",
        "rightBottom",
    ]),
};

export default UiPopover;
