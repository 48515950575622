import React, { Component } from 'react';
import { api } from 'services'
import _ from 'lodash';
import ResourcesView from './ResourcesView'
import axios from 'axios';
import { onEnter } from 'GlobalFunctions';
import "./Resources.scss";
import { UiToast } from "ui-components";


let CancelToken = axios.CancelToken;
let source = CancelToken.source();

class Resources extends Component {
    constructor(props) {
        super(props);

        // منابع کاربر
        let resources = [];

        // دسته ها
        let categories = [];

        // دسته هایی که کامل انتخاب شده اند
        let fullySelectedCategoriesIDs = [];

        //TODO:
        let fullySelectedCategoriesObject = [];

        // ساخت یک آرایه دوبعدی برای منابع، دسته ها و دسته های کامل
        // به ازای هر پلتفرم یک ردیف
        props.platforms && props.platforms.forEach(platform => {
            resources.push([]);
            categories.push([]);
            fullySelectedCategoriesIDs.push([]);
            fullySelectedCategoriesObject.push([]);
        })

        this.state = {
            modalVisible: false,

            // لیست منابع
            resources,

            //لیست دسته بندی ها
            categories,

            // عبارت سرچ شده برای منابع لود شده
            // این مقدار در زمان رندر هر دسته چک میشود
            searchLoadedResources_Term: '',

            //لیست دسته هایی که کامل انتخاب شده اند
            fullySelectedCategoriesIDs,

            //TODO:
            fullySelectedCategoriesObject,

            //ایندکس دسته فعال در آرایه خودش
            currentCategoryIndex: null,


            // حالت سرچ، معمولی یا پیشرفته
            advancedSearch: false,

            // لیست آیدی منابع انتخاب شده
            flatSelectedResourcesIDs: [],

            // ایندکس پلتفرم فعلی در منوی سمت راست مودال
            //  اگر یک پلتفرم داریم همان فعال باشه وگرنه هیچکدام تا کاربر کلیک نکرده
            selectedPlatform: this.props.platforms?.length === 1 ? 0 : 0,

            // خروجی که برای درخواست کننده این کامپوننت ارسال میشود
            outputResult: [],

            // لیست منابع انتخاب شده دسته بندی نشده
            unCategoryResourcesSelected: {},

            // تعداد منابع انتخاب شده در تمام بسترها
            selectedCount: this.props.selectedResourceCount ? this.props.selectedResourceCount : 0,

            // لودینگ اینکه بار اول هست که آیتمهای انتخاب شده را از ای پی آی میگیره
            selectedResourceLoading: {},

            // صفحه جاری در پیجینیت کردن
            currentPage: 1,

            // تعداد منابع در هر صفحه سرچ
            perPage: 100,

            //نمایش دکمه ریست 
            showResetBtn: false,

            // نگه داشتنم منابع جاری در زمان باز شدن مودال برای برگشت به حالت قبل در لغو
            currentSources: {},

            // صفحه جاری در انتخاب شده های هر بستر
            selectedCurrentPage: {},

            // تعداد منابع انتخاب شده در هر بستر
            platformsSelectedCount: {},

            // کلمه سرچ شده در انتخاب شده ها
            selectedSearchQ: "",

            // لودینگ سرچ انتخاب شده ها
            searchSelectedLoading: false,

        };

        this.showModal = this.showModal.bind(this)
        this.handleOk = this.handleOk.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.selectPlatform = this.selectPlatform.bind(this)
        this.flatten = this.flatten.bind(this)
        this.updateParentApplicant = this.updateParentApplicant.bind(this);
        this.getUnCategoryResources = this.getUnCategoryResources.bind(this);
        this.addToSelectionUnCategoryList = this.addToSelectionUnCategoryList.bind(this);
        this.removeUnCategoryResource = this.removeUnCategoryResource.bind(this);
        this.getSelectedResourcesInternally = this.getSelectedResourcesInternally.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getUnCategoryResourcesCount = this.getUnCategoryResourcesCount.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.selectedPageChange = this.selectedPageChange.bind(this);
        this.handleSearchLoading = this.handleSearchLoading.bind(this);
        this.reset = this.reset.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.searchSelectdResource = this.searchSelectdResource.bind(this);
        this.cancleSearch = this.cancleSearch.bind(this);
        this.getSelectedResourcesExternally = this.getSelectedResourcesExternally.bind(this);
        this.saveLastMode = this.saveLastMode.bind(this);
    }
   
    
    /**
     * نمایش مودال
     */
    showModal() {
        const {
            unCategoryResourcesSelected,
            selectedResourceLoading } = this.state;

        const {
            editMode,
            selectedResources,
            platforms } = this.props;

        // ویرایش
        if (editMode) {

            // اگر دفعه اول هست که مودال رو باز میکنه دیتا ویرایش تب اول رو بگیره
            if (!selectedResourceLoading[0])

                this.getSelectedResourcesInternally("first", 0);

            // اگر دفعه اول نیست که مودال رو باز میکنه دیتا قبلی رو بیاره
            else {
                let temp = {}

                platforms.forEach((item, index) => {

                    if (selectedResourceLoading[index]) {

                        temp = {
                            ...temp,
                            [index]: _.cloneDeep(unCategoryResourcesSelected[index])
                        }
                    }
                })

                this.setState({
                    currentSources: {
                        ...temp
                    }
                })
            }
        }

        // ایجاد
        else {
            this.saveLastMode()
        }


        this.setState({
            modalVisible: true,
        },()=>{
            
            selectedResources && selectedResources.forEach((x) => {
                const index = platforms.findIndex((z) => z === x.platform);
                setTimeout(() => {
                    this.selectPlatform(index);
                }, 200);
            });
            const { currentSources } = this.state;
             let finalTotalResourceCount = {},
                 selectedCount = 0;

             platforms.forEach((item, key) => {
                 finalTotalResourceCount[item] = currentSources[key] ? currentSources[key].length : 0;

                 selectedCount = selectedCount + finalTotalResourceCount[item];
             });

             this.setState(
                 {
                     finalTotalResourceCount,
                     selectedCount,
                 },
             );
        });


    }

    /**
     * نگه داشتن آخرین حالت برای زمانیکه کاربر لغو میکنه
     */
    saveLastMode() {

        const { platforms } = this.props;

        const { unCategoryResourcesSelected } = this.state;

        let temp = {}

        platforms && platforms.forEach((item, index) => {

            if (unCategoryResourcesSelected[index]) {

                temp = {
                    ...temp,
                    [index]: _.cloneDeep(unCategoryResourcesSelected[index])
                }
            }
        })

        this.setState({
            currentSources: {
                ...temp
            }
        })
    }

    /**
     * ریست مودال
     * @param {*} e 
     */
    handleCancel() {

        const {
            platforms,
            editMode,
            totalResourceCount } = this.props;

        let finalTotalResourceCount = {};

        if (editMode) {

            finalTotalResourceCount = _.cloneDeep(totalResourceCount);

        } else {

            platforms.forEach((item) => {

                finalTotalResourceCount[item] = 0;
            })
        }

        this.reset(true);

        this.setState({
            selectedCurrentPage: {},
            finalTotalResourceCount
        }, () => {
                this.props.typeWrapper === "full" &&   this.handleOk()
            this.getSelectedResourcesInternally("first", this.state.selectedPlatform)
        })
    }

    /**
     * لغو مودال
     */
    handleClose() {

        const {
            currentSources,
            selectedResourceLoading
        } = this.state;

        const {
            editMode,
            platforms
        } = this.props;

        //محاسبه مقادیر جدید از روی مقادیر قبلی بعد از زدن لغو
        let finalTotalResourceCount = {},
            selectedCount = 0;

        platforms.forEach((item, key) => {

            finalTotalResourceCount[item] = currentSources[key] ? currentSources[key].length : 0

            selectedCount = selectedCount + finalTotalResourceCount[item];
        })


        // آخرین حالت قبل از باز شدن مودال را برمیگرداند
        let temp = {};

        platforms.forEach((item, index) => {
            if (editMode) {
                if (selectedResourceLoading[index]) {
                    temp = {
                        ...temp,
                        [index]: _.cloneDeep(currentSources[index])
                    }

                }
            } else {
                if (currentSources[index]) {
                    temp = {
                        ...temp,
                        [index]: _.cloneDeep(currentSources[index])
                    }
                }

            }
        })


        this.setState({
            unCategoryResourcesSelected: {
                ...temp
            },
            modalVisible: false,
            finalTotalResourceCount,
            selectedCount
        },()=>{
            this.props.typeWrapper === "full" &&   this.handleOk()
        })

    }

    /**
     * کلیک ثبت در مودال
     * @param {*} e 
     */
    handleOk() {

        const {
            editMode,
            limited,
            selectedResources
        } = this.props;

        this.setState({
            modalVisible: false,
        }, () => {

            const { unCategoryResourcesSelected } = this.state

            let intArray = [];

            let objArray = [];

            let addedArray = [];

            let removedArray = [];

            let fullObjArray = [];

            // اگر منابع دسته بندی نشده انتخاب شد
            if (unCategoryResourcesSelected) {
                Object.keys(unCategoryResourcesSelected).forEach((key) => {

                    unCategoryResourcesSelected[key].forEach((item) => {

                        let temp = objArray.find(x => x.platform === this.props.platforms[item.platform]);

                        intArray.push(item.id);

                        if (temp) {
                            temp.ids = [...temp.ids, item.id];
                        }
                        else {
                            objArray.push({ platform: this.props.platforms[item.platform], ids: [item.id] });
                        }

                        // اگر محدود به تعدادی آیتم است آبجکت انتخاب شده ها را نیز برگرداند
                        if (limited) {
                            let tempObj = fullObjArray.find(x => x.platform === this.props.platforms[item.platform]);


                            if (tempObj) {
                                tempObj.objs = [...tempObj.objs, item];
                            }
                            else {
                                fullObjArray.push({ platform: this.props.platforms[item.platform], objs: [item] });
                            }
                        }

                        // اگر در حالت ویرایش است آیتمهای پاک شده و اضافه شده رو جداکنه و بریزه تو دو آبجکت جدا
                        if (editMode) {

                            if (item.isAdded) {
                                let tempAdd = addedArray.find(x => x.platform === this.props.platforms[item.platform]);

                                if (tempAdd) {
                                    tempAdd.ids = [...tempAdd.ids, item.id];
                                }
                                else {
                                    addedArray.push({ platform: this.props.platforms[item.platform], ids: [item.id] });
                                }
                            } else if (item.isRemoved) {
                                let tempRemove = removedArray.find(x => x.platform === this.props.platforms[item.platform]);

                                if (tempRemove) {
                                    tempRemove.ids = [...tempRemove.ids, item.id];
                                }
                                else {
                                    removedArray.push({ platform: this.props.platforms[item.platform], ids: [item.id] });
                                }
                            }
                        }



                    })
                })
            }

            // یکی کردن دسته ها با منابعی که هیچ دسته ای ندارند
            let temp = _.reduce(objArray, function (result, value, key) {
                (result[value.platform] || (result[value.platform] = [])).push(...value.ids);
                return result;
            }, {});

            // ادامه ...
            let objArray2 = [];
            _.reduce(temp, function (result, value, key) {
                objArray2.push({ platform: key, ids: value });
                return result;
            }, {});
            let changedCount = 0;
            //ست کردن تعداد کل  انتخاب شده ها
            if (editMode) {

                // اگر منابع از بیرون پاس داده شده بود
                if (!_.isEmpty(selectedResources)) {

                    // fullObjArray = _.cloneDeep(selectedResources);

                    selectedResources.forEach((item, key) => {

                        let find = false;

                        fullObjArray.forEach((objItem, index) => {

                            if (item.platform === objItem.platform) {
                                find = true;
                            }

                        })

                        if (!find) {

                            fullObjArray.push(item);
                        }

                    })

                    //fullObjArray = _.cloneDeep(selectedResources);

                    objArray2 = [];
                    let flatIds = [];

                    fullObjArray.forEach((item) => {
                        flatIds = [];

                        item.objs.forEach((obj) => {
                            flatIds.push(obj.id)
                        })

                        objArray2.push({ platform: item.platform, ids: flatIds });
                    })
                }

                // به دست آوردن آرایه تعداد اضافه شده ها در هر بستر
                let addedCount = addedArray.map((item) => {
                    return item.ids.length
                });
                // به دست آوردن آرایه تعداد کم شده ها در هر بستر
                let removedCount = removedArray.map((item) => {
                    return item.ids.length
                });

                changedCount = this.props.selectedResourceCount
                    + (addedArray.length > 0 ? addedCount.reduce((sum, value) => sum + value) : 0)
                    - (removedArray.length > 0 ? removedCount.reduce((sum, value) => sum + value) : 0)
            } else {
                changedCount = intArray.length
            }

            this.setState({
                flatSelectedResourcesIDs: [...intArray],
                // selectedCount: changedCount
            }, () => {
                this.updateParentApplicant(objArray2, intArray, addedArray, removedArray, fullObjArray)
            })
        });

    }

    /**
     * انتخاب بستر 
     * @param {*} key ایندکس پلتفرم
     */
    selectPlatform(key) {

        if (key !== this.state.selectedPlatform) {
            source.cancel('Operation canceled by the user change item.');

            CancelToken = axios.CancelToken;
            source = CancelToken.source();

            if (this.props.editMode && !this.state.selectedResourceLoading[key])
                this.getSelectedResourcesInternally("index", key);

            this.setState({
                selectedPlatform: key,
                unCategoryResources: [],
                unCategoryResourcesCount: 0,
                selectedSearchQ: "",
                selectedCurrentPage: {},
            })
        }
    }

    /**
     * فلت کردن درخت دسته بندی ها بعد از لود
     * ممکن است دسته بندی های همنام بیاید که آیدی متفاوتی دارند و پدر متفاوتی هم دارند
     * @param {*} tree درخت دسته بندیها 
     */
    flatten(tree) {
        let arr = tree.reduce((accumulator, item) => {
            return item.children.length > 0
                ? accumulator.concat([item, ...this.flatten(item.children)])
                : accumulator.concat(item)
        }, []);

        // بعد از فلت شدن برای هر دسته یک لیست از منابع و یک عدد برای شماره صفحه ست میشود 
        arr.forEach(item => {
            item.resources = [];
            item.selectedResources = []
            item.pageNo = 1;
            item.children = null
        })
        return arr
    }

    /**
     * دریافت منابع دسته بندی نشده
     * @param {*} resourceList لیست منابع
     */
    getUnCategoryResources(resourceList) {

        this.setState({
            unCategoryResources: resourceList,
        });

    }

    /**
  * دریافت تعداد کل منابع دسته بندی نشده
  * @param {*} resourceCount لیست منابع
  */
    getUnCategoryResourcesCount(resourceCount) {
        this.setState({
            unCategoryResourcesCount: resourceCount
        });
    }

    /**
     * افزودن منبع به لیست دسته بندی نشده
     * @param {*} item 
     */
    addToSelectionUnCategoryList(item) {

        const { platforms, singleSelect} = this.props;

        const finalSingleSelect = !_.isUndefined(singleSelect) && singleSelect ? true : false;

        let { unCategoryResourcesSelected, selectedPlatform } = this.state;



        // افزودن پلتفرم به آیتم
        item["platform"] = selectedPlatform;

        // اگر در حالت ویرایش بود به آیتم اضافه شده تگ اد بزنه
        if (this.props.editMode) {

            item.isAdded = true;
            item.isRemoved = false;

        }

        // اگر قبلا هیچ منبعی انتخاب نشده باشه و لیست منابع خالی باشه
        if (_.isEmpty(unCategoryResourcesSelected) || _.isEmpty(unCategoryResourcesSelected[selectedPlatform])) {

            // ایجاد دسته بندی خالی
            unCategoryResourcesSelected[selectedPlatform] = [];



            // افزودن منبع به لیست
            unCategoryResourcesSelected[selectedPlatform].push(item);

            this.setState((state) => {
                let newCount = state.finalTotalResourceCount;
                newCount[platforms[selectedPlatform]] = 1;
                return {
                    finalTotalResourceCount: newCount
                }
            },()=>{
                this.props.typeWrapper === "full" &&   this.handleOk()
            });

            this.setState({
                unCategoryResourcesSelected,
                showResetBtn: true,
            })


        }
        else {
            // اگر منبع انتخاب شده در لیست منابع انتخاب شده نبود
            if (!unCategoryResourcesSelected[selectedPlatform].find(x => x.id === item.id)) {

                if (finalSingleSelect) {

                    // جایگزین کردن منبع انتخاب شده به لیست منابع انتخاب شده ی دسته بندی نشده
                    // ایجاد دسته بندی خالی
                    unCategoryResourcesSelected[selectedPlatform] = [];

                    // افزودن منبع به لیست
                    unCategoryResourcesSelected[selectedPlatform].push(item);

                    this.setState({
                        unCategoryResourcesSelected,
                        showResetBtn: true,
                    })
                }
                else {


                    // افزودن منبع انتخاب شده به لیست منابع انتخاب شده ی دسته بندی نشده
                    unCategoryResourcesSelected[selectedPlatform].push(item);

                    this.setState((state) => {
                        let newCount = state.finalTotalResourceCount;
                        newCount[platforms[selectedPlatform]] = newCount[platforms[selectedPlatform]] + 1;

                        let selectedCount = 0;

                        Object.keys(newCount).forEach((item) => {

                            selectedCount = selectedCount + newCount[item];
                        })
                        return {
                            finalTotalResourceCount: newCount,
                            unCategoryResourcesSelected,
                            showResetBtn: true,
                            selectedCount
                        }
                    },()=>{
                        this.props.typeWrapper === "full" &&   this.handleOk()
                    });

                }

                // اگر منبع انتخاب شده در لیست منابع انتخاب شده بود
            }
            else {

                if (this.props.editMode) {
                    unCategoryResourcesSelected[selectedPlatform].find((x, key) => {
                        if (x.id === item.id) {

                            if (!_.isUndefined(unCategoryResourcesSelected[selectedPlatform][key].isAdded)) {
                                unCategoryResourcesSelected[selectedPlatform][key].isAdded = true;
                                unCategoryResourcesSelected[selectedPlatform][key].isRemoved = false;
                            }
                        }
                    })

                    this.setState({
                        showResetBtn: true,
                        unCategoryResourcesSelected
                    });
                }
            }
        }
    }


    removeUnCategoryResource(id, index, changeFlag) {

        const { platforms } = this.props;

        let { unCategoryResourcesSelected, selectedPlatform } = this.state;

        // پبدا کردن منبع از لیست منابع انتخاب شده
        const source = unCategoryResourcesSelected[selectedPlatform].find(x => x.id === id);

        // اگر منبع وجود داشت
        if (source) {

            this.setState((state) => {
                let newCount = state.finalTotalResourceCount;
                newCount[platforms[selectedPlatform]] = newCount[platforms[selectedPlatform]] - 1;

                let selectedCount = 0;

                Object.keys(newCount).forEach((item) => {

                    selectedCount = selectedCount + newCount[item];
                })
                return {
                    finalTotalResourceCount: newCount,
                    selectedCount
                }
            },()=>{
                this.props.typeWrapper === "full" &&   this.handleOk()
            });

            // اگر در حالت ویرایش بود به آیتم دیلیت شده تگ دیلیت بزنه
            if (this.props.editMode) {
                // اگه از لیست انتخاب شده های قبلی حذف شد
                if (changeFlag) {
                    unCategoryResourcesSelected[selectedPlatform][index].isRemoved = true;
                    unCategoryResourcesSelected[selectedPlatform][index].isAdded = false;
                }
                // اگه از لیست انتخاب شده های جدید حذف شد
                else {
                    unCategoryResourcesSelected[selectedPlatform].splice(index, 1);
                }


            } else {

                // حذف منبع از لیست انتخاب شده ها 
                unCategoryResourcesSelected[selectedPlatform].splice(index, 1);
            }


        }

        this.setState({
            unCategoryResourcesSelected,
            showResetBtn: true,
        });
    }

    /** 
     * به‌روزرسانی منابع خروجی
     */
    updateParentApplicant(ids, flatIds, added, removed, Objcs) {

        const { getResources, name } = this.props;

        getResources(ids, flatIds, added, removed, Objcs, name);
    }

    /**
     * گرفتن منابع انتخاب شده از قبل ( ویرایش)      
     * type: مشخص میکنه که همه بسترها رو بگیره یا بک بستر مشخص اگر هه هست all در غیر این صورت  رشته دیگری مثل first
     * index : اگر همه بسترها نبود ایندکس بستر رو میفرسته
     */
    getSelectedResourcesInternally(type, index, q) {

        const {
            selectedResourceApi,
            platforms,
            selectedResources,
            selectedResourceCount } = this.props;

        const {
            currentSources,
            selectedCurrentPage,
            unCategoryResourcesSelected,
            platformsSelectedCount,
        } = this.state;

        const tempPage = selectedCurrentPage[index] ? selectedCurrentPage[index] : 1;

        if (selectedResourceApi || !_.isEmpty(selectedResources)) {

            if (type === "all") {

                platforms.forEach((item, index) => {

                    api.get(selectedResourceApi, { platform: item, page: tempPage })
                        .then(response => response.data.result)
                        .then(response => {

                            this.setState({
                                unCategoryResourcesSelected: {
                                    ...unCategoryResourcesSelected,
                                    [index]: response.data.map((item) => ({ ...item, platform: index }))
                                },
                                platformsSelectedCount: {
                                    ...platformsSelectedCount,
                                    [index]: response.total
                                },
                                selectedCurrentPage: {
                                    ...selectedCurrentPage,
                                    [index]: tempPage
                                }
                            })

                        }).catch(error => {
                            UiToast.exceptions(error);
                            this.setState({
                                unCategoryResourcesSelected: {
                                    ...unCategoryResourcesSelected,
                                    [index]: []
                                }
                            })
                        })
                });

                this.setState({
                    selectedCount: selectedResourceCount,
                    modalVisible: false
                })
            }
            else {

                let newData;

                if (!_.isEmpty(selectedResources)) {

                    let responseData = [];
                    selectedResources.forEach((item) => {
                        if (!_.isEmpty(item.platform) && item.platform === platforms[index]) {
                            responseData = item.objs;
                        }
                    });

                    newData = responseData.map((items) => {
                        return { ...items, platform: index };
                    });

                    if (this.state.unCategoryResourcesSelected[index]) {

                        // آیتم هایی که کم یا زیاد شده رو از لیست اصلی جدا میکنه 
                        const addOrRemovedItems = this.state.unCategoryResourcesSelected[index].filter(x => (x.isRemoved || x.isAdded))

                        // تو داده هایی که تازه گرفته شده اونهایی که تو لیست بالا هست رو برمیداره
                        addOrRemovedItems.forEach((item, index) => {
                            const tempIndex = newData.findIndex(x => x.id === item.id);
                            if (tempIndex > -1)
                                newData.splice(tempIndex, 1)
                        })

                        // داده های جدید رو با حذف و اضافه شده های قبلی مجتمع میکنیم
                        newData = [...newData, ...addOrRemovedItems];
                    }

                    this.setState({
                        unCategoryResourcesSelected: {
                            ...this.state.unCategoryResourcesSelected,
                            [index]: newData
                        },
                        selectedResourceLoading: {
                            ...this.state.selectedResourceLoading,
                            [index]: true
                        },
                        currentSources: {
                            ...currentSources,
                            [index]: responseData.map((item) => ({ ...item, platform: index }))
                        },
                        platformsSelectedCount: {
                            ...this.state.platformsSelectedCount,
                            [index]: responseData.length
                        },
                        searchSelectedLoading: false,
                        selectedCurrentPage: {
                            ...selectedCurrentPage,
                            [index]: tempPage
                        }
                    })
                }

                else {

                    const params = {
                        platform: platforms[index],
                        page: tempPage
                    }

                    if (q)
                        params.q = q;

                    api.get(selectedResourceApi, params)
                        .then(response => response.data.result)
                        .then(response => {

                            // دیتای جدید گرفته شده از ای پی آی
                            newData = response.data.map((item) => {

                                return { ...item, platform: index };

                            });

                            if (this.state.unCategoryResourcesSelected[index]) {

                                // آیتم هایی که کم یا زیاد شده رو از لیست اصلی جدا میکنه 
                                const addOrRemovedItems = this.state.unCategoryResourcesSelected[index].filter(x => (x.isRemoved || x.isAdded))

                                // تو داده هایی که تازه گرفته شده اونهایی که تو لیست بالا هست رو برمیداره
                                addOrRemovedItems.forEach((item, index) => {

                                    const tempIndex = newData.findIndex(x => x.id === item.id);

                                    if (tempIndex > -1)
                                        newData.splice(tempIndex, 1)
                                })

                                // داده های جدید رو با حذف و اضافه شده های قبلی مجتمع میکنیم
                                newData = [...newData, ...addOrRemovedItems];
                            }

                            this.setState({
                                unCategoryResourcesSelected: {
                                    ...this.state.unCategoryResourcesSelected,
                                    [index]: newData
                                },
                                selectedResourceLoading: {
                                    ...this.state.selectedResourceLoading,
                                    [index]: true
                                },
                                currentSources: {
                                    ...currentSources,
                                    [index]: response.data.map((item) => ({ ...item, platform: index }))
                                },
                                platformsSelectedCount: {
                                    ...this.state.platformsSelectedCount,
                                    [index]: response.meta.total
                                },
                                searchSelectedLoading: false,
                                selectedCurrentPage: {
                                    ...selectedCurrentPage,
                                    [index]: tempPage
                                }
                            })

                        }).catch(error => {
                            UiToast.exceptions(error);
                            this.setState({
                                unCategoryResourcesSelected: {
                                    ...this.state.unCategoryResourcesSelected,
                                    [index]: []
                                }
                            })
                        })
                }
            }
        }
    }

    /**
   * ریست کردن کامپوننت
   */
    reset(modalState) {

        const {
            selectedResources,
            selectedResourceCount , platforms } = this.props;

            let finalTotalResourceCount = {};

            platforms.forEach((item) => {

                finalTotalResourceCount[item] = 0;
            })

            this.setState({
                finalTotalResourceCount
            })

        this.setState({
            modalVisible: modalState,
            searchLoadedResources_Term: '',
            currentCategoryIndex: null,
            advancedSearch: false,
            flatSelectedResourcesIDs: [],
            outputResult: [],
            unCategoryResourcesSelected: (!_.isEmpty(selectedResources) && selectedResources?.[0]?.objs?.length > 0) ? { [0]: _.cloneDeep(selectedResources[0]?.objs) } : {},
            selectedCount: selectedResourceCount ? selectedResourceCount : 0,
            selectedResourceLoading: {},
            selectedCurrentPage: {},
            platformsSelectedCount: {},
            perPage: 100,
            showResetBtn: false,
            currentSources: {},
            currentPage: 1,
            unCategoryResources: [],
            finalTotalResourceCount,
            unCategoryResourcesCount: ""
        })
    }

    /**
     * پجینیشن
     * @param {*} page صفحه
     */
    pageChange(page) {

        this.setState({
            currentPage: page
        });
    }

    /**
     * پجینیشنانتخاب شده ها
     * @param {*} page صفحه
     */
    selectedPageChange(page) {

        const {
            selectedCurrentPage,
            selectedPlatform } = this.state;

        this.setState({
            selectedCurrentPage: {
                ...selectedCurrentPage,
                [selectedPlatform]: page
            },
            searchSelectedLoading: true
        }, () => {
            this.getSelectedResourcesInternally("pagination", selectedPlatform)
        });

    }

    /**
     * کنترل لودینگ سرچ
     * @param {*} value مقدار لویدنگ سرچ
     */
    handleSearchLoading(value) {

        this.setState({
            searchLoading: value
        })
    }

    /**
     * تغییر مقدار اینپوت سرچ در انتخاب شده ها
     */
    inputChange(name,value) {

        this.setState({
            [name]: value
        })
    }

    /**
     * جستجو در منابع انتخاب شده
     */
    searchSelectdResource() {

        const {
            selectedPlatform,
            selectedSearchQ,
            selectedCurrentPage } = this.state;

        if (selectedSearchQ) {

            this.setState({
                searchSelectedLoading: true,
                selectedCurrentPage: {
                    ...selectedCurrentPage,
                    [selectedPlatform]: 1
                }

            }, () => this.getSelectedResourcesInternally("index", selectedPlatform, selectedSearchQ))
        }
    }

    /**
     * کنسل کردن سرچ
     */
    cancleSearch() {

        const {
            selectedPlatform,
            selectedCurrentPage } = this.state;

        this.setState({
            searchSelectedLoading: true,
            selectedCurrentPage: {
                ...selectedCurrentPage,
                [selectedPlatform]: 1
            }
        }, () => this.getSelectedResourcesInternally("index", selectedPlatform))
    }

    componentDidMount(){
        this.props.typeWrapper === "full" &&  this.showModal()
    }
    componentDidUpdate(prev) {

        const {
            platforms,
            selectedResourceCount,
            reset,
            totalResourceCount,
            selectedResources } = this.props;

        if (selectedResourceCount !== prev.selectedResourceCount) {

            this.setState({
                selectedCount: this.props.selectedResourceCount
            })
        }

        if (reset !== prev.reset) {

            this.reset(false)
        }

        if (totalResourceCount !== prev.totalResourceCount) {

            let finalTotalResourceCount = _.cloneDeep(totalResourceCount)

            this.setState({
                finalTotalResourceCount
            },()=>{
                this.props.typeWrapper === "full" &&   this.handleOk()
            })
        }

        if (_.isUndefined(totalResourceCount) && _.isUndefined(this.state.finalTotalResourceCount) && platforms && !_.isUndefined(platforms)) {

            let finalTotalResourceCount = {};

            platforms.forEach((item) => {

                finalTotalResourceCount[item] = 0;
            })

            this.setState({
                finalTotalResourceCount
            })
        }

        if (platforms !== prev.platforms) {

            this.reset(false);

            let finalTotalResourceCount = {};

            platforms.forEach((item) => {
                finalTotalResourceCount[item] = 0;
            })

            this.setState({
                finalTotalResourceCount
            },()=>{
                this.props.typeWrapper === "full" &&   this.handleOk()
            })
        }

        if (selectedResources !== prev.selectedResources && _.isEmpty(selectedResources)) {

            this.reset(false);
        }
    }

    /**
     * گرفتن منابع انتخاب شده زمانیکه از بیرون داده شده
     */
    getSelectedResourcesExternally() {

        const {
            selectedResources
        } = this.props

        const {
            unCategoryResourcesSelected
        } = this.state

        this.setState({
            unCategoryResourcesSelected: {
                ...unCategoryResourcesSelected,
                [0]: _.cloneDeep(selectedResources[0].objs)
            },
            selectedResourceLoading: {
                [0]: true
            }
        })
    }





    render() {

        return <ResourcesView
            showModal={this.showModal}
            handleOk={this.handleOk}
            handleCancel={this.handleCancel}
            selectPlatform={this.selectPlatform}
            flatten={this.flatten}
            renderResources={this.renderResources}
            renderResourcesSelected={this.renderResourcesSelected}
            getUnCategoryResources={this.getUnCategoryResources}
            addToSelectionUnCategoryList={this.addToSelectionUnCategoryList}
            removeUnCategoryResource={this.removeUnCategoryResource}
            handleClose={this.handleClose}
            getUnCategoryResourcesCount={this.getUnCategoryResourcesCount}
            pageChange={this.pageChange}
            selectedPageChange={this.selectedPageChange}
            handleSearchLoading={this.handleSearchLoading}
            source={source}
            inputChange={this.inputChange}
            searchSelectdResource={this.searchSelectdResource}
            cancleSearch={this.cancleSearch}
            onEnter={onEnter}
            onChangeSmartSearch={this.onChangeSmartSearch}
            {...this.state}
            {...this.props}
        />
    }
}

export default Resources;