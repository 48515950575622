import React from "react";
import { Row, Col } from "antd";
import { isEmpty } from "lodash";
import { FormattedSimpleMsg } from "GlobalFunctions";
import { injectIntl } from "react-intl";
import { UiSelect } from "ui-components";

const FaSelectField = (props) => {
    const { name, platformParams, item, noSelected, handleSelect, size, intl ,typeDescriptionFilter ,theme} = props;

    const selected = isEmpty(platformParams)
        ? {}
        : platformParams[name]
        ? {
              value: platformParams[name],
              label: platformParams[name],
          }
        : noSelected;

    let options = item.enum.map((val, index) => ({ value: val, label: val }));

    options = [noSelected, ...options];

    const { formatMessage } = intl;

    return (
        <Row key={name} align="middle" type="flex" gutter={12}>
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: `strings.${name}` })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col span={size === "md" ? 9 : size === "lg" ? 6 : size === "sm" ? 18 : 9}>
                {/* این مورد اصلاح شد ولی پیدا نشد کجاست */}
                <UiSelect
                    placeholder={<FormattedSimpleMsg id="filters.fa_select.not_have" />}
                    value={selected}
                    onChange={(val) => handleSelect(val, name)}
                    list={options}
                    size="xs"
                    theme={theme}
                    className="force"
                />
            </Col>
        </Row>
    );
};

export default injectIntl(FaSelectField);
