import React, { useContext } from "react";
import { UiTableStateContext } from "../context/UiTableContext";
import PropTypes from "prop-types";
import UiTableCell from "../cell/UiTableCell";
import UiTableValueNormalizer from "../row/UiTableValueNormalizer";

/**
 * قسمت کولپس باز شده بعد از هر ردیف
 * @param {*Object} item آیتم یا رکورد
 */
const UiTableExpended = ({ item }) => {
    const tableState = useContext(UiTableStateContext);
    const expandableFields = tableState?.expandableFields;
    return (
        <div className="ui-table-row expended" style={{ gridTemplateColumns: "100%" }}>
            {expandableFields?.map((field, key) => {
                return (
                    <UiTableCell key={key} field={field} item={item}>
                        <UiTableValueNormalizer field={field} value={item[field]} />
                    </UiTableCell>
                );
            })}
        </div>
    );
};
UiTableExpended.propTypes = {
    item: PropTypes.object,
};
export default UiTableExpended;
