import React from "react";
import {
    Spin,
} from 'antd';
import { FormattedSimpleMsg } from "GlobalFunctions";
import _ from 'lodash'
import ReactPaginate from 'react-paginate';
import ResourcesViewTable from "./ResourcesViewTable";
import ResourcesViewLeftPartSearch from "./ResourcesViewLeftPartSearch";



const ResourcesViewLeftPart = ({
    platforms,
    selectedPlatform,
    unCategoryResourcesSelected,
    removeUnCategoryResource,
    selectedResourceLoading,
    editMode,
    perPage,
    selectedPageChange,
    platformsSelectedCount,
    selectedCurrentPage,
    selectedSearchQ,
    inputChange,
    searchSelectdResource,
    searchSelectedLoading,
    intl,
    selectedResources
}) => {

    const { formatMessage } = intl;

    const unCategoryResourcesSelectedInPlatform = unCategoryResourcesSelected[selectedPlatform];

    let allItemsNew = true;

    unCategoryResourcesSelectedInPlatform && unCategoryResourcesSelectedInPlatform.forEach((item) => {

        if (!item.isRemoved && !item.isAdded) {

            allItemsNew = false;

            return;
        }
    });

    let selectedPageCount = platformsSelectedCount[selectedPlatform] ? Math.ceil(platformsSelectedCount[selectedPlatform] / perPage) : 0

    return (

        <>

            <div className="source-box-wrapper">

                <header> <FormattedSimpleMsg id="sourceSelect.selected_resources" /> </header>

                <div className="source-box">

                    {/* قسمت جستجو */}
                    {!!editMode && 
                    
                    <div className="search flex selected-search" >

                        <ResourcesViewLeftPartSearch 
                            inputChange={inputChange}
                            searchSelectdResource={searchSelectdResource}
                            selectedSearchQ={selectedSearchQ}
                            searchSelectedLoading={searchSelectedLoading}
                            placeholder={formatMessage({ id: 'sourceSelect.write_q' })}
                        />

                    </div>}

                    {!!editMode && !selectedResources &&  < Spin spinning={!selectedResourceLoading[selectedPlatform]}></Spin>}

                    {!!editMode && < Spin spinning={searchSelectedLoading}></Spin>}

                    {!searchSelectedLoading &&
                        <div>

                            {/* منابع انتخاب شده بدون دسته بندی در بستر جاری */}
                            {unCategoryResourcesSelectedInPlatform &&
                                !_.isEmpty(unCategoryResourcesSelectedInPlatform) &&
                                !allItemsNew &&

                                <ResourcesViewTable
                                    userSelect="leftTop"
                                    withHeader={true}
                                    data={unCategoryResourcesSelectedInPlatform}
                                    removeUnCategoryResource={removeUnCategoryResource}
                                    addToSelectionUnCategoryList={() => { }}
                                    deleteIcon={true}
                                    editMode={editMode}
                                    platform={platforms[selectedPlatform]}
                                    scrollbarStyle={{ width: '100%', height: editMode ? 200 : 550 }}
                                />
                            }

                        </div>
                    }

                    {!!selectedPageCount && selectedPageCount > 1 && !searchSelectedLoading && (selectedResources || selectedResourceLoading[selectedPlatform]) &&
                        <div className="source-select-pagination pagination-wrapper ">
                            <ReactPaginate
                                pageCount={selectedPageCount}
                                containerClassName="pagination"
                                activeClassName="active"
                                previousClassName="previous"
                                nextClassName="next"
                                previousLabel=""
                                nextLabel=""
                                pageRangeDisplayed={3}
                                forcePage={selectedCurrentPage[selectedPlatform] - 1}
                                onPageChange={(curr) => selectedPageChange(curr.selected + 1)}
                            />
                            <div>
                                {platformsSelectedCount[selectedPlatform]}
                            </div>
                        </div>
                    }

                    {!!editMode && !searchSelectedLoading && (selectedResources || selectedResourceLoading[selectedPlatform]) &&

                        <ResourcesViewTable
                            userSelect="leftBottom"
                            withHeader={allItemsNew}
                            data={unCategoryResourcesSelectedInPlatform}
                            removeUnCategoryResource={removeUnCategoryResource}
                            addToSelectionUnCategoryList={() => { }}
                            deleteIcon={true}
                            editMode={editMode}
                            platform={platforms[selectedPlatform]}
                            scrollbarStyle={{ width: '100%', height: allItemsNew ? 550 : 310, borderTop: allItemsNew ? "unset" : "1px solid gray", paddingTop: allItemsNew ? "unset" : "20px" }}
                        />
                    }
                </div>
            </div>
        </>
    )
}
export default ResourcesViewLeftPart;