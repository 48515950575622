import React, { Component } from 'react';
import { AppContextProvider } from './AppContext';


class WrapperAppContext extends Component {

    state = {
        planningBoxMsgCount: {}, // { 1190 : 4, 1193 : 18} //BOX_PLANNING Message Count on header
        receivedPlanBoxesItem: {},
        receivedNewDirect:null
    }

    /**
     * update BOX_PLANNING Message Count on header
     */
    updatePlanningBoxMsgCount = (boxId, value) => {

        let { planningBoxMsgCount } = this.state;
        // for set specified value exactly
        if (value) {
            planningBoxMsgCount[boxId] = value;
        }
        // add +1 to box ms count
        else {
            planningBoxMsgCount[boxId] = planningBoxMsgCount[boxId] ? planningBoxMsgCount[boxId] + 1 : 1;
        }
        this.setState({
            planningBoxMsgCount
        })
    }

    setReceivedPlanBoxesItem = (box_id , itemList, others) => {
        this.setState({
            receivedPlanBoxesItem: {[box_id] : itemList, ...others},
        })
    }

    setReceivedNewDirect = (data) => {
        this.setState({
            receivedNewDirect: data,
        })
    }


    render() {

        const providerValue = {
            state: this.state,
            methods: {
                updatePlanningBoxMsgCount: this.updatePlanningBoxMsgCount,
                setReceivedPlanBoxesItem: this.setReceivedPlanBoxesItem,
                setReceivedNewDirect: this.setReceivedNewDirect
            }
        }
        return (
            <AppContextProvider value={providerValue} >
                {this.props.children}
            </AppContextProvider>

        );
    }
}

export default WrapperAppContext;