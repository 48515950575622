import React from "react";
import PropTypes from "prop-types";
import { UiButton } from "ui-components";

/**
 * اکشن بروزرسانی
 * @param {*Object} action آبجکت اکشن
 * @param {*Number} itemId شناسه رکورد
 */
const UiActionRefresh = ({ action }) => {
    return (
        <UiButton
            type="icon"
            className={`icon ${action.icon}`}
            size="xxs"
            tooltip={action.tooltip}
            disabled={action.disabled}
        />
    );
};
UiActionRefresh.propTypes = {
    action: PropTypes.object,
    itemId: PropTypes.number,
};

export default UiActionRefresh;
