import React from "react";
import PropTypes from "prop-types";
import "./UiLabel.scss";

const UiLabel = ({ children, className, theme, size }) => {
    return <label className={[className, "ui-label", theme, size].join(" ")}>{children}</label>;
};

UiLabel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    theme: PropTypes.string,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

export default UiLabel;
