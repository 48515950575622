import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./UiMessage.scss";

const UiMessage = (props) => {
    const { closeText, onClose, closable = false, message = "test", showIcon = true, icon, type = "success" , className } = props;
    const [closed, setClosed] = useState(false);

    const closeIcon = "x";
    const isClosable = closeText ? true : closable;
    const isShowIcon = icon ? true : showIcon;

    const getIcon = () => {
        if (icon) return `icon ${icon}`;
        else {
            let iconType = "";
            switch (type) {
                case "success":
                    iconType = "icon icon-checkbox-active";
                    break;
                case "error":
                    iconType = "icon icon-warning";
                    break;
                case "warning":
                    iconType = "icon icon-exclamation";
                    break;
                case "info":
                    iconType = "icon icon-exclamation";
                    break;
                default:
                    iconType = "icon icon-exclamation";
                    break;
            }
            return iconType;
        }
    };

    const handleClose = (e) => {
        setClosed(true);
        typeof onClose === "function" && onClose(e);
    };

    useEffect(() => {}, []);

    if (closed) return null;

    return (
        <>
            <div className={["ui-message", type , className].join(" ")}>
                {isShowIcon && <i className={["ui-message-icon", getIcon()].join(" ")}></i>}
                {message && <span className="ui-message-description">{message}</span>}
                {isClosable && (
                    <span role="button" className="ui-message-close-icon" onClick={handleClose}>
                        {closeIcon}
                    </span>
                )}
            </div>
        </>
    );
};

UiMessage.propTypes = {
    theme: PropTypes.oneOf(["dark", "light"]),
    className: PropTypes.string,
    type: PropTypes.oneOf(["success", "info", "warning", "error"]),
    onClose: PropTypes.func,
    message: PropTypes.node,
    closable: PropTypes.bool,
    closeText: PropTypes.node,
    showIcon: PropTypes.bool,
    icon: PropTypes.node,
    dataTest: PropTypes.string,
};

export default UiMessage;
