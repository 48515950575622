import React, { useEffect, useRef , useLayoutEffect } from "react";
import PropTypes from "prop-types";
import "./UiTooltip.scss";
import { PortalWithState } from "react-portal";
import { isEmpty } from "lodash";
import usePosition from "./usePosition";
import PortalContent from "./PortalContent";

export let nodesList = [];
/**
 * تولتیپ
 * @param {*node} children
 * @param {*node} title
 * @param {*node} overlay
 * @param {*string} className
 * @param {*bool} unVisible
 * @param {*number} mouseEnterDelay
 * @param {*number} mouseLeaveDelay
 * @param {*bool} fixed
 * @param {*bool} forceClose
 * @param {*string} portalName
 * @param {*func} onVisibleChange
 * @param {*string} theme oneOf(["dark", "light"]),
 * @param {*string} trigger oneOf(["hover", "click", "contextmenu"]),
 * @param {*string} placement oneOf([
        "top",
        "topLeft",
        "topRight",
        "bottom",
        "bottomLeft",
        "bottomRight",
        "left",
        "leftTop",
        "leftBottom",
        "right",
        "rightTop",
        "rightBottom",
    ]),
 */
const UiTooltip = (props) => {
    const {
        children,
        title,
        theme,
        className,
        placement = "top",
        unVisible,
        mouseEnterDelay = 400,
        trigger = "hover",
        portalName,
        onVisibleChange,
        forceClose,
    } = props;
    // ایجاد تاخیر در باز شدن پورتال
    const openPortalTimeout = useRef(null);
    // محتوایی که با انجام یک اکشن بر روی آن، پورتال باز می‌شود
    const nodeRef = useRef(null);
    // نگه داری وضعیت باز یا بسته بودن پورتال
    const isOpenPortal = useRef(false);
    // پورتالی که در نهایت باز میشود
    const portalRef = useRef(null);
    // مختصات و محل نمایش پورتال
    const [position, currentPlacement, onOpen] = usePosition(portalRef, nodeRef, placement, trigger);

    useEffect(() => {
        nodesList = [...nodesList, nodeRef];
        return () => {
            nodesList = [];
        };
    }, []);

    useLayoutEffect(() => {
        onOpen();
    }, []);

    /**
     * ایجاد تاخیر در نمایش
     * @param {*} func
     */
    const delay = (func) => {
        // برای این مقدار دهی میشه که در زمان اسکرول تشخیص بدیم اگر باز است، اون رو ببندیم
        isOpenPortal.current = true;

        // اگر تاخیر در باز شدن نداشت
        if (mouseEnterDelay === 0) {
            typeof func === "function" && func();
            typeof onVisibleChange === "function" && onVisibleChange(true);
            return;
        }
        clearTimeout(openPortalTimeout.current);
        // ایجاد تاخیر در باز شدن
        openPortalTimeout.current = setTimeout(() => {
            typeof func === "function" && func();
            typeof onVisibleChange === "function" && onVisibleChange(true);
            clearTimeout(openPortalTimeout.current);
        }, mouseEnterDelay);
    };

    /**
     * لغو تاخیر ایجاد شده
     * @param {*} func
     */
    const clearDelay = (callback, onlyClear) => {
        // کلیر کردن به این معنیه که اگر موس را از روی آیتم بیرون برد، متد باز کننده دیگه اجرا نشه
        clearTimeout(openPortalTimeout.current);

        // اگر فقط نیاز به خالی کردن تایم اوت باشد که فقط از باز شدن پورتال جلوگیری کنه
        if (onlyClear) return;

        typeof callback === "function" && callback();
        typeof onVisibleChange === "function" && onVisibleChange(false);
    };

    return (
        <PortalWithState closeOnEsc onOpen={onOpen}>
            {({ openPortal, closePortal, portal, isOpen }) => (
                <>
                    <PortalContent
                        nodeRef={nodeRef}
                        openPortal={openPortal}
                        closePortal={closePortal}
                        delay={delay}
                        clearDelay={clearDelay}
                        trigger={trigger}
                        portalName={portalName}
                    >
                        {children}
                    </PortalContent>
                    {!isEmpty(title) &&
                        !unVisible &&
                        portal(
                            !forceClose && (
                                <div
                                    id="ui-tooltip-root"
                                    className={`ui-tooltip ${className} ${theme} ${isOpen ? "ui-tooltip-active" : ""}`}
                                    style={position}
                                    ref={portalRef}
                                    onClick={portalName === "menu" ? closePortal : void 0}
                                >
                                    <div className={`ui-tooltip-content ${currentPlacement}`}>{title}</div>
                                </div>
                            ),
                        )}
                </>
            )}
        </PortalWithState>
    );
};

UiTooltip.propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
    overlay: PropTypes.node,
    className: PropTypes.string,
    unVisible: PropTypes.bool,
    mouseEnterDelay: PropTypes.number,
    mouseLeaveDelay: PropTypes.number,
    fixed: PropTypes.bool,
    forceClose: PropTypes.bool,
    portalName: PropTypes.string,
    onVisibleChange: PropTypes.func,
    theme: PropTypes.oneOf(["dark", "light"]),
    trigger: PropTypes.oneOf(["hover", "click", "contextmenu"]),
    placement: PropTypes.oneOf([
        "top",
        "topLeft",
        "topRight",
        "bottom",
        "bottomLeft",
        "bottomRight",
        "left",
        "leftTop",
        "leftBottom",
        "right",
        "rightTop",
        "rightBottom",
    ]),
};

export default UiTooltip;
