import React from "react";
import { Row, Col } from "antd";
import UserAccess from "../../permission/UserAccess";
import DateTimeFromToFilter from "./DateTimeFromToFilter";
import { FormattedSimpleMsg, changeTimeDurationToString } from "GlobalFunctions";
import { injectIntl } from "react-intl";
import { isNumber } from "lodash";
import { UiRadio, UiRadioGroup } from "ui-components";

const DateTimePickerRangeField = (props) => {
    const {
        name,
        platformParams,
        handleDatePicker,
        handleClearDatePicker,
        handleTimeDuration,
        size,
        intl,
        typeDescriptionFilter,
        theme,
    } = props;

    if (isNumber(platformParams.time_duration)) {
        platformParams.time_duration = changeTimeDurationToString(platformParams.time_duration);
    }

    const { formatMessage } = intl;

    return (
        <>
            {/*<UserAccess can="SearchTimeInterval" type="SearchTimeInterval">*/}
            <Row key={name} type="flex">
                <Col lg={6} xl={size === "md" ? 6 : 4}>
                    <span className="row-label">
                        {formatMessage({ id: "strings.timespan" })}
                        {typeDescriptionFilter}
                    </span>
                </Col>
                <Col
                    lg={18}
                    xl={size === "md" ? 18 : 20}
                    className={`custom-date-picker-form-item ${size === "sm" ? "small" : ""}`}
                >
                    <DateTimeFromToFilter
                        data={platformParams}
                        handleDatePicker={handleDatePicker}
                        handleClearDatePicker={handleClearDatePicker}
                    />
                </Col>
            </Row>
            {/* زمان های یک روز و یک هفته و یک ماه */}
            {/*</UserAccess>*/}
            {/*<UserAccess can="SearchTimeDuration" type="SearchTimeDuration">*/}

            {handleTimeDuration && (
                <Row type="flex">
                    <Col lg={6} xl={size === "md" ? 6 : 4}>
                        <span className="row-label" />
                    </Col>

                    <Col lg={18} xl={size === "md" ? 18 : 20} className="custom-date-picker-form-item">
                        {/* رادیو باتن بازه زمانی در مودال فیلترها */}
                        <UiRadioGroup
                            name="time_duration"
                            theme={theme}
                            value={platformParams.time_duration}
                            onChange={(value) => handleTimeDuration(value, "time_duration")}
                            className="ant-col-22 time_duration"
                        >
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio
                                    theme={theme}
                                    disabled={!UserAccess.isAllowTimeDuration("today")}
                                    value="today"
                                >
                                    <FormattedSimpleMsg id="filters.date_time_range_general.today" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("6h")} value="6h">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.6h" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("24h")} value="24h">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.24h" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("1w")} value="1w">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.1w" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("2w")} value="2w">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.2w" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("1m")} value="1m">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.1m" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("6m")} value="6m">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.6m" />
                                </UiRadio>
                            </Col>
                            <Col span={size === "sm" ? 12 : 6}>
                                <UiRadio theme={theme} disabled={!UserAccess.isAllowTimeDuration("12m")} value="12m">
                                    <FormattedSimpleMsg id="filters.date_time_range_general.12m" />
                                </UiRadio>
                            </Col>
                            {UserAccess.isUnlimitedTime() && (
                                <Col span={size === "sm" ? 12 : 6}>
                                    <UiRadio theme={theme} value="-1">
                                        <FormattedSimpleMsg id="filters.date_time_range_general.unlimited" />
                                    </UiRadio>
                                </Col>
                            )}
                            <Col span={6}>
                                <UiRadio theme={theme} value="">
                                    هیچ کدام
                                </UiRadio>
                            </Col>
                            {/* <Col span={size === "sm" ? 12 : 6} ><Radio value="nothing" ><FormattedSimpleMsg id="filters.date_time_range_general.none" /></Radio></Col> */}
                        </UiRadioGroup>
                    </Col>
                </Row>
            )}

            {/*</UserAccess>*/}
        </>
    );
};
export default injectIntl(DateTimePickerRangeField);
