import axios from 'axios';
import { helper } from "services";
import { root as path } from 'GlobalVariables'
import moment from 'moment';
import qs from 'qs';
import _ from "lodash"
// axios.defaults.timeout = 15000;
let userInfoData;
// (get,post,put,delete,all,isAuthorize,headers) methods from this object
let apiService = {
  get: (address, query, customHeader, cancellation) => {
    if (query) {
      var queryString = qs.stringify(query);
      address = address + "?" + queryString;
    }
    return cancellation ? axios.get(path + address, { ...cancellation, ...apiService.headers(customHeader) }) : axios.get(path + address, apiService.headers(customHeader))
  },
  post: (address, data, customHeader,cancellation) => {
    return cancellation ? 
      axios.post(path + address, data, { ...cancellation, ...apiService.headers(customHeader)}) : 
      axios.post(path + address, data, apiService.headers(customHeader))
  },
  put: (address, data, customHeader) => {
    return axios.put(path + address, data, apiService.headers(customHeader))
  },
  patch: (address, data, customHeader) => {
    return axios.patch(path + address, data, apiService.headers(customHeader))
  },
  delete: (address, data, customHeader) => {
    return axios.delete(path + address, { data, ...apiService.headers(customHeader) })
  },
  all: (arrMethodes) => {
    return axios.all(arrMethodes)
  },
  serialize: (obj, prefix) => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          apiService.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  },

  // کنترل لاگین بودن کاربر
  isAuthorize: () => {
    if (apiService.userInfo()) {
      //       var s =  moment(apiService.userInfo().expires_at);
      //     let a =  moment(s).utcOffset(s);
      // f
      const expDate = moment(apiService.userInfo().expires_at).valueOf();
      const curentDate = moment().valueOf();
      if (expDate < curentDate) {
        // اگر تاریخ اکسپایر کاربر فرا رسیده بود
        localStorage.clear();
        window.location.href = "login";
      }
      // اگر کاربر لاگین بود
      return true;

    } else {
      return false;
    }
  },
  // افزودن هدر به متد های http
  // مقدار customHeader میتواند مقدار هدر را از ورودی دریافت کند
  headers: (customHeader) => {
    // دیفالت
    let headers = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    // اگر کاربر لاگین بود توکن ارسال شود
    if (apiService.userInfo()) {
      headers.headers['Authorization'] = apiService.userInfo().access_token;
    }

    // روش استفاده از customHeader :  [{"key":"value"}]
    if (customHeader && customHeader.length > 0) {
        customHeader.forEach((item) => {
            headers.headers[Object.keys(item)[0]] = Object.values(item)[0];
        });
    }
    //headers.headers = {...headers.headers,...customHeader};
    return headers;

  },
  // اطلاعات کامل مربوط به کاربر را  بر میگرداند. مانند توکن، پریست ها و ...
  userInfo: () => {
    const storageToken = localStorage.getItem("token")

    if (!storageToken) return null;

    if (!userInfoData || _.isEmpty(userInfoData))
      userInfoData = JSON.parse(helper.decode(storageToken, "token"));

    return userInfoData;
  }
}

export default apiService;
