import React from "react";
import { Row, Col } from "antd";
import _ from "lodash";
import { UiMultiSelect } from "ui-components";
const MediaTypesField = (props) => {
    const { name, formatMessage, platformParams, item, typeDescriptionFilter, handleMultiSelect, size ,theme} = props;

    let options = item?.items?.enum?.map((val, index) => ({
        value: val,
        label: formatMessage({ id: "filtersVal." + val }),
    }));

    const selectedX =
        _.isEmpty(platformParams) || _.isEmpty(platformParams[name])
            ? []
            : options.filter((x) => platformParams[name].includes(x.value)).map((i) => i.value);

    return (
        <Row key={name} type="flex">
            <Col lg={6} xl={size === "md" ? 6 : 4}>
                <span className="row-label">
                    {formatMessage({ id: "filtersKey." + name })}
                    {typeDescriptionFilter}
                </span>
            </Col>
            <Col lg={size === "md" ? 18 : 15} xl={size === "md" ? 18 : 17}>
                <UiMultiSelect
                    output="object"
                    onChange={(val) => handleMultiSelect(val, name)}
                    placeholder={formatMessage({ id: "filters.media_type.not_have" })}
                    list={options}
                    value={selectedX}
                    withoutImage
                    theme={theme}
                />
            </Col>
        </Row>
    );
};

export default MediaTypesField;
