import React, { useContext } from "react";
import PropTypes from "prop-types";
import { UiTableStateContext } from "../context/UiTableContext";

/**
 * پیاده سازی ستون های هر ردیف
 * @param {*node} children
 * @param {*string} className
 * @param {*string} field
 * @param {*object} item
 */
const UiTableCell = ({ children, className = "", field, item }) => {
    const tableState = useContext(UiTableStateContext);

    // اگر در فیلد جدول، رندر کرند محتوا وجود داشت، متد رندر فراخوانی گردد
    const { render, isSelectable } = tableState?.fields?.[field] || {};

    let child = children;

    if (render) child = render(item);

    return (
        <div className={`ui-table-cell ${className}`} style={{ userSelect: isSelectable ? "text" : "" }}>
            {child}
        </div>
    );
};
UiTableCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    field: PropTypes.string,
    item: PropTypes.object,
};
export default UiTableCell;
