import React from "react";
import { api } from "services";
import { FormattedSimpleMsg } from "GlobalFunctions";
import Loadings from "shared/loadings/Loadings";
import _ from "lodash";
import axios from "axios";
import "./ResourceSmartSearch.scss";
import { UiRadio, UiRadioGroup, UiTooltip, UiToast , UiSwitch } from "ui-components";
//تعریف متغیر مربوط به ای پی ای
let CancelToken = axios.CancelToken;
let source = CancelToken.source();
//
class ResourceSmartSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: true,
            searchLoading: false,
            smartSearchResultList: {},
            autoSmartSearchResultList: {},
            limitOfSmartItem: 10,
            activeItems: 0,
            searchType: "manual", // auto | manual,
            errorMsg: undefined,
        };

        this.getRecommends = this.getRecommends.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectWords = this.selectWords.bind(this);
        this.manageResult = this.manageResult.bind(this);
    }

    componentDidMount() {
        // پس از لود شدن کامپوننت، محاسبه نتایج رو انجام بده

        // برای محاسبه تعداد آیتم مجاز برای انتخاب کاربر
        const userData = api.userInfo().user.permissions;
        const limitOfSmartItem = userData.Variables.query_rules_words.int_value_1;
        this.setState({
            limitOfSmartItem,
        });

        this.getRecommends();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchValue, platform  } = this.props;

        const { isActive, searchType } = this.state;

        // اگر کارکتر سرچ تغییر کرد، برو به دیلی محاسبه کارکتر
        if (prevProps.searchValue !== searchValue) {
            this.refreshDelay();
        }

        // اگر وضعیت به اکتیو تغییر کرد، دوباره نتیجه را لود کن
        if (prevState.isActive !== isActive && isActive) {
            this.getRecommends();
        }

        // اگر وضعیت دستی و اتوماتیک عوض شد، نتایج جدید رو محاسبه و مجدد فایر کن به کال بک
        if (prevState.searchType !== searchType) {
            this.manageResult();
        }

        // اگر پلتفرم تغییر کرد، نتایج را خالی کن
        if (prevProps.platform !== platform) {
            this.setState(
                {
                    smartSearchResultList: {},
                    autoSmartSearchResultList: {},
                    activeItems: 0,
                },
                () => this.manageResult(),
            );
        }
          // اگر کامپوننت ریست شود
          if (this.props?.reset !== prevProps?.reset) {

            this.setState({
                    smartSearchResultList: {},
                    autoSmartSearchResultList: {},
                    activeItems: 0,
            },
            () => this.manageResult(),);
        }
    }

    //  دریافت کارکترهای ورودی سرچ با دیلی
    refreshDelay = _.debounce(() => {
        const { isActive } = this.state;

        if (isActive) {
            source.cancel("Operation canceled by the user change item.");

            CancelToken = axios.CancelToken;
            source = CancelToken.source();

            this.getRecommends(source);
        }
    }, 400);

    // ست کردن مقادیر پروپرتی در استیت
    handleChange(value, name) {
        this.setState({
            [name]: value,
        });
    }

    // دریافت نتایج از ای پی آی
    getRecommends(cancelation) {
        const { limitOfSmartItem } = this.state;

        const { searchValue, type } = this.props;

        const regex = /[a-z]/g;

        if (searchValue?.match(regex)) {
            return null;
        }

        this.setState({
            lastSearchValue: searchValue,
        });

        if (searchValue === "") return null;

        const params = {
            texts: searchValue,
            platform: "news",
        };

        this.setState({
            searchLoading: true,
        });

        let url = "/advanced_search/text_analyzer/recommends";
        if (type === "finglish") {
            // finglish
            url = "/advanced_search/text_analyzer/finglish";
        }

        api.post(url, params, null, { cancelToken: cancelation?.token })
            .then((response) => response.data.result.data)
            .then((response) => {
                let tempList = {};
                let tempListFirstItems = {};

                // ایجاد یک لیست از آیتم ها با مقدار 0 یعنی دیفالت انتخاب نشدند
                response &&
                    response.forEach((item, index) => {
                        // مکنه ریزالت به صورت آبجکت و همراه با نمره باشد
                        const tempItem = _.isObject(item) ? item.candidate : item;

                        tempList[tempItem] = 0;
                        tempListFirstItems[tempItem] = index < limitOfSmartItem ? 1 : 0;
                    });

                this.setState(
                    {
                        smartSearchResultList: tempList,
                        autoSmartSearchResultList: tempListFirstItems,
                        searchLoading: false,
                        errorMsg: undefined,
                    },
                    () => {
                        this.manageResult();
                    },
                );
            })
            .catch((error) => {
                UiToast.exceptions(error);
                if (error.response && error.response.status === 400) {
                    this.setState({
                        smartSearchResultList: {},
                        autoSmartSearchResultList: {},
                        searchLoading: false,
                        errorMsg: <FormattedSimpleMsg id="resource.smart_search.error" />,
                    });
                }
            })
            .then(() => {
                this.setState({
                    activeItems: 0,
                });
            });
    }

    // انتخاب آیتم اسمارت
    selectWords(data) {
        this.setState(
            (prevState) => {
                const { limitOfSmartItem } = prevState;
                let { smartSearchResultList, activeItems } = prevState;

                // select
                if (smartSearchResultList[data] === 0) {
                    if (activeItems >= limitOfSmartItem) {
                        console.log("out of range");
                    } else {
                        smartSearchResultList[data] = 1;
                        activeItems++;
                    }
                }
                // deSelect
                else {
                    smartSearchResultList[data] = 0;
                    activeItems--;
                }

                return {
                    smartSearchResultList,
                    activeItems,
                };
            },
            () => this.manageResult(),
        );
    }

    // نتیجه انتخاب شده ها را به فانکشن کال بک ارسال کن
    manageResult() {
        const { onChangeSmartSearch } = this.props;

        const { searchType, smartSearchResultList } = this.state;

        let result = [];
        if (searchType === "auto") {
            // اگر اتوماتیک بود، همه نتایج رو درنظر بگیر
            result = Object.keys(smartSearchResultList);
        } else {
            result = Object.keys(smartSearchResultList).filter((item) => smartSearchResultList[item] === 1);
        }

        // کال بک نتیجه
        onChangeSmartSearch(result);
    }

    render() {
        const {
            isActive,
            searchLoading,
            searchType,
            smartSearchResultList,
            limitOfSmartItem,
            activeItems,
            autoSmartSearchResultList,
            errorMsg,
        } = this.state;

        const displayActiveItems = searchType === "auto" ? autoSmartSearchResultList : smartSearchResultList;

        let displaySelectedItem = activeItems;
        if (searchType === "auto") {
            displaySelectedItem =
                Object.keys(smartSearchResultList).length > limitOfSmartItem
                    ? limitOfSmartItem
                    : Object.keys(smartSearchResultList).length;
        }

        return (
            <div className="resource-smart-search">
                <div className="smart-search">
                    <span className="section-switch">
                        <FormattedSimpleMsg id="search.smartSearch.activate" />
                        {/* سوئیچ فعالسازی در مودال انتخاب منبع در سرچ */}
                        <UiSwitch
                            name="switch-resource"
                            checked={isActive}
                            onChange={(e) => this.handleChange(e, "isActive")}
                        />
                    </span>

                    <div className={`smart-limit ${displaySelectedItem === limitOfSmartItem ? "full-limit" : ""}`}>
                        <span className="">{displaySelectedItem}</span>
                        <span className="sep">/</span>
                        <span className="">{limitOfSmartItem}</span>
                    </div>
                </div>
                {/* رادیو باتن انتخاب خودکار و دستی در مودال انتخاب منبع */}
                <UiRadioGroup
                    className="section-radio-group"
                    name="searchType"
                    onChange={(e) => this.handleChange(e, "searchType")}
                    defaultValue="auto"
                    value={searchType}
                >
                    <UiRadio key={0} className="radio-item" value="auto">
                        <FormattedSimpleMsg id="search.smartSearch.auto" />
                    </UiRadio>

                    <UiRadio key={1} className="radio-item" value="manual">
                        <FormattedSimpleMsg id="search.smartSearch.manual" />
                    </UiRadio>
                </UiRadioGroup>

                <div className={`section-result ${searchType === "auto" ? "auto" : ""}`}>
                    <Loadings type="button" spinning={searchLoading}>
                        {errorMsg && <span className="error-msg">{errorMsg}</span>}

                        {displayActiveItems &&
                            Object.keys(displayActiveItems).map((item, key) => (
                                <span
                                    key={key}
                                    className={`smart-item ${displayActiveItems[item] === 1 ? "selected" : ""} ${
                                        searchType == "manual" ? "manual" : "auto"
                                    }`}
                                    onClick={() => {
                                        if (searchType === "manual") this.selectWords(item);
                                    }}
                                >
                                    <UiTooltip title={item}>
                                        <div>{item}</div>
                                    </UiTooltip>
                                </span>
                            ))}
                    </Loadings>
                </div>
            </div>
        );
    }
}

export default ResourceSmartSearch;
