import React, { useContext } from "react";
// import PropTypes from "prop-types";
import { UiCheckbox }  from "ui-components";
import UiTableCell from "../cell/UiTableCell";
import { UiTableStateContext } from "../context/UiTableContext";
import useUiTableSelect from "../hooks/useUiTableSelect";

/**
 * هدر جدول جهت نمایش عناوین ستون ها
 */
export const UiTableHeader = () => {
    const tableState = useContext(UiTableStateContext);
    const { setSelectAll, findSelectedAll } = useUiTableSelect();
    const indeterminate = findSelectedAll();

    return (
        <div className="ui-table-header" style={tableState?.collWidth}>
            {tableState?.showFields?.map((field, key) => {
                // اگر فیلد مالتی‌سلکت تعریف شده بود چک‌باکس نمایش داده شود
                if (field === "multiSelect")
                    return (
                        <UiTableCell key={key}>
                            <UiCheckbox
                                id={"selectAll"}
                                indeterminate={indeterminate === "indeterminate"}
                                checked={!!indeterminate}
                                onChange={(checked) => setSelectAll(checked)}
                            />
                        </UiTableCell>
                    );

                // اگر برای فیلد تایتل ست شده بود نمایش داده شود
                if (tableState?.fields[field]?.title)
                    return <UiTableCell key={key}>{tableState?.fields[field]?.title}</UiTableCell>;

                // اگر هیچ شرطی برقرار نبود عنوان انگلیسی خود فیلد نمایش داده شود
                return <UiTableCell key={key}>{field}</UiTableCell>;
            })}
        </div>
    );
};
UiTableHeader.propTypes = {};
export default UiTableHeader;
