import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import usePrevious from "hooks/usePrevious";
import { isEqual } from "lodash";
import "./UiInput.scss";

const UiInput = (props) => {
    const {
        value,
        theme = "",
        name = "",
        className = "",
        placeholder = "",
        disabled = false,
        id,
        prefix,
        suffix,
        size = "md",
        type = "text",
        allowClear = false,
        loading = false,
        width,
        hasBorder = true,
        onChange,
        onClear,
        onEnter,
        onKeyUp,
        onBlur,
        background,
        autoFocus = false,
        autoSelect = false,
        uiRef,
        error = "",
        "selenium-id": seleniumId,
    } = props;
    let { style = {} } = props;
    if (width) {
        style = { ...style, ...{ width: width } };
    }
    if (background) {
        style = { ...style, ...{ background: background } };
    }
    const prevProps = usePrevious({ value });
    const [selectedValue, setSelectedValue] = useState(value || "");

    let inputRef = useRef(null);
    if (uiRef) {
        inputRef = uiRef;
    }

    useEffect(() => {
        if (autoSelect) {
            inputRef.current.select();
        }
    }, [autoSelect]);

    useEffect(() => {
        if (autoFocus) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    /**
     * آپدیت کردن مقدار ورودی
     */
    useEffect(() => {
        if (!isEqual(prevProps?.value, value)) {
            setSelectedValue(value || "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <>
            <div
                className={[
                    "ui-input",
                    theme,
                    className,
                    loading ? "loading" : "",
                    size,
                    hasBorder ? "has-border" : "",
                    !prefix ? "ui-input-no-prefix" : "",
                    disabled ? "disabled-mode" : "",
                ].join(" ")}
                style={style}
            >
                {prefix && <span className={[prefix ? "ui-input-prefix" : "", size].join(" ")}>{prefix}</span>}
                <input
                    value={selectedValue}
                    className={["ui-input-text", size].join(" ")}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    ref={inputRef}
                    id={id}
                    selenium-id={seleniumId}
                    onChange={(e) => {
                        const val = e.target.value;
                        setSelectedValue(() => {
                            typeof onChange === "function" && onChange(val);
                            return val;
                        });
                    }}
                    onKeyDown={(e) => {
                        const key = e.key || e.keyCode;
                        if (key === "Enter" && typeof onEnter === "function") {
                            e.preventDefault();
                        }
                    }}
                    onKeyUp={(e) => {
                        const key = e.key || e.keyCode;
                        if (key === "Enter" && typeof onEnter === "function") {
                            const val = e.target.value;
                            onEnter(val);
                        } else {
                            const val = e.target.value;
                            typeof onKeyUp === "function" && onKeyUp(val);
                        }
                    }}
                    onBlur={(e) => {
                        const val = e.target.value;
                        typeof onBlur === "function" && onBlur(val);
                    }}
                />
                {selectedValue && allowClear && (
                    <span className={[suffix ? "ui-input-clear" : "ui-input-clear-no-suffix", size].join(" ")}>
                        <i
                            className="icon icon-close-icon"
                            onClick={() => {
                                setSelectedValue("");
                                typeof onClear === "function" && onClear();
                            }}
                        />
                    </span>
                )}
                {suffix && <span className={[suffix ? "ui-input-suffix" : "", size].join(" ")}>{suffix}</span>}
            </div>
            {error && <div className="ui-input error">{error}</div>}
        </>
    );
};

UiInput.propTypes = {
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    value: PropTypes.string,
    theme: PropTypes.oneOf(["dark", "light"]),
    name: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    prefix: PropTypes.node,
    suffix: PropTypes.node,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    type: PropTypes.string,
    allowClear: PropTypes.bool,
    loading: PropTypes.bool,
    width: PropTypes.any,
    hasBorder: PropTypes.bool,
    style: PropTypes.object,
    background: PropTypes.any,
    autoFocus: PropTypes.bool,
    autoSelect: PropTypes.bool,
    uiRef: PropTypes.any,
    error: PropTypes.string,
    "selenium-id": PropTypes.string,
};

export default UiInput;
