import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { FormattedDate, FormattedMessage } from "react-intl";
import Loadings from "../../loadings/Loadings";
import { UiButton } from "ui-components";
import moment from "moment";

const ShareDashboardToDepartmentNotification = ({
    itemData,
    removeNotification,
    notifId,
    loading = { [notifId]: false },
    generateDate = moment().unix(),
}) => {
    const {
        dashboard_usage = "assignment",
        shared_by = { name: "محمد", last_name: "دربندی" },
        dashboard_name = "نام داشبورد",
        dashboard_id = 1,
    } = itemData;
    
    const generate_date = moment(new Date(generateDate * 1000)).format("YYYY-MM-DD HH:mm");

    return (
        <Loadings type="box" spinning={!!loading[notifId]}>
            <div className="post-notification">
                <div className="calendar">
                    <div className="right-section">
                        <bdi>
                            <span style={{ whiteSpace: "nowrap" }}>
                                {<FormattedDate value={generate_date.split(" ")[0]} />} - {generate_date.split(" ")[1]}
                            </span>
                        </bdi>
                    </div>

                    <div className="left-section">
                        {notifId && (
                            <UiButton
                                type="icon"
                                size="xxs"
                                className="icon icon-close-icon "
                                onClick={() => {
                                    removeNotification(notifId);
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="assign-notif-body">
                    <div>
                        <i className="icon icon-share" />
                        <bdi>
                            داشبوردی با شما به اشتراک گذاشته شد (
                            <FormattedMessage id={`notification.${dashboard_usage}`} />)
                        </bdi>
                    </div>

                    <div className="row">
                        <span className="label"> نام باکس: </span>
                        <span> {dashboard_name} </span>
                    </div>

                    <div className="row">
                        <span className="label"> توسط: </span>
                        <span> {shared_by.name + " " + shared_by.last_name} </span>
                    </div>

                    <div className="row">
                        <span className="label">تاریخ اشتراک: </span>
                        <bdi>
                            {<FormattedDate value={generate_date.split(" ")[0]} />} - {generate_date.split(" ")[1]}
                        </bdi>
                    </div>

                    <NavLink target="_blank" to={"/" + dashboard_usage + "/" + dashboard_id}>
                        <div className="view">
                            مشاهده <FormattedMessage id={`notification.${dashboard_usage}`} />
                        </div>
                    </NavLink>
                </div>
            </div>
        </Loadings>
    );
};

ShareDashboardToDepartmentNotification.propTypes = {
    platform: PropTypes.string,
    avatar: PropTypes.string,
};

export default ShareDashboardToDepartmentNotification;
