import React, { Component } from "react";
import { api } from "services";

export default class CatchWrapper extends Component {
    componentDidCatch(error, errorInfo) {
        console.log("error : ", error);
        console.log("errorInfo : ", errorInfo);
        const params = {
            url: window.location.href,
            message: error.toString(),
        };
        api.post("/frontend_logger/api/v1/log", params);
    }
    render() {
        return <> {this.props.children} </>;
    }
}
