// ثابتهای اکشنهای مربوط به سرچ
export const SEARCH_LOAD_RESULTS = "SEARCH_LOAD_RESULTS"
export const SEARCH_CHANGE_IN_PLATFORM = "SEARCH_CHANGE_IN_PLATFORM"
export const SEARCH_LOAD_SETTINGS = "SEARCH_LOAD_SETTINGS"
export const SEARCH_CHANGE_IN_GENERALSETTINGS = "SEARCH_CHANGE_IN_GENERALSETTINGS"
export const SEARCH_LOAD_PRESETS = "SEARCH_LOAD_PRESETS"
export const SEARCH_GET_PLATFORM_PARAMS = "SEARCH_GET_PLATFORM_PARAMS"
export const SEARCH_SET_PLATFORMS = "SEARCH_SET_PLATFORMS"
export const SEARCH_SET_PHRASE = "SEARCH_SET_PHRASE"
export const SEARCH_GET_PRESETS = "SEARCH_GET_PRESETS"
export const SEARCH_SET_PLATFORM_ACTIVITY_STATE = "SEARCH_SET_PLATFORM_ACTIVITY_STATE"
export const SEARCH_CHANGE_DEFAULT_PRESET = "SEARCH_CHANGE_DEFAULT_PRESET"
export const SEARCH_DELETE_ON_PAGE_CHANGE = "SEARCH_DELETE_ON_PAGE_CHANGE"


// ثابتهای اکشنهای مربوط به کاربر
export const USER_GET_USER_INFO = "USER_GET_USER_INFO"
export const BOX_CHANGE_PUBLIC_SETTINGS = "BOX_CHANGE_PUBLIC_SETTINGS";
export const BOX_SET_STEP = "BOX_SET_STEP";
export const WIDGET_RESIZE_STOP = "WIDGET_RESIZE_STOP";
export const DASHBOARD_SAVE_CACHE = "DASHBOARD_SAVE_CACHE";
export const DASHBOARD_UPDATE_CACHE = "DASHBOARD_UPDATE_CACHE";
export const DASHBOARD_RESET_CACHE = "DASHBOARD_RESET_CACHE";

// ثابتهای اکشنهای مربوط به دانلود اکسل
export const EXCEL_ADD = "EXCEL_ADD"
export const EXCEL_LIST = "EXCEL_LIST"
export const EXCEL_CANCEL = "EXCEL_CANCEL"


//ثابت های اکشنهای فیلتر سراسری
export const FILTER_CHANGE = "FILTER_CHANGE";

//ثابت های اکشن کلمات کلیدی
export const UPDATE_RULES = "UPDATE_RULES";

//ثابت های اکشن تگ‌ها
export const UPDATE_TAGS = "UPDATE_TAGS";

// تک محتوا
export const GET_TAG_CONTENT = "GET_TAG_CONTENT";