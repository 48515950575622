import { SEARCH_LOAD_RESULTS, SEARCH_DELETE_ON_PAGE_CHANGE } from '../actions/types'


const initialState = {
  dataReceived: false,
  platforms: []
};

const searchResultsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // load settings for all platforms
    case SEARCH_LOAD_RESULTS:
      //اگر پلت فرم را در لیست پلتفرم ها داریم (روی پیجینیشن کلیک شده) لیست پلت فرمها تغییر نکند
      // در غیر اینصورت پلت فرم جدید اضافه شود
      const newplaforms =
        (payload && payload.platform && state.platforms.find(x => x.name === payload.platform.name))
          ?
          state.platforms.map(platform => {
            if (platform.name === payload.platform.name)
              return { name: payload.platform.name, totalCount: payload.platform.totalCount || 0 }
            else
              return platform
          })
          :
          [
            ...state.platforms,
            { name: payload.platform.name, totalCount: payload.platform.totalCount || 0 }
          ]

      state = {
        ...state,
        dataReceived: 'done',
        [payload.platform.name]: [...payload.content],
        platforms: [...newplaforms]
      };
      break;

    case SEARCH_DELETE_ON_PAGE_CHANGE:
      state = {
        ...state,
        [payload]: [],
        dataReceived: 'paging'
      }
      break;

    default:
      break;
  }
  return state;
};

export default searchResultsReducer;
