import React, { lazy, Suspense } from "react"
import { connect } from 'react-redux';
import Loadings from "shared/loadings/Loadings";
import { filterChange } from "../../../../actions/globalFilterAction"
import {isEmpty} from "lodash"
import { FormattedSimpleMsg } from "GlobalFunctions";

// لود کردن کامپوننت یلکت کیورد
const QueryField = lazy(() => import('../../../queryBuilder/QueryField'));


const defaultQuery = {
    parsedQuery         : {},
    rawQuery            : "",
    selectedKeyword     : "",
    hasCustomQuery      : false
};

//  فیلتر سلکت کلمات کلیدی در هدر 
class GlobalKeywordsFilter extends React.Component {

    constructor(props) {
        super(props);

        this.changeSelect = this.changeSelect.bind(this)
    }

 
    // انتخاب یک کیورد در سلکت
    changeSelect(query) {

        const filterState = {
            query: query.selectedKeyword,
            query_field: query
        }

        // با استفاده از دیسپچر یک فانکشن را اجرا کن
        this.props.filterChange(filterState, false)

    }


    render() {

        const {
            keywords,
            query,
            theme,
            size
        } = this.props;


        const selectedQuery = !isEmpty(keywords) ?  keywords.find(item => item.id === query) : defaultQuery;

        return (
            <div className="globalKeywords">

                <FormattedSimpleMsg id="filters.global_keyword" /> 
                <Suspense fallback={<Loadings type="button" style={{ height: "25px", width: "100px", marginTop: '3px' }} spinning={true} />}>
                    <QueryField
                        value={selectedQuery}
                        onChange={(val) => this.changeSelect(val, "query")}
                        fieldType="block"
                        selectOnly={true}
                        theme={theme}
                        size={size}
                    />
                </Suspense>


            </div>
        )
    }
}



const mapStateToProps = store => {
    return {
        keywords: store.globalFilter.rules,
        query: store.globalFilter.query // selected keyword

    }
}
const mapDispatchToProps = dispatch => {
    return {
        filterChange: (data, localFlag, apiFlag) => dispatch(filterChange(data, localFlag, apiFlag))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalKeywordsFilter)

