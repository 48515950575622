import React, { useState } from "react";
import { Icon, Upload } from "antd";
import { root } from "GlobalVariables";
import { api } from "services";
import { UiToast } from "ui-components";
import PropTypes from "prop-types";

const FileUploader = ({ option }) => {
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState();
    const userInfo = api.userInfo();
    const token = userInfo.access_token;
    const userId = userInfo.user.id;

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            UiToast.error("فقط پسوند‌های json, zip, rar, 7zip مجاز است");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            UiToast.error("حجم فایل باید کمتر از 2 MB! باشد");
        }
        return isJpgOrPng && isLt2M;
    };
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleUpload = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        } else if (info.file.status === "done") {
            setLoading(false);
            setFile(info.file);
            getBase64(info.file.originFileObj, (imageUrl) => {
                // TODO
                option.value = {
                    name: info.file.name,
                    img: imageUrl,
                };
            });
        } else if (info.file.status === "error") {
            setLoading(false);
            UiToast.error(`${info.file.name} با خطا مواجه شد`);
        }
    };
    if (file) {
        return (
            <div className="ui-form-uploader-card">
                {file.name}
                <i
                    className="icon icon-close-icon"
                    onClick={() => {
                        delete option.value;
                        setFile(null);
                    }}
                />
            </div>
        );
    }
    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className="ui-form-uploader"
            showUploadList={false}
            action={`${root}/user/avatars/${userId}?_method=put`}
            headers={{ Authorization: token }}
            beforeUpload={beforeUpload}
            onChange={handleUpload}
        >
            <div>
                <Icon type={loading ? "loading" : "camera"} />
                <div className="ant-upload-text">بارگذاری</div>
            </div>
        </Upload>
    );
};
FileUploader.propTypes = {
    option: PropTypes.object,
};

export default FileUploader;