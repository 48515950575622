import { WindowLocation } from "GlobalVariables";
import { ReactGAM } from "google-analytics/googleAnalytics";
import React, { useState } from "react";
import { withRouter } from "react-router";
import { api } from "services";
import { UiButton, UiToast } from "ui-components";
import "./NewVersionBar.scss";

const NewVersionBar = ({ history }) => {
    const [canShow, setCanShow] = useState(true);

    let userInfo = api.userInfo();

    // get current address
    const access_token = userInfo?.access_token;
    // get current domain address
    const domain = WindowLocation?.origin;

    const params = new URLSearchParams(history?.location?.search);

    const referrerParam = params.get("referrer");
    const accessTokenParam = params.get("access_token");

    //اگر کاربر از سستم دیگر وارد سیستم شد و لاگین نکرده بود به صفحه لاگین برود
    if (referrerParam && accessTokenParam) {
        if (!access_token) {
            history.push("/login");
        } else {
            history.push(window.location?.pathname);
        }
    }

    const onHandleClick = () => {
        ReactGAM().trackEvent("new_version_bar", "content", "confirm_btn");
        api.get("/search/queries", { no_paginate: 1 })
            .then((response) => response?.data?.result?.data)
            .then((data) => {
                if (data?.length > 0) {
                    onHandleClose();
                    window.open(
                        `https://v3.dataak.com/home?referrer=${domain}&query_id=${
                            data[data?.length - 1]?.id
                        }&access_token=${access_token}`,
                        "_blank",
                    );
                } else {
                    history.push("/query-rules/add");
                    UiToast.error(
                        "جهت بهرمندی از امکانات سامانه جدید دیتاک بایستی ابتدا موضوع (کوئری) مورد نظر خود را ایجاد نمایید.",
                    );
                }
            });
    };

    const onHandleClose = () => {
        ReactGAM().trackEvent("new_version_bar", "content", "close_btn");
        localStorage.setItem("hidden-version-bar", true);
        setCanShow(false);
    };

    const hidden_version_bar = localStorage.getItem("hidden-version-bar");
    const domainAaccess =
        domain.includes("dashboard.dataak.com") ||
        domain.includes("pr.dataak.com") ||
        domain.includes("release.dataak.com") ||
        domain.includes("develop.dataak.com") ||
        domain.includes("localhost");

    return canShow && domainAaccess && hidden_version_bar !== "true" ? (
        <div className="new-version-bar">
            <div className="new-version-bar-close-container">
                <div className="new-version-bar-close-container-icon" onClick={onHandleClose}>
                    <i className="icon icon-close-icon" />
                </div>
            </div>
            <div className="new-version-bar-container">
                <div className="new-version-bar-container-title">
                    از شما دعوت می کنیم تا از نسخه آزمایشی سامانه جدید روابط عمومی دیتاک بازدید نمایید و نظرات و
                    پیشنهادات خود را با ما در میان بگذارید
                </div>
            </div>
            <div className="new-version-bar-container-actions">
                <UiButton onClick={onHandleClick} type="button" size="lg" className="btn  btn-outline-light">
                    نسخه جدید
                </UiButton>
            </div>
        </div>
    ) : null;
};

NewVersionBar.defaultName = "NewVersionBar";

NewVersionBar.defaultProps = {};

NewVersionBar.propTypes = {};
export default withRouter(NewVersionBar);
