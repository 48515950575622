import React from "react";
import PropTypes from "prop-types";
import { UiInput } from "ui-components";
import "./UiForm.scss";
import FileUploader from "./components/FileUploader";
import Row from "./components/Row";
import Loadings from "shared/loadings/Loadings";

const UiForm = ({ options = [], tableStyle = {}, invalidFields = [], loading }) => {
    return (
        <Loadings spinning={loading} type={"page"}>
            <form className="ui-form" style={{ width: tableStyle?.form?.width }}>
                {options?.map((option, index) => {
                    // اگر فرم درخواست رندر سمت خودش را داشت
                    if (option?.render) {
                        return (
                            <Row key={index} option={option} invalidFields={invalidFields} tableStyle={tableStyle}>
                                {option.render(option)}
                            </Row>
                        );
                    }
                    // اگر فرم از توه استرینگ بود
                    else if (option.type === "string") {
                        return (
                            <Row key={index} option={option} invalidFields={invalidFields} tableStyle={tableStyle}>
                                <UiInput
                                    type="text"
                                    value={option.value}
                                    placeholder={option.placeholder ?? option.label}
                                    onChange={(e) => (option.value = e)}
                                    style={option?.style}
                                />
                            </Row>
                        );
                    }
                    // اگر فرم از توع فایل آپلودر بود
                    else if (option.type === "fileupload") {
                        return (
                            <Row key={index} option={option} invalidFields={invalidFields} tableStyle={tableStyle}>
                                <FileUploader option={option} />
                            </Row>
                        );
                    }
                })}
            </form>
        </Loadings>
    );
};

UiForm.propTypes = {
    options: PropTypes.array,
    tableStyle: PropTypes.object,
    invalidFields: PropTypes.array,
    loading: PropTypes.bool,
};

export default UiForm;
